// import { Message, MessageType } from "common/types";

// type Props = {
//     message: Message;
// }

// const SystemMessage = ({ message }: Props) => {
//     return (
//         <div className="body-17 font-weight-bolder color-gulf-blue" >
//             Interaction {message.type === MessageType.SET_EXPIRED ? "expired" : "completed"}
//         </div>
//     )
// }

// export default SystemMessage;


import { Message, MessageType } from "common/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectActiveInteractionId,
  selectInteractions,
} from "../selectors";

type Props = {
    message: Message;
}

const SystemMessage = ({ message }: Props) => {
    const interactions = useSelector(selectInteractions);
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  

  useEffect(() => {
    setActiveInteraction(
      interactions
        ?.filter((interaction: any) => interaction.id === activeInteractionId)
        .pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions]);
    return (
        <>
            {activeInteraction && activeInteraction?.type === "EXPERT_ADVICE" ? (
            <div className="body-17 font-weight-bolder color-gulf-blue" >
              {message.type === MessageType.SET_EXPIRED ? "expired" : "No messages have been sent for 72 hours - is the interaction over?"}
            </div> ):( <div className="body-17 font-weight-bolder color-gulf-blue" >
                {message.type === MessageType.SET_EXPIRED ? "Interaction expired" : "The scheduled time for this interaction has now passed"}
            </div>
            )}
         </>
    )
}

export default SystemMessage;
