import { Message } from "common/types";
import { selectUser } from "features/auth/selectors";
import { User} from "common/types";
import { useSelector } from "react-redux";
type Props = {
    message: Message;
};

const RequestAdditionalTime = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if(message.sender === "SYSTEM") return null;
    return (
        <div className="color-gulf-blue interaction-texts">
            <span>{user?.id === message.sender.id ? "You have" : message.sender.firstName+" has"}</span>
            <span>&nbsp;requested additional times</span>
        </div>
    )
}

export default RequestAdditionalTime;
