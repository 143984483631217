import styled from "styled-components";
import InteractionTile from "./InteractionTile";

type Props = {
  username: string;
  handleInteractionOpen: any;
  activeInteractions: any;
  pastInteractions: any;
  awaitingFeedbackInteractions: any;
};

const InteractionList = ({ handleInteractionOpen, activeInteractions, awaitingFeedbackInteractions, pastInteractions, username }: Props) => {
  return (
    <StyledWrapper>
      {activeInteractions.length === 0 && pastInteractions.length === 0 &&
        <div className="no-interaction-message interaction-message-spacing">You have not interacted with {username} yet.</div>
      }
      {activeInteractions.length !== 0 &&
        <div className="active">
          <h3>Active Interactions</h3>
          {activeInteractions.length > 0 &&
            (activeInteractions.map((interaction: any) => (
              <InteractionTile
                key={interaction.id}
                interaction={interaction}
                handleClick={() => handleInteractionOpen(interaction.id)}
              />
            )))}
        </div>}
        {awaitingFeedbackInteractions.length !== 0 &&
        <div className="past-interactions">
          <h3>Reviews Pending</h3>
          {awaitingFeedbackInteractions.length > 0 &&
            (awaitingFeedbackInteractions.map((interaction: any) => (
              <InteractionTile
                key={interaction.id}
                interaction={interaction}
                handleClick={() => handleInteractionOpen(interaction.id)}
              />
            )))}
        </div>}
      {pastInteractions.length !== 0 &&
        <div className="past-interactions">
          <h3>Past Interactions</h3>
          {pastInteractions.length > 0 &&
            (pastInteractions.map((interaction: any) => (
              <InteractionTile
                key={interaction.id}
                interaction={interaction}
                handleClick={() => handleInteractionOpen(interaction.id)}
              />
            )))}
        </div>}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div` 
  
  .past-interaction-spacing {
    margin-top: 80px;
  }

  .no-active-interaction {
    font-size: 16px;
  }

  .interaction-message-spacing {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .past-interactions {
    margin-top: 80px;
    margin-bottom: 40px;
  }

`;

export default InteractionList;
