import { Interaction, InteractionState } from 'common/types';
import { getStartOfMonth } from 'common/utils';
import styled from "styled-components";
import { Box } from '@mui/material';

export default function ActivityRemainingCard({ interactions }: any) {

  const thisMonthInteractionCount: any = {
    "CAREER_CHAT": 0,
    "MOCK_INTERVIEW": 0,
    "EXPERT_ADVICE": 0,
    "MENTORING": 0
  }

  const allowedExpertAdvice = 5;
  const allowedCareerChats = 5;
  const allowedMockInterviews = 4;

  const now = new Date();
  const startOfMonth = getStartOfMonth(now);

  interactions.forEach((interaction: Interaction) => {
    if (interaction.state !== InteractionState.EXPIRED && interaction.state !== InteractionState.DECLINED) {
      if (interaction.createdAt >= startOfMonth) {
        thisMonthInteractionCount[interaction.type]++;
      }
    }
  })


  return (
    <StyledWrapper>
      <Box className="activity-tracker">
        <p className="body-14 activity-remaining my-0">Activity Remaining This Month</p>
        <p className="options"> <span className="tracking-numbers">{allowedExpertAdvice - thisMonthInteractionCount["EXPERT_ADVICE"]}/{allowedExpertAdvice}</span> Ask an Experts</p>
        <p className="options"> <span className="tracking-numbers">{allowedCareerChats - thisMonthInteractionCount["CAREER_CHAT"]}/{allowedCareerChats}</span> Career Chats</p>
        <p className="options"> <span className="tracking-numbers">{allowedMockInterviews - thisMonthInteractionCount["MOCK_INTERVIEW"]}/{allowedMockInterviews}</span> Mock Interviews</p>
       {/* Below code commented because of SMN 76 */}
        {/* <span className="color-dark-blue contact">Contact Programme Coordinator for more</span> */}
      </Box>
    </StyledWrapper>
  );
}


const StyledWrapper = styled.div`
  height: 100%;
  // padding: 0 12px;
  width: 100% !important;
  margin: 0 !important;
  .activity-tracker {
    height: 100%;
    min-height: 215px;
    padding: 20px 20px;
    padding-top: 24px !important;
    margin-bottom: 20px !important;
    border-radius: 5px !important;

    .activity-remaining {
      font-size: 20px;
      text-transform: capitalize;
    }
  },
  .options {
    font-size: 16px !important;
    margin: 16px 0;
  },
  .contact {
    display: inline-block;
    margin-top: 13px;
  }
`;