import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #F6FAFF;
    font-family: Nunito Sans, sans-serif !important;
    scroll-behavior: smooth;
  }
  .App {
    min-height: 100%;
  }
  .fhEClv{
    padding-right: 80px !important;
  }
  // spacing
  .mt-0 {
    margin-top: 0;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mt-2 {
    margin-top: 20px;
  }
  .mb-2 {
    margin-bottom: 20px;
  }
  .mb-3 {
    margin-bottom: 30px;
  }
  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-6 {
    margin-left: 6px;
    margin-right: 6px;
  }
  .mr-auto {
    margin-right: auto;
  }
  .ml-auto {
    margin-left: auto !important;
  }
  .mx-auto {
    margin: auto;
  }
  // display
  .d-flex {
    display: flex;
  }
  .d-block {
    display: block;
  }
  // Typography
  h1, .heading-1 {
    font-size: 38px;
    line-height: 47px;
    @media (max-width: 600px) {
      font-size: 28px;
      line-height: normal;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    }
  .body-11 {
    font-size: 11px;
    line-height: 15px;
  }
  .body-12 {
    font-size: 12px;
    line-height: 18px;
  }
  .body-13 {
    font-size: 13px;
    line-height: 18px;
  }
  .body-14 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }
  .body-15 {
    font-style: normal;
    font-size: 15px;
    line-height: 19px;
    @media (max-width: 600px) {
      font-size: 12.5px;
    };
  }
  .body-17 {
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    @media (max-width: 700px) {
      font-size: 15px;
    };
  }
  .body-18 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
  }
  .body-20 {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    @media (max-width: 900px) {
      font-size: 17.5px;
    };
  }
  .body-22 {
    font-size: 22px;
    font-weight: 600;
    line-height: 44px;
  }
  .body-24 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    @media (max-width: 600px) {
      font-size: 18px;
      line-height: normal;
    }
  }
  .header-links {
    font-size: 15px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0px;
  }
  .interaction-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .font-family-nunito {
    font-family: Nunito Sans, sans-serif !important;
  }
  // font-weight
  .font-weight-bold {
    font-weight: bold;
  }
  .font-weight-bolder {
    font-weight: bolder;
  }
  .font-weight-normal {
    font-weight: normal;
  }
  .font-weight-lighter {
    font-weight: lighter;
  }
  // cursor
  .cursor-pointer {
      cursor: pointer;
  }
  // space around even between
  .space-evenly {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .space-around {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .justify-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .float-right {
    float: right;
  }
  .float-right {
    float: right;
  }
  .content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  // background colors
  .background-dark-blue {
    background-color: #464FD0;
  }
  .background-black {
    background-color: #000000;
  }
  .background-white {
    background-color: #FFFFFF;
  }
  .background-light-navy {
    background-color: #9EA5EE;
  }
  .background-card-blue {
    background-color: #DEE1FF;
  }
  .background-sea-blue {
    background-color: #5EB8D9;
  }
  .background-persian-green {
    background-color: #009D90;
  }
  .background-gulf-blue {
    background-color: #304563;
  }
  .background-ceru {
    background-color: #0077B7;
  }
  .background-white-smoke {
    background-color: #F7F7F7;
  }
  // image fluid
  .img-fluid {
    width: 100%;
    height: auto;
  }
  .view-request-btn {
    max-width: 150px;
    background-color: #3f51b5 !important;
    @media (max-width: 600px) {
      font-size: 2px;
    }
  }
  .send-logo {
    @media (max-width: 600px) {
      line-height: normal;
    }
  }
  // text colors 
  .color-dark-blue {
    color: #464FD0;
  }
  .color-black {
    color: #000000;
  }
  .color-white {
    color: #FFFFFF;
  }
  .color-light-navy {
    color: #9EA5EE;
  }
  .color-card-blue {
    color: #DEE1FF;
  }
  .color-grey {
    color: #9F9D9D;
  }
  .color-gulf-blue {
    color: #304563;
  }
  //text alignment
  .text-center {
    text-align: center;
  }
  //text tranform
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  // border radius
  .border-radius-5 {
    border-radius: 5px;
  }
  .MuiDrawer-paperAnchorLeft {
    width: 100%;
    background-color: #F6FAFF !important;
  }
  .request-modal-header {
    font-size: 40px;
    line-height: 111.3%;
    color: #304563;
  }
  .request-modal-input .MuiFilledInput-multiline, .request-modal-input:hover .MuiFilledInput-multiline:focus {
    background: #F7F7F7;
  }
  .action-section {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .no-interaction-message {
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    color: #304563;
  }
  .object-fit-cover {
    object-fit: cover;
  }
  // User card styles 
    .usercard-link {
      text-decoration: none;
    }
    .profile-avatar {
      width: 60px;
      height: 60px;
    }
    .profile-info-spacing {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .usercard-name {
      line-height: 21px;
    }
    .available-for {
      font-size: 14px;
      font-style: normal;
      font-weight: bolder;
      line-height: 11px;
      letter-spacing: 0.5px;
      padding-right: 8px;
    }
    .available-for-details {
      font-size: 15px;
      line-height: 20px;
      /* display: flex; */
      align-items: center;
      color: #304563;
      margin: 10px 0px;
      font-weight: 600;
    }
    .view-btn {
      font-size: 13px !important;
      display: block !important;
      margin: 3px auto 0 auto !important;
      text-transform: capitalize !important;
    }
    .request-card {
      min-height: 150px;
      .MuiCardContent-root {
        padding-bottom: 8px !important;
      }
    }
    .divider {
      margin-top: 20px;
    }
    .bookmark {
      position: absolute;
      top: 10px;
      right: 15px;
      width: 12px;
    }
    .username-spacing {
      .card-height {
        height: 100%;
      }
    }
    .pwd-reset-dialog {
      .MuiDialog-paperWidthSm {
        padding: 30px 50px;
        @media (max-width: 600px) {
          padding: 30px 0px;
        }
      }
      #form-dialog-title h2, .MuiDialogContent-root p {
        font-family: Nunito Sans;
        font-weight: 400;
        color: #304563;
        letter-spacing: 0px;
      }
      #form-dialog-title h2 {
        font-size: 40px;
        line-height: 45px;
      }
      .MuiDialogContent-root p{
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 50px;
      }
      .reset-email {
        width: 100%;
        max-width: 375px;
        height: 50px;
        .MuiOutlinedInput-marginDense {
          height: 50px;
        }
      }
      .MuiOutlinedInput-notchedOutline .PrivateNotchedOutline-legendLabelled-7 {
          span {
            position: relative;
            top: 3px;
          }
        }
        .submitEmail {
          margin-right: auto;
          margin-left: 15px !important;
          width: 100%;
          max-width: 375px;
          height: 50px;
          background: #464FD0;
          @media (max-width: 600px) {
            margin-right: 15px !important;
          }
        }
        .cancel-popup {
          position: absolute;
          top: 20px;
          right: 20px;
        }
    }
    .profile-section {
      position: relative;
      left: 50px;
      .profile-avatar {
        position: relative;
        top: 18px;
      }
      .text-decoration-none {
        text-decoration: underline solid white;
        text-transform: capitalize;
      }
      .participant-details {
        margin-left: 20px;
        margin-top: 27px;
      }
    }
    .interaction-modal {
      max-width: 60% !important;
      @media (max-width: 992px) {
        max-width: 100% !important;
      }
    }
    // Professional User registration form
    .registration-box-shadow {
      box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 20%);
      background-color: white;
      border-radius: 20px;

    .MuiFormLabel-root {
      margin-bottom: 1rem;
    }
    .MuiGrid-item{
      margin-bottom: 1rem;
      padding: 0;
      @media (max-width: 600px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
      .prof-registration-heading {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
      }
    }
    .rhf-errors {
      font-size: 0.85rem;
      color: #f44336;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.66;
      letter-spacing: 0.03333em;
    }
    .toast-container {
      max-width: 600px;
    }
    .editProfile-drawer {
    .MuiContainer-maxWidthLg {
      padding-bottom: 150px;
    }
      .MuiDrawer-paperAnchorRight {
        width: 80%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .profile-picture {
        width: 80px;
        height: 80px;
      }
      .upload-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
      }
      .autocomplete-textfield {
        margin-top: 10px;
      }
      .flex-end {
        display: flex;
      flex-direction: column;
      justify-content: flex-end;
      }
      .flex-center {
        justify-content: flex-end;
        display: flex;
        margin: auto;
        @media (min-width: 600px) {
          display: flex;
          justify-content: center;
          margin: auto;
        }
      }
      .flex-start {
        justify-content: flex-start;
        display: flex;
        margin: auto;
      }
      .btn-subcontainer {
        display: flex;
        justify-content: center;
        margin-left: 40px;
        @media (max-width: 600px) {
          margin-left: unset;
        }
      }
      .btn-section {
        padding-bottom: 30px;
        padding-top: 30px;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 80%;
        background-color: white;
        z-index: 1;
        box-shadow: 1px -4px 10px -3px rgba(0,0,0,0.65);
        -webkit-box-shadow: 1px -4px 10px -3px rgba(0,0,0,0.65);
        -moz-box-shadow: 1px -4px 10px -3px rgba(0,0,0,0.65);
        @media (max-width: 600px) {
          width: 100%;
          justify-content: center;
          display: flex;
        }
        button {
          width: 100%;
          max-width: 250px;
        }
      }
      .cancel-btn {
        margin-right: 10px;
        color: #3f51b5 !important;
        border: 1px solid rgba(63, 81, 181, 0.5) !important;
      }
      .update-btn {
        margin-left: 10px;
      }
      .gender-radio-groups {
        .MuiRadio-colorSecondary.Mui-checked {
          color: #464FD0;
        }
      }
      .line-height-normal {
        line-height: 20px;
      }
      .show-on-profile {
        display: none;
        @media (min-width: 600px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-left: auto;
          margin-right: auto;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0px;
          color: #464FD0;
        }
      }
      .switch-position {
        display: flex;
        justify-content: flex-end;
      }
      .close-btn {
        position: absolute;
        top: 30px;
        right: 50px;
    }
      .edit-profile {
        font-size: 22px;
      }
      .blue-checkbox {
        color: #464FD0 !important;
      }
      .top-bottom-spacing {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .top-spacing {
        margin-top: 10px;
      }
    }
    input:checked + span {background: #ccf; border-color: #ccf;}

  .date-time-section-section {
    display: flex;
    justify-content: space-around;
  }
  .date-time-input {
    background: rgba(158, 165, 238, 0.1);
    margin-bottom: 20px !important;
    margin-right: 20px;
    .MuiOutlinedInput-input {
      height: 15px;
      border: 1px solid rgba(158, 165, 238, 0.2);
      color: #304563;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      border-right: none;
    }
  }
  .MuiOutlinedInput-notchedOutline{
    border-color : #3f51b5 !important;
  }
  
  .calendar-icon {
    margin-left: 20px !important;
    color : #3f51b5 !important;
  }
  .date-time{
    color : #3f51b5 !important;
    border :1px solid rgba(63, 81, 181, 0.5) !important;
    padding-left:10px !important;
    padding-right:10px !important;
  }
  .react-datepicker__header {
    background-color: rgb(241, 242, 255);
    border-bottom: 1px solid #d5d8fc;
  }
  .react-datepicker {
    border: 1px solid #d5d8fc;
  }
  .react-datepicker__time-container {
    border-left: 1px solid #d5d8fc;
  }
  .month-picker {
    margin-bottom: -0.7rem !important;
    .react-datepicker-popper {
      z-index: 2;
    }
    .react-datepicker-wrapper {
      display: block;
    }
    .react-datepicker__input-container {
      display: inline-flex;
    }
    .reactDatePicker {
      height: 50px;
      width: 100%;
      border: solid;
      border-width: 1px;
      border-radius: 5px;
      border-color: #b0bab6;
      font-size: inherit;
      padding-left: 14px;
    }
  }
  .font-style-italic {
    font-style: italic;
  }
  .alumni-pill {
    width: 65px;
    height: 23px;
    border-radius: 20px;
    color: white;
    background-color: #9ca1fd;
    text-align: center;
  }
  .MuiRating-visuallyhidden{
    // position:unset !important;
    // font-size:13px !important;
    // width: 10px !important;
    // word-break: break-word !important;
    // word-wrap: break-word;
    line-height:0.5 !important;
  }
  .css-1qqgbpl-MuiRating-root .MuiRating-visuallyHidden{
    position:unset !important;
    font-size:13px !important;
    width: 10px !important;
    color :black !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .MuiSvgIcon-root{
    // height:1.5em !important;
    text-align: center !important;
    width: 4.5em !important;
  }
  .MuiRating-icon{
    display: flex;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    justify-content: space-evenly !important;
    margin-bottom:10px !important;
  }
  .MuiRating-label{
    // white-space: nowrap !important;

  }
  .MuiRating-root{
    text-align: center !important;
    line-height:.8 !important;
    margin-top:15px !important;
  }
  .rating .MuiRating-pristine{
    display: none !important;
  }
  .MuiTypography-h6 {
    color: rgba(0, 0, 0, 0.87);
    text-align:center;
    font-size:18px !important;
    font-weight:bold !important;
  }
  .MuiFilledInput-multiline {
    padding: 12px 12px 10px !important;
  }
  .MuiDialogTitle-root {
    padding-bottom: 0;
  }
  .bZOAzH .post-interaction-background {
    background:#f3f3fb !important;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0,119,183,.2);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0077B7;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #009D90;
  }
  .MuiRating-pristine {
    display: none !important;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(48, 69, 99, .2);
    border-radius: unset;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(48, 69, 99, .8);
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(48, 69, 99, 1);
  }
.text-primary {
  color: #304563 !important;
}
.bg-white {
  box-shadow: 0px 0px 20px 10px rgba(48,69,99,.08);
}
.bg-primary, .btn-primary {
  background-color: #3f51b5 !important;
  border-color: #304563 !important;
}
.btn-primary:hover {
  background-color: #304563 !important;
  border-color: #304563 !important;
  color: #ffffff !important;
  opacity: 0.9;
}
.contactUs {
  color: #304563;
}
.bg-secondary {
  background-color: #9DA4F4 !important;
}
.cl {
  margin: 2rem 6rem;
  border-top: 7px solid;
}
.cl-primary {
  border-color: #304563 !important;
  opacity: unset !important;  
}
.cl-secondary {
  border-color: #9DA4F4 !important;
  opacity: unset !important;
}
.MuiIconButton-label {
  color: #304563 !important;
}
.lkBkmm .slider-on:before {
  background-color: #304563 !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 0px) scale(0.75) !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #3f51b5;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: rgba(48, 69, 99, 1) !important;
}
.MuiIconButton-colorSecondary:hover, .MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgba(48, 69, 99, 0.04) !important;
}
.border-primary {
  border: solid 5px #304563 !important;
}
.MuiSvgIcon-root {
  width: 1em !important;
  height: 1em !important;
}


.req-ast label::after {
  content:" *"; 
  color: #e32;
  position: absolute; 
  font-size: normal; 
  padding: 0 0 0 5px; 
}
.req-ast-leg legend::after {
  content:" *"; 
  color: #e32;
  position: absolute; 
  font-size: normal; 
  padding: 0 0 0 5px; 
}
.fw-600 {
  font-weight: 600 !important;
}
.b-line {
  border:solid 1px #ddd !important;
  font-size:12.3px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-23 {
  font-size: 23px !important;
}

.submit-bt {
  textTransform: none;
  color: white;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: #3f51b5 !important;
}
.action-section Button {
  background-color: #3f51b5 !important;
  color:white !important;
}

.action-section Button:disabled {
  background-color:rgba(0, 0, 0, 0.12) !important;

}
.send-btn {
  background-color: #3f51b5 !important;
  color:white !important;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper{
  max-width: 960px !important;
}
.css-1q7k2fz-MuiInputBase-root-MuiFilledInput-root {
  max-height: 90px !important;
  background-color : rgb(247, 247, 247) !important;
  padding :12px 1px 10px !important;
}
.icon-color{
  color:#3f51b5 !important
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  color: #3f51b5 ! important;
}
.css-wiglzk .login-section{
  left : -8% !important;
}
.css-7209ej-MuiInputBase-input-MuiFilledInput-input{
  text-indent :10px !important;
}
.update-btn{
  background-color: #3f51b5 !important;
}
.radio-color{
  color: rgb(48, 69, 99) !important;
}
.css-hyxlzm{
  background-color:white !important;
  color:rgb(48, 69, 99) !important;
  background:white !important;
}
.makeStyles-onlineBtns-12{
  background-color: #e0e0e0 !important;
  color: black !important;
}
.makeStyles-paper-1{
  width: 500px !important;
  padding: 32px 40px 32px !important;
}
.no-user-text{
  color: #3f51b5 !important;
}
.makeStyles-onlineBtns-90{
  background-color: #e0e0e0 !important;
  color: black !important;
}
.submit-btn{
  background-color: #3f51b5 !important;
}
.btn-primary{
  background-color: rgb(48, 69, 99) !important;
}
.css-n49iff {
  padding-top: 10px !important;
}
`;
export default GlobalStyle;