import { InteractionType, Message } from "common/types";
import { getAxiosInstance } from "common/utils";

export const sendMessage = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/sendMessage", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};

// for rating
export const sendRating = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/sendRating", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};

// created new for interactioncomplete
export const completeInteraction = async (interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/completeInteraction", {
      interactionId,
    });
  } catch (error) {
    console.log(error);
  }
};

// not over
export const interactionNotOver = async (interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/interactionNotOver", {
      interactionId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const intNoShow = async (interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/intNoShow", {
      interactionId,
    });
  } catch (error) {
    console.log(error);
  }
};
export const intNoShowComplete = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/intNoShowComplete", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};

// -----
export const sendUnableToAttend = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/sendUnableToAttend", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};
export const acceptInteractionRequest = async (interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/acceptInteractionRequest", {
      interactionId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const declineInteractionRequest = async (interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/declineInteractionRequest", {
      interactionId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendInteractionRequest = async (
  receiverId: string,
  interactionType: InteractionType,
  initialMessagePayload: any,
  expectedInterviewDate?: Date
) => {
  const axios = await getAxiosInstance();
  await axios.post("/interactions/sendInteractionRequest", {
    receiverId,
    interactionType,
    initialMessagePayload,
    expectedInterviewDate,
  });
};

export const dontSendFeedback = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/dontSendFeedback", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};

// remind me later
export const sendRemindMeLater = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/sendRemindMeLater", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendSorryModalClose = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/sendSorryModalClose", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendAppologyModalClose = async (message: Partial<Message>, interactionId: string) => {
  const axios = await getAxiosInstance();
  try {
    await axios.post("/interactions/sendAppologyModalClose", {
      interactionId,
      message,
    });
  } catch (error) {
    console.log(error);
  }
};