import styled from "styled-components";
const UserGuides = () => {
    return (
        <StyledWrapper>
            <div className="container contactUs mt-5 pb-5 ">
                <div>
                    <h3 className="h2 mb-3">User Guides</h3>
                    <p>On this page, you can find a number of guides to support you in using the Social Mobility Network. If you have any questions or would like additional support, please get in touch via the 'Contact Us' tab.</p>
                </div>
                <a href="/IntroductionToSMNLatest.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">An Introduction to the Social Mobility Network</h5>
                        <small>This guide will introduce you to the Social Mobility Network and how to make the most out of the platform.</small>
                    </div>
                </a>
                <a href="/InteractionTypeSMN.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">Interactions on the Social Mobility Network</h5>
                        <small>This guide provides an overview of the three interaction types and how to decide which interaction is right for you.</small>
                    </div>
                </a>
                <a href="/InterviewPreparationGuide.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">Interview Preparation Guide</h5>
                        <small>This guide runs through how to ace different types of interview questions.</small>
                    </div>
                </a>
                <a href="/BoostingYourNetworkingConfidence.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs pb-4">
                        <h5 className="fw-bold">Boosting Your Networking Confidence</h5>
                        <small className="mb-0">This guide provides advice on how to overcome networking fears and gives guidance on how to structure a message and questions that you can ask.</small>
                    </div>
                </a>
            </div>
        </StyledWrapper>)
}
export default UserGuides;
const StyledWrapper = styled.div`


.boxed{
    border:1px solid grey;
    padding:20px;
    margin-bottom :20px;
    background-color : white;
}
a{
    text-decoration:none;
    color:black;
}
`;