import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EmailChoices,
  GenderChoices,
  Professional,
  UserType,
} from "common/types/";

export type BasicDetails = Pick<
  Professional,
  | "id"
  | "firstName"
  | "lastName"
  | "preferredName"
  | "email"
  | "personalEmail"
  | "preferredEmail"
  | "gender"
  | "region"
  | "bio"
  | "roles"
  | "ethnicity"
  | "profilePicture"
  | "preferredVideoConferencing"
  | "participatingInteractionTypes"
  | "linkedinUrl"
>;

export type UniversityDetails = Pick<
  Professional,
  | "attendedUniversity"
  | "university"
  | "universityCourse"
  | "degreeType"
  | "graduationYear"
>;

export type WorkDetails = Pick<
  Professional,
  "company" | "jobTitle" | "sectors" | "employmentHistory"
>;

export type ProfessionalRegistrationType = {
  activeStep: number;
  basicDetails: BasicDetails;
  universityDetails: UniversityDetails;
  workDetails: WorkDetails;
  metadata: any;
  isNextStep: boolean;
};

export const initialState: ProfessionalRegistrationType = {
  activeStep: 0,
  basicDetails: {
    id: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    email: "",
    personalEmail: "",
    preferredEmail: EmailChoices.WORK_EMAIL,
    gender: GenderChoices.NOT_SPECIFIED,
    bio: "",
    region: {
      id: "",
      name: "",
    },
    ethnicity: {
      id: "",
      name: "",
    },
    profilePicture: "",
    preferredVideoConferencing: "",
    participatingInteractionTypes: [],
    roles: [UserType.PROFESSIONAL],
    linkedinUrl: "",
  },
  universityDetails: {
    attendedUniversity: true,
    university: {
      id: "",
      name: "",
    },
    universityCourse: {
      id: "",
      name: "",
    },
    degreeType: {
      id: "",
      name: "",
    },
    graduationYear: "",
  },
  workDetails: {
    company: {
      id: "",
      name: "",
    },
    jobTitle: "",
    sectors: [],
    employmentHistory: [],
  },
  metadata: {
    isDisabled: false,
    isGhost: false,
    emails: {
      receiveDailyEmail: true,
      receiveRequestEmail: true,
      receiveNewMessageEmail: true,
      receiveReminderEmail: true,
      receiveFeedbackEmail: true,
    }
  },
  isNextStep: false,
};

const slice = createSlice({
  name: "professionalRegistration",
  initialState,
  reducers: {
    goToNextStep: (state) => {
      state.activeStep += 1;
    },
    goToPrevStep: (state) => {
      state.activeStep -= 1;
    },
    setBasicDetails: (state, action: PayloadAction<any>) => {
      state.basicDetails = action.payload;
    },
    setUniversityDetails: (state, action: PayloadAction<any>) => {
      state.universityDetails = action.payload;
    },
    setWorkDetails: (state, action: PayloadAction<any>) => {
      state.workDetails = action.payload;
    },
    setProfessionalMetadata: (state, action: PayloadAction<any>) => {
      state.metadata = action.payload;
    },
    setIsNextStep: (state, action: PayloadAction<any>) => {
      state.isNextStep = action.payload;
      window.scrollTo(0, 0);
    },
    setActiveStep: (state, action: PayloadAction<any>) => {
      state.activeStep = action.payload;
    },
  },
});

export const {
  goToNextStep,
  goToPrevStep,
  setBasicDetails,
  setUniversityDetails,
  setWorkDetails,
  setProfessionalMetadata,
  setIsNextStep,
  setActiveStep,
} = slice.actions;

export default slice.reducer;
