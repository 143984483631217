import  { useState } from "react";
import { Message, MessageType, User, UserType } from "common/types";
import styled from "styled-components";
import {
    Button,
    Dialog,
    //DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMessageSendInProgress } from "../slice";
// import { selectMessageSendInProgress } from "../selectors";
import { selectUser } from "features/auth/selectors";
import { Grid } from "@mui/material";
import NotOver from "common/assets/images/svgToComponent/NotOver";
import YesOver from "common/assets/images/svgToComponent/YesOver";
import NoThanks from "common/assets/images/svgToComponent/NoThanks";
// import { getAxiosInstance } from "common/utils";
import { useEffect } from "react";
import { selectActiveInteractionId, selectInteractions } from "../selectors";
type Props = {
    interactionId: string;
    isThankYouModalOpen: boolean;
    setIsThankYouModalOpen: any;
    receiver: any;
};

const ThankYouNoteModal = ({ interactionId, isThankYouModalOpen, setIsThankYouModalOpen, receiver }: Props) => {
    const dispatch = useDispatch();

    const [thankYouNote, setThankYouNote] = useState<string>('');
    // const isDisabled = useSelector(selectMessageSendInProgress);
    const user: User | null = useSelector(selectUser);
    const interactions = useSelector(selectInteractions);
    const activeInteractionId = useSelector(selectActiveInteractionId);
    const [activeInteraction, setActiveInteraction] = useState<any>();
    const [count, setCount] = useState<number>(1);
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        setActiveInteraction(
          interactions
            ?.filter((interaction: any) => interaction.id === activeInteractionId)
            .pop()
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [interactions]);

    const sendMessage = () => {
        if (user?.roles.includes(UserType.STUDENT)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.THANKS_NOTE,
                payload: thankYouNote,
            };
            setThankYouNote("")
            dispatch({
                type: "SEND_MESSAGE",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
            setIsThankYouModalOpen(false);
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.FEEDBACK_NOTE,
                payload: thankYouNote,
            };
            setThankYouNote("")
            dispatch({
                type: "SEND_MESSAGE",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
            setIsThankYouModalOpen(false);
        }
    }
    const doNotSendMessage = () => {
        if (isSending) {
            return; // Don't proceed if the process is still ongoing
          }
          setIsSending(true);
        if (user?.roles.includes(UserType.STUDENT)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.THANKS_DONT_SEND,
                payload: thankYouNote,
            };
            dispatch({
                type: "DONT_SEND_FEEDBACK",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
            setIsThankYouModalOpen(false);
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.FEEDBACK_DONT_SEND,
                payload: thankYouNote,
            };
            dispatch({
                type: "DONT_SEND_FEEDBACK",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
            setIsThankYouModalOpen(false);
        }
    }
    // remind me later
    const sendRemindMeLater = () => {
        
        if (user?.roles.includes(UserType.STUDENT)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.THANKS_REMIND,
                payload: thankYouNote,
            };
            dispatch({
                type: "REMIND_ME_LATER",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
            setCount(count+1);
            setIsThankYouModalOpen(false);

            setTimeout(() => {
                setIsThankYouModalOpen(true);
            }, 120000);
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.FEEDBACK_REMIND,
                payload: thankYouNote,
            };
            dispatch({
                type: "REMIND_ME_LATER",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
            setCount(count+1);
            setIsThankYouModalOpen(false);

            setTimeout(() => {
                setIsThankYouModalOpen(true);
            }, 120000);
        }
     }
     const feedbackGiven = user
     ? activeInteraction?.feedbackGiven[user.id]
     : false;
 
   const feedbackDontGiven =
     user ? activeInteraction?.feedbackDontGiven && activeInteraction.feedbackDontGiven[user.id]
       : false;
 
   const remindMeLater =
     user ? activeInteraction?.remindMeLater && activeInteraction.remindMeLater[user.id]
       : false;

     useEffect(() => {
        if(count ===1 && remindMeLater === true && feedbackGiven === false && feedbackDontGiven === false ){
          setIsThankYouModalOpen(true);
        } else if(remindMeLater === true && feedbackGiven === false && feedbackDontGiven === false ){
            setTimeout(() => {
                setIsThankYouModalOpen(true);
            }, 120000) 
        } else {
            setIsThankYouModalOpen(false);
        }
        },[count,setIsThankYouModalOpen,remindMeLater,feedbackGiven,feedbackDontGiven])

    return (
        <StyledWrapper>
            <Dialog
                fullWidth
                className="post-interaction-background bubble"
                maxWidth="sm"
                open={isThankYouModalOpen}
                onClose={() => setIsThankYouModalOpen(true)}
            >
                <DialogTitle className="request-modal-header">
                    {(user?.roles.includes(UserType.PROFESSIONAL)) ? "Please send a feedback message to the student below." : `Please enter a message of thanks below that will be shared with ${receiver?.firstName} ${receiver?.lastName}.`}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        fullWidth
                        onChange={(e) => setThankYouNote(e.target.value)}
                        value={thankYouNote}
                        rows="4"
                        variant="filled"
                        className="request-input background-white-smoke request-modal-input"
                        // placeholder="Write your message of thanks here."
                        placeholder={(user?.roles.includes(UserType.PROFESSIONAL)) ? "Write your feedback message here." : "Write your message of thanks here."}
                        InputProps={{ disableUnderline: true }}
                    />
                </DialogContent>
                {/* <DialogActions className="action-section">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsThankYouModalOpen(false)}
                    >
                        Remind me later
                    </Button>
                    <Button disabled={isDisabled} variant="contained" color="primary" onClick={sendMessage}>
                        Send
                    </Button>
                </DialogActions> */}
                <DialogContent>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item md>
                            <Button disabled= {thankYouNote?.length === 0} variant="text" color="primary" onClick={sendMessage}>
                                <Grid direction="column">
                                    <Grid item><YesOver /></Grid>
                                    <Grid item>Send A Message</Grid>
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid item md={5}>
                            <Button variant="text" color="primary" onClick={doNotSendMessage
                                // (e: React.MouseEvent<HTMLElement>) => DontSend(e, interactionId)
                                }>
                                <Grid direction="column">
                                    <Grid item><NotOver /></Grid>
                                    <Grid item>
                                        {(user?.roles.includes(UserType.PROFESSIONAL)) ? "I don't want to send a message of feedback" : "I don't want to send a message of thanks" }
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid item md>
                            <Button variant="text" color="primary" onClick={() => sendRemindMeLater()}>
                                <Grid direction="column">
                                    <Grid item> <NoThanks /></Grid>
                                    <Grid item>Remind me later</Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </StyledWrapper>
    );
    
};

export default ThankYouNoteModal;

const StyledWrapper = styled.div`
  .interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }

  .click-here {
    margin-top: 30px;
  }
  .MuiButton-label {
    display: unset !important;
  }
  .message-options{
    color:red !important
  }
`;
