import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import makeStyles from '@mui/styles/makeStyles';
import {
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
  FormControl,
  FormGroup,
  InputLabel,
  Switch,
} from "@mui/material";
import { RadioButtonData } from "common/components/RadioButtonGroup";
import TextField, { InputType } from "common/components/TextField";
import {
  EmailChoices,
  GenderChoices,
  InteractionType,
  UserType,
} from "common/types/";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBasicDetails as setProfessionalBasicDetails,
  setProfessionalMetadata,
  setUniversityDetails as setProfessionalUniversityDetails,
  setWorkDetails as setProfessionalWorkDetails,
} from "../../features/registration/professional/professionalRegistrationSlice";
import {
  setBasicDetails as setAlumniBasicDetails,
  setAlumniMetadata,
  setUniversityDetails as setAlumniUniversityDetails,
  setWorkDetails as setAlumniWorkDetails,
} from "../../features/registration/alumni/alumniRegistrationSlice";
import {
  setBasicDetails as setStudentBasicDetails,
  setUniversityDetails as setStudentUniversityDetails,
} from "../../features/registration/student/studentRegistrationSlice";
import FormLabel from "@mui/material/FormLabel";
import DocumentPicker, {
  DocumentDefaultValue,
} from "../../common/components/DocumentPickerV2";
import {
  selectAuthUser,
  selectIsUpdateDrawerOpen,
  selectUser,
} from "features/auth/selectors";
import firebase from "firebase";
import Avatar from "@mui/material/Avatar";
import Skeleton from '@mui/material/Skeleton';
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
import {
  setIsUpdateDrawerOpen,
  setOnLoginRedirectRoute,
  setPromptModal,
} from "features/auth/slice";
import EmploymentHistory from "features/registration/professional/EmploymentHistory";
import React from "react";
import SelectComponent from "./SelectComponent";
import { setMetadata } from "features/registration/student/studentRegistrationSlice";
import { selectMetadata } from "features/registration/student/selectors";
import { selectProfessionalMetadata } from "features/registration/professional/selectors";
import ToggleButton from "common/components/ToggleButton";
import MonthYearPicker from "features/interactions/inputWidgets/MonthYearPicker";
import { selectAlumniMetadata } from "features/registration/alumni/selectors";

const preferredVideoConferencing: Array<RadioButtonData> = [
  { label: "Zoom", value: "Zoom" },
  { label: "Google Meet", value: "Google Meet" },
  { label: "Other", value: "Other" },
];

const genderRadioButtons: Array<RadioButtonData> = [
  { label: "Male", value: GenderChoices.MALE },
  { label: "Female", value: GenderChoices.FEMALE },
  { label: "Other", value: GenderChoices.OTHER },
  { label: "Do not wish to specify", value: GenderChoices.NOT_SPECIFIED },
];

const availabilityForAlumni: Array<RadioButtonData> = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const preferredEmailChoices: any = {
  [UserType.PROFESSIONAL]: [
    { label: "Work Email", value: EmailChoices.WORK_EMAIL },
    { label: "Personal Email", value: EmailChoices.PERSONAL_EMAIL },
  ],
  [UserType.STUDENT]: [
    { label: "University Email", value: EmailChoices.UNIVERSITY_EMAIL },
    { label: "Personal Email", value: EmailChoices.PERSONAL_EMAIL },
  ],
};

const interactionTypeAvailability = [
  {
    label: "Ask an Expert (Expert Advice) ",
    value: InteractionType.EXPERT_ADVICE,
    limit: 5,
  },
  { label: "Career Chat", value: InteractionType.CAREER_CHAT, limit: 5 },
  { label: "Mock Interviews", value: InteractionType.MOCK_INTERVIEW, limit: 4 },
];

const offlineDurationArray: Array<DocumentDefaultValue> = [
  {
    name: "1 week",
    id: "1",
    value: 604800,
  },
  { name: "2 weeks", id: "2", value: 1209600 },
  { name: "3 weeks", id: "3", value: 1814400 },
];

const useStyles = makeStyles({
  editProfile: {
    maxWidth: "95%",
    width: "100%",
    padding: "0 20px",
    marginTop: "20px",
    "@media (max-width:600px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "unset",
    },
  },
  fullList: {
    width: "auto",
  },
  onlineDiv: {
    display: "block",
  },
  onlineHeading: {
    marginBottom: "15px",
  },
  onlineBtns: {
    boxShadow: "none",
    borderRadius: "0",
  },
  btn__online: {
    backgroundColor: "#32CD32 !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#32CD32 !important",
    },
  },
  btn__offline: {
    backgroundColor: "#32CD32 !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#32CD32 !important",
    },
  },
  interaction__form: {
    width: "fit-content",
    margin: "15px 0 0 0",
  },
  interaction__div: {
    marginTop: "12px",
  },
  interaction__input: {
    width: "64px",
    height: "32px",
    textAlign: "center",
    fontStyle: "bold",
    "&:focus": {
      outline: "none",
    },
    border: "0.5px solid rgba(63, 81, 181, 0.5)",
  },
  interaction__btns: {
    "&--left": {
      borderRadius: "5px 0 0 5px",
    },
    "&--right": {
      borderRadius: "0 5px 5px 0",
    },
  },
});

export default function EditProfileDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // All selectors
  const authUser = useSelector(selectAuthUser);
  const user: any = useSelector(selectUser);
  const studentMetadata = useSelector(selectMetadata);
  const professionalMetadata = useSelector(selectProfessionalMetadata);
  const alumniMetadata = useSelector(selectAlumniMetadata);
  const isUpdateDrawerOpen = useSelector(selectIsUpdateDrawerOpen);

  //All states for form fields
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [preferredName, setPreferredName] = useState<any>("");
  const [personalEmail, setPersonalEmail] = useState<any>("");
  const [workEmail, setWorkEmail] = useState<any>("");
  const [universityEmail, setUniversityEmail] = useState<any>("");
  const [gender, setGender] = useState("");
  const [profilePictureUploading, setProfilePictureUploading] =
    useState<boolean>(false);
  const [profileUrl, setProfileUrl] = useState<string>("");
  const [preferredEmail, setPreferredEmail] = useState("");
  const [jobTitle, setJobTitle] = useState<any>("");
  const [currentEmploymentStartDate, setCurrentEmploymentStartDate] =
    useState<any>(null);
  const [graduationYear, setGraduationYear] = useState<any>("");
  const [linkedinUrl, setLinkedinUrl] = useState<any>("");
  const [selectedSectors, setSelectedSectors] = useState<any>([]);
  const [participatingInteractionTypes, setParticipatingInteractionTypes] =
    useState<any>([]);
  const [interactionTypeLimit, setInteractionTypeLimit] = useState<any>({});
  const [selectedEthnicity, setSelectedEthnicity] = useState<any | null>();
  const [selectedRegion, setSelectedRegion] = useState<any | null>();
  const [employersList, setEmployersList] = useState<any[]>([]);
  const [employer, setEmployer] = useState<any | null>();
  const [
    selectedPreferredVideoConferencing,
    setSelectedPreferredVideoConferencing,
  ] = useState<any | null>();
  const [attendedUniversity, setAttendedUniversity] = useState<boolean>(true);
  const [selectedUniversity, setSelectedUniversity] = useState<any>("");
  const [selectedCourse, setSelectedCourse] = useState<any | null>();
  const [selectedDegreeType, setSelectedDegreeType] = useState<any | null>();
  const [otherPreferredVideoConferencing, setOtherPreferredVideoConferencing] =
    useState<any | null>();
  const [bioCharacterLimit, setBioCharacterLimit] = useState<any | null>("");
  const [selectedDisadvantageIndicators, setSelectedDisadvantageIndicators] =
    useState<any>([]);
  const [householdIncome, setHouseholdIncome] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  const [dateOfBirth, setDateOfBirth] = useState<any>("");
  const [selectedYearOfStudy, setSelectedYearOfStudy] = useState<any | null>(1);
  const [aLevelGrades, setALevelGrades] = useState<any>("");
  const [realGrades, setRealGrades] = useState<any>("");
  const [selectedCareerStream, setSelectedCareerStream] = useState<
    any | null
  >();
  const [selectedCareerInterests, setSelectedCareerInterests] = useState<any>(
    []
  );
  const yearOfStudyValues = [1, 2, 3, 4, 5, 6];
  const [aLevelGradesVisibility, setALevelGradesVisibility] =
    useState<boolean>(false);
  const [realGradesVisibility, setRealGradesVisibility] =
    useState<boolean>(false);
  const [genderVisibility, setGenderVisibility] = useState<boolean>(false);
  const [regionVisibility, setRegionVisibility] = useState<boolean>(false);
  const [
    disadvantageIndicatorsVisibility,
    setDisadvantageIndicatorsVisibility,
  ] = useState<boolean>(false);
  const [householdIncomeVisibility, setHouseholdIncomeVisibility] =
    useState<boolean>(false);
  const [ethnicityVisibility, setEthnicityVisibility] =
    useState<boolean>(false);
  const [isGhost, setIsGhost] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [offlineDuration, setOfflineDuration] = useState<any>(604800);
  const [hasGraduateJob, setHasGraduateJob] = useState<boolean>(false);
  const [companyText, setCompanyText] = useState<string>("");
  const [isAvailableForAlumni, setIsAvailableForAlumni] =
    useState<boolean>(true);
  // Email notification preferences
  const [receiveDailyEmail, setReceiveDailyEmail] = useState<boolean>(true);
  const [receiveRequestEmail, setReceiveRequestEmail] = useState<boolean>(true);
  const [receiveNewMessageEmail, setReceiveNewMessageEmail] =
    useState<boolean>(true);
  const [receiveReminderEmail, setReceiveReminderEmail] =
    useState<boolean>(true);
  const [receiveFeedbackEmail, setReceiveFeedbackEmail] =
    useState<boolean>(true);

  // useEffect to prepopulate the fields in the form
  useEffect(() => {
    if (user?.profilePicture) setProfileUrl(user?.profilePicture);
    dispatch(setOnLoginRedirectRoute(null));
    if (window.location.hash) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPreferredName(user.preferredName);
    setPersonalEmail(user.personalEmail);
    setWorkEmail(authUser?.email);
    setUniversityEmail(user?.email);
    setGender(user.gender);
    setProfileUrl(user.profilePicture);
    setPreferredEmail(
      user.roles.includes(UserType.PROFESSIONAL) ||
        user.roles.includes(UserType.ALUMNI)
        ? user?.preferredEmail === user?.personalEmail
          ? EmailChoices.PERSONAL_EMAIL
          : EmailChoices.WORK_EMAIL
        : user?.preferredEmail === user?.personalEmail
        ? EmailChoices.PERSONAL_EMAIL
        : EmailChoices.UNIVERSITY_EMAIL
    );
    setCurrentEmploymentStartDate(user?.currentEmploymentStartDate);
    setJobTitle(user?.jobTitle);
    setGraduationYear(user?.graduationYear);
    setLinkedinUrl(user?.linkedinUrl);
    setSelectedSectors(user?.sectors);
    setParticipatingInteractionTypes(user?.participatingInteractionTypes);
    setInteractionTypeLimit(user?.metadata?.interactionTypeLimit);
    setSelectedEthnicity(user?.ethnicity);
    setSelectedRegion(user?.region);
    setEmployer(user?.company);
    setCompanyText(user?.company);
    setHasGraduateJob(user?.company && user?.company !== "" ? true : false);
    setSelectedPreferredVideoConferencing(user?.preferredVideoConferencing);
    setAttendedUniversity(user?.attendedUniversity);
    setSelectedUniversity(user?.university);
    setSelectedCourse(user?.universityCourse);
    setSelectedDegreeType(user?.degreeType);
    setOtherPreferredVideoConferencing(user?.otherPreferredVideoConferencing);
    setBioCharacterLimit(user?.bio);
    setSelectedDisadvantageIndicators(user?.disadvantageIndicators);
    setPhoneNumber(user?.phoneNumber);
    setDateOfBirth(user?.dateOfBirth);
    setHouseholdIncome(user?.householdIncome);
    setALevelGrades(user?.aLevelGrades);
    setRealGrades(user?.realGrades);
    setSelectedCareerInterests(user?.careerInterests);
    setSelectedCareerStream(user?.careerStream);
    setALevelGradesVisibility(
      user?.metadata?.profileDataVisibility?.aLevelGrades
    );
    setRealGradesVisibility(user?.metadata?.profileDataVisibility?.realGrades);
    setGenderVisibility(user?.metadata?.profileDataVisibility?.gender);
    setRegionVisibility(user?.metadata?.profileDataVisibility?.region);
    setDisadvantageIndicatorsVisibility(
      user?.metadata?.profileDataVisibility?.disadvantageIndicators
    );
    setEthnicityVisibility(user?.metadata?.profileDataVisibility?.ethnicity);
    setHouseholdIncomeVisibility(
      user?.metadata?.profileDataVisibility?.householdIncome
    );
    setIsGhost(user?.metadata?.isGhost);
    setIsOnline(user?.metadata?.isOnline);
    setIsAvailableForAlumni(user?.metadata?.isAvailableForAlumni)
    setOfflineDuration(user?.metadata?.offlineDuration);

    if (user.employmentHistory) {
      setEmployersList([...user.employmentHistory]);
    }
    setReceiveDailyEmail(user?.metadata?.emails?.receiveDailyEmail);
    setReceiveRequestEmail(user?.metadata?.emails?.receiveRequestEmail);
    setReceiveNewMessageEmail(user?.metadata?.emails?.receiveNewMessageEmail);
    setReceiveReminderEmail(user?.metadata?.emails?.receiveReminderEmail);
    setReceiveFeedbackEmail(user?.metadata?.emails?.receiveFeedbackEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !receiveRequestEmail &&
      !receiveNewMessageEmail &&
      !receiveReminderEmail &&
      !receiveFeedbackEmail
    ) {
      setReceiveDailyEmail(true);
    } else if (!receiveDailyEmail) {
      setReceiveRequestEmail(true);
      setReceiveNewMessageEmail(true);
    }
  }, [
    receiveDailyEmail,
    receiveRequestEmail,
    receiveNewMessageEmail,
    receiveReminderEmail,
    receiveFeedbackEmail,
  ]);

  const openDrawer = () => {
    dispatch(setIsUpdateDrawerOpen(!selectIsUpdateDrawerOpen));
  };

  const closeDrawer = () => {
    dispatch(
      setPromptModal({
        isOpen: true,
        ModalType: "SAVE_WARNING_MESSAGE_FOR_EDIT_PROFILE",
      })
    );
  };

  const handleParticipatingInteractionTypes = (e: any) => {
    let newArray = [...participatingInteractionTypes, e.target.value];
    if (participatingInteractionTypes.includes(e.target.value)) {
      newArray = newArray.filter((value) => value !== e.target.value);
    }
    setParticipatingInteractionTypes(newArray);
  };

  const handleInteractionTypeInfoChange = (e: any, interactionType: string) => {
    const interactionTypeLimitObj = { ...interactionTypeLimit };
    interactionTypeLimitObj[interactionType] = parseInt(e.target.value);
    setInteractionTypeLimit(interactionTypeLimitObj);
  };

  const appendToEmployerList = () => {
    setEmployersList(employersList.concat({}));
  };

  const deleteFromEmployerList = (index: number) => {
    setEmployersList(employersList.filter((x, i) => i !== index));
  };

  const updateToEmployerList = (value: any, index: number) => {
    const oldEmployersList = [...employersList];
    oldEmployersList[index] = { ...value };
    setEmployersList(oldEmployersList);
  };

  // This function has all the dispatches related to professional's profile update
  const updateNonAssociateProfile = async () => {
    const basicDetails = {
      firstName: firstName,
      lastName: lastName,
      preferredName: preferredName,
      email: authUser?.email,
      personalEmail: personalEmail,
      gender: gender,
      preferredEmail:
        preferredEmail === EmailChoices.PERSONAL_EMAIL
          ? personalEmail
          : workEmail,
      ethnicity: selectedEthnicity,
      region: selectedRegion,
      profilePicture: profileUrl,
      roles: [UserType.PROFESSIONAL],
      linkedinUrl: linkedinUrl,
      participatingInteractionTypes: participatingInteractionTypes,
      bio: bioCharacterLimit,
      preferredVideoConferencing: selectedPreferredVideoConferencing,
      otherPreferredVideoConferencing: otherPreferredVideoConferencing,
    };

    const universityDetails = {
      attendedUniversity: true,
      university: selectedUniversity,
      universityCourse: selectedCourse,
      degreeType: selectedDegreeType,
      graduationYear: graduationYear,
    };

    const emailMetadata = {
      receiveDailyEmail,
      receiveRequestEmail,
      receiveNewMessageEmail,
      receiveReminderEmail,
      receiveFeedbackEmail,
    };

    const newEmployentHistoryArray: any[] = [];
    await employersList.forEach((list) => {
      if (list.company && list.jobTitle) {
        newEmployentHistoryArray.push(list);
      }
    });

    const workDetails = {
      company: employer,
      employmentHistory: newEmployentHistoryArray,
      jobTitle: jobTitle,
      currentEmploymentStartDate: currentEmploymentStartDate,
      sectors: selectedSectors,
    };
    if (user.roles.includes(UserType.PROFESSIONAL)) {
      await dispatch(setProfessionalBasicDetails(basicDetails));
      await dispatch(
        setProfessionalUniversityDetails(
          attendedUniversity
            ? universityDetails
            : {
                attendedUniversity: false,
                university: null,
                universityCourse: null,
                degreeType: null,
                graduationYear: null,
              }
        )
      );
      await dispatch(setProfessionalWorkDetails(workDetails));
      await dispatch(
        setProfessionalMetadata({
          ...professionalMetadata,
          isGhost: isGhost,
          isOnline: isOnline,
          offlineDuration: offlineDuration,
          isAvailableForAlumni: isAvailableForAlumni,
          emails: emailMetadata,
          interactionTypeLimit: interactionTypeLimit,
        })
      );
      await dispatch({
        type: "PROFESSIONAL_PROFILE_UPDATE_ATTEMPT",
      });
    }

    if (user.roles.includes(UserType.ALUMNI)) {
      await dispatch(
        setAlumniBasicDetails({
          ...basicDetails,
          roles: [UserType.ALUMNI],
          associateStatus: user.associateStatus,
        })
      );
      await dispatch(
        setAlumniUniversityDetails(
          attendedUniversity
            ? universityDetails
            : {
                attendedUniversity: false,
                university: null,
                universityCourse: null,
                degreeType: null,
                graduationYear: null,
              }
        )
      );
      await dispatch(
        setAlumniWorkDetails(
          hasGraduateJob
            ? {
                ...workDetails,
                company: companyText,
                hasGraduateJob: true,
              }
            : {
                hasGraduateJob: false,
                company: null,
                jobTitle: null,
                currentEmploymentStartDate: null,
              }
        )
      );
      await dispatch(
        setAlumniMetadata({
          ...alumniMetadata,
          isGhost: isGhost,
          emails: emailMetadata,
          interactionTypeLimit: interactionTypeLimit,
        })
      );
      await dispatch({
        type: "ALUMNI_PROFILE_UPDATE_ATTEMPT",
      });
    }
  };

  // This function has all the dispatches related to student's profile update
  const updateStudentProfile = async () => {
    dispatch(
      setStudentBasicDetails({
        firstName: firstName,
        lastName: lastName,
        preferredName: preferredName,
        email: user?.email,
        personalEmail: personalEmail,
        gender: gender,
        preferredEmail:
          preferredEmail === EmailChoices.PERSONAL_EMAIL
            ? personalEmail
            : universityEmail,
        dateOfBirth: dateOfBirth,
        phoneNumber: phoneNumber,
        ethnicity: selectedEthnicity,
        region: selectedRegion,
        profilePicture: profileUrl,
        roles: [UserType.STUDENT],
        linkedinUrl: linkedinUrl,
        bio: bioCharacterLimit,
      })
    );

    dispatch(
      setStudentUniversityDetails({
        university: selectedUniversity,
        universityCourse: selectedCourse,
        degreeType: selectedDegreeType,
        graduationYear: graduationYear,
        careerInterests: selectedCareerInterests,
        careerStream: selectedCareerStream,
        yearOfStudy: selectedYearOfStudy,
        aLevelGrades: aLevelGrades,
        realGrades: realGrades,
      })
    );

    dispatch(
      setMetadata({
        ...studentMetadata,
        isGhost: isGhost,
        profileDataVisibility: {
          aLevelGrades: aLevelGradesVisibility,
          realGrades: realGradesVisibility,
          ethnicity: ethnicityVisibility,
          disadvantageIndicators: disadvantageIndicatorsVisibility,
          gender: genderVisibility,
          region: regionVisibility,
          householdIncome: householdIncomeVisibility,
        },
        emails: {
          receiveDailyEmail,
          receiveRequestEmail,
          receiveNewMessageEmail,
          receiveReminderEmail,
          receiveFeedbackEmail,
        },
      })
    );

    dispatch({
      type: "STUDENT_PROFILE_UPDATE_ATTEMPT",
    });
  };

  // This function is being called when clicked on submit button
  // This function calls the professional/student function based
  // on the usertype
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (
        user.roles.includes(UserType.PROFESSIONAL) ||
        user.roles.includes(UserType.ALUMNI)
      ) {
        await updateNonAssociateProfile();
      } else {
        await updateStudentProfile();
      }
      await openDrawer();
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: "You have successfully updated your profile.",
      };

      dispatch(showToast(toast));
    } catch (error) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: "Something went wrong. Please try again.",
      };
      dispatch(showToast(toast));
    }
  };

  // This function does all the tasks related to photo upload
  const uploadPhoto = async (file: any) => {
    setProfilePictureUploading(true);
    const firebaseStorageRef = firebase
      .storage()
      .ref("users/" + authUser?.id + "/profile.jpg");
    await firebaseStorageRef
      .put(file[0])
      .then(() => {
        console.log("Image successfully uploaded");
      })
      .catch((error) => {
        console.log("error uploading image", error);
      });
    await firebaseStorageRef
      .getDownloadURL()
      .then((url) => {
        setProfileUrl(url);
        setProfilePictureUploading(false);
      })
      .catch((error) => {
        console.log("error fetching user profile url", error);
        setProfilePictureUploading(false);
      });
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={isUpdateDrawerOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
        className="editProfile-drawer"
      >
        <form onSubmit={handleSubmit}>
          <Container className={classes.editProfile}>
            <Button color="primary" onClick={closeDrawer} className="close-btn">
              Close
            </Button>
            <Grid container spacing={1} item lg={12}>
              <Grid item xs={8}>
                <h3 className="edit-profile">Edit Your Profile</h3>
              </Grid>
              <Grid className="flex-end top-bottom-spacing" item xs={12} sm={8}>
                <h3 className="mb-0">Basic Information</h3>
              </Grid>
              <Grid className="show-on-profile" item xs={4}>
                {user.roles.includes(UserType.STUDENT) && (
                  <p className="body-16 show-on-profile">Show on Profile</p>
                )}
              </Grid>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                <TextField
                  name="firstName"
                  type={InputType.TEXT}
                  label="First Name"
                  handleChange={(e: any) => setFirstName(e.target.value)}
                  value={firstName}
                  required
                  error={!!!firstName}
                  helperText={firstName === "" ? "This field is required" : ""}
                />
              </Grid>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                <TextField
                  name="lastName"
                  type={InputType.TEXT}
                  label="Last Name"
                  handleChange={(e: any) => setLastName(e.target.value)}
                  value={lastName}
                  required
                  error={!!!lastName}
                  helperText={lastName === "" ? "This field is required" : ""}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} item lg={12}>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                <TextField
                  name="preferredName"
                  type={InputType.TEXT}
                  label="Preferred Name"
                  handleChange={(e: any) => setPreferredName(e.target.value)}
                  value={preferredName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} item lg={12}>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                <div className="d-flex">
                  {!profilePictureUploading ? (
                    <Avatar
                      className="profile-picture"
                      alt=""
                      src={profileUrl}
                    />
                  ) : (
                    <Skeleton className="profile-picture" variant="circular" />
                  )}
                  <div className="upload-section">
                    <Button color="primary" component="label">
                      Upload Photo
                      <input
                        type="file"
                        hidden
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => uploadPhoto(e.target.files)}
                      />
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid container spacing={1} item lg={12}>
                <Grid className="top-bottom-spacing" item xs={12} sm={8} md={8}>
                  <p className="body-14 font-weight-normal">
                    Add a short description of your background to tell{" "}
                    {user.roles.includes(UserType.PROFESSIONAL)
                      ? "Associates"
                      : "Professionals"}{" "}
                    about yourself
                  </p>
                  <TextField
                    name="bio"
                    multiline
                    type={InputType.TEXT}
                    label="Type your bio here"
                    inputProp={{ maxLength: 1000 }}
                    handleChange={(e: any) =>
                      setBioCharacterLimit(e.target.value)
                    }
                    value={bioCharacterLimit}
                  />
                  <span className="body-12">
                    {bioCharacterLimit?.length}/1000
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={8} md={8}></Grid>
              <Grid className="top-bottom-spacing" item xs={8} md={8}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className="radio-color"
                >
                  <FormLabel component="legend">Gender</FormLabel>
                  <div>
                    {genderRadioButtons.map((radioButton: RadioButtonData) => (
                      <FormControlLabel
                        // className="gender-radio-groups"
                        className="radio-color"
                        control={<Radio />}
                        value={radioButton.value}
                        label={radioButton.label}
                        disabled={radioButton.disabled}
                      />
                    ))}
                  </div>
                </RadioGroup>
              </Grid>
              {user.roles.includes(UserType.STUDENT) && (
                <Grid className="flex-start" item xs={4}>
                  <ToggleButton
                    handleToggle={setGenderVisibility}
                    checked={genderVisibility}
                  />
                </Grid>
              )}
            </Grid>
            {user.roles.includes(UserType.STUDENT) && (
              <>
                <Grid container spacing={1} item lg={12}>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={4}
                  >
                    <InputLabel className="top-bottom-spacing">
                      Enter your date of birth
                    </InputLabel>
                    <TextField
                      name="dateOfBirth"
                      type={InputType.DATE}
                      label=""
                      value={dateOfBirth}
                      handleChange={(e: any) => setDateOfBirth(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    className="flex-end top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={4}
                  >
                    <TextField
                      name="phoneNumber"
                      type={InputType.NUMBER}
                      label="Mobile Number"
                      handleChange={(e: any) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={1} item lg={12}>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                {user.roles.includes(UserType.STUDENT) ? (
                  <TextField
                    name="email"
                    type={InputType.EMAIL}
                    label="University Email"
                    value={universityEmail}
                    handleChange={(e: any) =>
                      setUniversityEmail(e.target.value)
                    }
                    error={!!!universityEmail}
                    required
                    helperText={
                      universityEmail === "" ? "This field is required" : ""
                    }
                  />
                ) : (
                  <TextField
                    name="email"
                    type={InputType.EMAIL}
                    label="Work Email"
                    defaultValue={authUser?.email}
                    disabled
                    handleChange={(e: any) => setWorkEmail(e.target.value)}
                  />
                )}
              </Grid>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                <TextField
                  name="personalEmail"
                  type={InputType.EMAIL}
                  label="Personal Email"
                  handleChange={(e: any) => setPersonalEmail(e.target.value)}
                  value={personalEmail}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} item lg={12}>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={4}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={preferredEmail}
                  className="radio-color"
                  onChange={(e) => setPreferredEmail(e.target.value)}
                >
                  <FormLabel component="legend">Preferred Email</FormLabel>
                  <div>
                    {preferredEmailChoices[
                      user.roles.includes(UserType.PROFESSIONAL) ||
                      user.roles.includes(UserType.ALUMNI)
                        ? UserType.PROFESSIONAL
                        : UserType.STUDENT
                    ].map((radioButton: RadioButtonData) => (
                      <FormControlLabel
                        // className="gender-radio-groups"
                        className="radio-color"
                        control={<Radio />}
                        value={radioButton.value}
                        label={radioButton.label}
                        disabled={radioButton.disabled}
                      />
                    ))}
                  </div>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container spacing={1} item lg={12}>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} md={8}>
                <FormLabel component="legend">
                  Which region do you live in?
                </FormLabel>
                <DocumentPicker
                  handleChange={(selectedRegion: any) =>
                    setSelectedRegion(selectedRegion)
                  }
                  collection="regions"
                  name="region"
                  defaultValue={selectedRegion}
                  label="Region"
                />
              </Grid>
              {user.roles.includes(UserType.STUDENT) && (
                <Grid className="flex-start" item xs={12} sm={8} md={4}>
                  <ToggleButton
                    handleToggle={setRegionVisibility}
                    checked={regionVisibility}
                  />
                </Grid>
              )}

              <Grid
                className="flex-end top-bottom-spacing"
                item
                xs={12}
                sm={8}
                md={8}
              >
                <DocumentPicker
                  handleChange={(selectedEthnicity: any) =>
                    setSelectedEthnicity(selectedEthnicity)
                  }
                  collection="ethnicities"
                  name="ethnicity"
                  defaultValue={selectedEthnicity}
                  label="Ethnicity"
                />
              </Grid>
              {user.roles.includes(UserType.STUDENT) && (
                <Grid className="flex-start" item xs={12} sm={8} md={4}>
                  <ToggleButton
                    handleToggle={setEthnicityVisibility}
                    checked={ethnicityVisibility}
                  />
                </Grid>
              )}
            </Grid>
            {(user.roles.includes(UserType.PROFESSIONAL) ||
              user.roles.includes(UserType.ALUMNI)) && (
              <>
                <Grid container spacing={1} item lg={12}>
                  <Grid className="top-bottom-spacing" item xs={8} lg={6}>
                    <RadioGroup
                      aria-label="preferredVideoConferencing"
                      name="preferredVideoConferencing"
                      className="radio-color"
                      value={selectedPreferredVideoConferencing}
                      onChange={(e) =>
                        setSelectedPreferredVideoConferencing(e.target.value)
                      }
                    >
                      <FormLabel component="legend">
                        Select your preferred Video Conferencing Options
                      </FormLabel>
                      <div>
                        {preferredVideoConferencing.map(
                          (radioButton: RadioButtonData) => (
                            <FormControlLabel
                              // className="gender-radio-groups"
                              className="radio-color"
                              control={<Radio />}
                              value={radioButton.value}
                              label={radioButton.label}
                              disabled={radioButton.disabled}
                            />
                          )
                        )}
                      </div>
                    </RadioGroup>
                    {selectedPreferredVideoConferencing === "Other" && (
                      <TextField
                        name="preferredVideoConferencing"
                        type={InputType.TEXT}
                        label="Please specify"
                        handleChange={(e: any) =>
                          setOtherPreferredVideoConferencing(e.target.value)
                        }
                        value={otherPreferredVideoConferencing}
                      />
                    )}
                  </Grid>
                </Grid>
                {(user.roles.includes(UserType.PROFESSIONAL) ||
                  user.roles.includes(UserType.ALUMNI)) && (
                  <Grid
                    className="flex-end top-bottom-spacing"
                    item
                    xs={8}
                    md={12}
                  >
                    <h3 className="mb-0">Current Employment</h3>
                  </Grid>
                )}
                {user.roles.includes(UserType.ALUMNI) && (
                  <Grid
                    container
                    className="month-picker"
                    spacing={1}
                    item
                    lg={12}
                  >
                    <Grid className="mt-5" item xs={12}>
                      <Checkbox
                        className="blue-checkbox"
                        name="hasGraduateJob"
                        onClick={() => setHasGraduateJob(!hasGraduateJob)}
                        checked={hasGraduateJob}
                      />
                      Do you have a graduate job?{" "}
                    </Grid>
                    {hasGraduateJob && (
                      <>
                        <Grid
                          className="top-bottom-spacing"
                          item
                          xs={12}
                          sm={8}
                          md={4}
                        >
                          <TextField
                            type={InputType.TEXT}
                            helperText={
                              companyText === "" ? "This field is required" : ""
                            }
                            handleChange={(e: any) =>
                              setCompanyText(e.target.value)
                            }
                            error={hasGraduateJob && !!!companyText}
                            name="company"
                            defaultValue={companyText}
                            label="Company"
                          />
                        </Grid>
                        <Grid
                          className="flex-end top-bottom-spacing"
                          item
                          xs={12}
                          sm={8}
                          md={4}
                        >
                          <TextField
                            name="jobTitle"
                            type={InputType.TEXT}
                            label="Job Title"
                            handleChange={(e: any) =>
                              setJobTitle(e.target.value)
                            }
                            value={jobTitle}
                            required
                            error={hasGraduateJob && !!!jobTitle}
                            helperText={
                              jobTitle === "" ? "This field is required" : ""
                            }
                          />
                        </Grid>
                        <Grid
                          className="mb-0"
                          item
                          lg={12}
                          container
                          spacing={1}
                        >
                          <Grid
                            className="flex-end top-bottom-spacing"
                            item
                            xs={12}
                            sm={8}
                            md={4}
                          >
                            <MonthYearPicker
                              value={currentEmploymentStartDate}
                              onChange={setCurrentEmploymentStartDate}
                              maxDate={new Date()}
                              placeholder="Select start date"
                              required={hasGraduateJob}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {user.roles.includes(UserType.ALUMNI) && (
                      <Grid
                        className="flex-end top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        md={12}
                      >
                        <h3 className="mb-0">Previous Employment History</h3>
                      </Grid>
                    )}
                    {React.Children.toArray(
                      employersList.map((data, i) => (
                        <EmploymentHistory
                          deleteFromEmployerList={deleteFromEmployerList}
                          updateToEmployerList={updateToEmployerList}
                          index={i}
                          value={data}
                        />
                      ))
                    )}
                    <Grid className="top-bottom-spacing" item xs={12}>
                      <Button onClick={appendToEmployerList} color="primary">
                        Add another previous employer
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {user.roles.includes(UserType.PROFESSIONAL) && (
                  <Grid
                    container
                    className="month-picker"
                    spacing={1}
                    item
                    lg={12}
                  >
                    <Grid
                      className="top-bottom-spacing"
                      item
                      xs={12}
                      sm={8}
                      md={4}
                    >
                      <DocumentPicker
                        handleChange={(employer: any) => setEmployer(employer)}
                        collection="companies"
                        name="company"
                        defaultValue={employer}
                        label="Company"
                        error={!!!employer}
                        required
                      />
                    </Grid>
                    <Grid
                      className="flex-end top-bottom-spacing"
                      item
                      xs={12}
                      sm={8}
                      md={4}
                    >
                      <TextField
                        name="jobTitle"
                        type={InputType.TEXT}
                        label="Job Title"
                        handleChange={(e: any) => setJobTitle(e.target.value)}
                        value={jobTitle}
                        required
                        error={!!!jobTitle}
                        helperText={
                          jobTitle === "" ? "This field is required" : ""
                        }
                      />
                    </Grid>
                    <Grid className="mb-0" item lg={12} container spacing={1}>
                      <Grid
                        className="flex-end top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        md={4}
                      >
                        <MonthYearPicker
                          value={currentEmploymentStartDate}
                          onChange={setCurrentEmploymentStartDate}
                          maxDate={new Date()}
                          placeholder="Select start date"
                          required
                        />
                      </Grid>
                    </Grid>
                    {user.roles.includes(UserType.PROFESSIONAL) && (
                      <Grid
                        className="flex-end top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        md={12}
                      >
                        <h3 className="mb-0">Previous Employment History</h3>
                      </Grid>
                    )}
                    {React.Children.toArray(
                      employersList.map((data, i) => (
                        <EmploymentHistory
                          deleteFromEmployerList={deleteFromEmployerList}
                          updateToEmployerList={updateToEmployerList}
                          index={i}
                          value={data}
                        />
                      ))
                    )}
                    <Grid className="top-bottom-spacing" item xs={12}>
                      <Button onClick={appendToEmployerList} color="primary">
                        Add another previous employer
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  className="flex-end top-bottom-spacing"
                  item
                  xs={8}
                  md={12}
                >
                  <h3 className="mb-0">University Information</h3>
                </Grid>
                {!user.roles.includes(UserType.ALUMNI) && (
                  <Grid container item spacing={1} xs={12}>
                    <Grid className="top-bottom-spacing" item xs={12} sm={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="blue-checkbox"
                            checked={attendedUniversity}
                            onClick={() =>
                              setAttendedUniversity(!attendedUniversity)
                            }
                            name="attendedUniversity"
                          />
                        }
                        label="Do you have a university degree?"
                      />
                    </Grid>
                  </Grid>
                )}
                {attendedUniversity && (
                  <>
                    <Grid container spacing={1} item lg={12}>
                      <Grid
                        className="top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        lg={4}
                      >
                        <DocumentPicker
                          handleChange={(selectedUniversity: any) =>
                            setSelectedUniversity(selectedUniversity)
                          }
                          collection="universities"
                          name="university"
                          defaultValue={selectedUniversity}
                          label="University"
                          error={!!!selectedUniversity && attendedUniversity}
                          required
                        />
                      </Grid>
                      <Grid
                        className="top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        lg={4}
                      >
                        <DocumentPicker
                          handleChange={(selectedCourse: any) =>
                            setSelectedCourse(selectedCourse)
                          }
                          collection="courses"
                          name="universityCourse"
                          defaultValue={selectedCourse}
                          label="University Course"
                          error={!!!selectedCourse && attendedUniversity}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} item lg={12}>
                      <Grid
                        className="top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        lg={4}
                      >
                        <DocumentPicker
                          handleChange={(selectedDegreeType: any) =>
                            setSelectedDegreeType(selectedDegreeType)
                          }
                          collection="degree-types"
                          name="degreeType"
                          defaultValue={selectedDegreeType}
                          label="Degree Type"
                          error={!!!selectedDegreeType && attendedUniversity}
                          required
                        />
                      </Grid>
                      <Grid
                        className="flex-end top-bottom-spacing"
                        item
                        xs={12}
                        sm={8}
                        lg={4}
                      >
                        <TextField
                          name="graduationYear"
                          type={InputType.NUMBER}
                          label="Graduation Year"
                          handleChange={(e: any) =>
                            setGraduationYear(e.target.value)
                          }
                          value={graduationYear}
                          error={!!!graduationYear && attendedUniversity}
                          required
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={1} item lg={12}>
                  <Grid
                    className="participatingInteractionTypes top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                  >
                    <FormControl component="fieldset">
                      <FormLabel
                        className="line-height-normal"
                        component="legend"
                      >
                        There are three types of interactions which students can
                        request of you, please select the interaction types that
                        you are happy to participate in:
                      </FormLabel>
                      <FormGroup>
                        {interactionTypeAvailability.map((option) => (
                          <>
                            <FormControlLabel
                              value={option.value}
                              className={classes.interaction__form}
                              control={
                                <Checkbox
                                  className="blue-checkbox"
                                  checked={participatingInteractionTypes?.includes(
                                    option.value
                                  )}
                                  required={
                                    participatingInteractionTypes?.length === 0
                                  }
                                  onChange={handleParticipatingInteractionTypes}
                                  name="participatingInteractionTypes"
                                />
                              }
                              label={option.label}
                            />
                            <FormLabel
                              className="line-height-normal"
                              component="legend"
                            >
                              Number of Interactions you are happy to receive
                              each month
                            </FormLabel>
                            <Grid
                              container
                              spacing={2}
                              className={classes.interaction__div}
                            >
                              <Grid item xs={12} sm={8} md={8}>
                                <TextField
                                  name={option.label}
                                  value={
                                    (interactionTypeLimit &&
                                      interactionTypeLimit[option.value]) ||
                                    option.limit
                                  }
                                  type={InputType.NUMBER}
                                  label={option.label}
                                  handleChange={(e: any) =>
                                    handleInteractionTypeInfoChange(
                                      e,
                                      option.value
                                    )
                                  }
                                  disabled={
                                    !participatingInteractionTypes?.includes(
                                      option.value
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </FormGroup>
                      {participatingInteractionTypes?.length === 0 && (
                        <p className="rhf-errors">This field is required.</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    lg={8}
                  >
                    <DocumentPicker
                      handleChange={(selectedSectors: any) =>
                        setSelectedSectors(selectedSectors)
                      }
                      collection="sectors"
                      multiple
                      name="sectors"
                      label="Sectors"
                      defaultValues={selectedSectors}
                      error={selectedSectors?.length === 0}
                      required={selectedSectors?.length === 0}
                    />
                  </Grid>
                </Grid>{" "}
              </>
            )}
            {user.roles.includes(UserType.STUDENT) && (
              <Grid className="flex-end top-bottom-spacing" item xs={8} md={12}>
                <h3 className="mb-0">University Information</h3>
              </Grid>
            )}
            {user.roles.includes(UserType.STUDENT) && (
              <>
                <Grid container spacing={1} item lg={12}>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={4}
                  >
                    <DocumentPicker
                      handleChange={(selectedUniversity: any) =>
                        setSelectedUniversity(selectedUniversity)
                      }
                      collection="universities"
                      name="university"
                      defaultValue={selectedUniversity}
                      label="University"
                      error={!!!selectedUniversity}
                      required
                    />
                  </Grid>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={4}
                  >
                    <DocumentPicker
                      handleChange={(selectedCourse: any) =>
                        setSelectedCourse(selectedCourse)
                      }
                      collection="courses"
                      name="universityCourse"
                      defaultValue={selectedCourse}
                      label="University Course"
                      error={!!!selectedCourse}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} item lg={12}>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={4}
                  >
                    <DocumentPicker
                      handleChange={(selectedDegreeType: any) =>
                        setSelectedDegreeType(selectedDegreeType)
                      }
                      collection="degree-types"
                      name="degreeType"
                      defaultValue={selectedDegreeType}
                      label="Degree Type"
                      error={!!!selectedDegreeType}
                      required
                    />
                  </Grid>
                  <Grid
                    className="flex-end top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={4}
                  >
                    <TextField
                      name="graduationYear"
                      type={InputType.NUMBER}
                      label="Graduation Year"
                      handleChange={(e: any) =>
                        setGraduationYear(e.target.value)
                      }
                      value={graduationYear}
                      error={!!!graduationYear}
                      required
                    />
                  </Grid>
                  <Grid
                    className="flex-end top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    lg={8}
                  >
                    <SelectComponent
                      name="yearOfStudy"
                      handleChange={(selectedYearOfStudy: any) =>
                        setSelectedYearOfStudy(selectedYearOfStudy)
                      }
                      selectedValue={selectedYearOfStudy}
                      values={yearOfStudyValues}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} item lg={12}>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    lg={4}
                  >
                    <DocumentPicker
                      multiple
                      handleChange={(selectedCareerInterests: any) =>
                        setSelectedCareerInterests(selectedCareerInterests)
                      }
                      collection="career-interests"
                      name="careerInterests"
                      defaultValues={selectedCareerInterests}
                      label="Career Interests"
                      error={selectedCareerInterests.length === 0}
                      required={selectedCareerInterests?.length === 0}
                    />
                  </Grid>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    lg={4}
                  >
                    <DocumentPicker
                      handleChange={(selectedCareerStream: any) =>
                        setSelectedCareerStream(selectedCareerStream)
                      }
                      collection="career-streams"
                      name="careerStream"
                      defaultValue={selectedCareerStream}
                      label="Springboard"
                      error={!!!selectedCareerStream}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} item lg={12}>
                  <Grid
                    className="flex-end top-bottom-spacing"
                    item
                    xs={12}
                    md={8}
                    sm={8}
                  >
                    <h3 className="mb-0">Academic & Contextual Information</h3>
                    <p className="body-14 font-weight-normal">
                      If any of the below information is not correct, please
                      contact your Programme Coordinator.
                    </p>
                  </Grid>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                  >
                    <TextField
                      name="aLevelGrades"
                      type={InputType.TEXT}
                      label="A Level Grades"
                      value={aLevelGrades}
                      handleChange={(e: any) => setALevelGrades(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid className="flex-start" item xs={12} sm={4} md={4}>
                    <ToggleButton
                      handleToggle={setALevelGradesVisibility}
                      checked={aLevelGradesVisibility}
                    />
                  </Grid>
                  <Grid
                    className="top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                  >
                    <TextField
                      name="realGrades"
                      type={InputType.TEXT}
                      label="REAL Grades"
                      value={realGrades}
                      handleChange={(e: any) => setRealGrades(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid className="flex-start" item xs={12} sm={4} md={4}>
                    <ToggleButton
                      handleToggle={setRealGradesVisibility}
                      checked={realGradesVisibility}
                    />
                  </Grid>
                  <Grid
                    className="participatingInteractionTypes top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                  >
                    <FormLabel component="legend">
                      Select all that apply from the drop-down box
                    </FormLabel>
                    <DocumentPicker
                      multiple
                      handleChange={(selectedDisadvantageIndicators: any) =>
                        setSelectedDisadvantageIndicators(
                          selectedDisadvantageIndicators
                        )
                      }
                      collection="disadvantage-indicators"
                      name="disadvantageIndicators"
                      required={selectedDisadvantageIndicators?.length === 0}
                      defaultValues={selectedDisadvantageIndicators}
                      label=""
                      disabled
                    />
                  </Grid>
                  <Grid className="flex-start" item xs={12} sm={4} md={4}>
                    <ToggleButton
                      handleToggle={setDisadvantageIndicatorsVisibility}
                      checked={disadvantageIndicatorsVisibility}
                    />
                  </Grid>
                  <Grid
                    className="flex-end top-bottom-spacing"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                  >
                    <TextField
                      name="householdIncome"
                      type={InputType.TEXT}
                      label="Household Income"
                      value={householdIncome}
                      disabled
                      handleChange={(e: any) =>
                        setHouseholdIncome(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid className="flex-start" item xs={12} sm={4} md={4}>
                    <ToggleButton
                      handleToggle={setHouseholdIncomeVisibility}
                      checked={householdIncomeVisibility}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={1} item lg={12}>
              <Grid className="top-bottom-spacing" item xs={12} sm={8} lg={8}>
                <TextField
                  name="linkedinUrl"
                  type={InputType.TEXT}
                  label="LinkedIn profile URL"
                  handleChange={(e: any) => setLinkedinUrl(e.target.value)}
                  value={linkedinUrl}
                />
              </Grid>
            </Grid>

            <Grid className="flex-end top-bottom-spacing" item xs={8} md={8}>
              <h3 className="mb-0">Notification Preferences</h3>
              <p className="body-14 font-weight-normal">
                Use the toggle feature below to select the actions that you
                would like to receive email notifications for. Please note - to
                ensure that you’re aware of requests and new messages, and to
                guarantee the platform runs successfully, you’re unable to turn
                off all notifications.
              </p>
            </Grid>
            <Grid className="mx-4" container item xs={12} md={8}>
              <Grid className="top-spacing" xs={8}>
                <p className="">
                  Receive an email summarising your daily activity
                </p>
              </Grid>
              <Grid className="top-spacing switch-position" item xs={4}>
                <Switch
                  checked={receiveDailyEmail}
                  onClick={() => setReceiveDailyEmail(!receiveDailyEmail)}
                  name="receiveDailyEmail"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid className="mx-4" container item xs={12} md={8}>
              <Grid className="top-spacing" xs={8}>
                {user.roles.includes(UserType.STUDENT) ? (
                  <p className="">
                    Receive email when a professional responds to an interaction
                  </p>
                ) : (
                  <p className="">
                    Receive email when a student sends an interaction
                  </p>
                )}
              </Grid>
              <Grid className="top-spacing switch-position" item xs={4}>
                <Switch
                  checked={receiveRequestEmail}
                  onClick={() => setReceiveRequestEmail(!receiveRequestEmail)}
                  name="receiveRequestEmail"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid className="mx-4" container item xs={12} md={8}>
              <Grid className="top-spacing" xs={8}>
                <p className="">
                  Receive an email when you're sent a new message
                </p>
              </Grid>
              <Grid className="top-spacing switch-position" item xs={4}>
                <Switch
                  checked={receiveNewMessageEmail}
                  onClick={() =>
                    setReceiveNewMessageEmail(!receiveNewMessageEmail)
                  }
                  name="receiveNewMessageEmail"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid className="mx-4" container item xs={12} md={8}>
              <Grid className="top-spacing" xs={8}>
                <p>Receive a reminder email before a call you’ve scheduled</p>
              </Grid>
              <Grid className="top-spacing switch-position" item xs={4}>
                <Switch
                  checked={receiveReminderEmail}
                  onClick={() => setReceiveReminderEmail(!receiveReminderEmail)}
                  name="receiveReminderEmail"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid className="mx-4" container item xs={12} md={8}>
              <Grid className="top-spacing" xs={8}>
                {user.roles.includes(UserType.STUDENT) ? (
                  <p className="">
                    Receive an email when you’re sent feedback from a
                    professional
                  </p>
                ) : (
                  <p className="">
                    Receive an email when you’re sent feedback from a student
                  </p>
                )}
              </Grid>
              <Grid className="top-spacing switch-position" item xs={4}>
                <Switch
                  checked={receiveFeedbackEmail}
                  onClick={() => setReceiveFeedbackEmail(!receiveFeedbackEmail)}
                  name="receiveFeedbackEmail"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid className="mx-4" container item xs={12} md={8}>
              <Grid className="top-spacing" xs={12}>
                {user.roles.includes(UserType.PROFESSIONAL) && (
                  <div>
                    <p className="">
                      Would you like to be available to upReach Alumni in
                      addition to current associates?
                    </p>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      className="radio-color"
                      value={isAvailableForAlumni}
                      onChange={(e: any) =>
                        setIsAvailableForAlumni(
                          e.target.value === "true" ? true : false
                        )
                      }
                    >
                      <div>
                        {availabilityForAlumni.map(
                          (radioButton: RadioButtonData) => (
                            <FormControlLabel
                              // className="gender-radio-groups"
                              className="radio-color"
                              control={<Radio />}
                              value={radioButton.value}
                              label={radioButton.label}
                              disabled={radioButton.disabled}
                            />
                          )
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                )}
              </Grid>
            </Grid>
            {user.roles.includes(UserType.PROFESSIONAL) && (
              <Grid container>
                <Grid
                  className={classes.onlineDiv}
                  container
                  item
                  xs={12}
                  md={8}
                >
                  <h3 className={classes.onlineHeading}>
                    Availability for new Interactions
                  </h3>
                  <Button
                    variant="contained"
                    className={`${classes.onlineBtns} ${
                      isOnline && classes.btn__online
                    }`}
                    onClick={() => setIsOnline(true)}
                  >
                    {!isOnline ? "Appear" : ""} Online
                  </Button>
                  <Button
                    variant="contained"
                    className={`${classes.onlineBtns} ${
                      !isOnline && classes.btn__offline
                    }`}
                    onClick={() => setIsOnline(false)}
                  >
                    {isOnline ? "Appear" : ""} Offline
                  </Button>
                </Grid>
                {!isOnline && (
                  <Grid container spacing={1} item lg={12}>
                    <Grid
                      className="top-bottom-spacing"
                      item
                      xs={12}
                      sm={8}
                      md={4}
                    >
                      <DocumentPicker
                        handleChange={(offlineDuration: any) =>
                          setOfflineDuration(offlineDuration)
                        }
                        defaultCollection={offlineDurationArray}
                        defaultValue={offlineDurationArray.find(
                          (duration) => duration.value === offlineDuration
                        )}
                        name="offline duration"
                        label="Offline Duration"
                        required
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid className="btn-section" container item xs={12} md={12}>
              <Grid className="btn-subcontainer" item xs={11} sm={6} md={7}>
                <Button
                  variant="outlined"
                  onClick={closeDrawer}
                  color="primary"
                  className="cancel-btn"
                >
                  {" "}
                  Cancel{" "}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className="update-btn"
                >
                  {" "}
                  Update{" "}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      </SwipeableDrawer>
    </div>
  );
}
