import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { UserType } from "common/types";
import HomeInteractions from "./HomeInteractions";
import { selectIsLoading, selectUser } from "features/auth/selectors";
import { selectInteractions } from "features/interactions/selectors";
import GettingStarted from "./GettingStarted";
import HomeUserCards from "./HomeUserCards";
import ActivityRemainingCard from "./ActivityRemainingCard";
import Loading from "common/components/Loading";

const Home = () => {
  const user: any = useSelector(selectUser);
  const interactions = useSelector(selectInteractions);
  const isLoading = useSelector(selectIsLoading);
  const activeInteractions = user
    ? interactions.filter(
        (interaction: any) =>
          interaction.state !== "COMPLETED" &&
          interaction.state !== "EXPIRED" &&
          interaction.state !== "DECLINED" &&
          // created new for interactComplete
          interaction.state !== "INTERACTIONCOMPLETED" &&
          interaction.state !== "INTERACTION_NOT_OVER" &&
          interaction.state !== "INT_NO_SHOW" &&
          interaction.state !== "INT_NO_SHOW_COMPLETE" &&
          interaction.state !== "INT_NO_SHOW_SORRY_COMPLETE" &&
          interaction.state !== "SORRY_MODAL_CLOSE" 
      )
    : [];

    const review = user
    ? interactions.filter(
        (interaction: any) =>
          interaction.state !== "EXPIRED" &&
          interaction.state !== "DECLINED" &&
          interaction.state !== "INTERACTIONCOMPLETED" &&
           !((interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) ||
          interaction.state === "EXPIRED" ||
          interaction.state === "DECLINED")
          && !(interaction.feedbackGiven[user.id] ||
          interaction.state === "EXPIRED" ||
          interaction.state === "DECLINED") && !((interaction?.remindMeLater && interaction?.remindMeLater[user.id]) || 
          interaction.state === "EXPIRED" || 
          interaction.state === "DECLINED")
          && !(interaction?.appologyMessage && interaction.appologyMessage[user.id])
          && !(interaction?.sorryModalClose && interaction.sorryModalClose[user.id])
          && !(interaction?.appologyModalClose && interaction.appologyModalClose[user.id])
      )
    : [];

  // created new for interactioncomplete
  // In below code Changed COMPLETED with  "INTERACTIONCOMPLETED"
     const awaitingFeedbackInteractions = user
    ? interactions.filter(
        (interaction: any) =>(interaction?.remindMeLater && interaction?.remindMeLater[user.id] && 
          !interaction.feedbackGiven[user.id] && !(interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) &&
          interaction.state === "INTERACTIONCOMPLETED") ||
          (!interaction.feedbackGiven[user.id] && !(interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) &&
          interaction.state === "INTERACTIONCOMPLETED") 
      )
    : [];

  const pastInteractions = user
    ? interactions.filter(
        (interaction: any) =>
          interaction.feedbackGiven[user.id] || (interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) ||
          interaction.state === "EXPIRED" ||
          interaction.state === "DECLINED" 
          || (interaction?.appologyMessage && interaction.appologyMessage[user.id])
          || (interaction?.sorryModalClose && interaction.sorryModalClose[user.id])
          || (interaction?.appologyModalClose && interaction.appologyModalClose[user.id])
      )
    : [];

  if (isLoading) return <Loading />;

  return (
    <StyledWrapper>
      <Container>
        {interactions?.length === 0 && (
          <>
            <Grid container>
              <Grid item sm={8} className="username-spacing">
                <h1 className="username">Welcome {user?.firstName},</h1>
                <h1 className="username">
                  {(user?.roles.includes(UserType.PROFESSIONAL) ||
                    user?.roles.includes(UserType.ALUMNI)) &&
                  user?.company
                    ? "Thanks for being part of the network for those without a network."
                    : "Start making connections and build your network!"}
                </h1>
              </Grid>
              {(user?.roles.includes(UserType.STUDENT) ||
                user?.roles.includes(UserType.ALUMNI)) &&
                !user?.company && (
                  <Grid item sm={4} className="activity-remaining-card">
                    <ActivityRemainingCard interactions={interactions} />
                  </Grid>
                )}
              <Grid className="activity-container">
                <Grid item sm={12}>
                  <GettingStarted />
                </Grid>
              </Grid>
              <Grid container>
                <HomeUserCards />
              </Grid>
            </Grid>
          </>
        )}
        {interactions?.length > 0 && (
          <HomeInteractions
            user={user}
            review = {review}
            interactions={interactions}
            activeInteractions={activeInteractions}
            pastInteractions={pastInteractions}
            awaitingFeedbackInteractions={awaitingFeedbackInteractions}
          />
        )}
      </Container>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;

  .username {
    font-size: 34px;
    font-weight: 400;
    letter-spacing: 0px;
    margin: 0;
  }

  .interaction-type {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 70px;
  }

  .username-spacing {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .activity-container {
    display: flex;
  }
  .activity-tracker {
    position: relative;
    background: #f1f2ff;
    border: 1px solid rgba(70, 79, 208, 0.1);
    box-sizing: border-box;
  }

  .activity-tracker {
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000000;
    padding: 10px 20px 20px 20px;
  }

  .homeinteraction-search-btn {
    margin-top: 30px;
  }

  .professional-section {
    a {
      text-decoration: none;
    }
    .professional-search {
      background-color: #464fd0;
      padding: 16px 25px;
      font-weight: 400;
      margin-bottom: 30px;
      text-transform: capitalize;
      font-size: 18px;
    }
  }

  .activity-remaining-card {
    max-width: 300px;
    margin-top: 30px;
    margin-left: auto;
  }

  .tracking-numbers {
    font-size: 18px;
    line-height: 25px;
    color: #464fd0;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .options {
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .action-req-section {
    margin-bottom: 80px;
    max-width: 90%; 
  }

  .contact {
    font-size: 12px;
    line-height: 20px;
  }

  .helper {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .card-intro {
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
  }

  .v-divider {
    border: 2px solid #9ea5ee;
    background-color: #9ea5ee;
    transform: rotate(0deg);
    height: 150px;
    margin: auto;
  }

  .getting-started-text {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    color: #304563;
    margin: auto;
    padding-left: inherit;
    li {
      margin-bottom: 15px;
    }
  }

  .the-network-so-far-text {
    color: #304563;
  }

  .identify-pro {
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    text-decoration-line: underline;
    color: #464fd0;
  }

  .flag-logo {
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .want-to-see {
    padding: 20px;
  }

  .want-to-see-list {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .want-to-see-heading {
    margin-top: 10px;
  }
`;

export default Home;
