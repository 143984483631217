import { Message } from 'common/types';

type Props = {
    message: Message;
};
const SorryMessage = ({ message }: Props) => {
    if (message.sender === "SYSTEM") return null;
    return (
      <div className="interaction-texts">{message.payload}</div>
    )
}
export default SorryMessage;