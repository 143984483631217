import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { Message, MessageType } from "common/types";
import styled from "styled-components";
import {
  selectInteractions,
  selectActiveInteractionId,
  selectMessageSendInProgress,
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { setMessageSendInProgress } from "../slice";
import recordEvent from "common/utils/mixpanel";
import { selectUser } from "features/auth/selectors";

const TimeSelectionInput = () => {
  const [activeInteraction, setActiveInteraction] = useState<any>();
  const interactions = useSelector(selectInteractions);
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const isDisabled = useSelector(selectMessageSendInProgress);
  const user: any = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveInteraction(
      interactions?.filter((interaction: any) => interaction.id === activeInteractionId).pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (time: Date) => {
    recordEvent("Availability Selected For Student", {
      "Date Selected": time.toString(),
      "Student Gender": user?.gender,
      "Student Region": user?.region?.name,
      "Student Ethnicity": user?.ethnicity?.name,
      "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
    });
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.SELECT_TIME,
      payload: time,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId,
        message,
      },
    });
  };

  const onRequestAnotherTime = () => {
    recordEvent("Request Another Time Clicked", {
      "Student Gender": user?.gender,
      "Student Region": user?.region?.name,
      "Student Ethnicity": user?.ethnicity?.name,
      "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
    });
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.REQUEST_ADDITIONAL_TIMES,
      payload: null,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId,
        message,
      },
    });
  };

  return (
    <StyledWrapper>
      <p className="body-14 time-selection-heading">Select the time which works best for you</p>
      <Grid className="timing-container" spacing={2} container>
        {activeInteraction &&
          activeInteraction.suggestedTimes.map((time: any) => (
            <Grid item xs={12} sm={4}>
              <Button
                disabled={isDisabled || new Date() > new Date(time)}
                onClick={() => handleClick(time)}
                className={`time-selection-input timing-suggested body-12`}
              >
                {format(parseISO(time), "do LLL yyyy - hh:mm 	a")}
              </Button>
            </Grid>
          ))}
      </Grid>
      <Grid item xs={12}>
        <Button disabled={isDisabled} onClick={() => onRequestAnotherTime()} color="primary" className="anothertime-color">
          Request another time
        </Button>
      </Grid>
    </StyledWrapper>
  );
};

export default TimeSelectionInput;

const StyledWrapper = styled.div`
  .time-selection-input {
    background: rgba(158, 165, 238, 0.1);
    border: 1px solid rgba(158, 165, 238, 0.2);
    color:rgba(0, 0, 0, 0.87);
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;

    :hover {
      background: rgba(158, 165, 238, 0.1);
    }
  }

  .time-selection-heading {
    font-weight: 500;
  }
  .anothertime-color{
    color: #3f51b5 !important;
  }
`;
