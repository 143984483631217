import React from 'react';
import { Pagination as MaterialPagination } from '@mui/material';
import FromAlgolia from 'common/components/FromAlgolia';
import styled from "styled-components";

export interface Props {
  count: number;
  value: number;
  onChange: (event: React.ChangeEvent<unknown>, pageNo: number) => void;
}

const Pagination = ({ count, value, onChange }: Props) => {
  return (
    <StyledWrapper>
      <MaterialPagination
        count={count}
        onChange={onChange}
        shape="rounded"
        color="primary"
        size="large"
        page={value}
      />
      <FromAlgolia />
      </StyledWrapper>
  );
};

export default Pagination;

const StyledWrapper = styled.div`

  .algolia-logo {
    margin: 30px auto auto auto;
  }
`;