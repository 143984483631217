import styled from "styled-components";
const ProfessionalResources = () => {
    return (
        <StyledWrapper>
            <div className="container contactUs mt-5 pb-5 ">
                <div>
                    <h3 className="h2 mb-3">Resources</h3>
                    <p>On this page, you can find a number of short guides to support you in engaging with the students on the Social Mobility Network. If you have any questions or would like additional support, please get in touch with the Social Mobility Network team via the 'Contact Us' tab.</p>
                </div>
                <a href="/SMNIntroduction.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">An Introduction to the Social Mobility Network</h5>
                        <small>This guide introduces you to the Social Mobility Network and why we launched the platform, as well as how to build your profile and set your preferences.</small>
                    </div>
                </a>
                <a href="/SMNSafeguardingGuide.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">The Social Mobility Network Safeguarding Guide</h5>
                        <small>This guide runs through the steps you should take if you encounter a safeguarding concern on the platform and provides guidance on how to respond in the moment.</small>
                    </div>
                </a>
                <a href="/SMNInteractionsLatest.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">Interactions on the Social Mobility Network</h5>
                        <small>This guide explores each of the different interaction types, how they are scheduled, how best to prepare, and provides top tips on how to support our students.</small>
                    </div>
                </a>
                <a href="/SMNPreparingforSpecificQuestions.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">How to Prepare for Specific Questions</h5>
                        <small>This guide provides an overview of how to support a student with CV, Cover Letter, and Application Reviews, as well as how to help them build their commercial awareness.</small>
                    </div>
                </a>
                <a href="/SMNMockInterviewGuide.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs">
                        <h5 className="fw-bold">Mock Interview Guide</h5>
                        <small>This guide supports you in running a Mock Interview by providing top tips, an overview of different types of interview questions, and what to look for in a student's response.</small>
                    </div>
                </a>
                <a href="/SMNMockInterviewTemplate.pdf#toolbar=0" target="_blank">
                    <div className="boxed contactUs pb-4">
                        <h5 className="fw-bold">Mock Interview Template</h5>
                        <small>This guide contains an example structure that you can use to facilitate a Mock Interview, as well as marking criteria to support you in providing feedback.</small>
                    </div>
                </a>
            </div>
        </StyledWrapper>)
}
export default ProfessionalResources;
const StyledWrapper = styled.div`
.boxed{
    border:1px solid grey;
    padding:20px;
    margin-bottom :20px;
    background-color : white;
}
a{
    text-decoration:none;
    color:black;
}
`;