import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setIsUpdateDrawerOpen, setPromptModal } from "features/auth/slice";
import { Link } from "react-router-dom";
import { selectAuthUser } from "features/auth/selectors";

const AboutAlumniModal = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(selectAuthUser);

    const handleEditProfileClick = () => {
        dispatch(setPromptModal({ isOpen: false, ModalType: null }));
        dispatch(setIsUpdateDrawerOpen(true));
    }

    return (
        <ModalContainer>
            <h3 className="modal-heading">upReach Alumni are being onboarded to the Social Mobility Network</h3>
            <p className="body-14 font-weight-normal">We've recently worked on some changes to the platform which now means that Alumni of an upReach programme
                can log in and give support to our Associates and receive support from each other and professional volunteers.</p>
            <p className="body-14 font-weight-normal">Our upReach Alumni have all graduated from university and were on one of our intensive programmes of employability support.
                They are all still very early on in their career and will be able to reach out to you and other professional volunteers in the same way an Associate can.
                This is so that they can broaden their network. </p>
            <p className="body-14 font-weight-normal">Many will already have a graduate job, and some will not have one yet. </p>
            <p className="body-14 font-weight-normal">If you'd like to only support Associates (and not upReach Alumni), you can edit your preferences in your profile settings. </p>

            <Button
                variant="outlined"
                color="secondary"
                className="mr-5 mt-5 font-family-nunito"
                onClick={() => dispatch(setPromptModal({ isOpen: false, ModalType: null }))}
            >
                Cancel
            </Button>
            <Link className="text-decoration-none" to={`/users/${authUser?.id}`} >
                <Button
                    onClick={handleEditProfileClick}
                    variant="outlined"
                    color="primary"
                    className="font-family-nunito mt-5"
                >
                    Edit My Profile
                </Button>
            </Link>
        </ModalContainer>
    )
}

export default AboutAlumniModal;

const ModalContainer = styled.div`
    .mr-5 {
        margin-right: 50px;
    }
    .mt-5 {
        margin-top: 50px;
    }
    .modal-heading {
        font-size: 1.4rem;
    }
    .text-decoration-none {
        text-decoration: none;
    }
`;
