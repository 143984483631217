// import { useState } from "react";
import { User, UserType,MessageType, Message } from "common/types";
import styled from "styled-components";
import {
    Button,
    Dialog,
    DialogContent
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "features/auth/selectors";
import { Grid } from "@mui/material";
// import { useEffect } from "react";
import { selectActiveInteractionId } from "../selectors";
import YesOver from "common/assets/images/svgToComponent/YesOver";
import NotOver from "common/assets/images/svgToComponent/NotOver";
// import SorryModal from "./SorryModal";
// import StudentSorryModal from "./StudentSorrymodal";

type Props = {
    interactionId: string;
    isCorrectModalOpen: boolean;
    setIsCorrectModalOpen: any;
    text: any;
    setText: any;
    closeModal: () => void;
};

const IsCorrectModal = ({ interactionId, isCorrectModalOpen, setIsCorrectModalOpen, text, setText, closeModal }: Props) => {

    const user: User | null = useSelector(selectUser);
    const dispatch = useDispatch();
    // const interactions = useSelector(selectInteractions);
    const activeInteractionId = useSelector(selectActiveInteractionId);
    // const [activeInteraction, setActiveInteraction] = useState<any>();
    // const [isSorryModalOpen, setIsSorryModalOpen] = useState<boolean>(false);
    // const [isStudentSorryModalOpen, setIsStudentSorryModalOpen] = useState<boolean>(false);

    // useEffect(() => {
    //     setActiveInteraction(
    //         interactions
    //             ?.filter((interaction: any) => interaction.id === activeInteractionId)
    //             .pop()
    //     );

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [interactions]);

    const sendMessage = () => {
        // dispatch({
        //     type: "INT_NO_SHOW",
        //     payload: { activeInteractionId },
        //   });
        if (user?.roles.includes(UserType.STUDENT)) {
            closeModal();
            setIsCorrectModalOpen(false);
            if(text === "Professional"){
                const message: Partial<Message> = {
                type: MessageType.PROFESSIONAL_NO_SHOW,
                payload: "ProfNoShow",
            };
            dispatch({
                type: "UNABLE_TO_ATTEND",
                payload: {
                    activeInteractionId: activeInteractionId,
                    message,
                },
            })
            // setIsSorryModalOpen(true)
            } else {
                const message: Partial<Message> = {
                    type: MessageType.STUDENT_NO_SHOW,
                    payload: "StudNoShow",
                };
                dispatch({
                    type: "UNABLE_TO_ATTEND",
                    payload: {
                        activeInteractionId: activeInteractionId,
                        message,
                    },
                })
                // setIsStudentSorryModalOpen(true)
            }
        } 
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            closeModal();
            setIsCorrectModalOpen(false);
           
            if(text === "Student"){
                const message: Partial<Message> = {
                    type: MessageType.STUDENT_NO_SHOW,
                    payload: "StudNoShow",
                };
                dispatch({
                    type: "UNABLE_TO_ATTEND",
                    payload: {
                        activeInteractionId: activeInteractionId,
                        message,
                    },
                })
                // setIsStudentSorryModalOpen(true)

            }  else {
                const message: Partial<Message> = {
                    type: MessageType.PROFESSIONAL_NO_SHOW,
                    payload: "ProfNoShow",
                };
                dispatch({
                    type: "UNABLE_TO_ATTEND",
                    payload: {
                        activeInteractionId: activeInteractionId,
                        message,
                    },
                })
                // setIsSorryModalOpen(true);
            }

        }

    }
    const doNotSendMessage = () => {
        if (user?.roles.includes(UserType.STUDENT)) {
            setIsCorrectModalOpen(false)
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            setIsCorrectModalOpen(false)
        }
    }
    return (
        <StyledWrapper>
            <Dialog
                fullWidth
                className="post-interaction-background bubble"
                maxWidth="sm"
                open={isCorrectModalOpen}
                onClose={() => setIsCorrectModalOpen(true)}
            >
                <DialogContent>
                    <Grid className="text-center my-3 f20">
                        <span>You have selected that the interaction did not take place because</span>
                        {
                            ((user?.roles.includes(UserType?.PROFESSIONAL) && text === "Professional") || (user?.roles.includes(UserType?.STUDENT) && text === "Student")) &&
                            <span className="font-weight-bold px-1">you were unable to attend.</span>
                        }
                        {
                            user?.roles.includes(UserType?.STUDENT) && text === "Professional" &&
                            <span className="font-weight-bold px-1">the professional did not attend.</span>
                        }
                        {
                            user?.roles.includes(UserType?.PROFESSIONAL) && text === "Student" &&
                            <span className="font-weight-bold px-1">the student did not attend.</span>
                        }
                    </Grid>
                    <Grid className="text-center f20">
                        Is this correct?
                    </Grid>
                </DialogContent>
                <DialogContent className="modal-content my-3">
                    <Grid container direction="row" justifyContent="center">
                        <Grid direction="column" item md={6} style={{ display: "flex" }}>
                            <Button variant="text" color="primary" onClick={sendMessage}>
                                <Grid direction="column" justifyContent="center">
                                    <Grid item><YesOver /></Grid>
                                    <Grid item>Yes</Grid>
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid direction="column" item md={6} style={{ display: "flex" }}>
                            <Button variant="text" color="primary" onClick={doNotSendMessage}>
                                <Grid direction="column">
                                    <Grid item><NotOver /></Grid>
                                    <Grid item>No</Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </StyledWrapper>
    );
};

export default IsCorrectModal;

const StyledWrapper = styled.div`
.interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
  }

  .modal-content {
    height: 1000px !important; /* Set your desired height here */
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }

  .click-here {
    margin-top: 30px;
  }
  .MuiButton-label {
    display: unset !important;
  }
  .Button {
    color: red !important;
  }
`;
