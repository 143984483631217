import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { setPromptModal } from "features/auth/slice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "600px",
      margin: "auto",
      [useTheme().breakpoints.down('xl')]: {
        width: "600px",
      },
      [useTheme().breakpoints.down('lg')]: {
        width: "400px",
      },
      [useTheme().breakpoints.down('sm')]: {
        width: "320px",
      },
    },
    paper: {
      backgroundColor: useTheme().palette.background.paper,
      boxShadow: useTheme().shadows[5],
      zIndex:"1",
      padding: "30px 100px 60px 100px",
      [useTheme().breakpoints.down('xl')]: {
        padding: "25px 80px 45px 80px",
      },
      [useTheme().breakpoints.down('lg')]: {
        padding: "22.5px 60px 40px 60px",
      },
      [useTheme().breakpoints.down('sm')]: {
        padding: "20px 45px 35px 45px",
      },
    },
    redirectButton: {
      fontFamily: "Nunito Sans",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
      letterSpacing: "0px",
      height: "40px",
      width: "200px",
      background: "#464FD0",
      marginTop: "20px",
    },
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "32px",
      color: "#304563",
    },
    subtext: {
      fontFamily: "Nunito Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    closeButton: {
      color: "#464FD0",
      marginLeft: "auto",
      display: "flex",
    },
  })
);

export default function TestimonialNotifyModal() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setPromptModal({ isOpen: false, ModalType: "NOTIFY_TESTIMONIAL" })
    );
  };

  const handleAddTestimonial = () => {
    handleClose();
    dispatch(setPromptModal({ isOpen: true, ModalType: "ADD_TESTIMONIAL" }));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className={classes.paper}>
            <Button
              onClick={handleClose}
              className={classes.closeButton}
              color="primary"
            >
              <CloseIcon />
            </Button>
            <p className={classes.heading}>Testimonial</p>
            <p className={classes.subtext}>
              You've reached 10 interactions on the platform. As we continue to
              grow the platform, we'd like to show the positive impact the
              platform is having. Would you like to tell us about your
              experience of the platform?
            </p>
            <Button
              variant="contained"
              className={classes.redirectButton}
              color="primary"
              onClick={handleAddTestimonial}
            >
              Add Testimonial
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
