import { Alumni, Professional, Student } from "common/types";
import { getAxiosInstance } from "common/utils";

export const createUserProfile = async (user: Professional | Student | Alumni) => {
  const axios = await getAxiosInstance();
  const resp = await axios.post("/users/createUserProfile", {
    ...user,
  });
  return resp;
};

export const updateUserProfile = async (user: Professional | Student | Alumni) => {
  const axios = await getAxiosInstance();
  const resp = await axios.post("/users/updateUserProfile", {
    ...user,
  });
  return resp;
};
