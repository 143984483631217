import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import network from "../../../common/assets/images/network.png"
// import styled from "styled-components";
import Button from '@mui/material/Button';
import linkedin from "../../../common/assets/images/linkedin.png"
import PreLoginHeader from '../PreLoginHeader';
import { initiateLinkedInLogin } from 'features/auth/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectFirstLoad, selectIsLoading, selectUser } from 'features/auth/selectors';
import { useHistory } from 'react-router-dom';
import Loading from "common/components/Loading";
import styled from '@emotion/styled';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

const AssociatesLogin = () => {

    const user = useSelector(selectUser);
    const history = useHistory();
    const isLoading = useSelector(selectIsLoading);
    const isFirstLoad = useSelector(selectFirstLoad);
    useEffect(() => {
        if (user && user.id) history.push("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    const classes = useStyles();

    if(isLoading || isFirstLoad) return <Loading message="Please wait while we retrieve your information." />;

    return (
        <StyledWrapper className="background-white">
            <PreLoginHeader />
            <div className={`${classes.root} w-100-percent`}>
                <Container className="w-100-percent" maxWidth="xl">
                    <Grid className="center-content w-100-percent d-flex align-items-center justify-content-center" container>
                        <Grid className="network-section content-center mx-auto d-block" item xs={12} md={6}>
                            <div className="linkedin-section d-block mx-auto">
                                <h1 className="network-intro color-gulf-blue color-gulf-blue">The network for those without a network</h1>
                                <p className="body-24 color-gulf-blue">
                                    Login to connect with professionals ready to support you.
                            </p>
                                <Button className="linkedin-login" onClick={initiateLinkedInLogin} variant="contained" color="primary">
                                    <img className="linkedin-logo" src={linkedin} alt="" />
                                </Button>
                            </div>
                        </Grid>
                        <Grid className="network-section content-center" item xs={12} md={6}>
                            <img className="img-fluid object-fit-cover" src={network} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </StyledWrapper>
    )
}

export default AssociatesLogin;


const StyledWrapper = styled.div`

    .w-100-percent {
        height: 90vh;
    }

    .linkedin-section {
        width: 100%;
        max-width: 420px;
    }

    .content-center {
        align-items: unset !important;
        position: relative;
    }

    .network-intro {
        font-weight: 200;
        max-width: 400px;
    }

    .linkedin-login {
        height: 70px;
        width: 100%;
        max-width: 379px;
        border-radius: 4px;
        background-color: #0077B7 !important;
        margin-top: 20px;


        .linkedin-logo {
            height: 60px;
            width: 100%;
            max-width: 350px;
            object-fit: contain;
        }

        .sign-in-linkedin {
            font-size: 15px;
            font-weight: 800;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: 20px;
        }
    }
`;
