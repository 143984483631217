import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export type SearchSlice = {
  filter: string;
  sectorFilter: string;
  pageNumber: string;
};

export const initialState: any = {
  filter: "",
  sectorFilter: "",
  interactionFilter: "",
  companyFilter: "",
  universityFilter: "",
  regionFilter: "",
  genderFilter: "",
  pageNumber: 0,
  filteredProfessionals: []
};

const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setFilter: (state: any, action: PayloadAction<string>): any => {
      state.filter = action.payload;
    },
    setSectorFilter: (state: any, action: PayloadAction<string>): any => {
      state.sectorFilter = action.payload;
    },
    setInteractionFilter: (state: any, action: PayloadAction<string>): any => {
      state.interactionFilter = action.payload;
    },
    setCompanyFilter: (state: any, action: PayloadAction<string>): any => {
      state.companyFilter = action.payload;
    },
    setUniversityFilter: (state: any, action: PayloadAction<string>): any => {
      state.universityFilter = action.payload;
    },
    setRegionFilter: (state: any, action: PayloadAction<string>): any => {
      state.regionFilter = action.payload;
    },
    setGenderFilter: (state: any, action: PayloadAction<string>): any => {
      state.genderFilter = action.payload;
    },
    setPageNumber: (state: any, action: PayloadAction<number>): any => {
      state.pageNumber = action.payload;
    },
    setFilteredProfessionals: (state: any, action: PayloadAction<any[]>): any => {
      state.filteredProfessionals = action.payload;
    }
  },
});

export const { 
    setFilter, 
    setSectorFilter, 
    setInteractionFilter, 
    setCompanyFilter, 
    setUniversityFilter,
    setRegionFilter,
    setGenderFilter,
    setPageNumber,
    setFilteredProfessionals 
} = slice.actions;

export default slice.reducer;
