import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from "react-redux";
import { DialogContentText } from '@mui/material';
import { useState } from 'react';

type Props = {
    setPasswordResetModalOpen: any,
    passwordResetModalOpen: boolean,
}

export default function PasswordResetModal(props: Props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const onSubmit = (email: string) => {
    dispatch({ type: "RESET_PASSWORD", payload: {email: email} })
    props.setPasswordResetModalOpen(false);
  };

  return (
      <Dialog fullWidth maxWidth="sm" open={props.passwordResetModalOpen} onClose={() => props.setPasswordResetModalOpen(false)} className="pwd-reset-dialog" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Recover Password</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Please enter the email address you used to register. We will send you a new password.
          </DialogContentText>
          <TextField
            className="reset-email"
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            variant="outlined"
            type="email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button className="cancel-popup" onClick={() => props.setPasswordResetModalOpen(false)} color="primary">
            Close
          </Button>
          <Button variant="contained" className="submitEmail" onClick={() => onSubmit(email)} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  );
}
