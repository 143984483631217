import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import TextField, { InputType } from "../../common/components/TextField";
import { Button, Grid, Typography } from "@mui/material";
// import styled from "styled-components";
import { useForm } from "react-hook-form";
import Logo from "common/assets/images/blue_logo.svg";
import {
  selectFirstLoad,
  selectLoginFormWorking,
  selectOnLoginSyncInProgress,
  selectUser,
} from "./selectors";
import { initiateLinkedInLogin } from "./utils";
import CircularProgress from "@mui/material/CircularProgress";
import PasswordResetModal from "./PasswordResetModal";
import Loading from "common/components/Loading";
import styled from "@emotion/styled";

type LoginFormType = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const { register, handleSubmit, errors } = useForm<LoginFormType>();
  const user = useSelector(selectUser);
  const loginFormWorking = useSelector(selectLoginFormWorking);
  const onLoginSyncInProgress = useSelector(selectOnLoginSyncInProgress);
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const firstLoad = useSelector(selectFirstLoad);

  useEffect(() => {
    if (user && user.id) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = (data: LoginFormType) =>
    dispatch({ type: "LOGIN_ATTEMPT", payload: data });

  const openPasswordResetModal = () => {
    setPasswordResetModalOpen(true);
  };

  if (firstLoad) return <Loading />;

  return (
    <StyledWrapper onSubmit={handleSubmit(onSubmit)}>
      <Grid
        spacing={3}
        container
        item
        xs={10}
        sm={6}
        md={4}
        lg={3}
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Grid container item justifyContent="space-evenly">
          <img src={Logo} alt="" />
        </Grid>
        <Grid container item>
          <TextField
            name="email"
            inputRef={register({
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please enter a valid email address",
              },
            })}
            type={InputType.TEXT}
            label="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid container item>
          <TextField
            name="password"
            inputRef={register({
              required: "This field is required",
            })}
            type={InputType.PASSWORD}
            label="password"
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Grid>
        <p
          className="reset-option"
          onClick={() => openPasswordResetModal()}
        >
          Forgot your password?
        </p>
        <Grid container item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            fullWidth
          >
            {loginFormWorking || onLoginSyncInProgress ? (
              <CircularProgress color="inherit" />
            ) : (
              "LOGIN"
            )}
          </Button>
        </Grid>
        <Grid container item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={initiateLinkedInLogin}
          >
            LOGIN With LinkedIn
          </Button>
        </Grid>
        <Grid container item justifyContent="space-evenly">
          <Typography variant="body1">
            Don't have an account yet? <Link to="/register">Register</Link>
          </Typography>
        </Grid>
      </Grid>
      <PasswordResetModal
        passwordResetModalOpen={passwordResetModalOpen}
        setPasswordResetModalOpen={setPasswordResetModalOpen}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.form`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 0.75rem;
    margin: 1rem 3rem;
  }
  img {
    margin: 1rem 3rem;
  }

  .reset-option {
    margin-left: auto;
    margin-right: 12px;
  }
`;

export default LoginForm;
