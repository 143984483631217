import {Theme}  from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import { useTheme } from '@mui/material/styles';

// import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal';
import { setPromptModal } from 'features/auth/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPromptModal } from 'features/auth/selectors';
import RequestReschedulePromptModal from './modals/RequestReschedulePromptModal';
import DeclineRequestPromptModal from './modals/DeclineRequestPromptModal';
import NoShowPromptModal from './modals/NoShowPromptModal';
import WarningMessageForUserProfileEdit from './modals/WarningMessageForUserProfileEdit';
import AboutAlumniModal from './modals/AboutAlumniModal';
import TestimonialModal from './modals/TestimonialModal';
import ThankYouMessagesModal from './modals/ThankYouMessageModal';
import SuggestedProfessionalsModal from './modals/SuggestedProfessionalsModal';
import TestimonialNotifyModal from './modals/TestimonialNotifyModal';
import OfferModal from './modals/OfferModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      padding: useTheme().spacing(4.5, 9, 5),
      width: '600px',
      [useTheme().breakpoints.down('xl')]: { 
        padding: useTheme().spacing(4, 5, 4),
        width: '500px'
      },
      [useTheme().breakpoints.down('lg')]: { 
        padding: useTheme().spacing(3, 3.5, 4),
        width: '400px'
      },
      [useTheme().breakpoints.down('sm')]: { 
        padding: useTheme().spacing(2, 2, 3),
        width: '320px',
      },
      backgroundColor: "#F3F3FB",
      borderRadius: '7px ',
      boxShadow: useTheme().shadows[5],
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const PromptModal = () => {
  const classes = useStyles();
  const PromptModel = useSelector(selectPromptModal);
  const dispatch = useDispatch();

  return (
    <div className="prompt-modal">
      <Modal// `disableBackdropClick` is removed by codemod.
// You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

        open={PromptModel.isOpen}
        onClose={() => dispatch(setPromptModal({ isOpen: false, ModalType: null }))}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}>
        <div className={classes.paper}>
          {PromptModel.ModalType === "NO_SHOW" &&
            <NoShowPromptModal />
          }
          {PromptModel.ModalType === "DECLINE_REQUEST" &&
            <DeclineRequestPromptModal />
          }
          {PromptModel.ModalType === "REQUEST_RESCHEDULE" &&
            <RequestReschedulePromptModal />
          }
          {PromptModel.ModalType === "SAVE_WARNING_MESSAGE_FOR_EDIT_PROFILE" &&
            <WarningMessageForUserProfileEdit />
          }
          {PromptModel.ModalType === "ABOUT_ALUMNI" &&
            <AboutAlumniModal />
          }
          {PromptModel.ModalType === "ADD_TESTIMONIAL" &&
            <TestimonialModal />
          }
          {PromptModel.ModalType === "THANKYOU_MESSAGE" &&
            <ThankYouMessagesModal />
          }
          {PromptModel.ModalType === "SUGGEST_PROFESSIONALS" &&
            <SuggestedProfessionalsModal />
          }
          {PromptModel.ModalType === "NOTIFY_TESTIMONIAL" &&
            <TestimonialNotifyModal />
          }
          {PromptModel.ModalType === "OFFER_MODAL" &&
            <OfferModal />
          }
        </div>
      </Modal>
    </div>
  );
}

export default PromptModal;

