import { Interaction, User, UserType } from "common/types";
import { selectUser } from "features/auth/selectors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import styled from "styled-components";
import overview from "../../common/assets/images/overview.svg";
import { requiresUserResponse } from "./utils";
import styled from "@emotion/styled";

const OverviewCard = ({ activeInteractions }: any) => {
  const user: User | null = useSelector(selectUser);

  const [actionRequired, setActionRequired] = useState(0);
  const [awaitingResponse, setAwaitingResponse] = useState(0);

  useEffect(() => {
    const userInteractionCounter = async () => {
      if (user) {
        let action = 0;
        let awaiting = 0;
        const otherUserType =
          user?.roles.includes(UserType.STUDENT)
            ? UserType.PROFESSIONAL
            : UserType.STUDENT;
        activeInteractions.forEach((interaction: Interaction) => {
          if (requiresUserResponse(interaction, user.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT)) action++;
          if (requiresUserResponse(interaction, otherUserType)) awaiting++;
        });

        setActionRequired(action);
        setAwaitingResponse(awaiting);
      }
    };
    userInteractionCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInteractions]);

  return (
    <StyledWrapper className="background-gulf-blue border-radius-5 card-height overview-card">
      <div className="overview-padding min-height-cards">
        <div className="d-flex">
          <img src={overview} alt="" />
          <p className="body-20 font-weight-bolder color-white overview-heading">
            Overview
          </p>
        </div>
          <div className="statistics">
            <p className="body-16 font-weight-normal color-white">
              {" "}
              <b className="figure"> {actionRequired} </b> <a className={actionRequired > 0 ? "hover-style" : "color-white-hover"}  href="#actionRequired"> Action{actionRequired <= 1 ? "" : "s"} Required</a>
            </p>
            <p className="body-16 font-weight-normal color-white">
              {" "}
              <b className="figure"> {awaitingResponse} </b>Awaiting Response{awaitingResponse <= 1 ? "" : "s"}
            </p>
          </div>
      </div>
    </StyledWrapper>
  );
};

export default OverviewCard;

const StyledWrapper = styled.div`
  .overview-padding {
    padding: 10px 20px;
  }

  .overview-card {
    min-height: 215px;
  }

  .overview-heading {
    margin-left: 10px;
    margin-top: revert !important;
  }

  // .statistics {
  //   margin-top: -9px;
  // }

  .figure {
    font-size: 18px;
    font-weight: 400;
    margin-top: 2px;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .hover-style {
    color: white;
  }

  .color-white-hover {
    color: white;
    text-decoration: none;
    cursor: auto;
  }
`;
