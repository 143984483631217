import { Button } from "@mui/material";
import RadioButtonGroup, {
  RadioButtonData,
} from "common/components/RadioButtonGroup";
import StepperControls from "common/components/StepperControls";
import TextField, { InputType } from "common/components/TextField";
import { EmailChoices, GenderChoices, UserType } from "common/types/";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import {
  BasicDetails,
  goToNextStep,
  goToPrevStep,
  setBasicDetails,
  setMetadata,
} from "./studentRegistrationSlice";
import DocumentPicker from "../../../common/components/DocumentPicker";
import { useEffect, useState } from "react";
import { selectBasicDetails, selectMetadata, selectStepperActiveStep } from "./selectors";
import { setIsNextStep } from "../professional/professionalRegistrationSlice";
import { selectIsNextStep } from "../professional/selectors";
import { selectAuthUser, selectUser } from "features/auth/selectors";
import firebase from "firebase";
import Avatar from "@mui/material/Avatar";
import { setOnLoginRedirectRoute } from "features/auth/slice";
import ToggleButton from "common/components/ToggleButton";
import {CircularProgress} from "@mui/material";
import styled from "@emotion/styled";

const genderRadioButtons: Array<RadioButtonData> = [
  { label: "Male", value: GenderChoices.MALE },
  { label: "Female", value: GenderChoices.FEMALE },
  { label: "Other", value: GenderChoices.OTHER },
  { label: "Do not wish to specify", value: GenderChoices.NOT_SPECIFIED },
];

const preferredEmailChoices: Array<RadioButtonData> = [
  { label: "University Email", value: EmailChoices.UNIVERSITY_EMAIL },
  { label: "Personal Email", value: EmailChoices.PERSONAL_EMAIL },
];

type Props = {
  isMyAccount?: boolean;
}

const PersonalDetails = ({ isMyAccount }: Props) => {
  const basicDetails: BasicDetails = useSelector(selectBasicDetails);
  const metadata: any = useSelector(selectMetadata);
  const isNextStep = useSelector(selectIsNextStep);
  const activeStep = useSelector(selectStepperActiveStep);
  const user: any = useSelector(selectUser);
  const userBasicDetails = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    preferredName: user?.preferredName,
    email: user?.email,
    personalEmail: user?.personalEmail,
    preferredEmail: user?.preferredEmail === basicDetails.email
      ? EmailChoices.UNIVERSITY_EMAIL
      : EmailChoices.PERSONAL_EMAIL,
    gender: user?.gender,
    associateStatus: user?.associateStatus,
    dateOfBirth: user?.dateOfBirth,
    region: user?.region,
    bio: user?.bio,
    profilePicture: user?.profilePicture,
    phoneNumber: user?.phoneNumber,
    ethnicity: user?.ethnicity,
    disadvantageIndicators: user?.disadvantageIndicators,
    linkedinUrl: user?.linkedinUrl,
    householdIncome: user?.householdIncome,
    programType: basicDetails?.programType
  }

  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: isMyAccount ? userBasicDetails :
      {
        ...basicDetails,
        ...metadata,
        preferredEmail:
          basicDetails.preferredEmail === basicDetails.email
            ? EmailChoices.UNIVERSITY_EMAIL
            : EmailChoices.PERSONAL_EMAIL,
      }
  });

  const dispatch = useDispatch();
  const [selectedEthnicity, setSelectedEthnicity] = useState<any | null>();
  const [selectedRegion, setSelectedRegion] = useState<any | null>();
  const [profilePictureUploading, setProfilePictureUploading] = useState<boolean>(false);
  const [profileUrl, setProfileUrl] = useState<any>("");
  const authUser = useSelector(selectAuthUser);
  const [bioCharacterLimit, setBioCharacterLimit] = useState<string>(user?.bio ? user?.bio : "");
  const [genderVisibility, setGenderVisibility] = useState<boolean>(false);
  const [regionVisibility, setRegionVisibility] = useState<boolean>(false);
  const [disadvantageIndicatorsVisibility, setDisadvantageIndicatorsVisibility] = useState<boolean>(false);
  const [householdIncomeVisibility, setHouseholdIncomeVisibility] = useState<boolean>(false);
  const [ethnicityVisibility, setEthnicityVisibility] = useState<boolean>(false);
  const [householdIncome, setHouseholdIncome] = useState<any>("");

  const [profileError, setProfileError]=useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDisadvantageIndicators, setSelectedDisadvantageIndicators] = useState<any>([]);
  const [assciateStatus, setAssociateStatus]=useState<any>(basicDetails.associateStatus);

  const uploadPhoto = async (file: any) => {
    setProfilePictureUploading(true);
    setIsLoading(true)
    const firebaseStorageRef = firebase.storage().ref("users/" + authUser?.id + '/profile.jpg');
    await firebaseStorageRef.put(file[0]).then(() => {
      console.log("Image successfully uploaded");
      setProfileError(null);
    }).catch((error) => {
      console.log("error uploading image", error);
      setProfilePictureUploading(false);
    })
    await firebaseStorageRef.getDownloadURL().then((url) => {
      setProfileUrl(url);
      setTimeout(() => {
        setIsLoading(false)
      }, 4000);

    }).catch((error) => {
      console.log("error fetching user profile url", error);
    })
  }

  useEffect(() => {
    dispatch(setOnLoginRedirectRoute(null));
    if (window.location.hash) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isMyAccount) {
      setSelectedEthnicity(basicDetails.ethnicity);
      setAssociateStatus(basicDetails.associateStatus);
      setSelectedRegion(basicDetails.region);
      setSelectedDisadvantageIndicators(basicDetails.disadvantageIndicators);
      setHouseholdIncome(basicDetails.householdIncome);
      setGenderVisibility(metadata?.profileDataVisibility?.gender);
      setRegionVisibility(metadata?.profileDataVisibility?.region);
      setDisadvantageIndicatorsVisibility(metadata?.profileDataVisibility?.disadvantageIndicators);
      setEthnicityVisibility(metadata?.profileDataVisibility?.ethnicity);
      setHouseholdIncomeVisibility(metadata?.profileDataVisibility?.householdIncome);
      setProfileUrl(basicDetails?.profilePicture);
      if(basicDetails.profilePicture !== undefined) {
        if(basicDetails.profilePicture !== '' && !(basicDetails.profilePicture.includes("img/associate-profiles"))) {
          setProfilePictureUploading(true);
        } else {
          setProfilePictureUploading(false);
        }
      }
    } else {
      setSelectedEthnicity(user?.ethnicity);
      setSelectedRegion(user?.region);
      setHouseholdIncome(user?.householdIncome);
      setSelectedDisadvantageIndicators(user?.disadvantageIndicators);
      setGenderVisibility(user?.metadata?.profileDataVisibility?.gender);
      setRegionVisibility(user?.metadata?.profileDataVisibility?.region);
      setDisadvantageIndicatorsVisibility(user?.metadata?.profileDataVisibility?.disadvantageIndicators);
      setEthnicityVisibility(user?.metadata?.profileDataVisibility?.ethnicity);
      setHouseholdIncomeVisibility(user?.metadata?.profileDataVisibility?.householdIncome);
      setProfileUrl(user?.profilePicture);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicDetails]);

  const onSubmit = (data: any) => {
    if(profilePictureUploading === false || profilePictureUploading === null || profilePictureUploading === undefined) {
      setProfileError("upload your photo")
    }else {
      setProfileError(null)
    }
    
    if (data.preferredEmail === EmailChoices.UNIVERSITY_EMAIL) {
      data.preferredEmail = data.email;
    } else {
      data.preferredEmail = data.personalEmail;
    }
    data.region = selectedRegion;
    data.ethnicity = selectedEthnicity;
    data.profilePicture = profileUrl;
    data.roles = [UserType.STUDENT];
    data.disadvantageIndicators = selectedDisadvantageIndicators;
    data.householdIncome = householdIncome;
    data.programType = basicDetails?.programType;
    data.associateStatus = assciateStatus;
    dispatch(setBasicDetails(data));
    dispatch(setMetadata({
      ...metadata, profileDataVisibility: {
        ...metadata.profileDataVisibility,
        ethnicity: ethnicityVisibility,
        disadvantageIndicators: disadvantageIndicatorsVisibility,
        gender: genderVisibility,
        region: regionVisibility,
        householdIncome: householdIncomeVisibility,
      }
    }));
    if ((profilePictureUploading === true) && isNextStep) {
      dispatch(goToNextStep());
    }
  };

  return (
    <StyledForm className="card bg-white border border-light rounded-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body bg-transparent mx-4 my-4">
        <div className="row d-flex align-items-center">
          <h3 className="text-primary mb-4">{isMyAccount ? "Edit your information below" : "To complete your profile, please add the information below."}</h3>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <TextField
              name="firstName"
              type={InputType.TEXT}
              inputRef={register({ required: true })}
              label="First Name"
              error={!!errors.firstName}
              helperText={errors.firstName ? "Please enter your first name" : ""}
            />
          </div>
          <div className="col-sm-6 req-ast">
            <TextField
              name="lastName"
              type={InputType.TEXT}
              inputRef={register({ required: true })}
              label="Last Name"
              error={!!errors.lastName}
              helperText={errors.lastName ? "Please enter your last name" : ""}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6">
            <TextField
              name="preferredName"
              type={InputType.TEXT}
              inputRef={register}
              label="Preferred Name"
            />
          </div>
          <div className="col-sm-6">
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <TextField
              name="email"
              type={InputType.EMAIL}
              inputRef={register({ required: true })}
              label="University Email"
              error={!!errors.email}
              helperText={
                errors.email ? "Please enter your university email" : ""
              }
            />
          </div>
          <div className="col-sm-6">
            <TextField
              name="personalEmail"
              type={InputType.EMAIL}
              inputRef={register}
              label="Personal Email"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 req-ast-leg">
            <RadioButtonGroup
              name="preferredEmail"
              radioButtons={preferredEmailChoices}
              formLabel="Preferred Email"
              control={control}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6">
            <p>Enter your date of birth <span className="text-danger">*</span></p>
            <TextField
              name="dateOfBirth"
              type={InputType.DATE}
              inputRef={register}
              label=""
              error={!!errors.dateOfBirth}
              helperText={
                errors.dateOfBirth ? "Please enter your date of birth" : ""
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6">
            <RadioButtonGroup
              name="gender"
              radioButtons={genderRadioButtons}
              formLabel="Gender"
              control={control}
            />
          </div>
          <div className="col-sm-6">
            <p>Show on Profile</p>
            <ToggleButton handleToggle={setGenderVisibility} checked={genderVisibility} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <p>Additional Information</p>
          </div>
        </div>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-sm-6">
            <DocumentPicker
              handleChange={(selectedRegion: any) =>
                setSelectedRegion(selectedRegion)
              }
              collection="regions"
              error={!!errors.region}
              name="region"
              // register={register}
              defaultValue={selectedRegion}
              label="Region"
            />
          </div>
          <div className="col-sm-6">
            <ToggleButton className="spacing-left" handleToggle={setRegionVisibility} checked={regionVisibility} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6">
            <TextField
              name="phoneNumber"
              type={InputType.NUMBER}
              inputRef={register}
              label="Mobile Number"
            />
          </div>
          <div className="col-sm-6">
          </div>
        </div>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-sm-6">
            <DocumentPicker
              handleChange={(selectedEthnicity: any) =>
                setSelectedEthnicity(selectedEthnicity)
              }
              collection="ethnicities"
              error={!!errors.ethnicity}
              name="ethnicity"
              // register={register}
              defaultValue={selectedEthnicity}
              label="Ethnicity"
            />
          </div>
          <div className="col-sm-6">
            <ToggleButton className="spacing-left spacing-left-ethnicity" handleToggle={setEthnicityVisibility} checked={ethnicityVisibility} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6">
            <TextField
              inputRef={register}
              name="linkedinUrl"
              type={InputType.TEXT}
              label="LinkedIn profile URL"
            />
          </div>
          <div className="col-sm-6">
          </div>
        </div>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-sm-6">
            <DocumentPicker
              multiple
              handleChange={(selectedDisadvantageIndicators: any) =>
                setSelectedDisadvantageIndicators(selectedDisadvantageIndicators)
              }
              collection="disadvantage-indicators"
              error={errors.disadvantageIndicators}
              name="disadvantageIndicators"
              required={selectedDisadvantageIndicators.length === 0}
              defaultValues={selectedDisadvantageIndicators}
              label=""
              disabled
            />
          </div>
          <div className="col-sm-6">
            <ToggleButton className="spacing-left" handleToggle={setDisadvantageIndicatorsVisibility} checked={disadvantageIndicatorsVisibility} />
          </div>
        </div>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-sm-6">
            <TextField
              name="householdIncome"
              type={InputType.TEXT}
              label="Household Income"
              value={householdIncome}
              disabled
            />
          </div>
          <div className="col-sm-6">
            <ToggleButton className="spacing-left" handleToggle={setHouseholdIncomeVisibility} checked={householdIncomeVisibility} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6">
            <p className="body-15 font-weight-normal">Add a short bio so the professionals can get to know you. You might want to include an overview of your background or any career interests you may have <span className="text-danger">*</span></p>
            <TextField
              name="bio"
              multiline
              type={InputType.TEXT}
              label="Type your bio here"
              inputProp={{ maxLength: 1000 }}
              handleChange={(e: any) => setBioCharacterLimit(e.target.value)}
              inputRef={register({ required: true })}
              error={!!errors.bio}
              helperText={errors.bio ? "Please add a short bio" : ""}
            />
            <span className="body-12">{bioCharacterLimit.length}/1000</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12">
            <div className="d-flex">
              <Avatar
                className="profile-picture"
                alt=""
                src={profileUrl}
              />{ isLoading ? (
                <CircularProgress className="no-user-text" />
              ) : (
              <div className="upload-section">
                <Button
                  color="primary"
                  component="label"
                >
                  Upload Photo&nbsp;<span className="text-danger">*</span>
                  <input
                    type="file"
                    hidden
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadPhoto(e.target.files)}
                  />
                </Button>
                <p className="text-danger">{profileError}</p>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer bg-transparent my-4 d-flex justify-content-center">
        <StepperControls
            disabled={false}
            nextButtonLabel="Next"
            nextButtonCallback={() => { dispatch(setIsNextStep(true)) }}
            backButtonLabel="Back"
            backButtonCallback={() => { dispatch(setIsNextStep(false)); dispatch(goToPrevStep()); }}
            activeStep={activeStep}
          />   
      </div>
    </StyledForm>
  );
};

export default PersonalDetails;
const StyledForm = styled.form``; 