import { Grid, Button } from "@mui/material";
import { Interaction, UserType } from "common/types";
import InteractionTile from "features/interactions/InteractionTile";
import ActivityCard from "./ActivityCard";
import OverviewCard from "./OverviewCard";
import ActivityRemainingCard from "./ActivityRemainingCard";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveInteractionId, setModal } from "features/interactions/slice";
import { useEffect, useState } from "react";
import { requiresUserResponse, sendSeachForProfessionalEvent } from "./utils";
import recordEvent from "common/utils/mixpanel";

type Props = {
  user: any;
  activeInteractions: any[];
  pastInteractions: any[];
  awaitingFeedbackInteractions: any[];
  interactions: any[];

  review:any;
};

const HomeInteractions = ({
  user,
  activeInteractions,
  pastInteractions,
  awaitingFeedbackInteractions,
  interactions,

  review,
}: Props) => {
  const dispatch = useDispatch();
  const handleInteractionClick = (interactionId: string) => {
    dispatch(setActiveInteractionId(interactionId));
    dispatch(setModal(true));
  };
  const [actionRequiredInteractions, setActionRequiredInteractions] = useState<any>([]);

  useEffect(() => {
    if (user) {
      setActionRequiredInteractions([]);
      // activeInteractions.forEach((newInteraction: Interaction) => {
        review.forEach((newInteraction: Interaction) => {

        if (
          requiresUserResponse(newInteraction, user.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT) &&
          // !actionRequiredInteractions.includes(newInteraction.id)
          !review.includes(newInteraction.id)
        ) {
          setActionRequiredInteractions((oldInteraction: any) => [
            ...oldInteraction,
            newInteraction,
          ]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInteractions]);

  const handleCurrentActivityCardClicked = (interaction: any) => {
    let eventName = "";
    let payload: any = {};
    if (user?.roles.includes(UserType.STUDENT)) {
      eventName = "Student Current Activity Click";
      payload = {
        "Student Gender": user.gender,
        "Student Region": user.region?.name,
        "Student Ethnicity": user.ethnicity?.name,
        "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
        "User Roles": user.roles,
        "Student Year Of Graduation": user.graduationYear,
      };
    } else if (user?.roles.includes(UserType.PROFESSIONAL)) {
      eventName = "Professional Current Activity Click";
      payload = {
        "Professional Gender": user.gender,
        "Professional Region": user.region?.name,
        "Professional Ethnicity": user.ethnicity?.name,
        "Professional Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
        "User Roles": user.roles,
        "Professional Company": user?.company?.name,
        "Professional Preferred Interaction Type": user.participatingInteractionTypes,
        "Professional Sector": user.sectors,
      };
    }
    recordEvent(eventName, payload);
    handleInteractionClick(interaction.id);
  };

  return (
    <>
      <Grid container spacing={3} className="username-spacing">
        <Grid item sm={12}>
          <h1 className="username">Hello {user.firstName}!</h1>
        </Grid>
        <Grid item xs={12} sm={4} lg={4} spacing={3}>
          <OverviewCard activeInteractions={activeInteractions} />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <ActivityCard activeInteractions={interactions} />
        </Grid>
        {(user?.roles.includes(UserType.STUDENT) || user?.roles.includes(UserType.ALUMNI)) && (
          <Grid item xs={12} sm={4} lg={4}>
            <ActivityRemainingCard interactions={interactions} />
          </Grid>
        )}
        {user?.roles.includes(UserType.STUDENT) && (
          <Grid item className="professional-section" sm={12}>
            <Link to="/professionals">
              <Button
                className="professional-search homeinteraction-search-btn font-family-nunito"
                variant="contained"
                color="primary"
                onClick={() => sendSeachForProfessionalEvent(user)}
              >
                Search for professionals
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
      {actionRequiredInteractions.length > 0 && (
        <Grid className="action-req-section" xs={12} md={10}>
          <h5 id="actionRequired" className="interaction-type">
            Action Required
          </h5>
          {actionRequiredInteractions?.map((interaction: any) => (
            <>
              <InteractionTile
                key={interaction.id}
                interaction={interaction}
                handleClick={() => handleInteractionClick(interaction.id)}
              />
            </>
          ))}
        </Grid>
      )}
      {activeInteractions.length>0 &&
      <Grid className="action-req-section" xs={12} md={10}>
        <h5 className="interaction-type">Current Activity</h5>
        {activeInteractions?.map((interaction: any) => (
          <>
            <InteractionTile
              key={interaction.id}
              interaction={interaction}
              handleClick={() => handleCurrentActivityCardClicked(interaction)}
            />
          </>
        ))}
      </Grid>
    }
      <Grid className="action-req-section" xs={12} md={10}>
        {awaitingFeedbackInteractions.length > 0 && (
          <h5 className="interaction-type">Reviews Pending</h5>
        )}
        {awaitingFeedbackInteractions.map((interaction: any) => (
          <>
            {awaitingFeedbackInteractions.length > 0 ? (
              <InteractionTile
                key={interaction.id}
                interaction={interaction}
                handleClick={() => handleInteractionClick(interaction.id)}
              />
            ) : (
              <div>You have no past interactions yet.</div>
            )}
          </>
        ))}
      </Grid>
      <Grid className="action-req-section" xs={12} md={10}>
        {pastInteractions.length > 0 && <h5 className="interaction-type">Past Interactions</h5>}
        {pastInteractions.map((interaction: any) => (
          <>
            {pastInteractions.length > 0 ? (
              <InteractionTile
                key={interaction.id}
                interaction={interaction}
                handleClick={() => handleInteractionClick(interaction.id)}
              />
            ) : (
              <div>You have no past interactions yet.</div>
            )}
          </>
        ))}
      </Grid>
    </>
  );
};

export default HomeInteractions;
