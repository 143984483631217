/* eslint-disable no-useless-concat */

import { Container, Grid, Button, useMediaQuery } from "@mui/material";
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleCheckedOutline from '@mui/icons-material/RadioButtonUncheckedRounded';
import { useState, useEffect } from "react";
import ProfessionalUserCard, {
  interactionTypeAvailability,
} from "./ProfessionalUserCard";
import Pagination from "./Pagination";
import React from "react";
import Select from "react-select";
import { GenderChoices, InteractionType, User } from "common/types";
import firebase from "app/firebase";
import { createFilter, searchProfessionals } from "./searchUtils";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoading, selectUser } from "features/auth/selectors";

import {
  setFilter,
  setSectorFilter,
  setInteractionFilter,
  setCompanyFilter,
  setUniversityFilter,
  setRegionFilter,
  setGenderFilter,
  setPageNumber,
  setFilteredProfessionals,
} from "features/network/slice";
import {
  selectFilter,
  selectSectorFilter,
  selectInteractionFilter,
  selectCompanyFilter,
  selectUniversityFilter,
  selectRegionFilter,
  selectGenderFilter,
  selectPageNumber,
  selectFilteredProfessionals,
} from "features/network/selectors";
import useMixPanelForFilters from "hooks/useMixPanelForFilters";
import { fetchBookmarkedUserIdArray } from "./utils";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import UserTabs from "./UserTabs";
import Loading from "common/components/Loading";
import { setIsLoading as setLoaderIsLoading } from "features/auth/slice";
import TextField, { InputType } from "common/components/TextField";

export const genderTypes: any = {
  [GenderChoices.FEMALE]: "Female",
  [GenderChoices.MALE]: "Male",
  [GenderChoices.OTHER]: "Other",
};
const AllProfessionals = () => {
  const dispatch = useDispatch();
  const pageNumber: number = useSelector(selectPageNumber);
  const filter: string = useSelector(selectFilter);
  const sectorFilter: string = useSelector(selectSectorFilter);
  const interactionFilter: string = useSelector(selectInteractionFilter);
  const companyFilter: string = useSelector(selectCompanyFilter);
  const universityFilter: string = useSelector(selectUniversityFilter);
  const regionFilter: string = useSelector(selectRegionFilter);
  const genderFilter: string = useSelector(selectGenderFilter);
  const globalFilteredProfessionals: any[] = useSelector(
    selectFilteredProfessionals
  );
  const isLoaderLoading = useSelector(selectIsLoading);
  const user: any = useSelector(selectUser);

  useMixPanelForFilters(
    user,
    sectorFilter,
    interactionFilter,
    companyFilter,
    universityFilter,
    regionFilter,
    genderFilter
  );

  const matches = useMediaQuery("(max-width:960px)");

  //for dropdowns
  const [sectors, setSectors] = useState<Array<any>>([]);
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [isUniversitySelected, setIsUniversitySelected] =
    useState<boolean>(true);
  const [isSameGender, setIsSameGender] = useState<boolean>(true);
  const [regions, setRegions] = useState<Array<any>>([]);
  const [interactions] = useState<Array<any>>([
    { name: InteractionType.CAREER_CHAT },
    { name: InteractionType.EXPERT_ADVICE },
    { name: InteractionType.MOCK_INTERVIEW },
  ]);

  const [advancedFilters, setAdvancedFilters] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const [searchedProfessionals, setSearchedProfessionals] = useState([]);
  // const [onlineProfessionals, setOnlineProfessionals] = useState<any[]>([])
  const [isUserBookmarked, setIsUserBookmarked] = useState<any>({});

  const [searchValuesNull, setSearchValuesNull] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAlumnis, setIsAlumnis] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState(0);

  let selectRef1: any = useSelector(selectSectorFilter);
  let selectRef2: any = useSelector(selectInteractionFilter);
  let selectRef3: any = useSelector(selectCompanyFilter);
  let selectRef4: any = useSelector(selectUniversityFilter);
  let selectRef5: any = useSelector(selectRegionFilter);
  let selectRef6: any = useSelector(selectGenderFilter);

  const clearFilters = () => {
    dispatch(setSectorFilter(""));
    dispatch(setInteractionFilter(""));
    dispatch(setCompanyFilter(""));
    dispatch(setUniversityFilter(""));
    dispatch(setRegionFilter(""));
    dispatch(setGenderFilter(""));
    setIsSameGender(true);
    setIsUniversitySelected(true);
    selectRef1?.select?.clearValue();
    selectRef2?.select?.clearValue();
    selectRef3?.select?.clearValue();
    selectRef4?.select?.clearValue();
    selectRef5?.select?.clearValue();
    selectRef6?.select?.clearValue();
    setSearchValuesNull(false);
    setIsLoading(false);
    dispatch(setPageNumber(0));
  };

  //for pagination
  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setPageNumber(page - 1)); // -1 because algolia page values start from 0
  };

  const toggleAdvancedFilters = () => {
    setAdvancedFilters(!advancedFilters);
  };

  useEffect(() => {
    const fetchSectors = async () => {
      const response = await firebase
        .firestore()
        .collection("sectors")
        .orderBy("name", "asc")
        .get();
      const sectors: Array<any> = response.docs.map((doc) => ({
        id: doc.id,
        name: doc.data()["name"],
      }));
      //sort sectors here
      sectors.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setSectors(sectors);
    };
    const fetchCompanies = async () => {
      const response = await firebase
        .firestore()
        .collection("companies")
        .orderBy("name", "asc")
        .get();
      const companies: Array<any> = response.docs.map((doc) => ({
        id: doc.id,
        name: doc.data()["name"],
      }));
      setCompanies(companies);
    };
    const fetchRegions = async () => {
      const response = await firebase
        .firestore()
        .collection("regions")
        .orderBy("name", "asc")
        .get();
      const regions: Array<any> = response.docs.map((doc) => ({
        id: doc.id,
        name: doc.data()["name"],
      }));
      setRegions(regions);
    };
    fetchSectors();
    fetchCompanies();
    fetchRegions();
  }, []);

  //filter effect
  useEffect(() => {
    const setSearchFilter = () => {
      const filterString = createFilter(
        interactionFilter,
        sectorFilter,
        companyFilter,
        universityFilter,
        regionFilter,
        genderFilter,
        user?.roles
      );
      let page = pageNumber;
      if (filter !== filterString) {
        page = 0;
      }
      dispatch(setLoaderIsLoading(true));
      dispatch(setFilter(filterString));
      dispatch(setPageNumber(page));
      sendSearchProfessionalsRequest(
        query,
        filterString,
        page,
        isAlumnis,
        "TOTAL_INTERACTIONS",
        "ASC"
      );
    };
    setSearchFilter();
    // eslint-disable-next-line
  }, [
    sectorFilter,
    interactionFilter,
    companyFilter,
    universityFilter,
    regionFilter,
    genderFilter,
    isAlumnis,
    pageNumber,
  ]);

  useEffect(() => {
    if (tabValue === 0) {
      dispatch(setPageNumber(0));
      setIsAlumnis(false);
    } else {
      dispatch(setPageNumber(0));
      setIsAlumnis(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  const handleUniversityClick = async () => {
    await setIsUniversitySelected(!isUniversitySelected);
    await setIsLoading(true);
    if (isUniversitySelected) {
      await dispatch(setUniversityFilter(user?.university?.name));
    } else {
      await dispatch(setUniversityFilter(""));
      await selectRef4?.select?.clearValue();
    }
  };

  const handleGenderClick = async () => {
    await setIsSameGender(!isSameGender);
    await setIsLoading(true);
    if (isSameGender) {
      await dispatch(setGenderFilter(user?.gender));
    } else {
      await dispatch(setGenderFilter(""));
      await selectRef6?.select?.clearValue();
    }
  };

  const handleSearchQuery = async (value: string) => {
    setQuery(value);
    sendSearchProfessionalsRequest(
      value,
      filter,
      pageNumber,
      isAlumnis,
      "TOTAL_INTERACTIONS",
      "ASC"
    );
  };

  useEffect(() => {
    async function loopThroughUsersArray() {
      const boomarkedUsersId: string[] = [];
      await searchedProfessionals.forEach((user: any) => {
        boomarkedUsersId.push(user.id);
      });
      const userBookmarkedArray: any = await fetchBookmarkedUserIdArray(
        boomarkedUsersId
      );
      await setIsUserBookmarked(userBookmarkedArray);
    }
    loopThroughUsersArray();
  }, [searchedProfessionals]);

  const sendSearchProfessionalsRequest = async (
    query: string,
    filters: string,
    page: number,
    queryForAlumnis: boolean,
    sortColumn: string,
    sortOrder: string
  ) => {
    searchProfessionals(
      query,
      filters,
      page,
      queryForAlumnis,
      sortColumn,
      sortOrder
    )
      .then((searchResults) => {
        setIsLoading(false);
        if (searchResults.searchResults.length === 0) {
          setSearchValuesNull(true);
          setSearchedProfessionals([]);
          setNumberOfPages(0);
        } else {
          setSearchedProfessionals(searchResults.searchResults);
          setNumberOfPages(searchResults.numberOfPages);
          setSearchValuesNull(false);
        }
        dispatch(setLoaderIsLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const allProfessionals: any[] = [...searchedProfessionals];
    const onlineProfessionals = allProfessionals.filter(
      (professional) => professional?.metadata?.isOnline === true
    );

    dispatch(setFilteredProfessionals(onlineProfessionals));

    // eslint-disable-next-line
  }, [searchedProfessionals]);

  if (isLoaderLoading) return <Loading />;

  return (
    <StyledWrapper>
      <Container>
        {!matches && (
          <Grid className="filter-section filter-section-desktop" item xs={12}>
            <div>
              <Button
                color="primary"
                className="clear-filter font-family-nunito"
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
              <div>
                <div className="d-flex">
                  <h3 className="filter-text">Filter professionals working in</h3>
                  <Select
                    ref={(ref) => {
                      selectRef1 = ref;
                    }}
                    placeholder={sectorFilter === "" ? "Any sector" : sectorFilter}
                    name="sector"
                    options={sectors}
                    getOptionValue={(option: any) => option.name}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(sector) => {
                      setIsLoading(true);
                      sector && dispatch(setSectorFilter(sector.name));
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    className="filter-dropdown"
                  />
                </div>
                <div className="d-flex">
                  <h3 className="filter-text">and available for</h3>
                  <Select
                    placeholder={
                      interactionFilter === ""
                        ? "Any interaction"
                        : interactionTypeAvailability[interactionFilter]
                    }
                    name="interaction"
                    ref={(ref) => {
                      selectRef2 = ref;
                    }}
                    options={interactions}
                    getOptionValue={(option: any) => option.name}
                    getOptionLabel={(option: any) =>
                      interactionTypeAvailability[option.name]
                    }
                    onChange={(interaction) => {
                      setIsLoading(true);
                      interaction &&
                        dispatch(setInteractionFilter(interaction.name));
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    className="filter-dropdown"
                  />
                </div>
              </div>
            </div>
          </Grid>
        )}
        {matches && (
          <Grid
            container
            className="filter-section filter-section-mobile"
            xs={12}
          >
            <Grid item xs={12} sm={6}>
              <Select
                ref={(ref) => {
                  selectRef1 = ref;
                }}
                placeholder={sectorFilter === "" ? "Any sector" : sectorFilter}
                name="sector"
                options={sectors}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) => option.name}
                onChange={(sector) => {
                  sector && dispatch(setSectorFilter(sector.name));
                  setIsLoading(true);
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                className="filter-dropdown filter-dropdown-sm"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                placeholder={
                  interactionFilter === ""
                    ? "Any interaction"
                    : interactionTypeAvailability[interactionFilter]
                }
                name="interaction"
                ref={(ref) => {
                  selectRef2 = ref;
                }}
                options={interactions}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) =>
                  interactionTypeAvailability[option.name]
                }
                onChange={(interaction) => {
                  setIsLoading(true);
                  interaction &&
                    dispatch(setInteractionFilter(interaction.name));
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                className="filter-dropdown filter-dropdown-sm"
              />
            </Grid>
            <Button
                  color="primary"
                  className="clear-filter font-family-nunito"
                  onClick={clearFilters}
              >
                  Clear Filters
            </Button>
          </Grid>
        )}
        {
          <Grid item xs={12} sm={6}>
            <Button color="primary" onClick={toggleAdvancedFilters}>
              <div className="font-family-nunito font-weight-bolder color-black">
                Advanced Filters
              </div>{" "}
              {advancedFilters ? <ExpandLessIcon className='color-black'/> : <ExpandMoreIcon className='color-black' />}{" "}
            </Button>
          </Grid>
        }
        {advancedFilters && (
          <>
            <div>
              <Grid container className="advanced-filters advanced-filters__first">
                {!isAlumnis && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Select
                      placeholder={
                        companyFilter === "" ? "Any Company" : companyFilter
                      }
                      name="company"
                      ref={(ref) => {
                        selectRef3 = ref;
                      }}
                      options={companies}
                      getOptionValue={(option: any) => option.name}
                      getOptionLabel={(option: any) => option.name}
                      onChange={(company) => {
                        company && dispatch(setCompanyFilter(company.name));
                        setIsLoading(true);
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      className="filter-dropdown filter-dropdown__second filter-dropdown-sm"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Select
                    placeholder={
                      regionFilter === "" ? "Any Region" : regionFilter
                    }
                    name="region"
                    ref={(ref) => {
                      selectRef5 = ref;
                    }}
                    options={regions}
                    getOptionValue={(option: any) => option.name}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(region) => {
                      region && dispatch(setRegionFilter(region.name));
                      setIsLoading(true);
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    className="filter-dropdown filter-dropdown__second filter-dropdown-sm"
                  />
                </Grid>
              </Grid>
              <Grid container className="advanced-filters advanced-filters__second" spacing={2}>
                <Grid
                  className="justify-content-center"
                  item
                >
                  <FormControlLabel
                    className='advance-filter-label'
                    control={
                      <Checkbox
                        checked={!isUniversitySelected}
                        onChange={() => handleUniversityClick()}
                        color="primary"
                        checkedIcon={<CircleCheckedFilled/>}
                        icon={<CircleCheckedOutline/>}
                      />
                    }
                    label={ <span className='advance-filter-label color-black' >Only show professionals from my university</span>}
                  />
                </Grid>
                <Grid
                  className="justify-content-center"
                  item
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!isSameGender}
                        onChange={() => handleGenderClick()}
                        color="primary"
                        checkedIcon={<CircleCheckedFilled/>}
                        icon={<CircleCheckedOutline/>}
                      />
                    }
                    label={ <span className='advance-filter-label color-black' >Only show professionals who are the same gender as me</span>}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )}
        <Grid item xs={12} sm={4} className='search-input'>
          <TextField
            value={query}
            handleChange={(e: any) => {
              handleSearchQuery(e.target.value);
            }}
            type={InputType.TEXT}
            name="Professionals Search"
            label="Search for Professionals"
          />
        </Grid>
        <UserTabs setTabValue={setTabValue} tabValue={tabValue} />
        <Grid
          container
          item
          spacing={3}
          direction="row"
          className='professional-card-container'
        >
          {!isLoading && searchValuesNull ? (
            <div className="no-user-text">
              <p className="no-search m-0">
                {" "}
                We did not find any professionals that match your filters,
                please expand your search for better results.{" "}
              </p>
              <p className="no-search no-serach-subtext spacing">
                {" "}
                (We’ve acknowledged your interest and will aim to get
                professionals matching your search criteria on the platform){" "}
              </p>
            </div>
          ) : (
            <> </>
          )}
          {isLoading ? (
            <CircularProgress className="no-user-text" />
          ) : (
            <>
              {React.Children.toArray(
                isUserBookmarked &&
                  globalFilteredProfessionals?.map(
                    (searchedProfessional: User) => (
                      <>
                        {searchedProfessional.id !== user.id && (
                            <ProfessionalUserCard
                              isUserBookmarked={
                                isUserBookmarked[searchedProfessional.id]
                              }
                              user={searchedProfessional}
                              student={user}
                            />
                        )}
                      </>
                    )
                  )
              )}
            </>
          )}
        </Grid>
        <Grid className="pagination">
          {numberOfPages > 1 && (
            <Pagination
              count={numberOfPages}
              value={pageNumber + 1}
              onChange={onPageChange}
            />
          )}
        </Grid>
      </Container>
    </StyledWrapper>
  );
};

export default AllProfessionals;

const StyledWrapper = styled.div`
  .filter-section {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .advanced-filters {
    display: flex;
    &__second {
      margin: 12px 0;
    }
  }
  .advanced-filter-section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .advance-filter-label {
    font-family: Nunito Sans, sans-serif !important;
  }

  .MuiFormControlLabel-label {
    color: #464fd0;
  }

  .justify-content-center {
    display: flex;
    justify-content: center;
    @media (max-width: 960px) {
      justify-content: unset;
    }
  }

  .clear-filter {
    display: block;
    height: 40px;
    margin-top: 10px;
    margin-right: 20px;
    font-weight: 900;
    float: right;
    color: #000000;
    @media (max-width: 960px) {
      margin-right: 0;
      float: none;
      margin: auto;
    }
  }

  .search-input {
    margin: 12px 0;
    height:56px;
    width:410px;
  }

  .mui-placeholder::placeholder {
    font-family: Nunito Sans, sans-serif !important;
  }

  .filter-dropdown {
    width: 100%;
    max-width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    top: 10px;
    ::placeholder {
      font-size: 30px;
    }
    .css-yk16xz-control {
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #464fd0;
      border-left: none;
      border-radius: 0;
      background-color: #f6faff;
    }

    svg {
      fill: #464fd0;
    }

    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
      color: #464fd0;
      font-weight: 400;
      font-size: 22px;
    }
  }

  .filter-dropdown__second {
    .css-yk16xz-control {
      border: 2px solid #464FD0;
      border-radius: 5px;
      background-color: #f6faff;
    }
  }

  .filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }
  .advanced-filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }

  .filter-section-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }
  .advanced-filter-section-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .filter-dropdown-sm {
    margin: auto;
    padding: 0 20px 20px 20px;
    @media (max-width: 600px) {
      padding-left: unset;
      padding-right: unset;
      max-width: 100%;
    }
  }

  .clear-filter-sm {
    @media (min-width: 600px) {
      margin-right: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-top: 20px;
      display: block;
    }
  }

  .filter-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
    margin: 0;
  }

  .professional-card-container {
    padding: 30px 5px;
    justify-content: space-around;
  }

  .no-search {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0px;
    color: #9f9d9d;
  }

  .spacing {
    margin-bottom: 60px;
  }

  .m-0 {
    margin: 0;
  }

  .mt-3 {
    margin-top: 30px;
  }

  .no-user-text {
    position: relative;
    top: 100px;
    margin: auto;
    color: #3f51b5 !important;
  }

  .no-serach-subtext {
    font-weight: 500;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
`;
