import { Checkbox, FormControlLabel } from "@mui/material";
import StepperControls from "common/components/StepperControls";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import { selectIsNextStep } from "./selectors";
import { goToPrevStep, setIsNextStep } from "./professionalRegistrationSlice";
import styled from "@emotion/styled";

type Props = {
  isMyAccount?: boolean;
}

const ProfessionalSharedExpectation = ({ isMyAccount }: Props) => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const isNextStep = useSelector(selectIsNextStep);

  const onSubmit = () => {
    if (isNextStep) {
      if (isMyAccount) {
        dispatch({
          type: "PROFESSIONAL_PROFILE_UPDATE_ATTEMPT",
        });
      }
      else {
        dispatch({
          type: "PROFESSIONAL_REGISTRATION_ATTEMPT",
        });
      }

      setIsRegister(true);
    }
    if (!isNextStep) {
      dispatch(goToPrevStep())
    }
  };

  return (
    <StyledForm className="card bg-white border border-light rounded-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body bg-transparent mx-4 my-4 mb-0">
        <div className="row d-flex align-items-center mb-3">
          <h3 className="prof-registration-heading">Shared Expectations</h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="fw-600">What you can expect...</p>
            <div>
              <ul>
                <li>
                  A fulfilling volunteering experience and the opportunity to engage with a number of high-potential students
                </li>
                <li>
                  Students will be polite and respectful when communicating with you, responding to messages within 1 week, turning up to calls on time, and rescheduling at least 1 day in advance if they can no longer attend a scheduled call
                </li>
                <li>
                  A friendly Social Mobility Network support team at upReach! Get in touch via the Contact Us tab and we’ll reply within 3-4 working days
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="fw-600">What we expect of you in return... </p>
            <div>
              <ul>
                <li>
                  Respond to messages within 1 week - you will receive an email notification when a student reaches out to you (make sure you check your spam folder) and we recommend replying as soon as possible
                </li>
                <li>
                  Be positive and encouraging when engaging with the students
                </li>
                <li>
                  Report any potential safeguarding concern as soon as possible, following the steps outlined in our Safeguarding Guide on the Resources page              </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="fw-600">What we expect of all of our members... </p>
            <div>
              <ul>
                <li>
                  The Social Mobility Network is a safe and inclusive platform to interact and build connections, so inappropriate behaviour e.g. swearing or discriminatory language, is not tolerated. If members engage in this behaviour, they will be removed from the platform
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <FormControlLabel
              control={<Checkbox name="professionalismCharter" onClick={() => setAcceptTerms(!acceptTerms)} />}
              label="I confirm that I have read and agree to the Shared Expectations"
            />
          </div>
        </div>
      </div>
      <div className="card-footer bg-transparent my-4 d-flex justify-content-center">
        <StepperControls
          nextButtonCallback={() => { dispatch(setIsNextStep(true)) }}
          disabled={!acceptTerms}
          nextButtonLabel="Register"
          backButtonCallback={() => { dispatch(setIsNextStep(false)) }}
          backButtonLabel="Back"
          isRegister={isRegister}
        />
      </div>
    </StyledForm>
  );
};

const StyledForm = styled.form`
.MuiTypography-body1 {
  font-family: Nunito Sans, sans-serif !important;
}
p {
  mt-3
}
li {
  margin-top: 10px !important;
}
`;

export default ProfessionalSharedExpectation;