import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectFirstLoad,
  selectAuthUser,
} from "features/auth/selectors";
import Loading from "common/components/Loading";

type Props = {
  component: any;
  path: string;
  exact?: boolean;
  mode?: PrivateRouteMode;
};

export enum PrivateRouteMode {
  REQUIRE_AUTH = "REQUIRE_AUTH",
  REQUIRE_FULL_PROFILE = "REQUIRE_FULL_PROFILE",
}

const PrivateRoute = ({
  component,
  mode = PrivateRouteMode.REQUIRE_FULL_PROFILE,
  ...rest
}: Props) => {
  const user = useSelector(selectUser);
  const authUser = useSelector(selectAuthUser);
  const firstLoad = useSelector(selectFirstLoad);

  if (firstLoad) return <Loading />;

  if (mode === PrivateRouteMode.REQUIRE_AUTH) {
    return (
      <Route
        {...rest}
        component={authUser ? component : () => <Redirect to="/welcome" />}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        component={user ? component : () => <Redirect to="/welcome" />}
      />
    );
  }
};

export default PrivateRoute;
