// import logo from './images/logo.png';
import defaultSMNLogo from'./images/defaultSMNLogo.png';
import gif from './images/gif.gif';
import { useDispatch } from "react-redux";
import { goToNextStep } from './studentRegistrationSlice';

type Props = {
  isMyAccount?: boolean;
}
const HomePage = ({ isMyAccount }: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="card bg-white rounded-0">
      <div className="card-header border-0 bg-transparent text-center">
        <img src={defaultSMNLogo} className="my-1" alt='SMN-logo' style={{width:"300px"}}/>
      </div>
      <div style={{display:"flex", justifyContent:"center"}}>
         <hr className="text-center font-weight-bold" style={{width:"95%",height:"4px",backgroundColor:"#304563", opacity:"1"}} />
      </div>      <div className="card-header border-0 bg-transparent">
        <div className="row d-flex text-center">
          <div className="col">
            <div className='card border-0 mt-2'>
              <div className='card-body'>
                <h2 className="text-primary">Welcome to the Social Mobility Network</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
        <div className="row d-flex align-items-center">
          <div className="col-sm-6">
            <div className='card border-0 mt-0'>
              <div className='card-body'>
                <blockquote className="blockquote ">Before you can start interacting with the hundreds of professionals we have on the Social Mobility Network, you will need to register your account with us! This should take no longer than 10 minutes to complete. </blockquote>
              </div>
            </div>
          </div>
          <div className="text-center col-sm-6">
            <img src={gif} className="img-fluid" alt='SMN-upReach' style={{height: "14rem",width:"auto"}} />
          </div>
        </div>
        </div>
      </div>
      <div style={{display:"flex", justifyContent:"center"}}>
      <hr className="text-center font-weight-bold" style={{width:"95%",height:"4px",backgroundColor:"#304563", opacity:"1"}} />
      </div>      
      <div className="card-body">
        <div className="row text-center">
          <div className="col-sm">
            <h2 className="mt-1 mb-4 text-primary">Registration Process</h2>
          </div>
        </div> 
        <div className="row mx-0 text-center">
          <div className="col-md-4 col-12 mb-md-0 mb-3">
            <div className="card bg-primary text-white rounded-0 h-100">
              <div className="card-body mx-3 my-5">
                <h1 className="display-4 font-weight-bold">1</h1>
                <hr className="cl cl-secondary" />
                <blockquote className="blockquote">Confirm that your personal and university details are correct and add a profile picture and bio</blockquote>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-md-0 mb-3">
            <div className="card bg-secondary text-white rounded-0 h-100">
              <div className="card-body mx-3 my-5">
                <h1 className="display-4 font-weight-bold">2</h1>
                <hr className="cl cl-primary" />
                <blockquote className="blockquote">Watch a short video about the platform and answer a couple of quick questions</blockquote>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-md-0 mb-3">
            <div className="card bg-primary text-white rounded-0 h-100">
              <div className="card-body mx-3 my-5">
                <h1 className="display-4 font-weight-bold">3</h1>
                <hr className="cl cl-secondary" />
                <blockquote className="blockquote">Agree to our Shared Expectations to make sure we're all on the same page</blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='font-weight-bold' style={{display:"flex", justifyContent:"center"}}>
        <hr className="text-center font-weight-bold" style={{width:"95%",height:"4px",backgroundColor:"#304563", opacity:"1"}} />
      </div>
      <div className="card-footer border-0 bg-transparent text-center">
        <button type="button" className="btn btn-lg btn-primary my-4 rounded-0" id="btn-lg" style={{width:"97.5%"}} onClick={() => { dispatch(goToNextStep()) }}>
          <h2 className='mt-0 mb-0'>Start Registration</h2>
          </button>
      </div>
    </div>
  );
}

export default HomePage;