import { Avatar, Box, Card, CardContent, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import avatar from "common/assets/images/avatar.svg";


const StudentsUserCard = ({ user }: any) => {

  return (
    <StyledWrapper>
      <Link className="usercard-link" to={`/users/${user.id}`}>
        <Card className="request-card space-between">
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <Avatar className="profile-avatar" 
                   src={user.profilePicture || avatar}
                />
              </Grid>
              <Grid item xs={8}>
                <p className="body-17 font-weight-bolder mb-0 usercard-name">
                  {user.firstName} {user.lastName}
                </p>
                <span className="body-13 spacing">{user?.university?.name}</span>
              </Grid>
            </Grid>
            <Box className="profile-info">
              <div>
                <p className="body-13 spacing font-weight-bold mb-0">
                  {user.degreeType.name} 
                </p>
                <p className="body-13 spacing mt-0">
                  {user.universityCourse}
                </p>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Link>
    </StyledWrapper>
  );
};

export default StudentsUserCard;

const StyledWrapper = styled.div`
  position: relative;

  .profile-avatar {
    width: 60px;
    height: 60px;
    margin-left: 3px;
    margin-right: 5px;
  }
`;
