import { Button } from "@mui/material";
import { selectIsUpdateDrawerOpen } from "features/auth/selectors";
import { setIsUpdateDrawerOpen, setPromptModal } from "features/auth/slice";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const WarningMessageForUserProfileEdit = () => {
    const dispatch = useDispatch();

    return (
        <ModalContainer>
            <p className="noshow-modal-text font-family-nunito">
                If you leave this page, any unsaved changes will be discarded.
            </p>
            <div className="modal-button-container">
                <Button
                    variant="outlined"
                    color="primary"
                    className="cancel-noshow-button font-family-nunito"
                    size="large"
                    onClick={() => dispatch(setPromptModal({ isOpen: false, ModalType: null }))}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className="confirm-noshow-button font-family-nunito"
                    onClick={() => {
                        dispatch(setIsUpdateDrawerOpen(!selectIsUpdateDrawerOpen));
                        dispatch(setPromptModal({ isOpen: false, ModalType: null }));
                    }}
                >
                    Discard
                </Button>
            </div>
        </ModalContainer>
    );
}

export default WarningMessageForUserProfileEdit;

const ModalContainer = styled.div`
  .modal-button-container {
    display: flex;
    justify-content: space-between;
  }

  .noshow-modal-text {
    color: #464FD0;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .confirm-noshow-button{
    background-color: #464FD0;
    text-transform: initial;
    padding: 8px 70px;
    font-weight: inherit;
  }
  .confirm-noshow-button:hover{
    background-color: #3f51b5;
  }

  .cancel-noshow-button{
    background-color: #FFFFFF;
    text-transform: initial;
    margin-right: 20px;
    padding: 8px 70px;
    font-weight: inherit;
    color: #3f51b5 !important;
    border: 1px solid rgba(63, 81, 181, 0.5) !important;
  }

  @media (max-width:600px){
    .noshow-modal-text {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }
`;