import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { initialState } from "./slice";

const authSlice = (state: RootState) => state.auth || initialState;

export const selectAuthUser = createSelector([authSlice], (state) => state.authUser);

export const selectUser = createSelector([authSlice], (state) => state.user);

export const selectFirstLoad = createSelector(
  [authSlice],
  (state) => state.firstLoad
);

export const selectLoginFormWorking = createSelector(
  [authSlice],
  (state) => state.loginFormWorking
);

export const selectRegistrationFormWorking = createSelector(
  [authSlice],
  (state) => state.registrationFormWorking
);

export const selectOnLoginSyncInProgress = createSelector(
  [authSlice],
  (state) => state.onLoginSyncInProgress
);

export const selectOnLoginRedirectRoute = createSelector(
  [authSlice],
  (state) => state.onLoginRedirectRoute
);

export const selectIsLoading = createSelector(
  [authSlice],
  (state) => state.isLoading
);

export const selectPromptModal = createSelector(
  [authSlice],
  (state) => state.promptModal
);

export const selectIsUpdateDrawerOpen = createSelector(
  [authSlice],
  (state) => state.isUpdateDrawerOpen
);

