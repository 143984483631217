import { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 120,
            width: "100%"
        },
        selectEmpty: {
            marginTop: useTheme().spacing(2),
        },
    }),
);

type Props = {
    values: any[],
    name: string,
    handleChange: any,
    selectedValue: any,
}

export default function SelectComponent({ values, name, selectedValue, handleChange }: Props) {

    const classes = useStyles();
    const [value, setValue] = useState(1);
    const date = new Date();

    useEffect(() => {
        if(selectedValue) {
            setValue(selectedValue);
        }
    }, [selectedValue])

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">{`Year of study as of September ${date.getFullYear()}`}</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    label={`Year of study as of September ${date.getFullYear()}`}
                    name={name}
                >
                    {values.map((value) => (
                        <MenuItem value={value}>{value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
