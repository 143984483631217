import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { initialState } from "./alumniRegistrationSlice";

const alumniRegistrationSlice = (state: RootState) =>
  state.alumniRegistration || initialState;

export const selectBasicDetails = createSelector(
  [alumniRegistrationSlice],
  (state) => state.basicDetails
);

export const selectUniversityDetails = createSelector(
  [alumniRegistrationSlice],
  (state) => state.universityDetails
);

export const selectWorkDetails = createSelector(
  [alumniRegistrationSlice],
  (state) => state.workDetails
);

export const selectAlumniMetadata = createSelector(
  [alumniRegistrationSlice],
  (state) => state.metadata
);
export const selectStepperActiveStep = createSelector(
  [alumniRegistrationSlice],
  (state) => state.activeStep
);

export const selectIsNextStep = createSelector(
  [alumniRegistrationSlice],
  (state) => state.isNextStep
);