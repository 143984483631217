import styled from "styled-components";
// import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { RootStateOrAny, useSelector } from "react-redux";
import Banner from "common/components/Banner";
import { useEffect, useState } from "react";
import { selectUser } from "features/auth/selectors";
import { UserType } from "common/types";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {

  const authState = useSelector((state: RootStateOrAny) => state.auth);
  const user: any = useSelector(selectUser);

  const [isBannerOpen, setIsBannerOpen] = useState<boolean>();

  useEffect(() => {
    if (localStorage.getItem("isBannerOpen")) {
      if (localStorage.getItem("isBannerOpen") === "true") {
        setIsBannerOpen(true);
      } else {
        setIsBannerOpen(false);
      }
    } else {
      localStorage.setItem("isBannerOpen", "true");
    }
  }, [user]);

  const handleBannerClose = () => {
    localStorage.setItem('isBannerOpen', "false");
    setIsBannerOpen(false);
  }

  return (
    <StyledWrapper>
      {isBannerOpen && (user?.roles.includes(UserType.STUDENT) || user?.roles.includes(UserType.PROFESSIONAL)) &&
        <Banner handleClose={() => handleBannerClose()} />}
      <div className="desktop-header">
        <Header />
      </div>
      <div className="mobile-header">
        <Sidebar authState={authState} />
      </div>
      {children}
      {/* <Footer /> */}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  /* min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%; */

  .desktop-header {
    display: none;
    position: relative;
    @media (min-width: 851px)  {
      display: block;
    }
  }

  .mobile-header {
    display: none;
    @media (max-width: 850px)  {
      display: block;
    }
  }

`;

export default Layout;
