import { useState } from "react";
import { User, UserType } from "common/types";
import styled from "styled-components";
import {
    Button,
    Dialog,
    DialogContent
} from "@mui/material";
import { useSelector } from "react-redux";
// import { setMessageSendInProgress } from "../slice";
import { selectUser } from "features/auth/selectors";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { selectActiveInteractionId, selectInteractions } from "../selectors";
import IsCorrectModal from "./IsCorrectModal";
type Props = {
    interactionId: string;
    isNoModalOpen: boolean;
    setIsNoModalOpen: any;
};

const NoModal = ({ interactionId, isNoModalOpen, setIsNoModalOpen }: Props) => {

    const user: User | null = useSelector(selectUser);
    // const dispatch = useDispatch();
    const interactions = useSelector(selectInteractions);
    const activeInteractionId = useSelector(selectActiveInteractionId);
    const [activeInteraction, setActiveInteraction] = useState<any>();
    const [isCorrectModalOpen, setIsCorrectModalOpen] = useState<boolean>(false);
    const [text, setText] = useState<any>('');

    useEffect(() => {
        setActiveInteraction(
            interactions
                ?.filter((interaction: any) => interaction.id === activeInteractionId)
                .pop()
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interactions]);

    const studentNotAbleToAttent = () => {

        if (user?.roles.includes(UserType.STUDENT)) {
            setText("Student")
            setIsCorrectModalOpen(true)
            // const message: Partial<Message> = {
            //     type: MessageType.STUDENT_UNABLE,
            //     payload: null,
            // };
            // dispatch({
            //     type: "UNABLE_TO_ATTEND",
            //     payload: {
            //         activeInteractionId: activeInteractionId,
            //         message,
            //     },
            // })
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            setText("Student")
            setIsCorrectModalOpen(true)
            // const message: Partial<Message> = {
            //     type: MessageType.STUDENT_UNABLE,
            //     payload: null,
            // };
            // dispatch({
            //     type: "UNABLE_TO_ATTEND",
            //     payload: {
            //         activeInteractionId: activeInteractionId,
            //         message,
            //     },
            // })

        }
    }

    const professionalNotAbleToAttend = () => {
        if (user?.roles.includes(UserType.STUDENT)) {
            setIsCorrectModalOpen(true)
            setText("Professional")
            // const message: Partial<Message> = {
            //     type: MessageType.PROF_UNABLE,
            //     payload: null,
            // };
            // dispatch({
            //     type: "UNABLE_TO_ATTEND",
            //     payload: {
            //         activeInteractionId: activeInteractionId,
            //         message,
            //     },
            // })
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            setText("Professional")
            setIsCorrectModalOpen(true)
            // const message: Partial<Message> = {
            //     type: MessageType.PROF_UNABLE,
            //     payload: null,
            // };
            // dispatch({
            //     type: "UNABLE_TO_ATTEND",
            //     payload: {
            //         activeInteractionId: activeInteractionId,
            //         message,
            //     },
            // })

        }

    }

    const closeModal = () => {
        setIsNoModalOpen(false);
      };


    return (
        <StyledWrapper>
            <Dialog
                fullWidth
                className="post-interaction-background bubble"
                maxWidth="sm"
                open={isNoModalOpen}
                onClose={() => setIsNoModalOpen(true)}
            >
                <Grid container justifyContent="flex-end">
                    <Button className="mt-3 me-3" variant="text" color="primary" onClick={() => setIsNoModalOpen(false)} style={{ textTransform: "none" }}>
                        <Grid direction="column" >
                            <Grid item>Close</Grid>
                        </Grid>
                    </Button>
                </Grid>

                <DialogContent className="mt-0 mb-3 mx-3 text-center f20">
                    <Grid>
                        We're sorry to hear the interaction did not take place. Please let us know why this was the case below.
                    </Grid>
                </DialogContent>
                <DialogContent className="modal-content">
                    {user?.roles.includes(UserType?.STUDENT) &&
                        <Grid container direction="row" justifyContent="center" className="text-center">
                            <Grid direction="column" item md={6}>
                                <Button className="mb-3 px-3 b-line" variant="text" color="primary" onClick={studentNotAbleToAttent}>
                                    <Grid direction="column">
                                        <Grid item>I was unable to attend</Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid direction="column" item md={6}>
                                <Button className="mb-3 px-3 b-line" variant="text" color="primary" onClick={professionalNotAbleToAttend}>
                                    <Grid direction="column">
                                        <Grid item>The professional did not attend</Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {user?.roles.includes(UserType?.PROFESSIONAL) &&
                        <Grid container direction="row" justifyContent="center" className="text-center">
                            <Grid direction="column" item md={6}>
                                <Button className="mb-3 px-3 b-line" variant="text" color="primary" onClick={professionalNotAbleToAttend}>
                                    <Grid direction="column">
                                        <Grid item>I was unable to attend</Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid direction="column" item md={6}>
                                <Button className="mb-3 px-3 b-line" variant="text" color="primary" onClick={studentNotAbleToAttent}>
                                    <Grid direction="column">
                                        <Grid item>The Student did not attend</Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
            </Dialog>
            <IsCorrectModal
                setIsCorrectModalOpen={setIsCorrectModalOpen}
                isCorrectModalOpen={isCorrectModalOpen}
                interactionId={activeInteraction?.id}
                text={text}
                setText={setText}
                closeModal={closeModal}
            />
        </StyledWrapper>
    );

};

export default NoModal;

const StyledWrapper = styled.div`
  .interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
  }

  .modal-content {
    height: 800px !important; /* Set your desired height here */
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }

  .click-here {
    margin-top: 30px;
  }
  .MuiButton-label {
    display: unset !important;
  }
`;
