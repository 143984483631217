import { Button, TextField } from "@mui/material";
import { Interaction, Message, MessageType, User, UserType } from "common/types";
import { selectUser } from "features/auth/selectors";
import { setPromptModal } from "features/auth/slice";
import { selectActiveInteractionId, selectInteractions } from "features/interactions/selectors";
import { setMessageSendInProgress } from "features/interactions/slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";


const RequestReschedulePromptModal = () => {
  const dispatch = useDispatch();
  const activeInteractionId = useSelector(selectActiveInteractionId);

  const user: User | null = useSelector(selectUser);
  const [participant, setParticipant] = useState<any>();
  const [text, setText] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  const interactions = useSelector(selectInteractions);

  useEffect(() => {
    if (text.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [text]);

  useEffect(() => {
    setActiveInteraction(
      interactions
        ?.filter(
          (interaction: Interaction) => interaction.id === activeInteractionId
        )
        .pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInteractionId]);

  useEffect(() => {
    if (user) setParticipant(activeInteraction?.connectionProfile[user.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInteraction]);

  const handleRequestReschedule = () => {
    setIsDisabled(true);
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: user?.roles.includes(UserType.PROFESSIONAL) ? MessageType.RESCHEDULE : MessageType.RESCHEDULE_REQUEST,
      payload: text,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId,
        message,
      },
    });
  };
  return (
    <ModalContainer>
      <p className="noshow-modal-text font-family-nunito">
        {user?.roles.includes(UserType.PROFESSIONAL) ? "Reason for reschedule" :`Send ${participant?.firstName} a message to ask to reschedule. Be sure to apologise for the need to reschedule`}
      </p>
      <TextField
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        fullWidth
        variant="outlined"
        placeholder="Write your message here"
        className="prompt-modal-textfield"
      />
      <div className="modal-button-container">
        <Button
          variant="outlined"
          color="primary"
          className="cancel-button font-family-nunito"
          size="large"
          onClick={() => dispatch(setPromptModal({ isOpen: false, ModalType: null }))}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="RequestReschedule-button font-family-nunito"
          onClick={handleRequestReschedule}
          disabled={isDisabled}
        >
          {user?.roles.includes(UserType.PROFESSIONAL) ? "Reschedule" :"Request Reschedule"}
        </Button>
      </div>
    </ModalContainer>
  );
}

export default RequestReschedulePromptModal;

const ModalContainer = styled.div`
  .prompt-modal {
    .MuiButton-label {
      width: max-content;
    }
  }

  .prompt-modal-textfield {
    padding-bottom: 35px;
  }

  .modal-button-container {
    display: flex;
    justify-content: space-between;
  }

  .noshow-modal-text {
    color: #464FD0;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .confirm-noshow-button{
    background-color: #464FD0;
    text-transform: initial;
    padding: 8px 70px;
    font-weight: inherit;
  }

  .decline-button{
    background-color: #464FD0;
    text-transform: initial;
    padding: 4% 15%;
    font-weight: inherit;
  }

  .RequestReschedule-button{
    background-color: #464FD0;
    text-transform: initial;
    padding: 4% 11%;
    font-weight: inherit;
    width: 100%;
    min-width: max-content;
  }

  .cancel-noshow-button{
    background-color: #FFFFFF;
    text-transform: initial;
    margin-right: 20px;
    padding: 8px 70px;
    font-weight: inherit;
  }

  .cancel-button{
    background-color: #FFFFFF;
    text-transform: initial;
    margin-right: 20px;
    padding: 4% 12%;
    font-weight: inherit;
    width: 100%;
  }

  @media (max-width:600px){
    .noshow-modal-text {
      font-size: 16px;
      margin-bottom: 18px;
    }

    .prompt-modal-textfield {
      padding-bottom: 20px;
    }

    .decline-button{
      background-color: #464FD0;
      text-transform: initial;
      padding: 4% 10%;
      font-weight: inherit;
    }

    .cancel-button{
      background-color: #FFFFFF;
      text-transform: initial;
      margin-right: 20px;
      padding: 4% 5%;
      font-weight: inherit;
      width: 100%;
    }

    .RequestReschedule-button{
      background-color: #464FD0;
      text-transform: initial;
      padding: 4% 5.5%;
      font-weight: inherit;
      width: 100%;
      min-width: max-content;
    }
  }
`;