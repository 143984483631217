import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import authReducer from "features/auth/slice";
import interactionsReducer from "features/interactions/slice";
import toastReducer from "features/toast/slice";
import studentRegistrationReducer from "features/registration/student/studentRegistrationSlice";
import professionalRegistrationReducer from "features/registration/professional/professionalRegistrationSlice";
import alumniRegistrationReducer from "features/registration/alumni/alumniRegistrationSlice";
import rootSaga from "./rootSaga";
import searchReducer from "features/network/slice";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  auth: authReducer,
  interactions: interactionsReducer,
  studentRegistration: studentRegistrationReducer,
  professionalRegistration: professionalRegistrationReducer,
  alumniRegistration: alumniRegistrationReducer,
  toast: toastReducer,
  search: searchReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

sagaMiddleware.run(rootSaga);
