import { useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import axios from "axios";
import { useSelector } from "react-redux";
import {selectUser } from "features/auth/selectors";
import { User, UserType } from "common/types";
const ContactUs = () => {
    const user: User | null = useSelector(selectUser);
    const [formValues, setFormValues] = useState({ name: "", email: "", query: "", message: "" });
    const [formErrors, setFormErrors] = useState<any>({});

    const [open, setOpen] = React.useState(false);
    const handleClose = () =>{
        setOpen(false)
        setTimeout(() => {
            setOpen(false);    
        }, 5000);
        } ;

    const enkiUrl = process.env.REACT_APP_ENKI_URL;

    const style = {
        position: 'absolute' as 'absolute',
        top: '90px',
        right: '1%',
        width: 300,
        bgcolor: '#B2D98B',
        border: '1px',
        borderRadius:'5px',
        p: 1,
      };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        const errors: any = {};
        const regex = /^(([^<>()\\[\]\\.,;:\s@“]+(\.[^<>()\\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!formValues.name) {
            errors.name = "Name is required";
        }
        if (!formValues.email) {
            errors.email = "Email is required"
        }
        else if (!regex.test(formValues.email)) {
            errors.email = "This is not a valid email"
        }
        if (!formValues.query) {
            errors.query = "Please select an option above"
        }
        if (!formValues.message) {
            errors.message = "Please enter message"
        }
        if (errors !== undefined && Object.keys(errors).length > 0) {
            setFormErrors(errors);
        } else {
        //  `${enkiUrl}/disadvantage/get-disadvantage`;
            axios.post(`${enkiUrl}/form/contact-us-smn-submit`, {
                name:formValues.name,
                email:formValues.email,
                query_type:formValues.query,
                message:formValues.message,
              })
              .then(function (response) {
                    window.scrollTo(0, 0);
                    setOpen(true);
                    setFormValues({ name: "", email: "", query: "", message: "" });
                    setFormErrors({});
                    setTimeout(() => {
                        handleClose();  
                      }, 3000);
              })
              .catch(function (error) {
                return error;
              });
        }
        
    }
    return (
        <>
            <div className="container contactUs mt-5">
                    <div >
                        <h3 className="h2 mb-3">
                            Contact Us
                        </h3>
                        <p>
                            If you need support or if you have any questions, comments, or feedback that you would like to share, please contact the Social Mobility Network team by completing the below form. We aim to get back to you within 3-4 working days.
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-3">
                        <div className="form-group col-md-4">
                            <label htmlFor="Name" className="form-label">Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="textbox"
                                placeholder="Enter Your Full Name"
                                className="form-control text-muted"
                                name="name"
                                value={formValues.name}
                                onChange={handleChange}
                            />
                        </div>
                        <p className="text-danger">{formErrors.name}</p>
                        <div className="form-group col-md-4 mt-4">
                            <label htmlFor="Name" className="form-label" >Email Address <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"
                                className="form-control text-muted"
                                placeholder={user && user?.roles.includes(UserType.STUDENT) ? "Enter University Email" : "Enter Work Email"}
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                        </div>
                        <p className="text-danger">{formErrors.email}</p>
                        <div className="form-group col-md-4 mt-4">
                            <label htmlFor="Name" className="form-label">Query Type <span style={{ color: 'red' }}>*</span></label>
                            <select className="form-select text-muted" name='query' value={formValues.query}
                                onChange={handleChange}>
                                <option selected disabled value="">Select Query Type</option>
                                <option value="General Query">General Query</option>
                                <option value="Technical Issue">Technical Issue</option>
                                <option value="Platform Feedback">Platform Feedback</option>
                                {user && (user.roles.includes(UserType.STUDENT) || user?.roles.includes(UserType.ALUMNI)) && (
                                <option value="Report Inappropriate Behaviour">Report Inappropriate Behaviour</option>)}
                                {user && (user.roles.includes(UserType.PROFESSIONAL))&& (
                                <option value="Safeguarding / Welfare Concern">Safeguarding / Welfare Concern</option>)}
                            </select>
                        </div>
                        <p className="text-danger">{formErrors.query}</p>
                        <div className="form-group col-md-4 mt-4">
                            <label htmlFor="Name" className="form-label text-muted">Message <span style={{ color: 'red' }}>*</span></label>
                            <textarea className="md-textarea form-control" placeholder="Enter Message" rows={3} name="message" id="message" value={formValues.message}
                                onChange={handleChange} style={{resize: "none"}} >
                            </textarea>
                        </div>
                        <p className="text-danger">{formErrors.message}</p>
                        <div >
                            <button className="btn btn-primary mb-4 px-4" >Submit</button>
                        </div>
                        <div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                hideBackdrop={true}
                            >
                                <Box sx={style}>
                                    <Typography style={{fontSize:"12px",color:"#304653"}}>
                                        Thank you for submitting your query. The team will get back to you via email within 3-4 working days.
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    </form>
            </div>
        </>
    )
}
export default ContactUs;