import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Interaction } from "common/types";

export type InteractionsSlice = {
  interactions: Array<Interaction>;
  modalOpen: boolean;
  activeInteractionId: string | null;
  interactionRequestModelOpen: boolean;
  isRequestButtonDisabled: boolean;
  messageSendInProgress: boolean;
};

export const initialState: InteractionsSlice = {
  interactions: [],
  modalOpen: false,
  activeInteractionId: null,
  interactionRequestModelOpen: false,
  isRequestButtonDisabled: false,
  messageSendInProgress: false,
};

const slice = createSlice({
  name: "interactions",
  initialState,
  reducers: {
    setInteractions: (state: InteractionsSlice, action: any) => {
      state.interactions = action.payload;
    },
    setModal: (state: InteractionsSlice, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    },
    setActiveInteractionId: (state: InteractionsSlice, action: PayloadAction<string | null>) => {
      state.activeInteractionId = action.payload;
    },
    setInteractionRequestModelOpen: (state: InteractionsSlice, action: PayloadAction<boolean>) => {
      state.interactionRequestModelOpen = action.payload;
    },
    setIsRequestButtonDisabled: (state: InteractionsSlice, action: PayloadAction<boolean>) => {
      state.isRequestButtonDisabled = action.payload;
    },
    setMessageSendInProgress: (state: InteractionsSlice, action: PayloadAction<boolean>) => {
      state.messageSendInProgress = action.payload;
    },
  },
});

export const {
  setInteractions,
  setModal,
  setActiveInteractionId,
  setInteractionRequestModelOpen,
  setIsRequestButtonDisabled,
  setMessageSendInProgress
} = slice.actions;

export default slice.reducer;
