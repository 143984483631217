import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { initialState } from "./studentRegistrationSlice";

const studentRegistrationSlice = (state: RootState) =>
  state.studentRegistration || initialState;

export const selectBasicDetails = createSelector(
  [studentRegistrationSlice],
  (state) => state.basicDetails
);

export const selectUniversityDetails = createSelector(
  [studentRegistrationSlice],
  (state) => state.universityDetails
);

export const selectMetadata = createSelector(
  [studentRegistrationSlice],
  (state) => state.metadata
);

export const selectStepperActiveStep = createSelector(
  [studentRegistrationSlice],
  (state) => state.activeStep
);
