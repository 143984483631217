import Stepper from "common/components/Stepper";
import { useSelector } from "react-redux";
import BasicDetails from "./BasicDetails";
import UniversityDetails from "./UniversityDetails";
import { Container } from "@mui/material";
import WorkDetails from "./WorkDetails";
import styled from "styled-components";
import { selectStepperActiveStep } from "./selectors";
import ProfessionalismCharter from "./ProfessionalismCharter";

const AlumniRegistrationForm = () => {
  const activeStep = useSelector(selectStepperActiveStep);

  const steps = [
    {
      label: "Personal Details",
      content: <BasicDetails />,
    },
    {
      label: "University Details",
      content: <UniversityDetails />,
    },
    {
      label: "Work Details",
      content: <WorkDetails />,
    },
    {
      label: "Professionalism Charter",
      content: <ProfessionalismCharter />,
    },
  ];

  return (
    <StyledWrapper>
      <Container>
        <Stepper steps={steps} activeStep={activeStep} />
        {steps[activeStep].content}
      </Container>
    </StyledWrapper>
  );
};

export default AlumniRegistrationForm;

const StyledWrapper = styled.div`
  padding-bottom: 100px;
`;
