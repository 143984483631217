import StepperControls from "common/components/StepperControls";
// import styled from "styled-components";
import {
  goToNextStep,
  goToPrevStep,
  setIsNextStep,
  setWorkDetails,
} from "./alumniRegistrationSlice";
import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { InputType } from "common/components/TextField";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import DocumentPicker from "../../../common/components/DocumentPicker";
import { selectIsNextStep, selectWorkDetails } from "./selectors";
import EmploymentHistory from "./EmploymentHistory";
import React from "react";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import MonthYearPicker from "features/interactions/inputWidgets/MonthYearPicker";
import styled from "@emotion/styled";

const WorkDetails = () => {
  const workDetails: any = useSelector(selectWorkDetails);
  const dispatch = useDispatch();
  const isNextStep = useSelector(selectIsNextStep);
  const [employer, setEmployer] = useState<any | null>();
  const [selectedSectors, setSelectedSectors] = useState<any>([]);
  const [employersList, setEmployersList] = useState<any[]>([]);
  const [hasGraduateJob, setHasGraduateJob] = useState<boolean>(true);
  const [currentEmploymentStartDate, setCurrentEmploymentStartDate] = useState<Date | null>(null);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: workDetails,
  });


  useEffect(() => {
    setEmployer(workDetails?.company);
    setSelectedSectors(workDetails.sectors);
    if (workDetails.employmentHistory) {
      setEmployersList([...workDetails.employmentHistory]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workDetails]);

  const onSubmit = (data: any) => {
    data.sectors = selectedSectors;
    if (hasGraduateJob) data.hasGraduateJob = true;
    else data.hasGraduateJob = false;
    if (currentEmploymentStartDate) data.currentEmploymentStartDate = currentEmploymentStartDate;
    const newEmployentHistoryArray: any[] = [];
    employersList.forEach((list) => {
      if (list.company !== "") {
        newEmployentHistoryArray.push(list);
      }
    });
    data.employmentHistory = newEmployentHistoryArray;
    dispatch(setWorkDetails(data));
    if (isNextStep) {
      dispatch(goToNextStep());
    }
    if (!isNextStep) {
      dispatch(goToPrevStep());
    }
  };

  const appendToEmployerList = () => {
    setEmployersList(employersList.concat({}));
  };

  const deleteFromEmployerList = (index: number) => {
    setEmployersList(employersList.filter((x, i) => i !== index));
  };

  const updateToEmployerList = (value: any, index: number) => {
    const oldEmployersList = employersList;
    oldEmployersList[index] = { ...value };
  };
  return (
    <StyledForm
      className="registration-box-shadow company-jobtitle"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <h3 className="prof-registration-heading">
          Add Career details below..
        </h3>
      </Grid>
      <Grid className="month-picker" container spacing={4} item lg={12}>
        <Grid className="mt-5" item xs={12}>
          <Checkbox
            name="hasGraduateJob"
            onClick={() => setHasGraduateJob(!hasGraduateJob)}
            checked={hasGraduateJob}
          />
          Do you have a graduate job?{" "}
        </Grid>
        {hasGraduateJob && <>
          <Grid item xs={12} lg={6}>
            <TextField
              className="work-textfields"
              type={InputType.TEXT}
              variant="outlined"
              helperText={errors.jobTitle ? "This field is required" : ""}
              error={errors.company}
              inputRef={register({ required: hasGraduateJob })}
              name="company"
              defaultValue={employer}
              label="Company"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              className="work-textfields"
              name="jobTitle"
              type={InputType.TEXT}
              inputRef={register({ required: hasGraduateJob })}
              variant="outlined"
              label="Job Title"
              error={!!errors.jobTitle}
              helperText={errors.jobTitle ? "This field is required" : ""}
            />
          </Grid>
          <Grid className="mb-0 currentJobDate" item lg={12} container spacing={1}>
            <Grid className="flex-end top-bottom-spacing no-padding" item xs={12} lg={6}>
              <MonthYearPicker
                value={currentEmploymentStartDate}
                name="currentEmploymentStartDate"
                onChange={(date: Date) => setCurrentEmploymentStartDate(date)}
                maxDate={new Date()}
                placeholder="Select start date"
                required={hasGraduateJob}
              />
            </Grid>
          </Grid> </>}
        <Grid item xs={12} lg={6}>
          <DocumentPicker
            handleChange={(selectedSectors: any) =>
              setSelectedSectors(selectedSectors)
            }
            collection="sectors"
            multiple
            error={errors.sectors}
            name="sectors"
            label="Sectors"
            required={selectedSectors?.length === 0}
            defaultValues={selectedSectors}
            inputRef={register({ required: selectedSectors?.length === 0 })}
          />
        </Grid>
        <Grid className="mb-0" item xs={12}>
          <h4 className="m-0">Previous Employment</h4>
        </Grid>
        {React.Children.toArray(
          employersList.map((data, i) => (
            <EmploymentHistory
              errors={errors}
              register={register}
              deleteFromEmployerList={deleteFromEmployerList}
              updateToEmployerList={updateToEmployerList}
              index={i}
              value={data}
            />
          ))
        )}
        <Grid item xs={12}>
          <Button onClick={appendToEmployerList} color="primary">
            Add another previous employer
          </Button>
        </Grid>
        <hr />
      </Grid>
      <StepperControls
        nextButtonLabel="Next"
        disabled={false}
        nextButtonCallback={() => dispatch(setIsNextStep(true))}
        backButtonLabel="Back"
        backButtonCallback={() => dispatch(setIsNextStep(false))}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 2rem;
  .MuiGrid-item {
    margin-bottom: 1rem;
    padding: 0 3rem;
  }
  .m-0 {
    margin: 0;
  }
  .mb-0 {
    margin: 0;
  }

  .mt-5 {
    margin-top: 50px;
  }
  .work-textfields {
    width: 100%;
  }

  .company-jobtitle {
    .month-picker {
      margin-bottom: -1.2rem !important;
    }
  }

  .currentJobDate .no-padding{
    padding: 0px;
    padding-right: inherit;
  }

`;

export default WorkDetails;
