import { Button } from "@mui/material";
import { Message, MessageType } from "common/types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { selectMessageSendInProgress, selectActiveInteractionId } from "../selectors";
import { setMessageSendInProgress } from "../slice";

const RescheduleRequestResponse = () => {

  const dispatch = useDispatch();
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const isDisabled = useSelector(selectMessageSendInProgress);
  
  const handleAccept = () => {
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.RESCHEDULE_ACCEPT,
      payload: null,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: { 
        activeInteractionId,
        message,
      },
    })
  };
  const handleDecline = () => {
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.RESCHEDULE_DECLINE,
      payload: null,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: { 
        activeInteractionId,
        message,
      },
    })
  };

  return (
    <StyledWrapper>
      <Button
        className="accept-request-btn color-dark-blue"
        disabled={isDisabled}
        onClick={handleAccept}
      >
        Accept Request
      </Button>
      <Button
        className="decline-request-btn"
        disabled={isDisabled}
        color="secondary"
        onClick={handleDecline}
      >
        Decline Request
      </Button>
    </StyledWrapper>
  );
};

export default RescheduleRequestResponse;

const StyledWrapper = styled.div`
  .accept-request-btn {
    border: 1px solid #464fd0;
    margin-left: 20px;
    margin-right: 20px;
    /* width: 100px; */
    background-color: #464fd0;
    color: white !important;
    text-transform: capitalize;
  }

  .accept-request-btn:hover {
    color: #464fd0 !important;
    background-color: white;
  }
  .decline-request-btn {
    border: 1px solid #464fd0;
    margin-left: 20px;
    margin-right: 20px;
    /* width: 100px; */
    color: #464fd0;
    text-transform: capitalize;
  }

  .decline-request-btn:hover {
    background-color: #464fd0;
    color: white;
  }


`;