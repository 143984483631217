import { useSelector } from "react-redux";
import { selectAuthUser } from "features/auth/selectors";
import IconButton from '@mui/material/IconButton';

type Props = {
    isBookmarked: boolean;
    handleBookmarkClick: any;
    userId: string;
}

const Bookmark = ({ isBookmarked = false, handleBookmarkClick, userId }: Props) => {
    const authUser = useSelector(selectAuthUser);
    return (
        <IconButton className="bookmark-logo" size="medium" onClick={() => handleBookmarkClick(userId, authUser)}>
            {!isBookmarked ?
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.57143 0H1.42857C0.642857 0 0.00714302 0.6 0.00714302 1.33333L0 12L5 10L10 12V1.33333C10 0.6 9.35714 0 8.57143 0ZM8.57143 10L5 8.54667L1.42857 10V1.33333H8.57143V10Z" fill="#9EA5EE" />
                </svg> :
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.57143 0H1.42857C0.642857 0 0.00714302 0.6 0.00714302 1.33333L0 12L5 10L10 12V1.33333C10 0.6 9.35714 0 8.57143 0Z" fill="#F2B542" />
                </svg>
            }
        </IconButton>
    )
}

export default Bookmark;
