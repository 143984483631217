import { getAxiosInstance } from "common/utils";

export const bookmark = async (userId: string) => {
  const axios = await getAxiosInstance();
  try {
    const userBookmarks = await axios.post("/bookmarks/bookmarkUser", { userId });
    return userBookmarks.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeBookmark = async (userId: string) => {
  const axios = await getAxiosInstance();
  try {
    const userBookmarks = await axios.post("/bookmarks/removeUserBookmark", { userId });
    return userBookmarks.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBookmarkedUsers = async (
  pageNo: number,
  pageSize: number,
  authUserId: string,
  algoliaFilters: string
) => {
  const axios = await getAxiosInstance();
  try {
    const userBookmarks = await axios.post("/bookmarks/fetchBookmarkedUsers", {
      pageNo,
      pageSize,
      authUserId,
      algoliaFilters,
    });
    return userBookmarks.data.result;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBookmarkedUserIdArray = async (bookmarkedUsersId: any[]) => {
  const axios = await getAxiosInstance();
  try {
    const userBookmarks = await axios.post("/bookmarks/fetchBookmarkedUserIdArray", {
      bookmarkedUsersId,
    });
    return userBookmarks.data;
  } catch (error) {
    console.log(error);
  }
};
