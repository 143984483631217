import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "common/types";

export type AuthSlice = {
  authUser: AuthUser | null;
  user: User | null;
  firstLoad: boolean;
  loginFormWorking: boolean;
  registrationFormWorking: boolean;
  onLoginSyncInProgress: boolean;
  onLoginRedirectRoute: string | null;
  isLoading: boolean;
  promptModal: PromptModalType;
  isUpdateDrawerOpen: boolean,
};

export type AuthUser = {
  id: string;
  email: string;
  emailVerified: boolean;
};

export type PromptModalType = {
  isOpen: boolean;
  ModalType: string | null;
};

export const initialState: AuthSlice = {
  authUser: null,
  user: null,
  firstLoad: true,
  loginFormWorking: false,
  registrationFormWorking: false,
  onLoginSyncInProgress: false,
  onLoginRedirectRoute: null,
  isLoading: false,
  promptModal: { isOpen: false, ModalType: null },
  isUpdateDrawerOpen: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state: AuthSlice, action: PayloadAction<AuthUser | null>) => {
      state.authUser = action.payload;
    },
    login: (state: AuthSlice, action: PayloadAction<any>): any => {
      state.user = action.payload;
    },
    logout: (state: AuthSlice): any => {
      state.user = null;
    },
    setFirstLoad: (state: AuthSlice, action: PayloadAction<boolean>) => {
      state.firstLoad = action.payload;
    },
    toggleLoginFormWorking: (state: AuthSlice) => {
      state.loginFormWorking = !state.loginFormWorking;
    },
    toggleRegistrationFormWorking: (state: AuthSlice) => {
      state.registrationFormWorking = !state.registrationFormWorking;
    },
    toggleOnLoginSyncInProgress: (state: AuthSlice) => {
      state.onLoginSyncInProgress = !state.onLoginSyncInProgress;
    },
    setOnLoginRedirectRoute: (state: AuthSlice, action: PayloadAction<string | null>) => {
      state.onLoginRedirectRoute = action.payload;
    },
    setIsLoading: (state: AuthSlice, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPromptModal: (state: AuthSlice, action: PayloadAction<PromptModalType>) => {
      state.promptModal = action.payload;
    },
    setIsUpdateDrawerOpen: (state: AuthSlice, action: PayloadAction<boolean>) => {
      state.isUpdateDrawerOpen = action.payload;
    },
  },
});

export const {
  setAuthUser,
  login,
  logout,
  setFirstLoad,
  toggleLoginFormWorking,
  toggleRegistrationFormWorking,
  toggleOnLoginSyncInProgress,
  setOnLoginRedirectRoute,
  setIsLoading,
  setPromptModal,
  setIsUpdateDrawerOpen,
} = slice.actions;

export default slice.reducer;
