import { InteractionState,User} from "common/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {selectActiveInteractionId, selectInteractions } from "../selectors";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { selectUser } from "features/auth/selectors";
import styled from "styled-components";
// import ThankYouNoteModal from "../../../features/interactions/threadMessageWidgets/ThankYouNoteModal";
import YesOver from "common/assets/images/svgToComponent/YesOver";
import NotOver from "common/assets/images/svgToComponent/NotOver";
// import sendLogo from "common/assets/images/sendLogo.svg";
// import { TextField} from "@mui/material";
// import { acceptInteractionRequest } from "../utils";
// import recordEvent from "common/utils/mixpanel";
// import { setMessageSendInProgress } from "../slice";
// import { setPromptModal } from "features/auth/slice";
// import ThankYou from "common/assets/images/svgToComponent/Like";
// import Like from "common/assets/images/svgToComponent/ThankYou";
// import NoShow from "common/assets/images/svgToComponent/NoShow";
// import NoShowDisabled from "common/assets/images/svgToComponent/NoShowDisabled";
// import LikeDisabled from "common/assets/images/svgToComponent/ThankYouDisabled";
// import ThankYouDisabled from "common/assets/images/svgToComponent/LikeDisabled";

import NoModal from "../threadMessageWidgets/NoModal";


const PostInteractionActions = () => {
  const interactions = useSelector(selectInteractions);
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  // const [isThankYouModalOpen, setIsThankYouModalOpen] = useState<boolean>(false);
  // const [receiver, setReceiver] = useState<any>(null);
  const user: User | null = useSelector(selectUser);
  // const isDisabled = useSelector(selectMessageSendInProgress);
  const dispatch = useDispatch();
  const [markInteractionBox, setMarkInteractionBox] = useState<boolean>(false);
  // const [text, setText] = useState<string>("");
  // const[textBox,setTextBox] = useState<boolean>(true);

  const [isNoModalOpen, setIsNoModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setActiveInteraction(
      interactions
        ?.filter((interaction: any) => interaction.id === activeInteractionId)
        .pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions]);

  useEffect(() => {
    // eslint-disable-next-line
    activeInteraction?.thread.some((el: any) => {
      if (el.sender.id !== user?.id) {
        // setReceiver(el.sender);
        return true;
      }
    });
  }, [activeInteraction, user]);

  // const handleNoShowClick = () => {
  //   dispatch(setPromptModal({ isOpen: true, ModalType: "NO_SHOW" }));
  // };

  // const handleAppreciateClick = () => {
  //   dispatch(setMessageSendInProgress(true));
  //   const message: Partial<Message> = {
  //     type: MessageType.LIKE,
  //     payload: null,
  //   };
  //   dispatch({
  //     type: "SEND_MESSAGE",
  //     payload: {
  //       activeInteractionId: activeInteraction?.id,
  //       message,
  //     },
  //   });
  // };

  // const handleThankYouNoteClick = () => {
  //   setIsThankYouModalOpen(true);
  // };

  // const hasLikeInteraction =
  //   activeInteraction?.thread.filter(
  //     (message: Message) =>
  //       message.sender !== "SYSTEM" &&
  //       message.type === MessageType.LIKE &&
  //       message.sender.id === user?.id
  //   ).length > 0;
  // const hasNoShowInteraction =
  //   activeInteraction?.thread.filter(
  //     (message: Message) =>
  //       message.sender !== "SYSTEM" &&
  //       message.type === MessageType.NO_SHOW &&
  //       message.sender.id === user?.id
  //   ).length > 0;
  // const hasThanksInteraction =
  //   activeInteraction?.thread.filter(
  //     (message: Message) =>
  //       message.sender !== "SYSTEM" &&
  //       message.type === MessageType.THANKS_NOTE &&
  //       message.sender.id === user?.id
  //   ).length > 0;

  const feedbackGiven = user
    ? activeInteraction?.feedbackGiven[user.id]
    : false;

  const feedbackDontGiven = 
    user ? activeInteraction?.feedbackDontGiven && activeInteraction.feedbackDontGiven[user.id]
    : false;
    


  // Start - Would you like to mark this interaction as complete?
  const handleInteractionCompleted = () => {

    // completeInteraction(activeInteraction.id);
    // recordEvent("Request Action Taken", {
    //   "Action Type": "Accepted",
    //   "Professional Sector": user?.sectors,
    //   "Professional Company": user?.company.name,
    //   "Professional Job Title": user?.jobTitle,
    //   "Professional University": user?.university?.name,
    //   "Professional University Course": user?.universityCourse,
    // });
    // dispatch(setMessageSendInProgress(true));
    dispatch({
      type: "INTERACTION_COMPLETED",
      payload: { activeInteractionId },
    });
    setMarkInteractionBox(true);
  };


  const handleInteractionNotCompleted = () => {
    // acceptInteractionRequest(activeInteraction.id)
    if(activeInteraction?.type === "EXPERT_ADVICE") 
    {
      dispatch({
        type: "INTERACTION_NOT_OVER",
        payload: { activeInteractionId },
      });
        setMarkInteractionBox(true);
      // setTextBox(false);
    }
    else setIsNoModalOpen(true)
    };
  // const handleClick = () => {
  //   // let eventData = {};
  //   // if (user?.roles.includes(UserType.STUDENT)) {
  //   //   eventData = {
  //   //     "Student Gender": user.gender,
  //   //     "Student Region": user.region?.name,
  //   //     "Student Ethnicity": user.ethnicity?.name,
  //   //     // "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
  //   //   };
  //   // }
  //   // if(user?.roles.includes(UserType.PROFESSIONAL)){
  //   //   eventData = {

  //   //   }
  //   // }
  //   // recordEvent("Follow Up Message Sent", { ...eventData });
  //   // dispatch(setMessageSendInProgress(true));
  //   const message: Partial<Message> = {
  //     type: MessageType.TEXT,
  //     payload: text,
  //   };
  //   dispatch({
  //     type: "SEND_MESSAGE",
  //     payload: {
  //       activeInteractionId,
  //       message,
  //     },
  //   });
  //   setText("");
  // };

  return (
    <StyledWrapper>
      {/* {activeInteraction?.state === InteractionState.COMPLETED &&
        !feedbackGiven && (
          <div className="post-interaction-background">
            <h3 className="feedback-text">
              Please choose one of the options below to leave your feedback.
            </h3>
            <Grid item className="after-interaction-actions d-flex" xs={12}>
              <div>
                <Button
                  onClick={() => handleNoShowClick()}
                  disabled={hasNoShowInteraction || isDisabled}
                  className={`action-btn ${hasNoShowInteraction && "disabled-action"
                    }`}
                  color="primary"
                >
                  {" "}
                  {hasNoShowInteraction ? <NoShowDisabled /> : <NoShow />}{" "}
                  <p className="body-11">No show</p>
                </Button>
              </div>
              {!user?.roles?.includes(UserType.PROFESSIONAL) && (
                <div>
                  <Button
                    onClick={() => handleAppreciateClick()}
                    disabled={hasLikeInteraction || isDisabled}
                    className={`action-btn ${hasLikeInteraction && "disabled-action"
                      }`}
                    color="primary"
                  >
                    {hasLikeInteraction ? <ThankYouDisabled /> : <ThankYou />}
                    <p className="mt-1 body-11">Appreciate</p>
                  </Button>{" "}
                </div>
              )}
              <div>
                <Button
                  onClick={() => handleThankYouNoteClick()}
                  disabled={hasThanksInteraction || isDisabled}
                  className={`action-btn ${hasThanksInteraction && "disabled-action"
                    }`}
                  color="primary"
                >
                  {hasThanksInteraction ? <LikeDisabled /> : <Like />}
                  <p className="mt-1 body-11">
                    {user?.roles.includes(UserType.STUDENT)
                      ? "Message of Thanks"
                      : "Feedback Message"}
                  </p>
                </Button>
              </div>
            </Grid>
          </div>
        )} */}
      {/* Start - Would you like to mark this interaction as complete? */}
      {activeInteraction?.state === InteractionState.COMPLETED && 
        !feedbackGiven && !markInteractionBox && !feedbackDontGiven && activeInteraction?.type === "EXPERT_ADVICE"
         ? (
          <div className="post-interaction-background bubble">
            <h3 className="text-center font-weight-bolder">Would you like to mark this interaction as complete?</h3>
            <Grid item className="after-interaction-actions d-flex" xs={12}>
              <div>
                <Button
                  onClick={() => handleInteractionCompleted()}
                  // disabled={isDisabled}
                  className={`action-btn ${"disabled-action"
                    }`}
                  color="primary">
                  {" "}
                  {<YesOver />}{" "}
                  <p className="mt-1 body-12">YES THE INTERACTION IS COMPLETE</p>
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => handleInteractionNotCompleted()}
                  // disabled={isDisabled}
                  className={`action-btn ${"disabled-action"
                    }`}
                  color="primary">
                  {<NotOver />}
                  <p className="mt-1 body-12">No the conversation is not over</p>
                </Button>
              </div>
            </Grid>
          </div>
        ) : ( 
          activeInteraction?.type !== "EXPERT_ADVICE" && (
            <div className="post-interaction-background bubble">
              <h3 className="text-center font-weight-bolder">The scheduled time for this interaction has now passed. Did this interaction take place? </h3>
              <Grid item className="after-interaction-actions d-flex" xs={12}>
                <div>
                  <Button
                    onClick={() => handleInteractionCompleted()}
                    // disabled={isDisabled}
                    className={`action-btn ${"disabled-action"
                      }`}
                    color="primary">
                    {" "}
                    {<YesOver />}{" "}
                    <p className="mt-1 body-12">YES THE INTERACTION TOOK PLACE</p>
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => handleInteractionNotCompleted()}
                    // disabled={isDisabled}
                    className={`action-btn ${"disabled-action"
                      }`}
                    color="primary">
                    {<NotOver />}
                    <p className="mt-1 body-12">NO THE INTERACTION DID NOT TAKE PLACE</p>
                  </Button>
                </div>
              </Grid>
            </div>
          )
          
        )
      }
      {/* {!textBox &&
        <>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            multiline
            rows={5}
            fullWidth
            variant="filled"
            placeholder="Write a response"
            className="thread-input"
            InputProps={{ disableUnderline: true }}
          />
          <Button
            variant="contained"
            disabled={isDisabled}
            color="primary"
            className="send-btn cursor-pointer"
            onClick={text.replace(/\s/g, "") !== "" ? handleClick : undefined}
          >
            {" "}
            <div className="d-flex send-logo">
              {" "}
              Send <img className="justify-center send-icon" src={sendLogo} alt="" />
            </div>{" "}
          </Button>
        </>
      } */}
      {/* End - Would you like to mark this interaction as complete? */}

      {/* <ThankYouNoteModal
          setIsThankYouModalOpen={setIsThankYouModalOpen}
          isThankYouModalOpen={isThankYouModalOpen}
          interactionId={activeInteraction?.id}
          receiver={receiver}
      /> */}
      
      <NoModal
        setIsNoModalOpen={setIsNoModalOpen}
        isNoModalOpen={isNoModalOpen}
        interactionId={activeInteraction?.id}
      />

    </StyledWrapper>
  );
};

export default PostInteractionActions;

const StyledWrapper = styled.div`
  .post-interaction-background {
    background-color: #f3f3fb;
    padding: 30px;
  }

  .after-interaction-actions {
    display: flex;
    justify-content: space-around;

    .action-btn {
      height: 100%;
      width: 100%;
      display: inline;

      p {
        margin: 0;
        color: #464fd0;
      }

      svg {
        margin: 0px auto 0 auto;
        display: block;
      }
    }

    .mt-1 {
      margin-top: 5px !important;
    }

    .disabled-action {
      height: 100%;
      width: 100%;
      display: inline;
      p {
        margin: 0;
        // color: grey;
      }
    }
  }

  .feedback-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 0;
  }

  .send-btn {
    float: right;
    margin-top: 30px;
    border: none;
    width: 100px;
    height: 39px;
    justify-content: center;
    padding-top: 8px;
    font-family: Nunito Sans, sans-serif !important;
  }
  .send-icon {
    margin-left: 10px;
    position: relative;
    top: -5px;
  }
  .send-logo {
    justify-content: center;
    margin: auto;
    font-family: Nunito Sans, sans-serif !important;
  }
  .thread-input {
    "& input::placeholder": {
      font-family: Nunito Sans, sans-serif !important;
    },
    textarea,
    div {
      background: #f7f7f7;
    },
  }
`;