import { TextField, Button } from "@mui/material";
import { Message, MessageType, UserType } from "common/types";
import { useState } from "react";
import styled from "styled-components";
import sendLogo from "common/assets/images/sendLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageSendInProgress, selectActiveInteractionId } from "../selectors";
import { setMessageSendInProgress } from "../slice";
import { selectUser } from "features/auth/selectors";
import recordEvent from "common/utils/mixpanel";

const TextMessageInput = () => {
  const [text, setText] = useState<string>("");
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const dispatch = useDispatch();
  const isDisabled = useSelector(selectMessageSendInProgress);
  const user: any = useSelector(selectUser);

  const handleClick = () => {
    let eventData = {};
    if (user?.roles.includes(UserType.STUDENT)) {
      eventData = {
        "Student Gender": user.gender,
        "Student Region": user.region?.name,
        "Student Ethnicity": user.ethnicity?.name,
        "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
      };
    } else {
      eventData = {
        "Professional Sector": user.sector,
        "Professional Company": user.company?.name,
        "Professional Job Title": user.jobTitle,
        "Professional University": user.university?.name,
      };
    }
    recordEvent("Follow Up Message Sent", { ...eventData });
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.TEXT,
      payload: text,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId,
        message,
      },
    });
    setText("");
  };

  return (
    <StyledWrapper>
      <TextField
        value={text}
        onChange={(e) => setText(e.target.value)}
        multiline
        rows={5}
        fullWidth
        variant="filled"
        placeholder="Write a response"
        className="thread-input"
        InputProps={{ disableUnderline: true }}
      />
      <Button
        variant="contained"
        disabled={isDisabled}
        color="primary"
        className="send-btn cursor-pointer"
        onClick={text.replace(/\s/g, "") !== "" ? handleClick : undefined}
      >
        {" "}
        <div className="d-flex send-logo">
          {" "}
          Send <img className="justify-center send-icon" src={sendLogo} alt="" />
        </div>{" "}
      </Button>
    </StyledWrapper>
  );
};

export default TextMessageInput;

const StyledWrapper = styled.div`
  .send-btn {
    float: right;
    margin-top: 30px;
    border: none;
    width: 100px;
    height: 39px;
    justify-content: center;
    padding-top: 8px;
    font-family: Nunito Sans, sans-serif !important;
    background-color: #3f51b5!important;
  }

  .send-icon {
    margin-left: 10px;
    position: relative;
    top: -5px;
  }

  .send-logo {
    justify-content: center;
    margin: auto;
    font-family: Nunito Sans, sans-serif !important;
  }

  .thread-input {
    "& input::placeholder": {
      font-family: Nunito Sans, sans-serif !important;
    },
    textarea,
    div {
      background: #f7f7f7;
    },
  }
`;
