import { Button, CircularProgress, Container, Grid, useMediaQuery } from "@mui/material";
import Loading from "common/components/Loading";
import { InteractionType } from "common/types";
import { selectAuthUser, selectUser } from "features/auth/selectors";
import Pagination from "features/network/Pagination";
import ProfessionalUserCard, {
  interactionTypeAvailability,
} from "features/network/ProfessionalUserCard";
import { createFilter } from "features/network/searchUtils";
import { fetchBookmarkedUserIdArray, fetchBookmarkedUsers } from "features/network/utils";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const BookmarkPage = () => {

  const authUser: any = useSelector(selectAuthUser);
  const user: any = useSelector(selectUser);

  const [fetchedBookmarkedUsers, setFetchedBookmarkedUsers] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isUserBookmarked, setIsUserBookmarked] = useState<any>(null);
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [sectors, setSectors] = useState<Array<any>>([]);
  const [isUniversitySelected, setIsUniversitySelected] = useState<boolean>(true);
  const [isSameGender, setIsSameGender] = useState<boolean>(true);
  const [regions, setRegions] = useState<Array<any>>([]);

  //filters
  const [interactionFilter, setInteractionFilter] = useState<string>("");
  const [companyFilter, setCompanyFilter] = useState<string>("");
  const [sectorFilter, setSectorFilter] = useState<string>("");
  const [universityFilter, setUniversityFilter] = useState<string>("");
  const [regionFilter, setRegionFilter] = useState<string>("");
  const [genderFilter, setGenderFilter] = useState<string>("");
  const [advancedFilters, setAdvancedFilters] = useState<boolean>(false);

  const matches = useMediaQuery("(max-width:960px)");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [interactions] = useState<Array<any>>([
    { name: InteractionType.CAREER_CHAT },
    { name: InteractionType.EXPERT_ADVICE },
    { name: InteractionType.MOCK_INTERVIEW },
  ]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await firebase.firestore().collection("companies").orderBy("name", "asc").get();
      const companies: Array<any> = response.docs.map((doc) => ({
        id: doc.id,
        name: doc.data()["name"],
      }));
      setCompanies(companies);
    };
    const fetchSectors = async () => {
      const response = await firebase.firestore().collection("sectors").orderBy("name", "asc").get();
      const sectors: Array<any> = response.docs.map((doc) => ({
        id: doc.id,
        name: doc.data()["name"],
      }));
      //sort sectors here
      sectors.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setSectors(sectors);
    };

    const fetchRegions = async () => {
      const response = await firebase.firestore().collection("regions").orderBy("name", "asc").get();
      const regions: Array<any> = response.docs.map((doc) => ({
        id: doc.id,
        name: doc.data()["name"],
      }));
      setRegions(regions);
    };

    fetchCompanies();
    fetchSectors();
    fetchRegions();
  }, []);

  useEffect(() => {
    const filterString = createFilter(
      interactionFilter,
      sectorFilter,
      companyFilter,
      universityFilter,
      regionFilter,
      genderFilter
    ).split(" AND ");
    filterString.shift();
    fetchUsers(filterString.join(" AND "));
    // eslint-disable-next-line
  }, [
    interactionFilter,
    sectorFilter,
    universityFilter,
    regionFilter,
    genderFilter,
    companyFilter,
    pageNumber,
  ]);

  const clearFilters = () => {
    setInteractionFilter("");
    setCompanyFilter("");
    setSectorFilter("");
    setUniversityFilter("");
    setRegionFilter("");
    setGenderFilter("");
    setIsSameGender(true);
    setIsUniversitySelected(true);
  };
  const toggleAdvancedFilters = () => {
    setAdvancedFilters(!advancedFilters);
  };

  const fetchUsers = async (algoliaFilters: string = "") => {
    setIsLoading(true);
    const users: any = await fetchBookmarkedUsers(pageNumber, 12, authUser.id, algoliaFilters);
    setFetchedBookmarkedUsers(users);
    setIsLoading(false);
  };

  const handleUniversityClick = async () => {
    await setIsUniversitySelected(!isUniversitySelected);
    await setIsLoading(true);
    if (isUniversitySelected) {
      setUniversityFilter(user?.university?.name);
    } else {
      setUniversityFilter("");
    }

  }

  const handleGenderClick = async () => {
    await setIsSameGender(!isSameGender);
    await setIsLoading(true);
    if (isSameGender) {
      setGenderFilter(user?.gender);
    } else {
      setGenderFilter("");
    }

  }

  useEffect(() => {
    async function loopThroughUsersArray() {
      const boomarkedUsersId: string[] = [];
      await fetchedBookmarkedUsers?.data?.forEach((user: any) => {
        boomarkedUsersId.push(user.id);
      });
      const userBookmarkedArray: any = await fetchBookmarkedUserIdArray(boomarkedUsersId);
      setIsUserBookmarked(userBookmarkedArray);
    }
    loopThroughUsersArray();
  }, [fetchedBookmarkedUsers]);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  if (!fetchedBookmarkedUsers || !isUserBookmarked) return <Loading />;

  return (
    <StyledWrapper>
      <Container>
        {matches && (
          <Grid container className="filter-section filter-section-mobile" xs={12}>
            <Grid item xs={12} sm={6}>
              <Select
                value={sectorFilter}
                placeholder={sectorFilter === "" ? "Any sector" : sectorFilter}
                name="sector"
                options={sectors}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) => option.name}
                onChange={(sector) => {
                  sector && setSectorFilter(sector.name);
                  setIsLoading(true);
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                className="filter-dropdown filter-dropdown-sm no-wrap"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                value={interactionFilter}
                placeholder={
                  interactionFilter === ""
                    ? "Any interaction"
                    : interactionTypeAvailability[interactionFilter]
                }
                name="interaction"
                options={interactions}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) => interactionTypeAvailability[option.name]}
                onChange={(interaction) => interaction && setInteractionFilter(interaction.name)}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                className="filter-dropdown filter-dropdown-sm no-wrap"
              />
            </Grid>
          </Grid>
        )}
        {!matches && (
          <Grid className="filter-section filter-section-desktop" xs={12}>
            <div className="d-flex">
              <h3 className="filter-text">Filter professionals working in</h3>
              <Select
                value={sectorFilter}
                placeholder={sectorFilter === "" ? "Any sector" : sectorFilter}
                name="sector"
                options={sectors}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) => option.name}
                onChange={(sector) => {
                  sector && setSectorFilter(sector.name);
                  setIsLoading(true);
                }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                className="filter-dropdown no-wrap"
              />
            </div>
            <div className="d-flex">
              <h3 className="filter-text">and available for</h3>
              <Select
                value={interactionFilter}
                placeholder={
                  interactionFilter === ""
                    ? "Any interaction"
                    : interactionTypeAvailability[interactionFilter]
                }
                name="interaction"
                options={interactions}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) => interactionTypeAvailability[option.name]}
                onChange={(interaction) => interaction && setInteractionFilter(interaction.name)}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                className="filter-dropdown no-wrap"
              />
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Button color="primary" onClick={toggleAdvancedFilters}>
            <div className="font-family-nunito font-weight-bolder">Advanced Filters</div>{" "}
            {advancedFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
          </Button>
        </Grid>

        {advancedFilters && (
          <>
            <div>
              <Grid container className="advanced-filters">
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Select
                    value={companyFilter}
                    placeholder={companyFilter === "" ? "Any Company" : companyFilter}
                    name="Company"
                    options={companies}
                    getOptionValue={(option: any) => option.name}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(company) => company && setCompanyFilter(company.name)}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    className="filter-dropdown filter-dropdown-sm no-wrap"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Select
                    value={regionFilter}
                    placeholder={regionFilter === "" ? "Any Region" : regionFilter}
                    name="region"
                    options={regions}
                    getOptionValue={(option: any) => option.name}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(region) => {
                      region && setRegionFilter(region.name);
                      setIsLoading(true);
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    className="filter-dropdown filter-dropdown-sm no-wrap"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControlLabel
                    control={<Checkbox checked={!isUniversitySelected} onChange={() => handleUniversityClick()} color="primary" />}
                    label="Studied at the same university?"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControlLabel
                    control={<Checkbox checked={!isSameGender} onChange={() => handleGenderClick()} color="primary" />}
                    label="Same gender as me?"
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )}
        <Button color="primary" className="clear-filter font-family-nunito" onClick={clearFilters}>
          Clear Filters
        </Button>
        <h3 className="bookmarked-heading">Bookmarked ({fetchedBookmarkedUsers?.data?.length})</h3>
        {fetchedBookmarkedUsers?.data.length === 0 ? (
          <p className="no-bookmarked-users">
            {" "}
            You don’t have any bookmarks yet. Click <Link to="/professionals">here</Link> to search
            for professionals
          </p>
        ) : (
          <Grid
            className="sub-container"
            container
            item
            spacing={3}
            direction="row"
            style={{ padding: "30px 0px" }}
          >
            {isLoading ? (
              <CircularProgress className="no-user-text" />
            ) : (
              <>
                {React.Children.toArray(
                  fetchedBookmarkedUsers?.data?.map((user: any) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <ProfessionalUserCard
                        user={user}
                        student={user}
                        isUserBookmarked={isUserBookmarked[user.id]}
                        fetchUsers={fetchUsers}
                        isBookmarkPage
                      />
                    </Grid>
                  ))
                )}
              </>
            )}
            {fetchedBookmarkedUsers?.noOfPagesAvailable > 1 && (
              <Grid item xs={12} className="pagination">
                <Pagination
                  count={fetchedBookmarkedUsers?.noOfPagesAvailable}
                  value={pageNumber}
                  onChange={onPageChange}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </StyledWrapper>
  );
};

export default BookmarkPage;

const StyledWrapper = styled.div`
  .no-bookmarked-users {
    text-align: center;
    margin-top: 150px;
    font-size: 18px;
  }

  .pagination {
    display: flex;
    justify-content: center;

    .algolia-logo {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .MuiFormControlLabel-label {
    color: #464fd0;
  }

  .justify-content-center {
    display: flex;
    justify-content: center;
    @media (max-width: 960px) {
      justify-content: unset;
    }
  }

  .bookmarked-heading {
    margin-top: 50px;
  }

  .main-container {
    min-height: 80vh;

    .sub-container {
      min-height: inherit;
    }
  }
  .filter-section {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .advanced-filters {
    display: flex;
  }
  .advanced-filter-section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .clear-filter {
    display: block;
    height: 40px;
    margin-top: 10px;
    font-weight: 900;
  }

  .filter-dropdown {
    width: 100%;
    max-width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    top: 10px;
    .css-yk16xz-control {
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #464fd0;
      border-left: none;
      border-radius: 0;
      background-color: #f6faff;
    }

    svg {
      fill: #464fd0;
    }

    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
      color: #464fd0;
      font-weight: 600;
      font-size:24px;
    }
  }

  .filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }
  .advanced-filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }

  .filter-section-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }
  .advanced-filter-section-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .filter-dropdown-sm {
    margin: auto;
    padding: 0 20px 20px 20px;
    @media (max-width: 600px) {
      padding-left: unset;
      padding-right: unset;
      max-width: 100%;
    }
  }

  .clear-filter-sm {
    @media (min-width: 600px) {
      margin-right: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-top: 20px;
      display: block;
    }
  }

  .filter-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
    margin: 0;
  }

  .no-search {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0px;
    color: #9f9d9d;
  }

  .spacing {
    margin-bottom: 60px;
  }

  .m-0 {
    margin: 0;
  }

  .no-user-text {
    position: relative;
    top: 100px;
    margin: auto;
    color: #3f51b5 !important;
  }

  .no-serach-subtext {
    font-weight: 500;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  .no-wrap{
    white-space: nowrap;
  }
`;
