import { Avatar, Container, Grid, Button } from "@mui/material";
import { GenderChoices, User, UserType } from "common/types";
import { getAxiosInstance } from "common/utils";
import InteractionList from "features/interactions/InteractionList";
import InitiateInteraction from "features/interactions/RequestInteraction";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "common/components/Loading";
import { selectAuthUser, selectUser } from "features/auth/selectors";
import { selectInteractions } from "features/interactions/selectors";
import { setActiveInteractionId, setModal } from "features/interactions/slice";
import male from "common/assets/images/avatar.svg";
import female from "common/assets/images/avatar.svg";
import neutral from "common/assets/images/avatar.svg";
import linkedinIcon from "common/assets/images/linkedin-ic.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import ActivityRemainingCard from "../../components/home/ActivityRemainingCard";
import recordEvent from "common/utils/mixpanel";
import { fetchBookmarkedUserIdArray } from "./utils";
import IconButton from "@mui/material/IconButton";
import EditProfileDrawer from "common/components/EditProfileModal";
import { setIsUpdateDrawerOpen, setPromptModal } from "features/auth/slice";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

const UserProfile = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const user: User | null = useSelector(selectUser);
  const interactions = useSelector(selectInteractions);
  const [userInteractions, setUserInteractions] = useState<any>();
  const [showFeedbackOption, setShowFeedbackOption] = useState<boolean>(false);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:960px)");
  const [profile, setProfile] = useState<any>();
  const [pcProfile, setPcProfile] = useState<any>();
  let location = useLocation();
  const authUser = useSelector(selectAuthUser);
  const urlId = location.pathname.split("/").pop();
  const date = new Date();
  const handleInteractionClick = (interactionId: string) => {
    dispatch(setActiveInteractionId(interactionId));
    dispatch(setModal(true));
  };
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    getAssociateFeedbackOption();
    const bookmark = async () => {
      const data: any = await fetchBookmarkedUserIdArray([id]);
      if (data) setIsBookmarked(data[id as string]);
    };
    bookmark();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchUserProfile = async (id: string) => {
      const axios = await getAxiosInstance();
      try {
        const { data } = await axios.get(`/users/fetchUserProfile?id=${id}`);
        setProfile({ ...data });
      } catch (error) {
        console.log(error);
      }
    };
    if (id) fetchUserProfile(id);
  }, [id, user]);

  useEffect(() => {
    const fetchPcProfile = async () => {
      const axios = await getAxiosInstance();
      const { data }: any = await axios.get(
        `/users/fetchUserProfile?id=${profile?.programCoordinatorId}`
      );
      setPcProfile({ ...data });
    };
    if (profile?.programCoordinatorId) fetchPcProfile();
  }, [profile]);

  const handleEditProfileClick = () => {
    recordEvent("Account Click", {
      "User Roles": user?.roles,
      "Edited Profile": true,
      "Logout Clicked": false,
    });
    dispatch(setIsUpdateDrawerOpen(true));
  };

  const getAssociateFeedbackOption = async () => {
    const axios = await getAxiosInstance();

    try {
      const { data } = await axios.get(
        "/dashboard/fetchFeedbackOptionSettings"
      );
      setShowFeedbackOption(data && data.data["TOGGLE_FEEDBACK_OPTION"]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setUserInteractions(
      interactions.filter((interaction: any) => interaction.users.includes(id))
    );
  }, [interactions, id]);

  const isFieldsHidden = (key: string) => {
    return (
      urlId === authUser?.id &&
      user?.roles.includes(UserType.STUDENT) &&
      user?.metadata?.profileDataVisibility &&
      !user?.metadata?.profileDataVisibility[key]
    );
  };

  let linkedInUrl;
  if (profile?.linkedinUrl && profile?.linkedinUrl !== "") {
    const urlWithHttp = (url: any) =>
      !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    linkedInUrl = profile?.linkedinUrl && urlWithHttp(profile?.linkedinUrl);
  }

  const activeInteractions = user
    ? userInteractions?.filter(
        (interaction: any) =>
          // interaction.state !== "COMPLETED" &&
          interaction.state !== "EXPIRED" &&
          interaction.state !== "DECLINED"
          // created new for interactionComplete
          && interaction.state !== "INTERACTIONCOMPLETED"
          && !(interaction?.appologyMessage && interaction.appologyMessage[user.id])
          && !(interaction?.sorryModalClose && interaction.sorryModalClose[user.id])
          && !(interaction?.appologyModalClose && interaction.appologyModalClose[user.id])
      )
    : [];

  const pastInteractions = user
    ? userInteractions?.filter(
        (interaction: any) =>
          interaction.feedbackGiven[user.id] || (interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) ||
          interaction.state === "EXPIRED" ||
          interaction.state === "DECLINED"
          || (interaction?.appologyMessage && interaction.appologyMessage[user.id])
          || (interaction?.sorryModalClose && interaction.sorryModalClose[user.id])
          || (interaction?.appologyModalClose && interaction.appologyModalClose[user.id])
          // interaction.state === "COMPLETED"
          // created new for interactioncomplete
          // interaction.state === "INTERACTIONCOMPLETED"
      )
    : [];

    const awaitingFeedbackInteractions = user
    ? userInteractions?.filter(
        (interaction: any) =>(interaction?.remindMeLater && interaction?.remindMeLater[user.id] && 
          !interaction.feedbackGiven[user.id] && !(interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) &&
          interaction.state === "INTERACTIONCOMPLETED") ||
          (!interaction.feedbackGiven[user.id] && !(interaction?.feedbackDontGiven && interaction.feedbackDontGiven[user.id]) &&
          interaction.state === "INTERACTIONCOMPLETED") 
      )
    : [];

  if (!user || !profile || !id) return <Loading />;

  return (
    <StyledWrapper>
      <Grid className="card-edit-btn" item xs={12}>
        {urlId === authUser?.id && (
          <div className="order-2 edit-profile-btn">
            <Button className="edit-profile" onClick={handleEditProfileClick}>
              Edit Profile
            </Button>
          </div>
        )}
        {user?.roles?.includes(UserType.STUDENT) && (
          <div className="activity-remaining-card">
            <ActivityRemainingCard interactions={interactions} />
          </div>
        )}
        {user?.roles?.includes(UserType.STUDENT) && (
          <div className="offer-card-wrapper">
            <h3 className="body-20">Account and Profile</h3>
            {showFeedbackOption && (
              <div className="offer-card mb-3">
                <p className="body-18 offer-para">
                  Tell us how the Social Mobility Network has helped you
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  className="offer-cta"
                  onClick={() =>
                    dispatch(
                      setPromptModal({ isOpen: true, ModalType: "OFFER_MODAL" })
                    )
                  }
                >
                  Leave feedback
                </Button>
              </div>
            )}
          </div>
        )}
      </Grid>
      {profile && (
        <Container className="profile-container">
          <Grid
            container
            className="user-info background-white border-radius-5"
            xs={12}
          >
            <Grid item xs={12} md={2}>
              {isBookmarked && (
                <div className="bookmark-userprofile">
                  <svg
                    width="22"
                    height="26"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.57143 0H1.42857C0.642857 0 0.00714302 0.6 0.00714302 1.33333L0 12L5 10L10 12V1.33333C10 0.6 9.35714 0 8.57143 0Z"
                      fill="#F2B542"
                    />
                  </svg>
                </div>
              )}
              <Avatar
                className="profile-avatar"
                src={
                  profile.profilePicture ||
                  (profile.gender === GenderChoices.MALE && male) ||
                  (profile.gender === GenderChoices.FEMALE && female) ||
                  (profile.gender === GenderChoices.NOT_SPECIFIED && neutral) ||
                  undefined
                }
              />
              <div className="interaction-desktop">
                {(user?.roles.includes(UserType.STUDENT) ||
                  user?.roles.includes(UserType.ALUMNI)) &&
                  urlId !== authUser?.id &&
                  id && (
                    <InitiateInteraction profile={profile} receiverId={id} />
                  )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="profile-info user-description">
              <div className="name-linkedin">
                <h1 className="body-24 mb-0 mt-0 text-center-sm user-name">
                  {profile.firstName} {profile.lastName}
                </h1>
                {linkedInUrl && linkedInUrl !== "" && (
                  <a target="_blank" rel="noreferrer" href={linkedInUrl}>
                    <IconButton
                      className="linkedin-button"
                      aria-label=""
                      size="small"
                    >
                      <img
                        src={linkedinIcon}
                        className="linkedin-icon"
                        alt=""
                      />
                    </IconButton>
                  </a>
                )}
              </div>
              {(profile?.roles.includes(UserType.PROFESSIONAL) ||
                profile?.roles.includes(UserType.ALUMNI)) && (
                <div>
                  {profile?.roles.includes(UserType.PROFESSIONAL) && (
                    <>
                      <p className="body-15 mt-5px text-center-sm">
                        {profile?.company?.name}
                      </p>
                      {profile?.metadata?.hasHelpedReceiveAnOffer && (
                        <p className="body-18 mt-5px text-center-sm">
                          This professional has helped students get an offer 🎉
                        </p>
                      )}
                    </>
                  )}
                  {profile?.roles.includes(UserType.ALUMNI) && (
                    <p className="body-15 mt-5px text-center-sm">
                      {profile?.company}
                    </p>
                  )}
                  {profile?.roles.includes(UserType.ALUMNI) && (
                    <div className="alumni-pill">Alumni</div>
                  )}
                  <p className="body-16 font-weight-bolder mt-2 mb-0 text-center-sm">
                    {profile.jobTitle}
                  </p>
                  <p className="body-16 spacing">{profile?.division?.name}</p>
                  {profile?.region?.name !== "" && (
                    <p className="body-16 font-weight-bolder mt-2">
                      HOMETOWN:{" "}
                      <span className="font-weight-lighter color-gulf-blue">
                        {profile?.region?.name}
                      </span>
                    </p>
                  )}
                  <p className="body-16 mt-2 color-gulf-blue user-bio">
                    {profile?.bio}
                  </p>
                  {profile?.employmentHistory?.company && (
                    <p className="body-16 font-weight-bolder mt-3">
                      PREVIOUS EXPERIENCE:{" "}
                      <span className="font-weight-lighter color-gulf-blue">
                        {profile?.employmentHistory?.jobTitle} -{" "}
                        {profile?.employmentHistory?.company}
                      </span>
                    </p>
                  )}
                </div>
              )}

              {profile?.roles.includes(UserType.STUDENT) && (
                <div>
                  <p className="body-16 spacing">{profile.university?.name}</p>
                  {profile.preferredName && (
                    <p className="body-16 font-weight-bolder mt-1">
                      PREFERRED NAME:{" "}
                      <span className="color-dark-blue text-uppercase">
                        {profile.preferredName}
                      </span>
                    </p>
                  )}
                  {/* {profile.careerInterests.length > 0 &&
                  <p className="body-16 font-weight-bolder mt-2">
                    INTERESTED IN:{" "}
                    <span className="font-weight-lighter color-gulf-blue">
                  {profile.careerInterests?.map((interest: any, i: number) => <span>{interest.name}{(profile.careerInterests.length - 1) === i ? "" : "," } </span>)}
                    </span>
                  </p>} */}
                  {profile.region?.name && (
                    <p className="body-16 font-weight-bolder mt-1">
                      {isFieldsHidden("region") && (
                        <Tooltip
                          placement="top"
                          title="This information is hidden from professionals viewing your profile"
                        >
                          <ErrorIcon
                            className="position-relative"
                            fontSize="small"
                            color="primary"
                          />
                        </Tooltip>
                      )}{" "}
                      HOMETOWN:{" "}
                      <span className="font-weight-lighter color-gulf-blue">
                        {profile.region?.name}
                      </span>
                    </p>
                  )}
                  {profile?.ethnicity && profile?.ethnicity?.name !== "" && (
                    <p className="body-16 font-weight-bolder mt-2">
                      {isFieldsHidden("ethnicity") && (
                        <Tooltip
                          placement="top"
                          title="This information is hidden from professionals viewing your profile"
                        >
                          <ErrorIcon
                            className="position-relative"
                            fontSize="small"
                            color="primary"
                          />
                        </Tooltip>
                      )}{" "}
                      ETHNICITY:{" "}
                      <span className="font-weight-lighter color-gulf-blue">
                        {profile?.ethnicity?.name}
                      </span>
                    </p>
                  )}
                  <p className="body-16 mt-2 color-gulf-blue user-bio">
                    {profile?.bio}
                  </p>
                </div>
              )}
            </Grid>
            {pcProfile && (
              <Grid
                item
                xs={12}
                md={4}
                className="profile-info user-description"
              >
                <div>
                  <p className="body-15 font-weight-bold mb-0">
                    PROGRAMME COORDINATOR
                  </p>
                  <div className="d-flex">
                    <div>
                      <Avatar
                        className="thumnail-img"
                        src={pcProfile.profilePicture || undefined}
                      />
                    </div>
                    <div>
                      <h3 className="mb-0 mt-0">{`${pcProfile.firstName} ${pcProfile.lastName}`}</h3>
                      <p className="mt-0">{pcProfile.email}</p>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            {(profile?.roles.includes(UserType.PROFESSIONAL) ||
              profile?.roles.includes(UserType.ALUMNI)) && (
              <Grid className="work-edu" item xs={12} md={4}>
                {profile?.employmentHistory?.length > 0 && (
                  <div className="mb-3">
                    <span className="body-16 font-weight-bolder">
                      EMPLOYMENT HISTORY:
                    </span>
                    <div className="d-flex">
                      <div>
                        {profile?.employmentHistory.map((data: any) => (
                          <>
                            <p className="body-15 mb-0 mt-1 font-weight-bolder">
                              {data?.company}
                            </p>
                            <p className="body-15 mt-5px font-style-italic">
                              {data?.jobTitle}
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {profile.university?.name && (
                    <>
                      <span className="body-16 font-weight-bolder">
                        EDUCATION:
                      </span>
                      <div className="d-flex">
                        <div>
                          <p className="body-15 mb-0 mt-1 font-weight-bolder">
                            {profile.university?.name}
                          </p>
                          <p className="body-15 mt-5px font-style-italic">
                            {profile.degreeType?.name &&
                              `${profile.degreeType?.name},`}{" "}
                            <b>{profile.universityCourse?.name}</b>{" "}
                          </p>
                        </div>
                      </div>{" "}
                    </>
                  )}
                </div>
              </Grid>
            )}
            <Grid className="work-edu interaction-sm" item xs={12} md={3}>
              {user?.roles.includes(UserType.STUDENT) &&
                !matches &&
                urlId !== authUser?.id &&
                id && <InitiateInteraction profile={profile} receiverId={id} />}
            </Grid>
            {profile?.roles.includes(UserType.STUDENT) && (
              <>
                <Grid item xs={12}>
                  <p className="about-user-header">
                    {" "}
                    About {profile?.firstName}{" "}
                  </p>
                </Grid>
                <Grid className="about-container-spacing" item xs={12} md={4}>
                  <div className="light-background-container-color">
                    <div className="dark-background-header-color padding-space body-15 font-weight-bold">
                      Personal Information
                    </div>
                    <div className="padding-space inner-padding">
                      {profile?.gender && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width warning-d-flex">
                            GENDER:{" "}
                            {isFieldsHidden("gender") && (
                              <Tooltip
                                className="info-tooltip"
                                placement="top"
                                title="This information is hidden from professionals viewing your profile"
                              >
                                <ErrorIcon fontSize="small" color="primary" />
                              </Tooltip>
                            )}
                          </span>
                          <span className="value-left-spacing text-capitalize">
                            {profile?.gender.toLowerCase()}
                          </span>
                        </div>
                      )}
                      {profile?.careerInterests?.length > 0 && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width">
                            CAREER INTERESTS:
                          </span>
                          <ul className="careerInterests-list value-left-spacing">
                            {profile.careerInterests.map((data: any) => (
                              <li>{data.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid
                  className="about-container about-container-spacing"
                  item
                  xs={12}
                  md={4}
                >
                  <div className="light-background-container-color">
                    <div className="dark-background-header-color padding-space body-15 font-weight-bold">
                      University Information
                    </div>
                    <div className="padding-space">
                      {profile?.university?.name !== "" && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width">
                            UNIVERSITY:
                          </span>{" "}
                          <span className="value-left-spacing">
                            {profile?.university?.name}
                          </span>
                        </div>
                      )}
                      {profile?.universityCourse?.name !== "" && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width">
                            COURSE:
                          </span>{" "}
                          <span className="value-left-spacing">
                            {profile?.universityCourse?.name}
                          </span>
                        </div>
                      )}
                      {profile?.yearOfStudy && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width">
                            {`YEAR AT UNIVERSITY (AS OF SEP ${date.getFullYear()}):`}
                          </span>{" "}
                          <span className="value-left-spacing">
                            {profile?.yearOfStudy}
                          </span>
                        </div>
                      )}
                      {profile?.graduationYear !== "" && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width">
                            GRADUATION YEAR:
                          </span>
                          <span className="value-left-spacing">
                            {profile?.graduationYear}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid className="about-container-spacing" item xs={12} md={4}>
                  <div className="light-background-container-color">
                    <div className="dark-background-header-color padding-space body-15 font-weight-bold">
                      Academic & Contextual Information
                    </div>
                    <div className="padding-space">
                      {profile?.aLevelGrades && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width warning-d-flex">
                            A-LEVEL GRADES:{" "}
                            {isFieldsHidden("aLevelGrades") && (
                              <Tooltip
                                className="info-tooltip"
                                placement="top"
                                title="This information is hidden from professionals viewing your profile"
                              >
                                <ErrorIcon fontSize="small" color="primary" />
                              </Tooltip>
                            )}
                          </span>{" "}
                          <span className="value-left-spacing">
                            {profile?.aLevelGrades}
                          </span>
                        </div>
                      )}
                      {profile?.disadvantageIndicators &&
                        profile?.disadvantageIndicators?.length > 0 && (
                          <div className="d-flex">
                            <span className="body-13 font-weight-bold key-width warning-d-flex">
                              DISADVANTAGE INDICATORS:{" "}
                              {isFieldsHidden("disadvantageIndicators") && (
                                <Tooltip
                                  className="info-tooltip"
                                  placement="top"
                                  title="This information is hidden from professionals viewing your profile"
                                >
                                  <ErrorIcon fontSize="small" color="primary" />
                                </Tooltip>
                              )}
                            </span>{" "}
                            <ul className="careerInterests-list value-left-spacing">
                              {profile.disadvantageIndicators.map(
                                (data: any) => (
                                  <li>{data.name}</li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      {profile?.householdIncome && (
                        <div className="d-flex">
                          <span className="body-13 font-weight-bold key-width warning-d-flex">
                            HOUSEHOLD INCOME:{" "}
                            {isFieldsHidden("householdIncome") && (
                              <Tooltip
                                placement="top"
                                title="This information is hidden from professionals viewing your profile"
                              >
                                <ErrorIcon fontSize="small" color="primary" />
                              </Tooltip>
                            )}
                          </span>{" "}
                          <span className="value-left-spacing">
                            {profile?.householdIncome}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          {urlId !== authUser?.id && (
            <Grid className="interaction-section" container spacing={5}>
              <Grid item xs={12} md={9}>
                <InteractionList
                  username={profile.firstName}
                  activeInteractions={activeInteractions}
                  pastInteractions={pastInteractions}
                  awaitingFeedbackInteractions={awaitingFeedbackInteractions}
                  handleInteractionOpen={handleInteractionClick}
                />
              </Grid>
            </Grid>
          )}
        </Container>
      )}
      <EditProfileDrawer />
    </StyledWrapper>
  );
};

export default UserProfile;

const StyledWrapper = styled.div`
  margin-top: 50px;

  .bookmark-userprofile {
    position: absolute;
    right: 20px;
  }

  .user-info {
    position: relative;
    padding: 20px 55px 35px 55px;

    @media (max-width: 960px) {
      padding: 20px;
    }
  }

  .mt-1 {
    margin-top: 10px;
  }

  .mt-5px {
    margin-top: 5px;
  }

  .mt-2 {
    margin-top: 20px;
  }

  .mt-3 {
    margin-top: 30px;
  }

  .mb-3 {
    margin-bottom: 30px;
  }

  .warning-d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      @media (max-width: 600px) {
        position: relative;
        left: -10px;
      }
    }
  }

  .profile-avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    @media (max-width: 960px) {
      margin: auto;
    }
  }

  .profile-container {
    max-width: 1400px;
  }

  .spacing {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .user-description {
    padding-left: 90px;
    @media (max-width: 960px) {
      padding-left: unset;
    }
  }
  .user-description h1 {
    font-weight: 600;
  }

  .position-relative {
    position: relative;
    top: 3px;
  }

  .work-edu {
    padding-left: 90px;
    @media (max-width: 960px) {
      padding-left: unset;
      order: 1;
    }
  }

  .interaction-section {
    margin-top: 20px;
  }

  .dummy-img {
    margin-right: 20px;
  }

  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    @media (max-width: 960px) {
      padding-top: 0px;
    }
  }

  .interaction-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .interaction-sm {
    @media (min-width: 960px) {
      display: none;
    }
  }

  .text-center-sm {
    line-height: normal;
    @media (max-width: 960px) {
      text-align: center;
    }
  }

  .thumnail-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
  }

  .edit-profile {
    margin-right: 44px;
    margin-bottom: 20px;
    font-weight: 500;
    float: right;
    color: #304563;
    text-transform: capitalize;
    font-size: 16px;
  }

  .info-tooltip {
    margin-right: auto;
    margin-left: 5px;
    @media (max-width: 600px) {
      margin-left: 12px;
    }
  }

  .order-2 {
    @media (max-width: 600px) {
      order: 2;
    }
  }

  .activity-remaining-card {
    max-width: 400px;
    margin-bottom: 30px;
    float: right;
    margin-right: 40px;
    margin-left: 16px;

    @media (max-width: 600px) {
      float: unset;
      margin-right: 16px;
    }
  }

  .offer-card-wrapper {
    flex-grow: 1;
    align-self: flex-end;
    @media (max-width: 1200px) {
      align-self: auto;
      flex-grow: 1;
    }
  }

  .offer-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    height: 40px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 9px -1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border-radius: 4px;
    flex-grow: 1;
    @media (max-width: 1200px) {
      align-self: auto;
      flex-grow: 1;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      height: auto;
    }
    .offer-cta {
      text-transform: none;
      font-family: Nunito Sans, sans-serif !important;
      background-color : #3f51b5;
    }
    .offer-para {
      line-height: 22.5px;
      color: #304563;
      @media (max-width: 1200px) {
        margin-right: 15px;
      }
    }
  }

  .tracking-numbers {
    font-size: 18px;
    line-height: 25px;
    color: #464fd0;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .options {
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .card-edit-btn {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 24px;
    margin: 0 20px;
    height: auto;
    @media (max-width: 1250px) {
      margin: 0;
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .activity-tracker {
    position: relative;
    background: #f1f2ff;
    border: 1px solid rgba(70, 79, 208, 0.1);
    box-sizing: border-box;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000000;
    padding: 10px 20px 20px 20px;
  }

  .user-bio {
    white-space: break-spaces;
  }

  .dark-background-header-color {
    background-color: #ecedfc;
    min-height: 25px;
    display: flex;
    align-items: center;
  }

  .light-background-container-color {
    background-color: #f8f9ff;
    height: 100%;
  }

  .about-container {
    @media (min-width: 960px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .padding-space {
    padding-left: 20px;
    padding-right: 20px;
  }

  .careerInterests-list {
    margin-top: 0;
    padding-left: 18px;
    list-style-type: decimal;
  }

  .value-left-spacing {
    margin-left: 10%;
    font-size: 15px;
    color: #304563;

    @media (max-width: 600px) {
      margin-left: unset;
    }
  }

  .key-width {
    width: 150px;
    min-width: 150px;
  }

  .inner-padding {
    padding-bottom: 30px;
  }

  .d-flex {
    margin-top: 20px;
  }

  .about-container-spacing {
    margin-top: 15px;
    margin-bottom: 20px;
    min-height: 220px;
  }

  .about-user-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    margin-bottom: 0;
  }

  .linkedin-icon {
    width: 20px;
  }

  .linkedin-button {
    margin-left: 10px;
    position: relative;
    top: 2px;

    @media (max-width: 600px) {
      top: unset;
    }
  }

  .user-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .name-linkedin {
    display: flex;
    @media (max-width: 959px) {
      display: flex;
      margin: auto;
      justify-content: center;
    }
  }
`;
