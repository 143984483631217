import { takeLatest, put, call } from "redux-saga/effects";

import firebase from "app/firebase";
import {
  login,
  logout,
  setFirstLoad,
  setIsLoading,
  // setIsLoading,
  setOnLoginRedirectRoute,
  toggleLoginFormWorking,
  toggleOnLoginSyncInProgress,
} from "./slice";
import {
  fetchLinkedInToken,
  isCompleteProfile,
  linkedInLoginUnderway,
  loginWithCustomToken,
  performOnLoginSync,
  sendVerificationEmail,
} from "./utils";
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
import { UserType } from "common/types";
import { BasicDetails, setBasicDetails, setUniversityDetails, UniversityDetails } from "features/registration/student/studentRegistrationSlice";
import {
  BasicDetails as AlumniBasicDetails,
  setBasicDetails as setAlumniBasicDetails,
  setUniversityDetails as setAlumniUniversityDetails,
  UniversityDetails as AlumniUniversityDetails
} from "features/registration/alumni/alumniRegistrationSlice";

const ERROR_MESSAGES_FIREBASE: any = {
  "auth/invalid-email": "Please enter a valid email address",
  "auth/user-not-found":
    "We couldn't find a user with that email address. Please create an account.",
  "auth/wrong-password":
    "The email/password entered was incorrect. Please try again.",
};

function* doLogin(action: any) {
  const { email, password } = action.payload;
  try {
    yield put(toggleLoginFormWorking());
    yield firebase.auth().signInWithEmailAndPassword(email, password);
    yield put(toggleLoginFormWorking());
  } catch (error: any) {
    yield put(toggleLoginFormWorking());
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: ERROR_MESSAGES_FIREBASE[error.code],
    };
    yield put(showToast(toast));
    console.log(error);
  }
}

function* handleLinkedInLogin(): any {
  if (linkedInLoginUnderway()) {
    yield put(setIsLoading(true));
    try {
      yield put(toggleLoginFormWorking());
      const token = yield call(fetchLinkedInToken);
      yield call(loginWithCustomToken, token);
      yield put(toggleLoginFormWorking());
    } catch (error) {
      console.error("Error fetching LinkedIn token", error);
    }
  }
}

function* doLogout() {
  localStorage.removeItem("hasProfilePictureAndBio");
  localStorage.removeItem("isBannerOpen");
  yield firebase.auth().signOut();
}

function* triggerOnLoginSync(action: any): any {
  yield put(toggleOnLoginSyncInProgress());
  try {
    const response = yield call(
      performOnLoginSync,
      action.payload.id,
      action.payload.email
    );

    const isStudent = response?.userType === UserType.STUDENT;
    const isProfessional = response?.userType === UserType.PROFESSIONAL;
    const isAlumni = response?.userType === UserType.ALUMNI;
    if (response.userProfile) {
      const isUpreachUser = response?.userProfile?.roles?.includes(UserType.PROFESSIONAL) ||
        response?.userProfile?.roles?.includes(UserType.STUDENT) ||
        response?.userProfile?.roles?.includes(UserType.ALUMNI);
      if (isUpreachUser) {
        const accessApp = yield isCompleteProfile(response.userProfile);
        if (accessApp) {
          yield put(login({ ...response.userProfile }));
          yield put({ type: "START_INTERACTION_LISTENER" });
        }
        else {
          yield put(
            setOnLoginRedirectRoute(
              isStudent
                ? "/associates/register" :
                isProfessional ?
                  "/professionals/register" :
                  isAlumni ?
                    "/alumni/register" : "/"
            )
          );
        }
      } else {
        yield put(
          setOnLoginRedirectRoute(
            isStudent
              ? "/associates/login" :
              isProfessional ?
                "/professionals/login" :
                isAlumni ?
                  "/alumni/login" : "/"
          )
        );
        throw new Error();
      }
    }
    else if (response.emailRecognized) {
      yield put(
        setOnLoginRedirectRoute(
          isStudent
            ? "/associates/register" :
            isProfessional ?
              "/professionals/register" :
              isAlumni ?
                "/alumni/register" : "/"
        )
      );
    }
    else if (response.enkiData) {
      const basicDetails: Partial<AlumniBasicDetails | BasicDetails> = { ...response.enkiData }
      const universityDetails: Partial<AlumniUniversityDetails | UniversityDetails> = { ...response.enkiData, yearOfStudy: 1 }
      //  Created the array and changed the below condition because of SMN NA  
      var status_array: Array<string> = ['Associate', 'On going'];
      if (!status_array.includes(response.enkiData.associateStatus)) {
        yield put(setAlumniBasicDetails(basicDetails));
        yield put(setAlumniUniversityDetails(universityDetails))
        yield put(setOnLoginRedirectRoute("/alumni/register"));
      } else {
        yield put(setBasicDetails(basicDetails));
        yield put(setUniversityDetails(universityDetails))
        yield put(setOnLoginRedirectRoute("/associates/register"));
      }
    } else {
      throw new Error();
    }
    yield put(setFirstLoad(false));
    yield put(toggleOnLoginSyncInProgress());
  } catch (error) {
    console.error(error);
    yield put(toggleOnLoginSyncInProgress());
    yield put(logout());
    const toast: ToastMessage = {
      message: `We did not find a profile matching ${action.payload.email}`,
      type: ToastType.ERROR,
    };
    yield put(showToast(toast));
    yield put(setIsLoading(false));
    yield put(setFirstLoad(false));
  }
}

function* resetPassword(action: any) {
  try {
    const { email } = action.payload;
    var actionCodeSettings = {
      url: `${process.env.REACT_APP_SELF_URL}/login`,
    };
    const auth = firebase.auth();
    yield auth.sendPasswordResetEmail(email, actionCodeSettings);
    console.log("Reset Link successfully sent.");
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: "Reset link has been successfully sent.",
    };
    yield put(showToast(toast));
  } catch (error) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: "Failed to send the reset link. Please try again.",
    };
    yield put(showToast(toast));
  }
}

function* doSendVerificationEmail(): any {
  try {
    yield sendVerificationEmail();
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: "Verification email sent",
    };
    yield put(showToast(toast));
  } catch (error: any) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: error.message,
    };
    yield put(showToast(toast));
  }
}

export default function* authSaga() {
  yield takeLatest("LOGIN_ATTEMPT", doLogin);
  yield takeLatest("LOGOUT_ATTEMPT", doLogout);
  yield takeLatest("TRIGGER_ON_LOGIN_SYNC", triggerOnLoginSync);
  yield takeLatest("RESET_PASSWORD", resetPassword);
  yield takeLatest("HANDLE_LINKEDIN_LOGIN", handleLinkedInLogin);
  yield takeLatest("SEND_VERIFICATION_EMAIL", doSendVerificationEmail);
}
