import Stepper from "common/components/Stepper";
import { RootStateOrAny, useSelector } from "react-redux";
import PersonalDetails from "./BasicDetails";
import UniversityDetails from "./UniversityDetails";
import ProfessionalismCharter from "./ProfessionalismCharter";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import WelcomeVideo from "./WelcomeVideo";
import HomePage from "./HomePage";

type Props = {
  isMyAccount?: boolean;
}

const StudentRegistrationForm = ({ isMyAccount = false }: Props) => {
  const activeStep = useSelector(
    (state: RootStateOrAny) => state.studentRegistration.activeStep
  );

  const authState = useSelector((state: RootStateOrAny) => state.auth);
  const history = useHistory();

  const registrationSteps = [
    {
      label: "Home",
      content: <HomePage isMyAccount={isMyAccount} />,
    },
    {
      label: "Personal Details",
      content: <PersonalDetails isMyAccount={isMyAccount} />,
    },
    {
      label: "University Details",
      content: <UniversityDetails isMyAccount={isMyAccount} />,
    },
    {
      label: "Welcome Video",
      content: <WelcomeVideo isMyAccount={isMyAccount} />,
    },
    {
      label: "Shared Expectations",
      content: <ProfessionalismCharter isMyAccount={isMyAccount} />,
    },
  ];

  const profileEditSteps = [
    {
      label: "Personal Details",
      content: <PersonalDetails isMyAccount={isMyAccount} />,
    },
    {
      label: "University Details",
      content: <UniversityDetails isMyAccount={isMyAccount} />,
    }
  ];


  useEffect(() => {
    if (authState.user) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <Container>
      <div className="table-responsive">
      <Stepper steps={isMyAccount ? profileEditSteps : registrationSteps} activeStep={activeStep} />
      </div>
      {isMyAccount ? profileEditSteps[activeStep].content : registrationSteps[activeStep].content}
    </Container>
  );
};

export default StudentRegistrationForm;
