import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { User, UserType } from "common/types";
// import styled from "styled-components";
import Drawer from '@mui/material/Drawer';
import MobileHeader from './MobileHeader';
import { Container, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser, selectUser } from 'features/auth/selectors';
import avatar from "common/assets/images/avatar.svg";
import { setPromptModal } from 'features/auth/slice';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';

type Anchor = 'left';

type Props = {
    authState: any,
}

export default function SwipeableTemporaryDrawer({ authState }: Props) {
    const user: User | null = useSelector(selectUser);
    const [sidebarState, setSidebarState] = React.useState({
        left: false,
    });
    const dispatch = useDispatch();
    const authUser = useSelector(selectAuthUser);
    const [userGuide, setUserGuide] = React.useState('');
    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setSidebarState({ ...sidebarState, [anchor]: open });
    };

    const handleClickOnTestimonial = () => {
        dispatch(
          setPromptModal({
            isOpen: true,
            ModalType: "ADD_TESTIMONIAL",
          })
        );
      };

      const handleChange = (e:any) => {
        if(e.target.value === "userGuide"){
            window.location.href = "/userGuides";
        }else if(e.target.value === "employerOverview"){
            window.location.href = "/employerOverview";
        }
        setUserGuide(e.target.value);
      };

    const list = (anchor: Anchor) => (
        <StyledWrapper
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            className="interaction-sidebar"
        >
            <Container className="profile-container" >
                <Grid className="close-sidebar" direction="row">
                    <CloseIcon fontSize="large" />
                </Grid>
                <Grid className="" direction="row">
                    <Avatar className="sidebar-avatar" src={user?.profilePicture || avatar} />
                    <p className="body-18">{`${authState?.user?.firstName} ${authState?.user?.lastName}`}</p>
                </Grid>
            </Container>
            <Divider className="profile-divider" />
            <List>
                <NavLink className="ml-auto header-links font-weight-lighter" exact to="/" activeClassName="active-link">
                    <ListItem button >
                        <ListItemText className="sidebar-list" primary="Home" />
                    </ListItem>
                </NavLink>
                {authState.user && authState.user.roles.includes(UserType.PROFESSIONAL) && (
                    <>
                        <NavLink className="ml-auto header-links font-weight-lighter" exact to="professionalResources" activeClassName="active-link">
                            <ListItem button >
                                <ListItemText className="sidebar-list" primary="Resources" />
                            </ListItem>
                        </NavLink>  
                    </>
                )}
                {authState.user && authState.user.roles.includes(UserType.STUDENT) && (
                    <>
                        <NavLink className="ml-auto header-links font-weight-lighter" exact to="/professionals" activeClassName="active-link">
                            <ListItem button >
                                <ListItemText className="sidebar-list" primary="Search For Professionals" />
                            </ListItem>
                        </NavLink>
                        <FormControl ml-auto header-links font-weight-lighter style={{width:"200px",marginLeft:"18px"}} >
                        <InputLabel id="demo-simple-select-label" className="sidebar-list" style={{color:"#304563", fontFamily:"Nunito Sans", fontSize:"18px"}}>Resources</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='userGuide'
                            value={userGuide}
                            onChange={handleChange}
                            >
                            <MenuItem value = "userGuide" className="sidebar-list" style={{color:"#304563", fontFamily:"Nunito Sans", fontSize:"18px"}}>User Guides</MenuItem>
                            <MenuItem value= "employerOverview" className="sidebar-list" style={{color:"#304563", fontFamily:"Nunito Sans", fontSize:"18px"}}>Employer Overviews</MenuItem>
                            </Select>
                        </FormControl>
                        <ListItem button onClick={handleClickOnTestimonial}>
                            <ListItemText className="sidebar-list" primary="Add Testimonial" />
                        </ListItem>
                    </>
                )}

                {authState.user && (
                    <>
                    <NavLink className="ml-auto header-links font-weight-lighter" exact to={`/users/${authUser?.id}`} activeClassName="active-link">
                        <ListItem button >
                            <ListItemText className="sidebar-list" primary="My Profile" />
                        </ListItem>
                    </NavLink>
                    <NavLink className="ml-auto header-links font-weight-lighter" exact to="/contactUs" >
                        <ListItem >
                            <ListItemText className="sidebar-list" primary="Contact Us" />
                        </ListItem>
                    </NavLink>
                        <ListItem button >
                            <ListItemText onClick={() =>
                                dispatch({ type: "LOGOUT_ATTEMPT", payload: null })
                            } className="sidebar-list" primary="Logout" />
                        </ListItem>
                    </>
                )}
            </List>
        </StyledWrapper>
    );

    return (
        <div>
            {(['left'] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                    <StyledWrapper>
                        <Drawer anchor={anchor}
                            open={sidebarState[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </StyledWrapper>
                    {authState.user &&
                        <MobileHeader userEmail={authState?.user?.email} toggleDrawer={toggleDrawer} setSidebarState={setSidebarState} sidebarState={sidebarState} anchor={anchor}
                        />}
                </React.Fragment>
            ))}
        </div>
    );
}

const StyledWrapper = styled.div`

    padding-left: 8%;
    padding-right: 8%;

    .interaction-sidebar {
        max-width: 1200px;
        margin-left: auto;
    }

    .close-sidebar {
            position: absolute;
            right: 20px;
            top: 15px;
    }

    .sidebar-avatar {
        width: 70px;
        height: 70px;
    }

    .profile-divider {
        width: 90%;
        margin: auto;
        border: 1px solid #9EA5EE;
        background-color: #9EA5EE;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .profile-container {
        padding-top: 100px;
    }

    .sidebar-list {
        span {
            font-family: Nunito Sans;
            font-size: 18px;
            line-height: 110%;
            color: #304563;
            margin-top: 20px;
        }
    }


    a {
        text-decoration: none;
    }

    .active-link {
        span {
            font-weight: bold;
        }
    }
 `;