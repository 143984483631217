import { useDispatch, useSelector } from "react-redux";
import { AuthUser, setIsUpdateDrawerOpen, setPromptModal} from "../features/auth/slice";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "../components/home/Home";
import PrivateRoute, { PrivateRouteMode } from "./PrivateRoute";
import GlobalStyle from "./globalStyle";
import Toast from "features/toast/Toast";
import Network from "features/network/Network";
import AllProfessionals from "features/network/AllProfessionals";
import DevTools from "./DevTools";
import UserProfile from "features/network/UserProfile";
import Layout from "./Layout";
import Interaction from "features/interactions/Interaction";
import Modal from "common/components/Modal";
import TypeformUserRegistration from "../features/registration/AccountCreationForm";
import AssociatesLogin from "components/login/associates/Associates";
import ProfessionalLogin from "components/login/professional/Professionals";
import Welcome from "components/login/WelcomePage";
import LoginForm from "features/auth/LoginForm";
import { selectActiveInteractionId, selectInteractions } from "features/interactions/selectors";
import { setActiveInteractionId } from "features/interactions/slice";
import StudentRegistrationForm from "features/registration/student";
import ProfessionalRegistrationForm from "features/registration/professional";
import AuthGateway from "./AuthGateway";
import { selectAuthUser, selectUser } from "features/auth/selectors";
import UnverifiedEmailBlocker from "./UnverifiedEmailBlocker";
import { useEffect, useState } from "react";
import { gaPageView } from "analytics/gaUtils";
import * as Sentry from "@sentry/react";
import EmailHandler from "./EmailHandler";
import { UserType } from "common/types";
import UserNotifyModal from "common/components/UserNotifyModal";
import BookmarkPage from "components/bookmark/BookmarkPage";
import PromptModal from "common/components/PromptModal";
import AlumniRegistrationForm from "features/registration/alumni";
import ContactUs from "components/contactUs/ContactUs";
import UserGuides from "components/resources/UserGuides";
import EmployerOverview from "components/resources/EmployerOverview";
import ProfessionalResources from "components/professionalResources/ProfessionalResources";

function App() {
  const dispatch = useDispatch();
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const interactions = useSelector(selectInteractions);
  const authUser: AuthUser | null = useSelector(selectAuthUser);
  const user: any = useSelector(selectUser);
  const [showUserNotifyModal, setShowUserNotifyModal] = useState<boolean>(false);

  const pageUrl = window.location.pathname;

  useEffect(() => {
    gaPageView();
  }, [pageUrl]);

  useEffect(() => {
    if (localStorage.getItem("hasProfilePictureAndBio")) {
      if (localStorage.getItem("hasProfilePictureAndBio") === "true") {
        if (user?.roles.includes(UserType.STUDENT)) {
          setShowUserNotifyModal(true);
        }
        if (user?.profilePicture === "" || user?.bio === "") {
          setShowUserNotifyModal(true);
        }
      } else {
        setShowUserNotifyModal(false);
      }
    } else {
      localStorage.setItem("hasProfilePictureAndBio", "true")
    }
  }, [user])

  const onButtonClick = () => {
    localStorage.setItem('hasProfilePictureAndBio', "false");
    setShowUserNotifyModal(false);
    dispatch(setIsUpdateDrawerOpen(true));
  }

  const handleClose = () => {
    localStorage.setItem('hasProfilePictureAndBio', "false");
    setShowUserNotifyModal(false);
    if(interactions.length > 10 && user?.roles?.includes(UserType.STUDENT)){
      dispatch(setPromptModal({ isOpen: true, ModalType: 'NOTIFY_TESTIMONIAL' }));
    };
  }

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred' as any}>
      <div className="App">
        <GlobalStyle />
        {authUser && !authUser.emailVerified ? (
          <BrowserRouter>
            <Layout>
              <Switch>
                <Route path="/email-handler" component={EmailHandler} />
                <Route component={UnverifiedEmailBlocker} />
              </Switch>
            </Layout>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <AuthGateway />
            <Layout>
              <Switch>
                <Route path="/welcome" component={Welcome} />
                <Route path="/associates/login" component={AssociatesLogin} />
                <Route path="/alumni/login" component={AssociatesLogin} />
                <Route
                  path="/professionals/login"
                  component={ProfessionalLogin}
                />
                <Route
                  path="/hsJ9yEzeXj"
                  component={TypeformUserRegistration}
                />
                <PrivateRoute
                  path="/associates/register"
                  component={StudentRegistrationForm}
                  mode={PrivateRouteMode.REQUIRE_AUTH}
                />
                <PrivateRoute
                  path="/professionals/register"
                  component={ProfessionalRegistrationForm}
                  mode={PrivateRouteMode.REQUIRE_AUTH}
                />
                <PrivateRoute
                  path="/alumni/register"
                  component={AlumniRegistrationForm}
                  mode={PrivateRouteMode.REQUIRE_AUTH}
                />
                <Route path="/login" component={LoginForm} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/network" component={Network} />
                <PrivateRoute
                  exact
                  path="/professionals"
                  component={AllProfessionals}
                />
                <PrivateRoute
                  exact
                  path="/bookmarks"
                  component={BookmarkPage}
                />
                <PrivateRoute
                  exact
                  path="/professionalResources"
                  component={ProfessionalResources}
                />
                <PrivateRoute
                  exact
                  path="/contactUs"
                  component={ContactUs}
                />
                <PrivateRoute
                  exact
                  path="/userGuides"
                  component={UserGuides}
                />
                <PrivateRoute
                  exact
                  path="/employerOverview"
                  component={EmployerOverview}
                />
                <PrivateRoute exact path="/users/:id" component={UserProfile} />
                {process.env.NODE_ENV !== "production" && (
                  <PrivateRoute exact path="/devtools" component={DevTools} />
                )}
                <Route path="/email-handler" component={EmailHandler} />
              </Switch>
            </Layout>
            {showUserNotifyModal &&
              <UserNotifyModal onButtonClick={() => onButtonClick()} handleClose={() => handleClose()} />}
            <PromptModal />
          </BrowserRouter>
        )}
        <Modal
          open={activeInteractionId ? true : false}
          fullScreen
          handleClose={() => {
            dispatch(setActiveInteractionId(null));
          }}
          title=""
        >
          <Interaction />
        </Modal>
        <Toast />
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
