import { useEffect, useState } from "react";
import { TextField as NumberField } from "@mui/material";
import StepperControls from "common/components/StepperControls";
import TextField, { InputType } from "common/components/TextField";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import {
  goToPrevStep,
  setUniversityDetails,
  goToNextStep,
  setMetadata,
} from "./studentRegistrationSlice";
import { useForm } from "react-hook-form";
import DocumentPicker from "../../../common/components/DocumentPicker";
import SelectComponent from "../../../common/components/SelectComponent";
import { selectMetadata, selectUniversityDetails } from "./selectors";
import { setIsNextStep } from "../professional/professionalRegistrationSlice";
import { selectIsNextStep } from "../professional/selectors";
import { selectUser } from "features/auth/selectors";
import ToggleButton from "common/components/ToggleButton";
import styled from "@emotion/styled";

type Props = {
  isMyAccount?: boolean;
}

const UniversityDetails = ({ isMyAccount }: Props) => {
  const universityDetails = useSelector(selectUniversityDetails);
  const metadata = useSelector(selectMetadata);
  const isNextStep = useSelector(selectIsNextStep);

  const dispatch = useDispatch();
  const user: any = useSelector(selectUser);
  const [selectedUniversity, setSelectedUniversity] = useState<any>();
  const [selectedCourse, setSelectedCourse] = useState<any | null>();
  const [selectedYearOfStudy, setSelectedYearOfStudy] = useState<any | null>(1);
  const [selectedDegreeType, setSelectedDegreeType] = useState<any | null>();
  const [selectedCareerStream, setSelectedCareerStream] = useState<any | null>();
  const [selectedCareerInterests, setSelectedCareerInterests] = useState<any>(
    []
  );
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [aLevelGradesVisibility, setALevelGradesVisibility] = useState<boolean>(false);
  const [realGradesVisibility, setRealGradesVisibility] = useState<boolean>(false);
  const [aLevelGrades, setALevelGrades] = useState<any>("");
  const [realGrades, setRealGrades] = useState<any>("");
  const yearOfStudyValues = [1, 2, 3, 4, 5, 6];

  const { register, handleSubmit, errors } = useForm({
    defaultValues: isMyAccount ? {
      aLevelGrades: user?.aLevelGrades,
      realGrades: user?.realGrades,
      graduationYear: user?.graduationYear,
      // gefResult: user?.gefResult,
    } : universityDetails,
  });

  useEffect(() => {
    if (isMyAccount) {
      setSelectedUniversity(user?.university);
      setSelectedCourse(user?.universityCourse);
      setSelectedDegreeType(user?.degreeType);
      setSelectedCareerInterests(user?.careerInterests);
      setALevelGrades(user?.aLevelGrades);
      setRealGrades(user?.realGrades);
      setSelectedCareerStream(user?.careerStream);
      setSelectedYearOfStudy(user?.yearOfStudy);
      setALevelGradesVisibility(user?.metadata?.profileDataVisibility?.aLevelGrades)
      setRealGradesVisibility(user?.metadata?.profileDataVisibility?.realGrades)
    } else {
      setALevelGrades(universityDetails?.aLevelGrades);
      setRealGrades(universityDetails?.realGrades);
      setSelectedUniversity(universityDetails.university);
      setSelectedCourse(universityDetails.universityCourse);
      setSelectedDegreeType(universityDetails.degreeType);
      setSelectedCareerInterests(universityDetails.careerInterests);
      setSelectedCareerStream(universityDetails.careerStream);
      setSelectedYearOfStudy(universityDetails.yearOfStudy);
      setALevelGradesVisibility(metadata?.profileDataVisibility?.aLevelGrades)
      setRealGradesVisibility(metadata?.profileDataVisibility?.realGrades)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    data.university = selectedUniversity;
    data.universityCourse = selectedCourse;
    data.degreeType = selectedDegreeType;
    data.careerInterests = selectedCareerInterests;
    data.careerStream = selectedCareerStream;
    data.yearOfStudy = selectedYearOfStudy;
    data.aLevelGrades = aLevelGrades;
    data.realGrades = realGrades;
    dispatch(setUniversityDetails(data));
    dispatch(setMetadata({
      ...metadata, profileDataVisibility: {
        ...metadata.profileDataVisibility,
        aLevelGrades: aLevelGradesVisibility,
        realGrades: realGradesVisibility,
      }
    }));
    if (isNextStep && !isMyAccount) {
      dispatch(goToNextStep());
    }
    if (isMyAccount && isNextStep) {
      setIsRegister(true);
      dispatch({
        type: "STUDENT_PROFILE_UPDATE_ATTEMPT",
      });
    }
    if (!isNextStep) {
      dispatch(goToPrevStep())
    }
  };

  const handleUniversityChange = (university: any) =>
    setSelectedUniversity(university);

  const handleCourseChange = (course: any) => setSelectedCourse(course);

  const handleDegreeType = (degree: any) => setSelectedDegreeType(degree);

  return (
    <StyledForm className="card bg-white border border-light rounded-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body bg-transparent mx-4 my-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-12">
            <h3 className="text-primary mb-4">University Details</h3>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <DocumentPicker
              handleChange={handleUniversityChange}
              collection="universities"
              name="university"
              inputRef={register({ required: true })}
              error={errors.university}
              defaultValue={selectedUniversity}
              label="University"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <DocumentPicker
              handleChange={handleCourseChange}
              collection="courses"
              error={errors.universityCourse}
              name="universityCourse"
              inputRef={register({ required: true })}
              defaultValue={selectedCourse}
              label="University Course"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <DocumentPicker
              handleChange={handleDegreeType}
              collection="degree-types"
              error={errors.degreeType}
              name="degreeType"
              inputRef={register({ required: true })}
              defaultValue={selectedDegreeType}
              label="Degree Type"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <DocumentPicker
              multiple
              handleChange={(selectedCareerInterests: any) =>
                setSelectedCareerInterests(selectedCareerInterests)
              }
              collection="career-interests"
              error={errors.careerInterests}
              name="careerInterests"
              inputRef={register({ required: selectedCareerInterests.length === 0 })}
              defaultValues={selectedCareerInterests}
              label="Career Interests"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <DocumentPicker
              handleChange={(selectedCareerStream: any) =>
                setSelectedCareerStream(selectedCareerStream)
              }
              collection="career-streams"
              error={!!errors.careerStream}
              name="careerStream"
              inputRef={register({ required: true })}
              defaultValue={selectedCareerStream}
              label="Springboard"
            />
          </div>
        </div>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-sm-6 ">
            <TextField
              name="aLevelGrades"
              type={InputType.TEXT}
              label="A Level Grades"
              value={aLevelGrades}
              disabled
            />
          </div>
          <div className="col-sm-6">
            <p className="body-14 show-on-profile">Show on Profile</p>
            <ToggleButton className="spacing-left spacing-left-ethnicity" handleToggle={setALevelGradesVisibility} checked={aLevelGradesVisibility} />
          </div>
        </div>
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-sm-6">
            <TextField
              name="realGrades"
              type={InputType.TEXT}
              label="REAL Grades"
              value={realGrades}
              disabled
            />
          </div>
          <div className="col-sm-6">
            <ToggleButton className="spacing-left spacing-left-ethnicity" handleToggle={setRealGradesVisibility} checked={realGradesVisibility} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <SelectComponent name="yearOfStudy"
              handleChange={(selectedYearOfStudy: any) =>
                setSelectedYearOfStudy(selectedYearOfStudy)
              }
              selectedValue={selectedYearOfStudy}
              values={yearOfStudyValues} />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-6 req-ast">
            <NumberField
              inputRef={register({
                minLength: 4,
                maxLength: 4,
                min: new Date().getFullYear(),
                validate: {
                  required: (value) => {
                    if (!value && selectedUniversity.id !== "")
                      return "This field is required.";
                    return true;
                  }
                }
              })}
              name="graduationYear"
              type={InputType.NUMBER}
              variant="outlined"
              fullWidth
              label="Graduation Year"
            />
            {errors && (errors.graduationYear) &&
              <>
                <p className="rhf-errors">{(errors.graduationYear as any).message}</p>
                {errors.graduationYear.type === "maxLength" && <span className="rhf-errors">Please enter a valid, 4 digit graduation year.</span>}
                {errors.graduationYear.type === "minLength" && <span className="rhf-errors">Please enter a valid, 4 digit graduation year.</span>}
                {errors.graduationYear.type === "min" && <span className="rhf-errors">We are currently only accepting students graduating in {new Date().getFullYear()} or later.</span>}
              </>
            }
          </div>
        </div>
        <div className="card-footer bg-transparent mt-4 d-flex justify-content-center">
          <StepperControls
            nextButtonLabel={isMyAccount ? "Update" : "Next"}
            disabled={false}
            nextButtonCallback={() => { dispatch(setIsNextStep(true)) }}
            backButtonLabel="Back"
            backButtonCallback={() => { dispatch(setIsNextStep(false)) }}
            isRegister={isMyAccount ? isRegister : false}
            />   
        </div>
      </div>
    </StyledForm>
  );
};

const StyledForm = styled.form``;

export default UniversityDetails;