import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import StepperControls from "common/components/StepperControls";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import { goToPrevStep, setIsNextStep } from "./alumniRegistrationSlice";
import { selectIsNextStep } from "./selectors";
import styled from "@emotion/styled";

const ProfessionalismCharter = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const isNextStep = useSelector(selectIsNextStep);

  const onSubmit = () => {
    if (isNextStep) {
      dispatch({
        type: "ALUMNI_REGISTRATION_ATTEMPT",
      });
      setIsRegister(true);
    }
    if (!isNextStep) {
      dispatch(goToPrevStep())
    }
  };

  return (
    <StyledForm className="registration-box-shadow" onSubmit={handleSubmit(onSubmit)}>
      <Grid container item spacing={4} xs={12}>
        <Grid item xs={12}>
          <h3> Professionalism charter: </h3>
          <ul>
            <li>
              I understand that I am expected to interact with mentors in a professional manner at all times on the Social Mobility Network
            </li>
            <li>
              I understand that messages I send on the platform should be written as if they were an email, with an appropriate greeting and sign-off
            </li>
            <li>
              I confirm that I will respond within a reasonable time frame (no longer than 48 hours) to any mentor I interact with on the Social Mobility Network
            </li>
            <li>
              I understand that it is my responsibility to honour commitments made with a professional on the platform
            </li>
            <li>
              If for whatever reason I am unable to attend an interaction after scheduling one with a professional, I confirm that I will inform the professional as soon as possible. I understand that failure to show up to an interaction is not acceptable and that this could result in my account being restricted.
            </li>
            <li>
              I understand that interactions are expected to be held via video-conferencing software. However, if an interaction is scheduled face-to-face, I agree to always meet the professional in a public place
            </li>
            <li>
              I understand that I should dress professionally for an interaction
            </li>
            <li>
              I agree to effectively use the mentor’s time and counsel by doing necessary research in advance
            </li>
            <li>
              I agree to be open and receptive to feedback and coaching
            </li>
            <li>
              I understand that any failure to meet the above can lead to me losing access to the Social Mobility Network
            </li>

          </ul>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox name="professionalismCharter" onClick={() => setAcceptTerms(!acceptTerms)} />}
            label="I confirm that I have read and agree to the above professionalism charter."
          />
        </Grid>
      </Grid>
      <StepperControls
        nextButtonCallback={() => dispatch(setIsNextStep(true))}
        disabled={!acceptTerms}
        nextButtonLabel="Register"
        backButtonCallback={() => dispatch(setIsNextStep(false))}
        backButtonLabel="Back"
        isRegister={isRegister}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 3rem;
  .MuiGrid-item {
    margin-bottom: 1rem;
    padding: 0 3rem;
  }

  ul {
    li {
      margin-bottom: 10px;
      color: #4e4e4e;
    }
  }
`;

export default ProfessionalismCharter;
