import { useEffect, useState } from "react";
import { InteractionType } from "common/types";
import styled from "styled-components";
import { Box } from "@mui/material";
import videoCall from "common/assets/images/vc.svg";
import comment from "common/assets/images/comment.svg";
import { useDispatch, useSelector } from "react-redux";
import { setInteractionRequestModelOpen, setIsRequestButtonDisabled } from "./slice";
import { isRequestButtonDisabled, selectedInteractionRequestModelOpen } from "./selectors";
import RequestInteractionPopup from "./RequestInteractionPopup";
import { selectUser } from "features/auth/selectors";
import recordEvent from "common/utils/mixpanel";

const interactions: any = {
  [InteractionType.EXPERT_ADVICE]: {
    buttonLabel: "Expert Advice",
    backgroundColor: "background-sea-blue",
    logo: comment,
  },
  [InteractionType.CAREER_CHAT]: {
    buttonLabel: "Career Chat",
    backgroundColor: "background-persian-green",
    logo: videoCall,
  },
  [InteractionType.MOCK_INTERVIEW]: {
    buttonLabel: "Mock Interview",
    backgroundColor: "background-ceru",
    logo: videoCall,
  },
  [InteractionType.MENTORING]: {
    buttonLabel: "Request Mentoring",
    backgroundColor: "background-gulf-blue",
    logo: videoCall,
  },
};

type Props = {
  receiverId: string;
  profile: any;
  isSuggestedProfessionalModal?: boolean;
};

const InitiateInteraction = ({ receiverId, profile, isSuggestedProfessionalModal }: Props) => {
  const dispatch = useDispatch();
  const [interactionType, setInteractionType] = useState<InteractionType | null>();
  const [message, setMessage] = useState<string>("");
  const [expectedInterviewDate, setExpectedInterviewDate] = useState<Date | null>(null);

  const interactionRequestModelOpen = useSelector(selectedInteractionRequestModelOpen);
  const isButtonDisabled = useSelector(isRequestButtonDisabled);
  const user: any = useSelector(selectUser);

  useEffect(() => {
    if (interactionRequestModelOpen) {
      setMessage("");
    }
  }, [interactionRequestModelOpen]);

  const initiateInteracitionRequest = (type: InteractionType) => {
    setInteractionType(type);
    dispatch(setInteractionRequestModelOpen(true));
  };

  const sendRequest = () => {
    try {
      recordEvent("Send Request Click", {
        "Interaction Type": interactionType,
        "Professional Sector": profile.sectors,
        "Professional Company": profile.company?.name,
        "Professional Job Title": profile.jobTitle,
        "Professional University": profile.university?.name,
        "Professional University Course": profile.universityCourse?.name,
        "Student Gender": user.gender,
        "Student Region": user.region?.name,
        "Student Ethnicity": user.ethnicity?.name,
        "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
        "User Roles": user.roles,
      });
      if (message.replace(/\s/g, "") !== "") {
        dispatch(setIsRequestButtonDisabled(true));
        const messagePayload = {
          receiverId,
          interactionType,
          initialMessagePayload: message,
        };
        if (interactionType === InteractionType.MOCK_INTERVIEW) {
          dispatch({
            type: "SEND_INTERACTION_REQUEST",
            payload: { ...messagePayload, expectedInterviewDate },
          });
        } else
          dispatch({
            type: "SEND_INTERACTION_REQUEST",
            payload: messagePayload,
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInteractionsButtonClicked = (interaction: any) => {
    if(isSuggestedProfessionalModal) return
    recordEvent("Request Interaction Click", {
      "Interaction Type": interaction,
      "Professional Sector": profile.sectors,
      "Professional Company": profile.company?.name,
      "Professional Job Title": profile.jobTitle,
      "Professional University": profile.university?.name,
      "Professional University Course": profile.universityCourse?.name,
      "Student Gender": user?.gender,
      "Student Region": user?.region,
      "Student Ethnicity": user?.ethnicity?.name,
      "Student Age": new Date().getFullYear() - new Date(user?.dateOfBirth).getFullYear(),
    });
    initiateInteracitionRequest(interaction);
  };
  return (
    <>
      {!profile.metadata.isDisabled && (
        <StyledWrapper>
          <h3 className=" text-uppercase font-weight-bold body-13 click-here">{isSuggestedProfessionalModal ? 'AVAILABLE FOR:' : 'CLICK HERE FOR:'}</h3>
          {profile?.participatingInteractionTypes?.map((interaction: any) => (
            <>
              {interaction !== InteractionType.MENTORING && (
                <Box
                  my={2}
                  justifyContent="space-between"
                  className={`interaction-button border-radius-5 ${interactions[interaction].backgroundColor}`}
                  onClick={() => handleInteractionsButtonClicked(interaction)}
                >
                  <Box>
                    <p className="interaction-title text-uppercase">
                      {" "}
                      {interactions[interaction].buttonLabel}{" "}
                    </p>
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <img src={interactions[interaction].logo} alt="" />
                  </Box>
                </Box>
              )}
            </>
          ))}
        </StyledWrapper>
      )}
      <RequestInteractionPopup
        interactionRequestModelOpen={interactionRequestModelOpen}
        setInteractionRequestModelOpen={setInteractionRequestModelOpen}
        interactionType={interactionType}
        message={message}
        setMessage={setMessage}
        sendRequest={sendRequest}
        isButtonDisabled={isButtonDisabled}
        expectedInterviewDate={expectedInterviewDate}
        setExpectedInterviewDate={setExpectedInterviewDate}
      />
    </>
  );
};

export default InitiateInteraction;

const StyledWrapper = styled.div`
  .interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    height: 38px;
    min-width: 110px;
    width: 100%;
    @media (max-width: 960px) {
      min-width: unset;
      width: unset;
    }
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }
`;
