import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  formLabel: string;
  radioButtons: Array<RadioButtonData>;
  control?: any;
  handleChange?: any;
};

export type RadioButtonData = {
  value: any;
  label: string;
  disabled?: boolean;
};

const RadioButtonGroup = ({
  name,
  formLabel,
  radioButtons,
  control,
  handleChange,
}: Props) => {
  return (
    <div>
      <FormLabel component="legend">{formLabel}</FormLabel>
      <Controller
        as={
          <RadioGroup row>
            {radioButtons.map((radioButton: RadioButtonData) => (
              <>
                <FormControlLabel
                  onChange={handleChange}
                  value={radioButton.value}
                  control={<Radio />}
                  label={radioButton.label}
                  disabled={radioButton.disabled}
                />
              </>
            ))}
          </RadioGroup>
        }
        name={name}
        control={control}
        rules={{ required: "This field is required." }}
      />
    </div>
  );
};

export default RadioButtonGroup;
