import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Button, Grid } from "@mui/material";
import ProfessionalUserCard from "features/network/ProfessionalUserCard";
import { useSelector } from "react-redux";
import { UserType } from "common/types";
import UserContext from "./UserContext";
import { Link } from "react-router-dom";
import { selectUser } from "features/auth/selectors";
import { sendSeachForProfessionalEvent } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeCardWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
  })
);

const HomeUserCards = () => {
  const user = useSelector(selectUser);

  const classes = useStyles();

  const { professionals } = useContext(UserContext);
  const [filteredProfessionals, setFilteredProfessionals] = useState<any[]>([]);

  useEffect(() => {
    user?.roles?.includes(UserType.ALUMNI)
      ? handleFilterProfessionals(professionals, true)
      : handleFilterProfessionals(professionals);
  }, [professionals, user]);

  const handleFilterProfessionals = (
    unfilteredProfessionals: any[],
    isAlumni = false
  ) => {
    const filteredProfessionals = unfilteredProfessionals.filter(
      (professional) => {
        if (isAlumni) {
          return (
            professional?.metadata?.isOnline === true &&
            professional?.metadata?.isAvailableForAlumni === true
          );
        } else {
          return professional?.metadata?.isOnline === true;
        }
      }
    );
    setFilteredProfessionals(filteredProfessionals);
  };

  return (
    <>
      <Grid container spacing={3} style={{ padding: "30px 0px" }}>
        {(user?.roles.includes(UserType.STUDENT) ||
          user?.roles.includes(UserType.ALUMNI)) &&
          professionals.length > 0 && (
            <>
              <Grid item xs={12} className="professional-section">
                <Link to="/professionals">
                  <Button
                    className="professional-search font-family-nunito"
                    variant="contained"
                    color="primary"
                    onClick={() => sendSeachForProfessionalEvent(user)}
                  >
                    Search for professionals
                  </Button>
                </Link>
              </Grid>

              <Grid item xs={12} className="card-intro">
                You may want to connect with :
              </Grid>

              <div className={classes.homeCardWrapper}>
                {React.Children.toArray(
                  filteredProfessionals?.map((professional: any[]) => (
                    <ProfessionalUserCard user={professional} student={user} />
                  ))
                )}
              </div>
            </>
          )}
      </Grid>
    </>
  );
};

export default HomeUserCards;
