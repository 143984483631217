import { Message, MessageType } from "common/types";
import { selectUser } from "features/auth/selectors";
import { User} from "common/types";
import { useSelector } from "react-redux";

type Props = {
  message: Message;
  interactionType: string;
};

const AcceptDeclineMessage = ({ message, interactionType }: Props) => {
  const user: User | null = useSelector(selectUser);

  const capitalizeWords = (str: string): string => {
    return str.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  }
  const input = interactionType.replaceAll("_", " ").toLowerCase();
  const outputInteractionType = capitalizeWords(input);
  
  if(message.sender === "SYSTEM") return null;
  return (
    <div className="color-gulf-blue body-17 font-weight-bolder">
      <span>{user?.id === message.sender.id ? "You have" : message.sender.firstName+" has"}</span>
      <span>
        &nbsp;{message.type === MessageType.ACCEPT ? "accepted" : "declined"}
      </span>
      <span>&nbsp;the request for {outputInteractionType}</span>
    </div>
  );
};

export default AcceptDeclineMessage;
