import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Logo from "common/assets/images/blue_logo.svg";

const UnverifiedEmailBlocker = () => {
  const dispatch = useDispatch();

  const handleVerificationEmailSend = () => {
    dispatch({ type: "SEND_VERIFICATION_EMAIL" });
  };

  return (
    <StyledWrapper>
      <div>
        <img src={Logo} alt="" />
        <h3>Please verify your email address before proceeding</h3>
        <Button
          onClick={handleVerificationEmailSend}
          variant="contained"
          color="primary"
        >
          Send Verification Email
        </Button>
        <p className="instructions">
          Follow the verification link sent to you via email and hit the
          'Refresh' button below when completed
        </p>
        <Button
          onClick={() => window.location.reload()}
          variant="outlined"
          color="secondary"
        >
          Refresh
        </Button>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;

  div {
    text-align: center;
    max-width: 500px;

    img {
      margin: 30px 0;
    }

    .instructions {
      margin: 50px 0;
    }
  }
`;

export default UnverifiedEmailBlocker;
