import { Message } from "common/types";
import { User } from "common/types";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/selectors";

type Props = {
  message: Message;
};

const RequestRescheduleMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);
  if(message.sender === "SYSTEM") return null;

  return (
    <>
    <div className="color-gulf-blue body-17 font-weight-bolder">
      <span >{user?.id === message.sender.id 
      ? "You have requested to reschedule the interaction" 
      : message.sender.firstName+" has requested to reschedule"}</span>
    </div>  
    <div
      className={`interaction-container bubble background-white ${message.sender.id === user?.id ? "sent-bubble" : "received-bubble" }`}
    >
    <div className="interaction-texts">{message.payload}</div>
    </div>
    </>
  );
};

export default RequestRescheduleMessage;
