import { Box, Grid } from "@mui/material";
import flag from '../../common/assets/images/getting-started.svg';
import { useContext } from "react";
import UserContext from "./UserContext";

const GettingStarted = () => {

    const { professionals } = useContext(UserContext);

    return (
        <>
            {professionals.length > 0 ?
                <Grid container className="students-getting-started-section" spacing={5}>
                    <Grid item sm={7} >
                        <Box display="flex">
                            <img className="flag-logo" src={flag} alt="" /><h4 className="mx-6 body-18">Getting Started:</h4>
                        </Box>
                        <ol className="getting-started-text">
                            <li>Search for professionals who you'd like to interact with.</li>
                            <li>Think about what you would like to achieve from interacting with a professional (e.g. do you want to find out more about their role? Could they tell you more about the organisation they work for, beyond what you have been able to find out online?).</li>
                            <li>View the profile of a professional you want to interact with and send them an interaction request.</li>
                        </ol>
                    </Grid>
                </Grid> :
                <Grid container spacing={5}>
                    <Grid item sm={12} md={4}>
                        <Box display="flex">
                            <img className="flag-logo" src={flag} alt="" /><h4 className="mx-6 body-18">Getting Started:</h4>
                        </Box>
                        <ol className="getting-started-text">
                            <li>Students will send you a request to interact. You will receive an email notification informing you of this request being received.</li>
                            <li>Once you receive an email, please log in to the Social Mobility Network to review and respond to the student's request.</li>
                        </ol>
                    </Grid>
                    {/* <Divider className="v-divider" /> */}
                    <Grid item xs={6} md={4}>
                        <h4 className="body-18">The Network so far:</h4>
                        <p className="body-16 font-weight-normal the-network-so-far-text">35 Employers</p>
                        <p className="body-16 font-weight-normal the-network-so-far-text">350 Professionals</p>
                        <p className="body-16 font-weight-normal the-network-so-far-text">1500 Students</p>
                    </Grid>
                    {/* <Grid item sm={4} >
                        <Box className="background-dark-blue want-to-see border-radius-5">
                            <h5 className="color-white want-to-see-heading">You may want to see</h5>
                            <Box display="flex"><img className="mx-4" src={yt} alt="" /> <p className="color-white body-12 font-weight-bold want-to-see-list">The upReach Mission</p> </Box>
                            <Box display="flex"><img className="mx-4" src={pdf} alt="" /> <p className="color-white body-12 font-weight-bold want-to-see-list">Ways to Support Students</p> </Box>
                            <Box display="flex"><img className="mx-4" src={pdf} alt="" /> <p className="color-white body-12 font-weight-bold want-to-see-list">More Information</p> </Box>
                            <Box display="flex"><img className="mx-4" src={pdf} alt="" /> <p className="color-white body-12 font-weight-bold want-to-see-list">Troubleshooting</p> </Box>
                        </Box>
                    </Grid> */}
                </Grid>}
        </>
    )
}

export default GettingStarted;
