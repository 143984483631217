import { Message } from 'common/types';
import { selectUser } from "features/auth/selectors";
import { User} from "common/types";
import { useSelector } from "react-redux";

type Props = {
    message: Message;
};

const NoShowMessage = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);
    if (message.sender === "SYSTEM") return null;
    return (
        <div>
            <span className="body-17 font-weight-bolder color-gulf-blue">
                {user?.id===message.sender.id ? "You" :message.sender.firstName} reported no show.
            </span>
        </div>
    )
}

export default NoShowMessage;
