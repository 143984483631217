import { Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Professional } from "common/types";
import ProfessionalUserCard from "features/network/ProfessionalUserCard";

type Props = {
  classes: any;
  handleButtonClick: (type: string) => void;
  handleShowProfile: (id: string) => void;
  prevSlide: () => void;
  nextSlide: () => void;
  current: number;
  suggestedProfessionals: any[];
};

const Carousel = ({
  classes,
  handleButtonClick,
  handleShowProfile,
  prevSlide,
  nextSlide,
  current,
  suggestedProfessionals,
}: Props) => {
  return (
    <div className={classes.suggestions}>
      <Button
        color="primary"
        className={classes.close__btn}
        onClick={() => handleButtonClick("close")}
      >
        Close
      </Button>
      <div className={classes.content}>
        <h2 className={classes.heading}>
          This professional has reached their interaction limit for this month.
        </h2>
        <h3 className={classes.sub__heading}>
          You cannot interact with this professional right now. Meanwhile here
          are some suggested professionals you may want to reach out to.
        </h3>
      </div>
      <div className={classes.slider}>
        <ArrowBack className={classes.left__arrow} onClick={prevSlide} />
        <ArrowForward className={classes.right__arrow} onClick={nextSlide} />
        {suggestedProfessionals?.map(
          (professional: Professional, index: number) => (
            <div
              className={
                index === current
                  ? `${classes.slide} ${classes.active}`
                  : classes.slide
              }
              key={index}
            >
              {index === current && (
                <ProfessionalUserCard
                  user={professional}
                  isSuggestedProfessionalModal
                  handleBtnClick={(id: string) => handleShowProfile(id)}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Carousel;
