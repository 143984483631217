import StepperControls from "common/components/StepperControls";
// import styled from "styled-components";
import {
  goToNextStep,
  goToPrevStep,
  setIsNextStep,
  setWorkDetails,
} from "./professionalRegistrationSlice";
import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { InputType } from "common/components/TextField";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import DocumentPicker from "../../../common/components/DocumentPicker";
import { selectIsNextStep, selectWorkDetails } from "./selectors";
import EmploymentHistory from "./EmploymentHistory";
import React from "react";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import { selectUser } from "features/auth/selectors";
import MonthYearPicker from "features/interactions/inputWidgets/MonthYearPicker";
import styled from "@emotion/styled";

type Props = {
  isMyAccount?: boolean;
}

const WorkDetails = ({ isMyAccount }: Props) => {
  const workDetails: any = useSelector(selectWorkDetails);
  const dispatch = useDispatch();
  const isNextStep = useSelector(selectIsNextStep);
  const [employer, setEmployer] = useState<any | null>();
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [selectedSectors, setSelectedSectors] = useState<any>([]);
  const [employersList, setEmployersList] = useState<any[]>([]);
  const [acceptDataPolicy, setAcceptDataPolicy] = useState<boolean>(false);
  const [currentEmploymentStartDate, setCurrentEmploymentStartDate] = useState<Date | null>(null);
  const user: any = useSelector(selectUser);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: isMyAccount ? { jobTitle: user?.jobTitle } : workDetails,
  });


  useEffect(() => {
    if (!isMyAccount) {
      setEmployer(workDetails.company);
      setSelectedSectors(workDetails.sectors);
      setCurrentEmploymentStartDate(workDetails.currentEmploymentStartDate)
      if (workDetails.employmentHistory) {
        setEmployersList([...workDetails.employmentHistory]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workDetails]);

  useEffect(() => {
    if (isMyAccount) {
      setEmployer(user?.company);
      setSelectedSectors(user?.sectors);
      setCurrentEmploymentStartDate(user.currentEmploymentStartDate);
      if (user.employmentHistory) {
        setEmployersList([...user.employmentHistory]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: any) => {
    data.sectors = selectedSectors;
    data.company = employer;
    data.currentEmploymentStartDate = currentEmploymentStartDate;
    const newEmployentHistoryArray: any[] = [];
    employersList.forEach((list) => {
      if (list.company !== "") {
        newEmployentHistoryArray.push(list);
      }
    });
    data.employmentHistory = newEmployentHistoryArray;
    dispatch(setWorkDetails(data));
    if (isNextStep && !isMyAccount) {
      dispatch(goToNextStep());
    }
    if (isMyAccount && isNextStep) {
      setIsRegister(true);
      dispatch({
        type: "PROFESSIONAL_PROFILE_UPDATE_ATTEMPT",
      });
    }
    if (!isNextStep) {
      dispatch(goToPrevStep());
    };
  };

  const appendToEmployerList = () => {
    setEmployersList(employersList.concat({}));
  };

  const deleteFromEmployerList = (index: number) => {
    setEmployersList(employersList.filter((x, i) => i !== index));
  };

  const updateToEmployerList = (value: any, index: number) => {
    const oldEmployersList = employersList;
    oldEmployersList[index] = { ...value };
  };
  return (
    <StyledForm
      className="registration-box-shadow company-jobtitle"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <h3 className="prof-registration-heading">
        Please add your employment details below.
        </h3>
      </Grid>
      <Grid className="month-picker" container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6} className="req-ast">
          <DocumentPicker
            handleChange={(employer: any) => setEmployer(employer)}
            collection="companies"
            error={errors.company}
            inputRef={register({ required: true })}
            name="company"
            // required
            defaultValue={employer}
            label="Company"
          />
        </Grid>
        <Grid item xs={12} lg={6} className="req-ast">
          <TextField
            className="work-textfields"
            name="jobTitle"
            type={InputType.TEXT}
            inputRef={register({ required: true })}
            variant="outlined"
            label="Job Title"
            error={!!errors.jobTitle}
            helperText={errors.jobTitle ? "This field is required" : ""}
          />
        </Grid>
        <Grid className="mb-0 currentJobDate" item lg={12} container spacing={1}>
          <Grid className="flex-end top-bottom-spacing no-padding req-ast-myp" item xs={12} lg={6}>
          <p className="body-14 font-weight-normal text-muted">Start Date <span className="text-danger">*</span></p>
            <MonthYearPicker
              value={currentEmploymentStartDate}
              name="currentEmploymentStartDate"
              onChange={(date: Date) => setCurrentEmploymentStartDate(date)}
              maxDate={new Date()}
              placeholder="Select start date"
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} className="req-ast">
          <DocumentPicker
            handleChange={(selectedSectors: any) =>
              setSelectedSectors(selectedSectors)
            }
            collection="sectors"
            multiple
            error={errors.sectors}
            name="sectors"
            label="Sectors"
            // required={selectedSectors?.length === 0}
            defaultValues={selectedSectors}
            inputRef={register({ required: selectedSectors?.length === 0 })}
          />
        </Grid>
        <Grid className="mb-0" item xs={12}>
          <h4 className="m-0">Previous Employment</h4>
        </Grid>
        {React.Children.toArray(
          employersList.map((data, i) => (
            <EmploymentHistory
              errors={errors}
              register={register}
              deleteFromEmployerList={deleteFromEmployerList}
              updateToEmployerList={updateToEmployerList}
              index={i}
              value={data}
            />
          ))
        )}
        <Grid item xs={12}>
          <Button onClick={appendToEmployerList} color="primary">
            Add another previous employer
          </Button>
        </Grid>
        <hr />
        {!isMyAccount &&
          <Grid item xs={12}>
            <Checkbox
              name="dataPolicy"
              onClick={() => setAcceptDataPolicy(!acceptDataPolicy)}
            />
            By ticking this box, I agree to upReach's{" "}
            <a
              href="https://cms.upreach.org.uk/uploads/up_Reach_SMN_Mentor_User_Agreement_April_2021_2_45a24b97f2.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Mentor User Agreement and Data Protection Policy
            </a> <span className="text-danger">*</span>
          </Grid>}
      </Grid>
      <StepperControls
        nextButtonCallback={() => {
          dispatch(setIsNextStep(true));
        }}
        disabled={isMyAccount ? acceptDataPolicy : !acceptDataPolicy}
        nextButtonLabel={isMyAccount ? "Update" : "Next"}
        backButtonCallback={() => {
          dispatch(setIsNextStep(false));
        }}
        backButtonLabel="Back"
        isRegister={isMyAccount ? isRegister : false}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 2rem;
  .MuiGrid-item {
    margin-bottom: 1rem;
    padding: 0 3rem;
  }
  .m-0 {
    margin: 0;
  }
  .mb-0 {
    margin: 0;
  }
  .work-textfields {
    width: 100%;
  }

  .company-jobtitle {
    .month-picker {
      margin-bottom: -1.2rem !important;
    }
  }

  .currentJobDate .no-padding{
    padding: 0px;
    padding-right: inherit;
  }

`;

export default WorkDetails;
