import { InteractionState, Message, MessageType, User, UserType } from "common/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveInteractionId, selectInteractions } from "../selectors";
import { Grid } from "@mui/material";
import { selectUser } from "features/auth/selectors";
import styled from "styled-components";
import ThankYouNoteModal from "../threadMessageWidgets/ThankYouNoteModal";
import Box from '@mui/material/Box';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';


const PostInteractionCompletion = () => {
  const interactions = useSelector(selectInteractions);
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState<boolean>(false);
  const [receiver, setReceiver] = useState<any>(null);
  const user: User | null = useSelector(selectUser);
  const dispatch = useDispatch();
  const [ratingBoxStudent, setRatingBoxStudent] = useState<boolean>(false);
  const [ratingBoxProfessional, setRatingBoxProfessional] = useState<boolean>(false);
  useEffect(() => {
    setActiveInteraction(
      interactions
        ?.filter((interaction: any) => interaction.id === activeInteractionId)
        .pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions]);

  useEffect(() => {
    // eslint-disable-next-line
    activeInteraction?.thread.some((el: any) => {
      if (el.sender.id !== user?.id) {
        setReceiver(el.sender);
        return true;
      }
    });
  }, [activeInteraction, user]);

  const feedbackGiven = user
    ? activeInteraction?.feedbackGiven[user.id]
    : false;

  const feedbackDontGiven =
    user ? activeInteraction?.feedbackDontGiven && activeInteraction.feedbackDontGiven[user.id]
      : false;

  const remindMeLater =
    user ? activeInteraction?.remindMeLater && activeInteraction.remindMeLater[user.id]
      : false;

  const dontRemindMeLater =
    user ? activeInteraction?.dontRemindMeLater && activeInteraction.dontRemindMeLater[user.id]
      : false;

  const customIconsStudent: { [index: string]: { icon: React.ReactElement; label: string } } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon />,
      label: 'Not At All Useful',
    },
    2: {
      icon: <SentimentDissatisfiedIcon />,
      label: 'Not Very Useful',
    },
    3: {
      icon: <SentimentSatisfiedIcon />,
      label: 'Useful',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon />,
      label: 'Very Useful',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon />,
      label: 'Extremely Useful',
    },
  };
  function IconContainerStudent(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other} >{customIconsStudent[value].icon}</span>;
  }
  const ratingClickStudent = (e: any) => {
    const label = customIconsStudent[e.target.value].label;
    if (user?.roles.includes(UserType.STUDENT)) {
      const message: Partial<Message> = {
        type: MessageType.STUD_RATING,
        payload: label,
      };
      dispatch({
        type: "SEND_RATING",
        payload: {
          activeInteractionId: activeInteractionId,
          message,
        },
      })
    }
    setRatingBoxStudent(true);
    setIsThankYouModalOpen(true);
  }

  const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon />,
      label: 'Not At All Impactful',
    },
    2: {
      icon: <SentimentDissatisfiedIcon />,
      label: 'Not Very Impactful',
    },
    3: {
      icon: <SentimentSatisfiedIcon />,
      label: 'Impactful',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon />,
      label: 'Very Impactful',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon />,
      label: 'Extremely Impactful',
    },
  };
  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  const ratingClick = (e: any) => {
    const label = customIcons[e.target.value].label;
    if (user?.roles.includes(UserType.PROFESSIONAL)) {
      const message: Partial<Message> = {
        type: MessageType.PROF_RATING,
        payload: label,
      };
      dispatch({
        type: "SEND_RATING",
        payload: {
          activeInteractionId: activeInteractionId,
          message,
        },
      })
    }
    setRatingBoxProfessional(true);
    setIsThankYouModalOpen(true);
  }

  return (
    <StyledWrapper>
      {/* Start - Rating System */}
      {activeInteraction?.state === InteractionState?.INTERACTIONCOMPLETED && !dontRemindMeLater && !remindMeLater  &&
        !feedbackGiven && !ratingBoxStudent && !feedbackDontGiven && user?.roles?.includes(UserType.STUDENT) &&
        <Grid className="post-interaction-background bubble" xs={12}>
          <Box mb={1} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          {
           activeInteraction?.type ==="EXPERT_ADVICE" 
            ? <h3 className="font-weight-bolder"> How useful was this Expert Advice interaction?</h3>
              : (activeInteraction?.type !== "EXPERT_ADVICE" 
              ? <h3 className="font-weight-bolder"> How useful did you find this Career Chat?</h3>
              :<h3 className="font-weight-bolder">How useful did you find this Mock Interview?</h3>
              )
          }
            <p className="ratingNote">Note: This rating is only shared with upReach and will not be visible to the professional.</p>
            <Rating style={{ textAlign: "center" }}
              name="customized-icons"
              getLabelText={(value: number) => customIconsStudent[value].label}
              onChange={(e: any) => ratingClickStudent(e)}
              IconContainerComponent={IconContainerStudent}
            />
          </Box>
        </Grid>
      }
      {activeInteraction?.state === InteractionState.INTERACTIONCOMPLETED && !dontRemindMeLater && !remindMeLater &&
        !feedbackGiven && !ratingBoxProfessional && !feedbackDontGiven && user?.roles?.includes(UserType.PROFESSIONAL) &&
        <Grid className="post-interaction-background bubble" xs={12}>
          <Box mb={1} sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h3 className="font-weight-bolder"> How impactful do you feel your support was in this interaction?</h3>
            <p className="ratingNote">Note: This rating is only shared with upReach and will not be visible to the student.</p>
            <Rating style={{ textAlign: "center" }}
              name="customized-icons"
              getLabelText={(value: number) => customIcons[value].label}
              onChange={(e: any) => ratingClick(e)}
              IconContainerComponent={IconContainer}
            />
          </Box>
        </Grid>
      }
      {/* End - Rating System */}
      <ThankYouNoteModal
        setIsThankYouModalOpen={setIsThankYouModalOpen}
        isThankYouModalOpen={isThankYouModalOpen}
        interactionId={activeInteraction?.id}
        receiver={receiver}
      />
    </StyledWrapper>
  );
};

export default PostInteractionCompletion;

const StyledWrapper = styled.div`
  .post-interaction-background {
    background-color: #f3f3fb;
    padding: 22px;
  }

  .after-interaction-actions {
    display: flex;
    justify-content: space-around;

    .action-btn {
      height: 100%;
      width: 100%;
      display: inline;

      p {
        margin: 0;
        color: #464fd0;
      }

      svg {
        margin: 0px auto 0 auto;
        display: block;
      }
    }

    .mt-1 {
      margin-top: 5px !important;
    }

    .disabled-action {
      height: 100%;
      width: 100%;
      display: inline;
      p {
        margin: 0;
        // color: grey;
      }
    }
  }

  .feedback-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 0;
  }

  .send-btn {
    float: right;
    margin-top: 30px;
    border: none;
    width: 100px;
    height: 39px;
    justify-content: center;
    padding-top: 8px;
    font-family: Nunito Sans, sans-serif !important;
  }
  .send-icon {
    margin-left: 10px;
    position: relative;
    top: -5px;
  }
  .send-logo {
    justify-content: center;
    margin: auto;
    font-family: Nunito Sans, sans-serif !important;
  }
  .thread-input {
    "& input::placeholder": {
      font-family: Nunito Sans, sans-serif !important;
    },
    textarea,
    div {
      background: #f7f7f7;
    },
  }
  .ratingNote {
    font-size:12px;
  }
  .MuiRating-label {
    padding-left:30px;
    padding-right:30px;
  }
  input[type="radio"] {
    appearance: none !important;
  }
`;