import { Tooltip, Theme, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { InteractionType, MessageType, UserType } from "common/types";
import { selectUser } from "features/auth/selectors";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import help from "../../common/assets/images/help.svg";
import { setPromptModal } from "features/auth/slice";
import { useTheme } from '@mui/material/styles';


export default function ActivityCard({ activeInteractions }: any) {
  const user: any = useSelector(selectUser);
  const dispatch = useDispatch();
  const themeProps = useTheme();

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: themeProps.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: themeProps.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  let askAnExperts = 0;
  let careerChats = 0;
  let mockInterviews = 0;
  let likedMessages = 0;
  let AppreciatedMessages = 0;
  let feedbackMessages = 0;
  activeInteractions.forEach((interaction: any) => {
    if (interaction.type === InteractionType.EXPERT_ADVICE) askAnExperts++;
    if (interaction.type === InteractionType.CAREER_CHAT) careerChats++;
    if (interaction.type === InteractionType.MOCK_INTERVIEW) mockInterviews++;
    interaction.thread.forEach((message: any) => {
      if (message.type === MessageType.LIKE && message.sender.id !== user?.id)
        likedMessages++;
      if (
        message.type === MessageType.THANKS_NOTE &&
        message.sender.id !== user?.id
      )
        AppreciatedMessages++;
      if (
        message.type === MessageType.FEEDBACK_NOTE &&
        message.sender.id !== user?.id
      )
        feedbackMessages++;
    });
  });

  const handleShowBtnClick = () => {
    dispatch(setPromptModal({ isOpen: true, ModalType: "THANKYOU_MESSAGE" }));
  };

  return (
    <StyledWrapper>
      <Card className="card-height min-height-cards activity-card">
        <CardContent className="card-content ">
          <p className="body-20 font-weight-400 mt-0">
            {(user?.roles.includes(UserType.STUDENT) ||
              (user?.roles.includes(UserType.ALUMNI) &&
                !user?.hasGraduateJob)) &&
              "Your Activity"}
            {(user?.roles.includes(UserType.PROFESSIONAL) ||
              (user?.roles.includes(UserType.ALUMNI) &&
                user?.hasGraduateJob)) &&
              "My Impact"}
          </p>
          <p className="body-16 font-weight-normal">
            <b className="figure">{askAnExperts}</b> Ask an Expert
            {askAnExperts <= 1 ? "" : "s"}
          </p>
          <p className="body-16 font-weight-normal">
            <b className="figure">{careerChats}</b> Career Chat
            {askAnExperts <= 1 ? "" : "s"}
          </p>
          <p className="body-16 font-weight-normal">
            <b className="figure">{mockInterviews}</b> Mock Interview
            {mockInterviews <= 1 ? "" : "s"}
          </p>
          {(user?.roles.includes(UserType.PROFESSIONAL) ||
            (user?.roles.includes(UserType.ALUMNI) &&
              user?.hasGraduateJob)) && (
            <>
              <p className="body-16 font-weight-normal activity-flex-container">
                <b className="figure">{AppreciatedMessages}</b>&nbsp;{" "}
                <span>
                  {" "}
                  Thank you message{AppreciatedMessages <= 1 ? "" : "s"}{" "}
                </span>
                <LightTooltip
                  className="tooltip-icon1"
                  title="This displays how many messages of thanks you have received from Associates."
                  placement="top"
                  arrow
                >
                  <img src={help} alt="" />
                </LightTooltip>
                {AppreciatedMessages > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowBtnClick}
                    className="thankyou-btn"
                  >
                    View
                  </Button>
                )}
              </p>
              <p className="body-16 font-weight-normal activity-flex-container">
                <b className="figure">{likedMessages}</b>&nbsp;Appreciation
                {likedMessages <= 1 ? "" : "s"} received
                <LightTooltip
                  className="tooltip-icon2"
                  title="This displays how many notes of appreciation you have received from Associates."
                  placement="top"
                  arrow
                >
                  <img src={help} alt="" />
                </LightTooltip>
              </p>
            </>
          )}
          {(user?.roles.includes(UserType.STUDENT) ||
            (user?.roles.includes(UserType.ALUMNI) &&
              !user?.hasGraduateJob)) && (
            <>
              <p className="body-16 font-weight-normal activity-flex-container">
                <b className="figure">{feedbackMessages}</b>&nbsp;{" "}
                <span>
                  {" "}
                  Feedback message{feedbackMessages <= 1 ? "" : "s"}{" "}
                </span>
                <LightTooltip
                  className="tooltip-icon1"
                  title="This displays how many feedbacks you have received from Professionals."
                  placement="top"
                  arrow
                >
                  <img src={help} alt="" />
                </LightTooltip>
                {feedbackMessages > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowBtnClick}
                    className="thankyou-btn"
                  >
                    View
                  </Button>
                )}
              </p>
            </>
          )}
        </CardContent>
      </Card>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  height: 100%;
  .card-content {
    padding: 30px 20px;
  }

  .activity-card {
    min-height: 215px;
  }

  .min-height-cards {
    @media (min-width: 600px) {
      min-height: 215px;
    }
  }

  .figure {
    font-size: 18px;
    font-weight: 400;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .tooltip-icon1 {
    position: relative;
    bottom: -5px;
    margin-top: -4px;
    left: 8px;
  }

  .thankyou-btn {
    margin-left: 20px;
    background-color: #3f51b5 ! important;
  }

  .show-btn {
    margin-left: 22px;
  }

  .tooltip-icon2 {
    position: relative;
    bottom: -5px;
    margin-top: -4px;
    left: 19px;
  }

  .activity-flex-container {
    display: flex;
    align-items: center;
  }
`;
