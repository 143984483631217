import { InteractionState, MessageType, UserType } from "common/types";
import InteractionRequestResponseInput from "../threadMessageWidgets/InteractionRequestResponseInput";
import TextMessageInput from "./TextMessageInput";
import TimeSuggestionInput from "./TimeSuggestionInput";
import TimeSelectionInput from "./TimeSelectionInput";
import CallDetailsInput from "./CallDetailsInput";
import PostInteractionActions from "./PostInteractionActions";
import RescheduleRequestInput from "./RescheduleRequestInput";
import RescheduleInput from "./RescheduleInput";
import RescheduleRequestResponse from "../threadMessageWidgets/RescheduleRequestResponse";
import PostInteractionCompletion from "./PostInteractionCompletion";
import SorryModal from "../threadMessageWidgets/SorryModal";
import ApologyMessageModal from "../threadMessageWidgets/ApologyMessageModal";

enum AlumniType {
  ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL = "ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL",
  ALUMNI_WITH_GRADUATE_JOB_STUDENT = "ALUMNI_WITH_GRADUATE_JOB_STUDENT",
  ALUMNI_WITHOUT_GRADUATE_JOB = "ALUMNI_WITHOUT_GRADUATE_JOB",
}

const InputWidgetMap: any = {
  [InteractionState.REQUESTED]: {
    [UserType.STUDENT]: <TextMessageInput />,
    [UserType.PROFESSIONAL]: <InteractionRequestResponseInput />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <InteractionRequestResponseInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <TextMessageInput />,
  },
  [InteractionState.ACCEPTED]: {
    [UserType.STUDENT]: <TextMessageInput />,
    [UserType.PROFESSIONAL]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <TextMessageInput />,
  },
  [InteractionState.DECLINED]: {
    [UserType.STUDENT]: null,
    [UserType.PROFESSIONAL]: null,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
  },
  
  // created new for interactioncomplete
  [InteractionState.INTERACTIONCOMPLETED]: {
    [UserType.STUDENT]: <PostInteractionCompletion />,
    [UserType.PROFESSIONAL]: <PostInteractionCompletion />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
  },

    //not over
    [InteractionState.INTERACTION_NOT_OVER]: {
      [UserType.STUDENT]: <TextMessageInput />,
      [UserType.PROFESSIONAL]: <TextMessageInput />,
      [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
      [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
      [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
    },

     //-----
     [InteractionState.INT_NO_SHOW ]: {
      [UserType.STUDENT]:null,
      [UserType.PROFESSIONAL]: null,
      [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
      [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
      [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
    },
    [InteractionState.INT_NO_SHOW_COMPLETE ]: {
      [UserType.STUDENT]:null,
      [UserType.PROFESSIONAL]: null,
      [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
      [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
      [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
    },
  [InteractionState.SCHEDULING_TIMES_REQUESTED]: {
    [UserType.STUDENT]: null,
    [UserType.PROFESSIONAL]: <TimeSuggestionInput />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <TimeSuggestionInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
  },
  [InteractionState.SCHEDULING_TIMES_SUGGESTED]: {
    [UserType.STUDENT]:
      <>
        <TimeSelectionInput />
        <br />
        <TextMessageInput />
      </>,
    [UserType.PROFESSIONAL]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]:
      <>
        <TimeSelectionInput />
        <br />
        <TextMessageInput />
      </>,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]:
      <>
        <TimeSelectionInput />
        <br />
        <TextMessageInput />
      </>,
  },
  [InteractionState.SCHEDULING_ADDITIONAL_TIMES_REQUESTED]: {
    [UserType.STUDENT]: <TextMessageInput />,
    [UserType.PROFESSIONAL]:
      <>
        <TimeSuggestionInput />
        <br />
        <TextMessageInput />
      </>,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]:
      <>
        <TimeSuggestionInput />
        <br />
        <TextMessageInput />
      </>,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <TextMessageInput />,
  },
  [InteractionState.SCHEDULED]: {
    [UserType.STUDENT]: <TextMessageInput />,
    [UserType.PROFESSIONAL]: <CallDetailsInput />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <CallDetailsInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <TextMessageInput />,
  },
  [InteractionState.CALL_DETAILS_SET]: {
    [UserType.STUDENT]: <RescheduleRequestInput />,
    [UserType.PROFESSIONAL]: <RescheduleInput />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <RescheduleRequestInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <RescheduleInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <RescheduleRequestInput />,
  },
  [InteractionState.RESCHEDULE_REQUESTED]: {
    [UserType.STUDENT]: <TextMessageInput />,
    [UserType.PROFESSIONAL]:
      <>
        <RescheduleRequestResponse />
        <br />
        <TextMessageInput />
      </>,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <TextMessageInput />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]:
      <>
        <RescheduleRequestResponse />
        <br />
        <TextMessageInput />
      </>,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <TextMessageInput />,
  },
  [InteractionState.COMPLETED]: {
    [UserType.STUDENT]: <PostInteractionActions />,
    [UserType.PROFESSIONAL]: <PostInteractionActions />,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: <PostInteractionActions />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: <PostInteractionActions />,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: <PostInteractionActions />,
  },
  [InteractionState.EXPIRED]: {
    [UserType.STUDENT]: null,
    [UserType.PROFESSIONAL]: null,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
  },
  [InteractionState.SORRY_MODAL_CLOSE]: {
    [UserType.STUDENT]: null,
    [UserType.PROFESSIONAL]: null,
    [AlumniType.ALUMNI_WITHOUT_GRADUATE_JOB]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_STUDENT]: null,
    [AlumniType.ALUMNI_WITH_GRADUATE_JOB_PROFESSIONAL]: null,
  },
};

type Props = {
  interactionState: InteractionState;
  userType: UserType;
  hasGraduateJob?: boolean;
  isAlumniAsProfessional?: boolean;
  isSorryModalOpen: any;
  setIsSorryModalOpen: any;
  messageType: MessageType;
  interactionId: any;
};

const InputWidgetFactory = ({ interactionState, userType, hasGraduateJob, isAlumniAsProfessional, messageType, interactionId,isSorryModalOpen, setIsSorryModalOpen }: Props) => {
  const isStudentSorryModal = ((interactionState === InteractionState.INT_NO_SHOW && UserType.STUDENT && messageType === MessageType.STUDENT_NO_SHOW) || (interactionState === InteractionState.SORRY_MODAL_CLOSE &&  messageType === MessageType.PROF_SORRY_MODAL_CLOSE));
  const isSorryModalOpen1 = ((interactionState === InteractionState.INT_NO_SHOW && UserType.STUDENT && messageType === MessageType.PROFESSIONAL_NO_SHOW) || (interactionState === InteractionState.INT_NO_SHOW_COMPLETE && messageType === MessageType.PROF_APPOLOGY) || (interactionState === InteractionState.INT_NO_SHOW_COMPLETE && messageType === MessageType.PROF_APPOLOGY_MODAL_CLOSE));
  const isProfessionalSorryModal = ((interactionState === InteractionState.INT_NO_SHOW && UserType.PROFESSIONAL && messageType === MessageType.PROFESSIONAL_NO_SHOW) || (interactionState === InteractionState.SORRY_MODAL_CLOSE &&  messageType === MessageType.STUD_SORRY_MODAL_CLOSE) );
  const isProfessionalModalOpen = ((interactionState === InteractionState.INT_NO_SHOW && UserType.PROFESSIONAL && messageType === MessageType.STUDENT_NO_SHOW) || (interactionState === InteractionState.INT_NO_SHOW_COMPLETE && messageType === MessageType.STUD_APPOLOGY) || (interactionState === InteractionState.INT_NO_SHOW_COMPLETE && messageType === MessageType.STUD_APPOLOGY_MODAL_CLOSE));
    return (
    <>
      {isSorryModalOpen1 && userType === "STUDENT" &&(
      
        <SorryModal
          interactionId={interactionId} // Pass the appropriate interaction ID here
          isSorryModalOpen={isSorryModalOpen}
          setIsSorryModalOpen={setIsSorryModalOpen} // You can provide the appropriate setter function here        
          interactionState={interactionState}
        />
      )}
      {isStudentSorryModal && userType === "STUDENT" &&(
      <ApologyMessageModal
        interactionId={interactionId} // Pass the appropriate interaction ID here
        isApologyMessageModalOpen={isStudentSorryModal}
        setIsApologyMessageModalOpen={setIsSorryModalOpen} // You can provide the appropriate setter function here
      />
      )}
      {isProfessionalSorryModal && userType === "PROFESSIONAL" &&(
        <ApologyMessageModal
        interactionId={interactionId} // Pass the appropriate interaction ID here
        isApologyMessageModalOpen={isProfessionalSorryModal}
        setIsApologyMessageModalOpen={setIsSorryModalOpen} // You can provide the appropriate setter function here
      />
      
      ) }
      {isProfessionalModalOpen && userType === "PROFESSIONAL" &&(
        <SorryModal
        interactionId={interactionId} // Pass the appropriate interaction ID here
        isSorryModalOpen={isProfessionalModalOpen}
        setIsSorryModalOpen={setIsSorryModalOpen} // You can provide the appropriate setter function here
        interactionState={interactionState}

      />
      

      )
}
      {InputWidgetMap[interactionState][userType === UserType.ALUMNI ? `${userType}_${hasGraduateJob ? `WITH_GRADUATE_JOB_${isAlumniAsProfessional}` : "WITHOUT_GRADUATE_JOB"}` : userType]}
    </>
  );
};
  // InputWidgetMap[interactionState][userType === UserType.ALUMNI ? `${userType}_${hasGraduateJob ? `WITH_GRADUATE_JOB_${isAlumniAsProfessional}` : "WITHOUT_GRADUATE_JOB"}` : userType];

export default InputWidgetFactory;
