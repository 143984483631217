import { Avatar } from "@mui/material";
// import styled from "styled-components";
import {
  InteractionState,
  InteractionType,
  User,
  UserType,
} from "common/types";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InteractionStateWidgetFactory from "./InteractionStateWidgetFactory";
import { format, parseISO } from "date-fns";
import call from "common/assets/images/call.svg";
import Loading from "common/components/Loading";
import { selectUser } from "features/auth/selectors";
import avatar from "common/assets/images/avatar.svg";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const colorMap: any = {
  [InteractionType.EXPERT_ADVICE]: "background-sea-blue",
  [InteractionType.CAREER_CHAT]: "background-persian-green",
  [InteractionType.MOCK_INTERVIEW]: "background-ceru",
  [InteractionType.MENTORING]: "background-gulf-blue",
};

type Props = {
  interaction: any;
  handleClick: any;
};

const InteractionTile = ({ interaction, handleClick: handleOpen }: Props) => {
  const user: User | null = useSelector(selectUser);
  const [participant, setParticipant] = useState<any>();

  useEffect(() => {
    if (user) setParticipant(interaction.connectionProfile[user.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const urlWithHttp = (url: any) =>
    !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  const videoCallUrl =
    interaction?.callDetails && urlWithHttp(interaction?.callDetails);

  if (!user) return <Loading />;

  if (!participant) return null;

  const thread = interaction.thread.map((sender: any) => sender);
  const senders = thread.map((data: any) => data.sender);
  let count = 0;

  senders.forEach((item: any) => {
    if (
      item.roles !== undefined &&
      item.roles.length > 0 &&
      item.roles[0] === "PROFESSIONAL"
    ) {
      count++;
    }
  });

  return (
    <StyledWrapper className="border-radius-5 background-white">
      <div className="grid-container">
        <div
          onClick={handleOpen}
          className={`justify-center active-highlight color-white cursor-pointer highlight border-radius-rtb-5 one ${
            colorMap[interaction.type]
          }`}
        >
          <span className="text-center interaction-type-span">
            {interaction?.type.replace("_", " ").toLowerCase()}
          </span>
        </div>
        <div className="outer-wrapper">
          <div className="first-wrapper d-flex">
            <div className="active-highlight cursor-pointer two">
              <div className="first-first-wrapper d-flex">
                <div className="avatar">
                  <Link className="redirect-to" to={`/users/${participant.id}`}>
                    <Avatar
                      className="profile-avatar-tile mx-auto"
                      src={participant.profilePicture || avatar}
                    />
                  </Link>
                </div>
                <div className="name">
                  <Link className="redirect-to" to={`/users/${participant.id}`}>
                    <p className="body-17 font-weight-bold my-0 text-center color-black">{`${participant.firstName} ${participant.lastName}`}</p>
                  </Link>
                  {participant?.roles.includes(UserType.ALUMNI) && (
                    <span
                      onClick={handleOpen}
                      className="body-15 text-center d-block"
                    >
                      {participant?.company && participant?.company}
                    </span>
                  )}
                  <span
                    onClick={handleOpen}
                    className="body-15 text-center d-block"
                  >
                    {participant?.company?.name ||
                      participant?.university?.name}
                  </span>
                </div>
              </div>
            </div>
            <div
              onClick={handleOpen}
              className="active-highlight cursor-pointer three"
            >
              {interaction?.scheduledFor && (
                <div>
                  <p className="body-15 font-weight-bold my-0 mx-auto text-center">
                    {format(
                      parseISO(interaction?.scheduledFor),
                      "d.M.y - HH:mm"
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="second-wrapper d-flex">
            <div
              onClick={!interaction?.callDetails && handleOpen}
              className={`cursor-pointer four`}
            >
              {interaction?.callDetails &&
                interaction.state !== InteractionState.COMPLETED && (
                  <a target="_blank" rel="noreferrer" href={videoCallUrl}>
                    <img width="30px" src={call} alt="" />
                  </a>
                )}
            </div>
            <div
              onClick={handleOpen}
              className="cursor-pointer body-15  text-center five"
            >
              <div
                className={
                  interaction?.isAttendanceConfirmed &&
                  !interaction.isAttendanceConfirmed &&
                  interaction.state === InteractionState.COMPLETED
                    ? " "
                    : "btn-wrapper"
                }
              >
                <InteractionStateWidgetFactory
                  interactionType={interaction.type}
                  interactionState={interaction.state}
                  messageType={interaction.thread[interaction.thread.length-1].type}
                  isAttendanceConfirmed={
                    interaction?.isAttendanceConfirmed ? true : false
                  }
                  interactionId={interaction.id}
                  professionalcount = {count}
                  userType={
                    user.roles.includes(UserType.PROFESSIONAL)
                      ? UserType.PROFESSIONAL
                      : UserType.STUDENT
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: 30px;
  min-height: 80px;
  .grid-container {
    min-height: 80px;
    display: flex;
  }

  .interaction-type-span {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: normal;
    @media (max-width: 700px) {
      text-align: left;
      margin-left: 15px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
      text-align: left;
      margin-left: 15px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
      text-align: left;
      margin-left: 10px;
    }
  }

  .one {
    width: 25%;
    max-width: 156px;
  }

  .outer-wrapper {
    display: flex;
    justify-content: space-around;
    width: 125%;
    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .first-wrapper {
    width: 60%;
    align-items: center;
    margin-top: 0 !important;
    @media (max-width: 700px) {
      justify-content: space-around;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .first-first-wrapper {
    margin-top: 0 !important;
    align-items: center;
    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .name {
    margin-left: 15px;
    text-align: left;
    @media (max-width: 700px) {
      margin-left: 0;
    }
  }

  .second-wrapper {
    width: 25%;
    align-items: center;
    justify-content: space-around;
    margin-top: 0 !important;
    @media (max-width: 500px) {
      border-top: 1px solid rgba(158, 165, 238, 0.2);
      width: 100%;
      padding: 10px 0;
      justify-content: space-evenly;
    }
  }

  .four {
    margin-left: 30px;
  }

  .five {
    margin: auto;
    height: 100%;
  }

  .confirm-attendance-btn {
    margin-bottom: 28px;
    font-size: 12px;
    background-color: #3f51b5;
  }

  .btn-wrapper {
    padding-top: 30px;
    @media (max-width: 900px) {
      padding-top: 10px;
    }
    @media (max-width: 500px) {
      padding-top: 0;
    }
  }

  .two {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 12px;
  }

  .three {
    margin-left: auto;
    @media (max-width: 700px) {
      margin-left: 15px;
    }
  }

  .name-avatar {
    @media (max-width: 500px) {
      display: flex;
      align-items: center;
    }
  }

  .body-15,
  .body-20,
  .body-17,
  .body-13 {
    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 17px;
    }
  }

  .profile-avatar-tile {
    margin: auto;
    @media (max-width: 500px) {
      margin-right: 12px;
    }
  }

  .border-radius-rtb-5 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .redirect-to {
    color: black;
    text-decoration: none;
  }

  .redirect-to:hover {
    text-decoration: underline;
  }
`;

export default InteractionTile;
