import { User } from "@sentry/react";
import { selectUser } from "features/auth/selectors";
import { useSelector } from "react-redux";
import { Message } from "./../../../common/types";

type Props = {
  message: Message;
};

const CallDetailsMessageNoLink = ({ message }: Props) => {

  const user: User | null = useSelector(selectUser);
  if(message.sender === "SYSTEM") return null;
  return (
    <div>
      <span className="body-16 font-weight-bolder color-gulf-blue">
        {user?.id === message.sender.id ? "You have" : message.sender.firstName+" has"} set the call details:&nbsp;
      </span>
      <div
      className={`interaction-container bubble background-white ${message.sender.id === user?.id ? "sent-bubble" : "received-bubble" }`}
      >
        <div className="interaction-texts">{message.payload}</div>
      </div>
    </div>
  );
};

export default CallDetailsMessageNoLink;
