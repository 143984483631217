import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { selectAuthUser, selectUser } from "features/auth/selectors";
import { useSelector } from "react-redux";
import { UserType } from "common/types";
import recordEvent from "common/utils/mixpanel";
import { Link } from "react-router-dom";
import { AuthUser } from "features/auth/slice";

type Props = {
  handleClose: any;
  onButtonClick: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: useTheme().palette.background.paper,
      boxShadow: useTheme().shadows[5],
      padding: "30px 100px 60px 100px",
      [useTheme().breakpoints.down('lg')]: {
        padding: "30px 20px 60px 20px",
        width: "80%",
      },
      zIndex:"1"
    },
    redirectButton: {
      fontFamily: "Nunito Sans",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "18px",
      letterSpacing: "0px",
      height: "40px",
      width: "200px",
      background: "#464FD0",
      marginTop: "20px",
    },
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "32px",
      color: "#304563",
    },
    subtext: {
      fontFamily: "Nunito Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    closeButton: {
      color: "#464FD0",
      marginLeft: "auto",
      display: "flex",
    },
  })
);

enum FeatureStatus {
  NO_PROFILE_PICTURE_AND_BIO = "NO_PROFILE_AND_BIO",
  NO_PROFILE_PICTURE_BUT_HAS_BIO = "NO_PROFILE_PICTURE_BUT_HAS_BIO",
  NO_BIO_BUT_HAS_PROFILE_PICTURE = "NO_BIO_BUT_HAS_PROFILE_PICTURE",
  HIDE_SENSITIVE_DATA_FOR_ASSOCIATES = "HIDE_SENSITIVE_DATA_FOR_ASSOCIATES",
}

const userTypeMapWithText: any = {
  [FeatureStatus.NO_PROFILE_PICTURE_AND_BIO]: {
    [UserType.STUDENT]: (
      <>
        <li>Add a bio to tell professionals a bit about yourself.</li>
        <li>Include a profile picture to personalise your account.</li>
      </>
    ),
    [UserType.PROFESSIONAL]: (
      <>
        <li>Please add a bio to tell students a bit about your job.</li>
        <li>Include a profile picture to personalise your account.</li>
      </>
    ),
  },
  [FeatureStatus.NO_PROFILE_PICTURE_BUT_HAS_BIO]: {
    [UserType.STUDENT]: <li>Include a profile picture to personalise your account.</li>,
    [UserType.PROFESSIONAL]: <li>Include a profile picture to personalise your account.</li>,
  },
  [FeatureStatus.NO_BIO_BUT_HAS_PROFILE_PICTURE]: {
    [UserType.STUDENT]: <li>Add a bio to tell professionals a bit about yourself.</li>,
    [UserType.PROFESSIONAL]: <li>Please add a bio to tell students a bit about your job.</li>,
  },
  [FeatureStatus.HIDE_SENSITIVE_DATA_FOR_ASSOCIATES]: {
    [UserType.STUDENT]: (
      <li>
        Choose what information is shown to professionals on the platform.
      </li>
    ),
    [UserType.PROFESSIONAL]: null,
  },
};

export default function UserNotifyModal({ onButtonClick, handleClose }: Props) {
  const classes = useStyles();
  const user: any = useSelector(selectUser);
  const authUser: AuthUser | null = useSelector(selectAuthUser);

  const sendEventToMixPanel = (user: any) => {
    let featureType = "";
    if (user?.profilePicture === "" && user?.bio !== "") featureType = "BIO FEATURE";
    else if (user?.bio === "" && user?.profilePicture !== "")
      featureType = "PROFILE PICTURE FEATURE";
    else if (user?.bio === "" && user?.profilePicture === "")
      featureType = "BIO AND PROFILE PICTURE FEATURE";

    let basicEventDetails = {};
    if (user.roles.includes(UserType.PROFESSIONAL)) {
      basicEventDetails = {
        "User Roles": user.roles,
        "Professional Sector": user.sector,
        "Professional Company": user.company?.name,
        "Professional Job Title": user.jobTitle,
        "Professional University": user.university?.name,
      };
    } else if (user.roles.includes(UserType.STUDENT)) {
      basicEventDetails = {
        "User Roles": user.roles,
        "Student Gender": user.gender,
        "Student Region": user.region?.name,
        "Student Ethnicity": user.ethnicity?.name,
        "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
      };
      recordEvent("New Feature Click", {
        "New Feature Type": "HIDE SENSITIVE INFORMATION FEATURE",
        ...basicEventDetails,
      });
    }

    recordEvent("New Feature Click", {
      "New Feature Type": featureType,
      ...basicEventDetails,
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className={classes.paper}>
            <Button onClick={handleClose} className={classes.closeButton} color="primary">
              Close
            </Button>
            <p className={classes.heading}>New Features</p>
            <ul>
              {user?.profilePicture === "" &&
                user?.bio !== "" &&
                userTypeMapWithText[FeatureStatus.NO_PROFILE_PICTURE_BUT_HAS_BIO][user.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT]}
              {user?.bio === "" &&
                user?.profilePicture !== "" &&
                userTypeMapWithText[FeatureStatus.NO_BIO_BUT_HAS_PROFILE_PICTURE][user.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT]}
              {user?.bio === "" &&
                user?.profilePicture === "" &&
                userTypeMapWithText[FeatureStatus.NO_PROFILE_PICTURE_AND_BIO][user.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT]}
              {userTypeMapWithText[FeatureStatus.HIDE_SENSITIVE_DATA_FOR_ASSOCIATES][user.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT]}
            </ul>
            <Link to={`/users/${authUser?.id}`} >
              <Button
                onClick={(e) => {
                  sendEventToMixPanel(user);
                  onButtonClick(e);
                }}
                variant="contained"
                className={classes.redirectButton}
                color="primary"
              >
                Edit my profile
              </Button>
            </Link>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
