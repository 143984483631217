import { Button, TextField } from "@mui/material";
import { Message, MessageType } from "common/types";
import recordEvent from "common/utils/mixpanel";
import { selectUser } from "features/auth/selectors";
import { setPromptModal } from "features/auth/slice";
import { selectActiveInteractionId } from "features/interactions/selectors";
import { setMessageSendInProgress } from "features/interactions/slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";


const DeclineRequestPromptModal = () => {
    const dispatch = useDispatch();
    const activeInteractionId = useSelector(selectActiveInteractionId);
    
    const user: any = useSelector(selectUser);
    const [text, setText] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState(false);
  
    const handleRejectRequest = () => {
      setIsDisabled(true);
      recordEvent("Request Action Taken", {
        "Action Type": "Rejected",
        "Professional Sector": user?.sectors,
        "Professional Company": user?.company.name,
        "Professional Job Title": user?.jobTitle,
        "Professional University": user?.university?.name,
        "Professional University Course": user?.universityCourse,
      });
      dispatch(setMessageSendInProgress(true));
      dispatch({
        type: "DECLINE_INTERACTION_REQUEST",
        payload: { interactionId: activeInteractionId },
      });
  
      if(text !== ""){
        const message: Partial<Message> = {
          type: MessageType.TEXT,
          payload: text,
        };
        dispatch({
          type: "SEND_MESSAGE",
          payload: {
            activeInteractionId: activeInteractionId,
            message,
          },
        });
      }
    };
    return (
      <ModalContainer>
        <p className="noshow-modal-text font-family-nunito">
          If you would like to let the Associate know why you are rejecting a request, please use the text box below.
        </p>
        <TextField
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          variant="outlined"
          placeholder="Write your message to the Associate here (Optional)"
          className="prompt-modal-textfield"
        />
        <div className="modal-button-container">
          <Button
            variant="outlined"
            color="primary"
            className="cancel-button font-family-nunito"
            size="large"
            onClick={() => dispatch(setPromptModal({ isOpen: false, ModalType: null }))}
            disabled={isDisabled}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="decline-button font-family-nunito"
            onClick={handleRejectRequest}
            disabled={isDisabled}
          >
            Decline Request
          </Button>
        </div>
      </ModalContainer>
    );
  }

export default DeclineRequestPromptModal;

const ModalContainer = styled.div`
  .prompt-modal-textfield {
    padding-bottom: 35px;
  }

  .modal-button-container {
    display: flex;
    justify-content: space-between;
  }

  .noshow-modal-text {
    color: #464FD0;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .decline-button{
    background-color: #464FD0;
    text-transform: initial;
    padding: 4% 15%;
    font-weight: inherit;
  }

  .cancel-button{
    background-color: #FFFFFF;
    text-transform: initial;
    margin-right: 20px;
    padding: 4% 12%;
    font-weight: inherit;
  }

  @media (max-width:600px){
    .noshow-modal-text {
      font-size: 16px;
      margin-bottom: 18px;
    }

    .prompt-modal-textfield {
      padding-bottom: 20px;
    }

    .decline-button{
      background-color: #464FD0;
      text-transform: initial;
      padding: 4% 10%;
      font-weight: inherit;
    }

    .cancel-button{
      background-color: #FFFFFF;
      text-transform: initial;
      margin-right: 20px;
      padding: 4% 10%;
      font-weight: inherit;
    }
  }
`;