import { useState } from "react";
import { Message, MessageType, User, UserType } from "common/types";
// import { selectActiveInteractionId, selectInteractions } from "../selectors";

import styled from "styled-components";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMessageSendInProgress } from "../slice";
import { selectUser } from "features/auth/selectors";
import { Grid } from "@mui/material";

type Props = {
    interactionId: string;
    isApologyMessageModalOpen: boolean;
    setIsApologyMessageModalOpen: any;
    // receiver: any;
};

const ApologyMessageModal = ({ interactionId, isApologyMessageModalOpen, setIsApologyMessageModalOpen }: Props) => {
    const dispatch = useDispatch();
    // const interactions = useSelector(selectInteractions);
    // const activeInteractionId = useSelector(selectActiveInteractionId);

    const [appologyNote, setAppologyNote] = useState<string>('');
    const user: User | null = useSelector(selectUser);

    const sendMessage = () => {
        // setIsApologyMessageModalOpen= false
        // dispatch({
        //     type: "INT_NO_SHOW_COMPLETE",
        //     payload: { activeInteractionId },
        //   });
        if (user?.roles.includes(UserType.STUDENT)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.STUD_APPOLOGY,
                payload: appologyNote,
            };
            setAppologyNote("")
                dispatch({
                    type: "INT_NO_SHOW_COMPLETE",
                    payload: {
                        activeInteractionId: interactionId,
                        message,
                    },
                })
        }
        // }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.PROF_APPOLOGY,
                payload: appologyNote,
            };
            setAppologyNote("")
            // if(activeInteraction && activeInteraction?.state === "INT_NO_SHOW"){
                dispatch({
                    type: "INT_NO_SHOW_COMPLETE",
                    payload: {
                        activeInteractionId: interactionId,
                        message,
                    },
                })
            
            // }
            // dispatch({
            //     type: "INT_NO_SHOW_COMPLETE",
            //     payload: {
            //         activeInteractionId: interactionId,
            //         message,
            //     },
            // })
            // setIsApologyMessageModalOpen(false);
        }
    }   
    
    
    const handleClose = () => {
        // setIsApologyMessageModalOpen(false);
        // sendMessage();
        if (user?.roles.includes(UserType.STUDENT)) {
            // dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.STUD_APPOLOGY_MODAL_CLOSE,
                payload: null,
            };
            dispatch({
                type: "APPOLOGY_MODAL_CLOSE",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            // dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.PROF_APPOLOGY_MODAL_CLOSE,
                payload: null,
            };
            dispatch({
                type: "APPOLOGY_MODAL_CLOSE",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
        }
    }

    return (
        <StyledWrapper>
            <Dialog
                fullWidth
                className="post-interaction-background bubble"
                maxWidth="sm"
                open={isApologyMessageModalOpen}
                onClose={() => setIsApologyMessageModalOpen(true)}
            >
                <Grid container justifyContent="flex-end">
                    <Button variant="text" color="primary" onClick={handleClose} style={{ textTransform: "none" }}>
                        <Grid direction="column" >
                            <Grid item>Close</Grid>
                        </Grid>
                    </Button>
                </Grid>
                <DialogTitle className="px-4 pt-0 pb-0">
                    <Grid className=" text-center f-23 font-family-nunito">
                        It is really important to attend scheduled interactions
                    </Grid>
                </DialogTitle>
                {user?.roles.includes(UserType.STUDENT) ? (
                    <DialogContent>
                        <Grid className ="text-center f-18 mt-2">
                            We understand that plans can change last minute, but it is really important that you honour commitments that are made on the platform and turn up promptly to interactions that are scheduled with our professional volunteers. Remember, you have the option to reschedule interactions if the time no longer works for you.
                        </Grid>
                        <Grid className ="text-center f-18 mt-3">
                            We encourage you to send your apologies to the professional below. If you would like to arrange a new interaction, please do so via the professional's profile.
                        </Grid>
                    </DialogContent>):(
                        <DialogContent>
                        <Grid className ="text-center f-18 mt-2">
                            We understand that plans can change last minute, but we really encourage you to inform the student in advance if you can no longer attend an interaction that you have scheduled. Remember, you have the option to reschedule interaction via the platform if the time no longer works for you.
                        </Grid>
                        <Grid className ="text-center f-18 mt-3">
                            If you would like to send your apologies to the student, you can do so below.
                        </Grid>
                    </DialogContent>
                )}
                <DialogContent>
                    <TextField
                        multiline
                        fullWidth
                        onChange={(e) => setAppologyNote(e.target.value)}
                        value={appologyNote}
                        rows="4"
                        variant="filled"
                        className="request-input background-white-smoke request-modal-input"
                        placeholder="Write your message here."
                        InputProps={{ disableUnderline: true }}
                    />
                </DialogContent>
                <DialogContent>
                        <Grid className="text-center mb-2">
                            {/* <Button disabled={appologyNote?.length === 0} variant="text" onClick={sendMessage} className="btn-primary">
                                <Grid direction="column">
                                    <Grid item>Submit</Grid>
                                </Grid>
                            </Button> */}
                            <Button disabled={appologyNote.trim() ===""} className="submit-btn" variant="contained" color="primary"  onClick={sendMessage} style={{fontWeight:"bold", color:"white", fontFamily:"Nunito Sans", backgroundColor: "#3f51b5 !important"}}> Submit </Button>

                        </Grid>
                </DialogContent>
            </Dialog>
        </StyledWrapper>
    );
};

export default ApologyMessageModal;

const StyledWrapper = styled.div`
  .interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }

  .click-here {
    margin-top: 30px;
  }
  .MuiButton-label {
    display: unset !important;
  }
`;
