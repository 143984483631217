import { Message} from "common/types";

type Props = {
  message: Message;
  interactionType: string;
};

const InteractionComplete = ({ message, interactionType }: Props) => {

  if(message.sender === "SYSTEM") return null;
  return (
    <div className="color-gulf-blue body-17 font-weight-bolder">
      Interaction Complete
    </div>
  );
};

export default InteractionComplete;
