import { useState } from "react";
import { Grid, AppBar, Toolbar, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from "common/assets/images/logo.svg";
import { User, UserType } from "common/types";
import Avatar from "@mui/material/Avatar";
import avatar from "common/assets/images/avatar.svg";
import { selectAuthUser, selectUser } from "features/auth/selectors";
import { selectInteractions } from "features/interactions/selectors";
import {
  setPromptModal,
} from "features/auth/slice";
import { sendSeachForProfessionalEvent } from "components/home/utils";
import recordEvent from "common/utils/mixpanel";


const Header = () => {
  const dispatch = useDispatch();
  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>();
  const user: User | null = useSelector(selectUser);
  const authUser = useSelector(selectAuthUser);
  const interactions = useSelector(selectInteractions)

  const handleClickOnTestimonial = () => {
    dispatch(
      setPromptModal({
        isOpen: true,
        ModalType: "ADD_TESTIMONIAL",
      })
    );
  };

  if (!user) return null;
  return (
    <StyledWrapper>
      {user && (
        <AppBar position="static" color="transparent" className="nav">
          <Toolbar className="toolbar background-gulf-blue">
            <Grid container spacing={4} className="grid-container">
              <Grid item sm={3} md={3}>
                <Link
                  to="/"
                  onClick={() =>
                    recordEvent("Home / Logo Click", {
                      "User Roles": user.roles,
                    })
                  }
                >
                  <img className="d-block img-fluid upreach-logo" src={Logo} alt="" />
                </Link>
              </Grid>
              <Grid item sm={8} md={8} className="nav-links router-links">
                <NavLink
                  className="ml-auto header-links"
                  exact
                  to="/"
                  activeClassName="active-link"
                  onClick={() =>
                    recordEvent("Home / Logo Click", {
                      "User Roles": user.roles,
                    })
                  }
                >
                  Home
                  </NavLink>
                    {(user.roles.includes(UserType.STUDENT) || user.roles.includes(UserType.ALUMNI)) && (
                      <NavLink
                          className="header-links"
                          exact
                          to="/professionals"
                          activeClassName="active-link"
                          onClick={() => sendSeachForProfessionalEvent(user)}
                        >
                        Search For Professionals
                      </NavLink>
                    )}
                    {(user.roles.includes(UserType.PROFESSIONAL)) && (
                      <NavLink
                        className="header-links"
                        exact
                        to="/professionalResources"
                        activeClassName="active-link"
                      >
                        Resources
                      </NavLink>
                    )}
                    {(user.roles.includes(UserType.STUDENT) || user.roles.includes(UserType.ALUMNI)) && (
                      <div className="dropdown header-links ms-5 cursor-pointer">
                          RESOURCES
                          <div className="dropdown-content pt-4 text-left">
                        <NavLink
                            className="header-links ms-0 mb-3"
                            activeClassName="active-link"
                            exact
                            to="/userGuides"
                          >
                          USER GUIDES
                        </NavLink>

                        <NavLink
                            className="header-links ms-0"
                            activeClassName="active-link"
                            exact
                            to="/employerOverview"
                          >
                          EMPLOYER OVERVIEWS
                        </NavLink>
                      </div>
                        </div>
                    )}
                    {(user.roles.includes(UserType.STUDENT) || user.roles.includes(UserType.ALUMNI) || user.roles.includes(UserType.PROFESSIONAL)) && (
                      <NavLink
                        className="header-links"
                        exact
                        to="/contactUs"
                        activeClassName="active-link"
                      >
                        Contact Us
                      </NavLink>
                    )}
                    {(user.roles.includes(UserType.STUDENT) || user.roles.includes(UserType.ALUMNI)) &&  (interactions.length >= 10) && (
                      <NavLink
                          className="header-links"
                          to=""
                          onClick={handleClickOnTestimonial}
                        >
                        Add Testimonial
                      </NavLink>
                    )}
              </Grid>
              {user && (
                <Grid item sm={1} md={1} className="nav-links">
                  <Avatar
                    onMouseEnter={() => setProfileDropdownOpen(true)}
                    onMouseLeave={() => setProfileDropdownOpen(false)}
                    className="cursor-pointer avatar-white mx-auto"
                    alt=""
                    src={user.profilePicture || avatar}
                  />
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      {profileDropdownOpen && user && (
        <div
          onMouseEnter={() => {
            setProfileDropdownOpen(true);
            recordEvent("Account Click", {
              "User Roles": user.roles,
              "Edited Profile": false,
              "Logout Clicked": false,
            });
          }}
          onMouseLeave={() => setProfileDropdownOpen(false)}
          className="dropdown-padding"
        >
          <div className="profile-dropdown">
            <Link className="profile-button" to={`/users/${authUser?.id}`}>
              <Button
                className="logout-button box-height font-family-nunito border-bottom"
                color="inherit"
              >
                My Profile
              </Button>
            </Link>
            {!user.roles.includes(UserType.PROFESSIONAL) &&
              <Link className="profile-button" to="/bookmarks">
                <Button
                  className="logout-button box-height font-family-nunito border-bottom"
                  color="inherit"
                >
                  My Bookmarks
                </Button>
              </Link>}
            <Button
              className="logout-button box-height font-family-nunito"
              color="inherit"
              onClick={() => {
                dispatch({ type: "LOGOUT_ATTEMPT", payload: null });
                setProfileDropdownOpen(!profileDropdownOpen);
                recordEvent("Account Click", {
                  "User Roles": user.roles,
                  "Edited Profile": false,
                  "Logout Clicked": true,
                });
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  color: white;
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #304563;
    min-width: 202px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .profile-button {
    text-decoration: none;
  }
  .nav-links {
    a {
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      &:focus {
        color: white;
      }

      margin-left: 4rem;

      @media (max-width: 650px) {
        margin-left: 3rem;
      }
    }

    .active-link {
      border-bottom: 2px solid white;
    }
  }

  .header-links {
    display: inline-block;
    line-height: unset;
  }

  .header-links:after {
    display: block;
    content: "";
    border-bottom: solid 1px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .header-links:hover:after {
    transform: scaleX(1);
  }

  .user-email {
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logout-button {
    font-weight: bold;
    width: 100%;
    background-color: white;
    border-radius: 0;
    color: black;
  }

  .logout-button:hover {
    background-color: #dee1ff;
  }
  .box-height {
    min-height: 50px;
  }
  img {
    padding: 0.5rem;
    max-width: 200px;
  }

  .toolbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .profile-dropdown {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #c4c4c4;
  }

  .dropdown-padding {
    padding-top: 30px;
    position: absolute;
    max-width: 300px;
    min-width: 250px;
    width: 100%;
    top: 40px;
    right: 20px;
    z-index: 1;
  }

  .avatar-white {
    border: 1px solid white;
  }
  .border-bottom {
    border-bottom: 1px solid #c4c4c4;
  }

  .router-links {
    padding-left: 50px;
  }
  .sub-m {
    white-space: nowrap !important;
  }
  .ml-0 {
    margin-left:0 !important;
  }
  .grid-container{
    align-items:center;
  }
`;

export default Header;
