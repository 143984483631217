import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import TextField, { InputType } from "../TextField";
import { useDispatch, useSelector } from "react-redux";
import { useForm, ValidationError } from "@formspree/react";
import { setPromptModal } from "features/auth/slice";
import { selectUser } from "features/auth/selectors";
import { useEffect, useState } from "react";
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
import { getAxiosInstance } from "common/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "800px",
      margin: "auto",
      [useTheme().breakpoints.down('xl')]: {
        width: "700px",
      },
      [useTheme().breakpoints.down('lg')]: {
        width: "400px",
      },
      [useTheme().breakpoints.down('sm')]: {
        width: "320px",
      },
    },
    paper: {
      position: "relative",
      backgroundColor: useTheme().palette.background.paper,
      boxShadow: useTheme().shadows[5],
      padding: "30px 90px 60px 90px",
      width: "800px",
      [useTheme().breakpoints.down('xl')]: {
        padding: "25px 60px 45px 60px",
      },
      [useTheme().breakpoints.down('lg')]: {
        padding: "22.5px 60px 40px 60px",
      },
      [useTheme().breakpoints.down('sm')]: {
        padding: "20px 45px 35px 45px",
      },
    },
    closeButton: {
      position: "absolute",
      color: "#464FD0",
      top: "12.5px",
      right: "2.5px",
      padding: 0,
    },
    buttonsDiv: {
      display: "flex",
      justifyContent: "flex-start",
      padding: "15px 10px",
    },
    subtext: {
      fontFamily: "Nunito Sans",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "20px",
      margin: "10px 0",
      padding: "0 10px",
    },
    button: {
      fontFamily: "Nunito Sans",
      textTransform: "none",
      "&:not(:last-child)": {
        marginRight: "10px",
      },
    },
    expandableDiv: {
      margin: "20px 0",
    },
    submitButton: {
      marginTop: "20px",
    },
  })
);

const OfferModal = () => {
  const dispatch = useDispatch();
  const user: any = useSelector(selectUser);
  const classes = useStyles();

  const [expandModal, setExpandModal] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [professionalName, setProfessionalName] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");

  const [form, handleFormSubmit] = useForm("xgedaenr", {
    data: {
      userId: JSON.stringify(user?.id),
      userName: JSON.stringify(`${user?.firstName} ${user?.lastName}`),
    },
  });

  const sendOfferCelebrationEmails = async () => {
    const axios = await getAxiosInstance();

    try {
      await axios.post("/tasks/sendOfferCelebrationMail/", {
        professionalName: professionalName,
        employerName: companyName,
        role: jobTitle,
        associateFirstName: user?.firstName,
        userId: user?.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (form.succeeded) {
      sendOfferCelebrationEmails();
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: "Details submitted successfully",
      };
      dispatch(showToast(toast));
      dispatch(setPromptModal({ isOpen: false, ModalType: null }));
    }
    // eslint-disable-next-line
  }, [form]);

  const handleButtonsClick: any = (value: any) => {
    const actions: any = {
      close: () =>
        dispatch(setPromptModal({ isOpen: false, ModalType: "OFFER_MODAL" })),
      yes: () => setExpandModal(true),
      no: () =>
        dispatch(setPromptModal({ isOpen: false, ModalType: "OFFER_MODAL" })),
    };

    return actions[value]();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.paper}>
          <Button
            onClick={() => handleButtonsClick("close")}
            className={classes.closeButton}
            color="primary"
          >
            <CloseIcon />
          </Button>
          <p className={classes.subtext}>
            {expandModal
              ? "Please enter the following details"
              : "Has Social Mobility Network helped you receive an offer ?"}
          </p>
          {!expandModal && (
            <div className={classes.buttonsDiv}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                onClick={() => handleButtonsClick("yes")}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                type="submit"
                onClick={() => handleButtonsClick("no")}
              >
                No
              </Button>
            </div>
          )}
          {expandModal && (
            <form className={classes.expandableDiv} onSubmit={handleFormSubmit}>
              <TextField
                name="jobTitle"
                type={InputType.TEXT}
                label={`Enter your Job Title`}
                value={jobTitle}
                handleChange={(e: any) => setJobTitle(e.target.value)}
              />
              <ValidationError
                prefix="jobTitle"
                field="jobTitle"
                errors={form.errors}
              />
              <br />
              <br />
              <TextField
                name="company"
                type={InputType.TEXT}
                label={`Enter the name of company`}
                value={companyName}
                handleChange={(e: any) => setCompanyName(e.target.value)}
              />
              <ValidationError
                prefix="Company"
                field="Company"
                errors={form.errors}
              />
              <br />
              <br />
              <TextField
                name="professional"
                type={InputType.TEXT}
                label={`Enter the name of Professional that helped you get this offer`}
                value={professionalName}
                handleChange={(e: any) => setProfessionalName(e.target.value)}
              />
              <ValidationError
                prefix="Professional"
                field="Company"
                errors={form.errors}
              />
              <br />
              <br />
              <TextField
                name="feedback"
                type={InputType.TEXT}
                label={`Enter your Feedback`}
                value={feedback}
                handleChange={(e: any) => setFeedback(e.target.value)}
                multiline
              />
              <ValidationError
                prefix="feedback"
                field="feedback"
                errors={form.errors}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default OfferModal;
