import styled from "styled-components";
import { useEffect, useState } from "react";

type Props = {
  handleToggle: any;
  checked: any;
  className?: string;
};

export default function ToggleButton({
  handleToggle,
  checked,
  className,
}: Props) {
  const [showToggleMessage, setShowToggleMessage] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowToggleMessage(false);
    }, 3000);
  }, [checked]);

  const handleToggleChange = () => {
    setShowToggleMessage(true);
    handleToggle(!checked);
  };

  return (
    <StyledWrapper>
      <div className="toggle-component">
        <div className={className}>
          <label className="switch">
            <input
              onChange={handleToggleChange}
              checked={checked}
              type="checkbox"
              id="togBtn"
            />
            <div className={`slider round ${checked && "slider-on"}`}></div>
          </label>
        </div>
        {showToggleMessage && (
          <small className="mx-3">
            {checked && <span className="text-success">Visible on profile</span>}
            {!checked && <span className="text-danger">Hidden from profile</span>}
          </small>
        )}
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  // css for toggle switch
  margin-left: 10px;

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
  }

  .toggle-component {
    display: flex;
    align-items: center;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c4c4c4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider-on:before {
    background-color: #464fd0 !important;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: -1px;
    bottom: -2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 2px 3px 13px 1px rgba(0, 0, 0, 0.4);
  }

  input:checked + .slider {
    background-color: #9ea5ee;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(41px);
    -ms-transform: translateX(41px);
    transform: translateX(41px);
  }

  /*------ ADDED CSS ---------*/

  .on {
    display: none;
  }

  .toggle-component {
    display: flex;
    align-items: center;
  }

  .on,
  .off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 25%;
    left: 25%;
    font-size: 10px;
    font-family: Nunito Sans;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0px;
  }

  .on {
    top: 10px;
    left: 22px;
    color: #ffffff;
  }

  .off {
    left: auto;
    right: -4px;
    top: 10px;
    color: black;
  }

  input:checked + .slider .on {
    display: block;
  }

  input:checked + .slider .off {
    display: none;
  }

  /*--------- END --------*/

  /* Rounded sliders */

  .slider.round {
    border-radius: 17px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
