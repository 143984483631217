import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "features/auth/selectors";
import { UserType } from "common/types";
import { setPromptModal } from "features/auth/slice";

type Props = {
    message?: string;
    handleClose?: any;
}

const Banner = ({ handleClose }: Props) => {
    const user: any = useSelector(selectUser);
    const dispatch = useDispatch();

    const handleButtonClick = () => {
        dispatch(setPromptModal({ isOpen: true, ModalType: "ABOUT_ALUMNI" }))
    };

    return (
        <StyledWrapper>
            <div className="banner-container">
                {user.roles.includes(UserType.STUDENT) ?
                    <p className="body-16 text-center">Remember to update your applications in the Application Tracker on <a className="font-weight-bold" href="https://my.upreach.org.uk/" target="_blank" rel="noreferrer">my.upReach</a></p> :
                    <p className="body-16 text-center">upReach Alumni now have access to the Network and request an interaction with you. <Button onClick={handleButtonClick} color="primary" variant="text">Find out more.</Button></p>
                }
                <IconButton onClick={handleClose} className="close-btn" color="primary" size="large">
                    <HighlightOffIcon />
                </IconButton>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    padding-left: 80px;
    padding-right: 80px;

    .banner-container {
        display: flex;
        justify-content: center;
    }

    .close-btn {
        position: absolute;
        right: 20px;
        top: 5px;
    }

    .close-btn:hover {
        cursor: pointer;
    }
`;

export default Banner;
