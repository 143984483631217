export enum UserType {
  STUDENT = "STUDENT",
  PROFESSIONAL = "PROFESSIONAL",
  EMPLOYER = "EMPLOYER",
  UPREACH_ADMIN = "UPREACH_ADMIN",
  UNIVERSITY_ADMIN = "UNIVERSITY_ADMIN",
  ALUMNI = "ALUMNI",
}

export enum GenderChoices {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export enum EmailChoices {
  WORK_EMAIL = "WORK_EMAIL",
  PERSONAL_EMAIL = "PERSONAL_EMAIL",
  UNIVERSITY_EMAIL = "UNIVERSITY_EMAIL",
}

export enum AvailabilityStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export type DegreeType = {
  id: string;
  name: string;
};

// TODO - make name singular
export type DisadvantageIndicators = {
  id: string;
  name: string;
};

export type EmploymentHistoryItem = {
  company: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
};

export type University = {
  id: string;
  name: string;
  logoUrl?: string;
};

export type UniversityCourse = {
  id: string;
  name: string;
};

export type CareerInterest = {
  id: string;
  name: string;
};

export type CareerStream = {
  id: string;
  name: string;
};

export type Company = {
  id: string;
  name: string;
  logoUrl?: string;
};

export type Sector = {
  id: string;
  name: string;
};

export type Ethnicity = {
  id: string;
  name: string;
};

export type CourseCategoryType = {
  id: string;
  name: string;
};

export type Region = {
  id: string;
  name: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  preferredEmail: string;
  personalEmail?: string;
  roles: UserType[];
  gender: GenderChoices;
  region?: Region;
  ethnicity?: Ethnicity;
  bio?: string;
  profilePicture?: string;
  metadata?: any;
  linkedinUrl?: string;
  bookmarks?: Array<string>;
  createdAt?: Date;
  isProfessional?: boolean;
};

export type Student = User & {
  dateOfBirth: string;
  phoneNumber?: string;
  associateStatus: string;
  university: Pick<University, "id" | "name">;
  universityCourse: UniversityCourse;
  degreeType: DegreeType;
  degreeTypeOther?: string;
  graduationYear?: string;
  disadvantageIndicators?: Array<DisadvantageIndicators>;
  careerInterests: Array<CareerInterest>;
  careerStream: CareerStream;
  programCoordinatorId?: string;
  programType: string;
  aLevelGrades: string;
  realGrades: string;
  courseCategory: CourseCategoryType;
  yearOfStudy?: number;
  gefResult: string;
  householdIncome?: string;
  disability?: boolean;
};

export type Professional = User & {
  jobTitle: string;
  currentEmploymentStartDate: string;
  company: Pick<Company, "id" | "name">;
  sectors: Array<Pick<Sector, "id" | "name">>;
  preferredVideoConferencing: string;
  participatingInteractionTypes: Array<InteractionType>;
  attendedUniversity: boolean;
  university?: Pick<University, "id" | "name">;
  universityCourse?: Pick<UniversityCourse, "id" | "name">;
  degreeType?: DegreeType;
  degreeTypeOther?: string;
  graduationYear?: string;
  yearJoined?: string;
  employmentHistory?: Array<EmploymentHistoryItem>;
  isDisabled?: boolean;
};

export type Alumni = User & Omit<Professional, 'company'> & {
  company: string;
  hasGraduateJob: boolean;
  associateStatus: string;
}

export type AuthorizedProfessional = {
  email: string;
  firstName: string;
  lastName: string;
  roles: [UserType.PROFESSIONAL];
  company: Company;
};

export const shapeToBasicUserDetails = (data: any): BasicUserDetails => {
  const {
    id,
    firstName,
    lastName,
    roles,
    email,
    preferredEmail,
    profilePicture,
  } = data;
  if ((data as BasicProfessionalDetails).company === undefined) {
    const university = data.university;
    return {
      id,
      firstName,
      lastName,
      roles,
      email,
      preferredEmail,
      university,
      profilePicture: profilePicture ? profilePicture : "",
    };
  } else {
    const company = data.company;
    return {
      id,
      firstName,
      lastName,
      roles,
      email,
      preferredEmail,
      company,
      profilePicture: profilePicture ? profilePicture : "",
    };
  }
};

export type BasicStudentDetails = Pick<
  Student,
  | "id"
  | "firstName"
  | "lastName"
  | "roles"
  | "email"
  | "preferredEmail"
  | "university"
  | "profilePicture"
>;

export type BasicProfessionalDetails = Pick<
  Professional,
  | "id"
  | "firstName"
  | "lastName"
  | "roles"
  | "email"
  | "preferredEmail"
  | "company"
  | "profilePicture"
  | "isDisabled"
>;

export type BasicAlumniDetails = Pick<
  Alumni,
  | "id"
  | "firstName"
  | "lastName"
  | "roles"
  | "email"
  | "preferredEmail"
  | "company"
  | "profilePicture"
  | "university"
  | "isProfessional"
>;

export type BasicUserDetails = BasicStudentDetails | BasicProfessionalDetails | BasicAlumniDetails;

export const isProfessional = (user: Student | Professional): user is Professional =>
  (user as Professional).jobTitle !== undefined;

// export const shapeToProfessional = (data: any): Professional => {

//   let professional: Professional;

//   const company: Company = {
//     id: data.company.id,
//     name: data.company.name,
//   }

//   const sectors: Array<Sector> = data.sectors.map((sector: any) => {
//     const s: Sector = {
//       id: sector.id,
//       name: sector.name
//     }
//     return s;
//   });

//   if(data.attendedUniversity) {
//     if(data.university) {
//       const university:
//     }
//   }

//   professional = {
//     id: data.id,
//     firstName: data.firstName,
//     lastName: data.lastName,
//     email: data.email,
//     preferredEmail: data.preferredEmail,
//     preferredName: data.preferredName,
//     company:
//   }
//   return professional;
// }

export enum ConnectionState {
  PENDING = "PENDING",
  CONNECTED = "CONNECTED",
}

export type Connection = {
  id: string;
  state: ConnectionState;
  users: Array<string>;
  sender: User;
  receiver: User;
};

export enum InteractionType {
  EXPERT_ADVICE = "EXPERT_ADVICE",
  CAREER_CHAT = "CAREER_CHAT",
  MOCK_INTERVIEW = "MOCK_INTERVIEW",
  MENTORING = "MENTORING",
}

export type ConnectionProfile = {
  [index: string]: BasicUserDetails;
};

export type Interaction = {
  id: string;
  type: InteractionType;
  state: InteractionState;
  thread: Array<Message>;
  users: Array<string>;
  userKey: string;
  connectionProfile: ConnectionProfile;
  isAttendanceConfirmed?: boolean;
  suggestedTimes?: Array<Date>;
  scheduledFor?: Date;
  callDetails?: string;
  feedbackGiven?: any;
  feedbackDontGiven?: any;
  createdAt: Date;
  updatedAt: Date;
  remindMeLater?: any;
  dontRemindMeLater?: any;
  studentNoShow?:boolean;
  professionalNoShow?:boolean;
  rating?:any;
  // close sorry modal
  sorryModalClose?: any; 
  // appologymessage
  appologyMessage?: any;
  //Unable to Attend
  studentUnableToAttend: any;
  professionalUnableToAttend: any;
  //appologymodalclose
  appologyModalClose? : any;
};

export const shapeToInteraction = (data: any): Interaction => {
  const thread: Array<Message> = data.thread.map((message: any) => ({
    ...message,
    timestamp: message.timestamp.toDate(),
  }));

  return {
    id: data.id,
    type: data.type,
    state: data.state,
    thread: thread,
    suggestedTimes: data.suggestedTimes,
    users: data.users,
    userKey: data.userKey,
    connectionProfile: data.connectionProfile,
    scheduledFor: data.scheduledFor,
    createdAt: data.createdAt.toDate(),
    updatedAt: data.updatedAt.toDate(),
    callDetails: data.callDetails,
    feedbackGiven: data.feedbackGiven,
    feedbackDontGiven: data.feedbackDontGiven,
    isAttendanceConfirmed: data.isAttendanceConfirmed ? true : false,
    remindMeLater: data.remindMeLater,
    dontRemindMeLater: data.dontRemindMeLater,
    studentNoShow: data.studentNoShow ? true: false,
    professionalNoShow: data.professionalNoShow ? true: false,
    rating: data.rating,
     // Unable to Attend
     studentUnableToAttend: data.studentNoShow,
     professionalUnableToAttend: data.professionalNoShow,
    // close sorry modal
    sorryModalClose: data.sorryModalClose,
    // appologymessage
    appologyMessage: data.appologyMessage,
    //appologymodalclose
    appologyModalClose: data.appologyModalClose,
  };
};

export enum InteractionState {
  REQUESTED = "REQUESTED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  SCHEDULING_TIMES_REQUESTED = "SCHEDULING_TIMES_REQUESTED",
  SCHEDULING_TIMES_SUGGESTED = "SCHEDULING_TIMES_SUGGESTED",
  SCHEDULING_ADDITIONAL_TIMES_REQUESTED = "SCHEDULING_ADDITIONAL_TIMES_REQUESTED",
  SCHEDULED = "SCHEDULED",
  CALL_DETAILS_SET = "CALL_DETAILS_SET",
  RESCHEDULE_REQUESTED = "RESCHEDULE_REQUESTED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",

  // created new for interactioncomplete
  INTERACTIONCOMPLETED = "INTERACTIONCOMPLETED",

  // not over
  INTERACTION_NOT_OVER = "INTERACTION_NOT_OVER",

  INT_NO_SHOW = "INT_NO_SHOW",
  INT_NO_SHOW_COMPLETE = "INT_NO_SHOW_COMPLETE",
  INT_NO_SHOW_SORRY_COMPLETE = "INT_NO_SHOW_SORRY_COMPLETE",
  // Close sorry modal
  SORRY_MODAL_CLOSE = "SORRY_MODAL_CLOSE",
  //AppologyModal Close
  APPOLOGY_MODAL_CLOSE = "APPOLOGY_MODAL_CLOSE"

}

export enum MessageType {
  REQUEST = "REQUEST",
  ACCEPT = "ACCEPT",
  DECLINE = "DECLINE",
  TEXT = "TEXT",
  SUGGEST_TIMES = "SUGGEST_TIMES",
  SELECT_TIME = "SELECT_TIME",
  REQUEST_ADDITIONAL_TIMES = "REQUEST_ADDITIONAL_TIMES",
  SET_CALL_DETAILS = "SET_CALL_DETAILS",
  SET_CALL_DETAILS_WITHOUT_LINK = "SET_CALL_DETAILS_WITHOUT_LINK",
  LIKE = "LIKE",
  NO_SHOW = "NO_SHOW",
  THANKS_NOTE = "THANKS_NOTE",
  FEEDBACK_NOTE = "FEEDBACK_NOTE",
  RESCHEDULE_REQUEST = "RESCHEDULE_REQUEST",
  RESCHEDULE_ACCEPT = "RESCHEDULE_ACCEPT",
  RESCHEDULE_DECLINE = "RESCHEDULE_DECLINE",
  RESCHEDULE = "RESCHEDULE",
  SET_COMPLETED = "SET_COMPLETED",
  SET_EXPIRED = "SET_EXPIRED",
  EXPECTED_INTERVIEW_DATE = "EXPECTED_INTERVIEW_DATE",
  STUD_RATING = "STUD_RATING",
  PROF_RATING = "PROF_RATING",
  FEEDBACK_DONT_SEND = "FEEDBACK_DONT_SEND",
  THANKS_DONT_SEND = "THANKS_DONT_SEND",
  THANKS_REMIND = "THANKS_REMIND",
  FEEDBACK_REMIND = "FEEDBACK_REMIND",
  // testing
  SET_INTERACTIONCOMPLETED = "SET_INTERACTIONCOMPLETED",
  // not over
  SET_INTERACTION_NOT_OVER = "SET_INTERACTION_NOT_OVER",

  // Create New for Career Chat
  SET_INT_NO_SHOW = "SET_INT_NO_SHOW",
  STUDENT_NO_SHOW = "STUDENT_NO_SHOW",
  PROFESSIONAL_NO_SHOW = "PROFESSIONAL_NO_SHOW",
  SET_INT_NO_SHOW_COMPLETE = "SET_INT_NO_SHOW_COMPLETE",

  // created for appologyMessageModal
  STUD_APPOLOGY = "STUD_APPOLOGY",
  PROF_APPOLOGY = "PROF_APPOLOGY",

  // SorryModal close 
  STUD_SORRY_MODAL_CLOSE = "STUD_SORRY_MODAL_CLOSE",
  PROF_SORRY_MODAL_CLOSE = "PROF_SORRY_MODAL_CLOSE",
  SET_SORRY_MODAL_CLOSE = "SET_SORRY_MODAL_CLOSE",
  //AppologyModal Close
  STUD_APPOLOGY_MODAL_CLOSE = "STUD_APPOLOGY_MODAL_CLOSE",
  PROF_APPOLOGY_MODAL_CLOSE = "PROF_APPOLOGY_MODAL_CLOSE",
  SET_APPOLOGY_MODAL_CLOSE = "SET_APPOLOGY_MODAL_CLOSE",
}

export type Message = {
  id: string;
  type: MessageType;
  sender: BasicUserDetails | "SYSTEM";
  payload: any;
  timestamp: Date;
};
