import { useState, useEffect } from "react";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import StepperControls from "common/components/StepperControls";
import { InputType } from "common/components/TextField";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import {
  goToPrevStep,
  setUniversityDetails,
  goToNextStep,
  setIsNextStep,
} from "./professionalRegistrationSlice";
import { useForm } from "react-hook-form";
import DocumentPicker from "../../../common/components/DocumentPicker";
import { selectIsNextStep, selectUniversityDetails } from "./selectors";
import { selectUser } from "features/auth/selectors";
import styled from "@emotion/styled";

type Props = {
  isMyAccount?: boolean;
}

const UniversityDetails = ({ isMyAccount }: Props) => {
  const universityDetails = useSelector(selectUniversityDetails);
  const isNextStep = useSelector(selectIsNextStep);
  const dispatch = useDispatch();

  const [attendedUniversity, setAttendedUniversity] = useState<boolean>(true);
  const [selectedUniversity, setSelectedUniversity] = useState<any>();
  const [selectedCourse, setSelectedCourse] = useState<any | null>();
  const [selectedDegreeType, setSelectedDegreeType] = useState<any | null>();
  const user: any = useSelector(selectUser);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: isMyAccount ? { graduationYear: user?.graduationYear, attendedUniversity: user?.attendedUniversity } : universityDetails,
  });

  useEffect(() => {
    if(isMyAccount) {
      setSelectedUniversity(user?.university);
      setSelectedCourse(user?.universityCourse);
      setSelectedDegreeType(user?.degreeType); 
      setAttendedUniversity(user?.attendedUniversity);
    }
    else {
      setSelectedUniversity(universityDetails.university);
      setSelectedCourse(universityDetails.universityCourse);
      setSelectedDegreeType(universityDetails.degreeType); 
      setAttendedUniversity(universityDetails.attendedUniversity);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!attendedUniversity) {
      setSelectedUniversity(null);
      setSelectedCourse(null);
      setSelectedDegreeType(null); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendedUniversity]);

  const onSubmit = (data: any) => {
    if(attendedUniversity) {
      data.university = selectedUniversity;
      data.universityCourse = selectedCourse;
      data.degreeType = selectedDegreeType;
    }
    if(!attendedUniversity) {
      data.university = null;
      data.universityCourse = null;
      data.degreeType = null;
      data.graduationYear = null
    }

    dispatch(setUniversityDetails(data));
    if(isNextStep) {
      dispatch(goToNextStep());
    }
    if(!isNextStep) {
      dispatch(goToPrevStep())
    }
  };

  const handleUniversityChange = (university: any) =>
    setSelectedUniversity(university);

  const handleCourseChange = (course: any) => setSelectedCourse(course);
  const handleDegreeType = (degree: any) => setSelectedDegreeType(degree);

  return (
    <StyledForm
      className="registration-box-shadow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container item spacing={4} xs={12}>
        <Grid item xs={12}>
          <h3 className="prof-registration-heading mb-1">
          Please add your university details below.
          </h3>
        {/* </Grid>
        <Grid item xs={12}> */}
          <p className="body-16 font-weight-normal mt-4 mb-3">If you did not attend university, please deselect the box below and press 'Next'.</p>
        {/* </Grid>
        <Grid item xs={12}> */}
          <FormControlLabel
            control={
              <Checkbox
                inputRef={register}
                checked={attendedUniversity}
                onClick={() => setAttendedUniversity(!attendedUniversity)}
                name="attendedUniversity"
              />
            }
            label="Do you have a university degree?"
          />
        </Grid>
      </Grid>
      {attendedUniversity && (
        <>
          <Grid container spacing={4} item lg={12}>
            <Grid item xs={12} lg={6} className="req-ast">
              <DocumentPicker
                handleChange={handleUniversityChange}
                collection="universities"
                name="university"
                inputRef={register({ required: attendedUniversity })}
                error={errors.university}
                defaultValue={selectedUniversity}
                label="University"
              />
            </Grid>
            <Grid item xs={12} lg={6} className="req-ast">
              <DocumentPicker
                handleChange={handleCourseChange}
                collection="courses"
                error={errors.universityCourse}
                name="universityCourse"
                inputRef={register({ required: attendedUniversity })}
                // required={!!selectedUniversity}
                defaultValue={selectedCourse}
                label="University Course"
              />
            </Grid>

            <Grid item xs={12} lg={6} className="req-ast">
              <DocumentPicker
                handleChange={handleDegreeType}
                collection="degree-types"
                error={errors.degreeType}
                name="degreeType"
                inputRef={register({ required: attendedUniversity })}
                // required={!!selectedUniversity}
                defaultValue={selectedDegreeType}
                label="Degree Type"
              />
            </Grid>

            <Grid item xs={12} lg={6} className="req-ast">
              <TextField
                inputRef={register({
                  minLength: 4,
                  maxLength: 4,
                  max: 2023,
                  min: 1950,
                  validate: {
                    required: (value) => {
                      if (!value && selectedUniversity.id !== "")
                        return "This field is required.";
                      return true;
                    },
                  },
                })}
                name="graduationYear"
                type={InputType.NUMBER}
                variant="outlined"
                fullWidth
                label="Graduation Year"
              />
              {errors && errors.graduationYear && (
                <>
                  <p className="rhf-errors">
                    {(errors.graduationYear as any).message}
                  </p>
                  {errors.graduationYear.type === "maxLength" && (
                    <span className="rhf-errors">
                      Please enter your 4 digit graduation year.
                    </span>
                  )}
                  {errors.graduationYear.type === "minLength" && (
                    <span className="rhf-errors">
                      Please enter your 4 digit graduation year.
                    </span>
                  )}
                  {errors.graduationYear.type === "max" && (
                    <span className="rhf-errors">
                      Graduation year should be between 1950 and 2023
                    </span>
                  )}
                  {errors.graduationYear.type === "min" && (
                    <span className="rhf-errors">
                      Graduation year should be between 1950 and 2023
                    </span>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <StepperControls
        nextButtonLabel="Next"
        disabled={false}
        nextButtonCallback={() => {dispatch(setIsNextStep(true))}}
        backButtonLabel="Back"
        backButtonCallback={() => {dispatch(setIsNextStep(false))}}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 2rem;
  .MuiGrid-item {
    margin-bottom: 1rem;
    padding: 0 3rem;
  }
  .MuiFormLabel-root {
    margin-bottom: 1rem;
  }

  .outline-dropdown {
    width: 100%;
  }
`;

export default UniversityDetails;
