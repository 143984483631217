import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import logo from "../../common/assets/images/blue_logo.svg"
import network from "../../common/assets/images/network.png"
// import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import PreLoginHeader from './PreLoginHeader';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/auth/selectors';
import { useEffect } from 'react';
import styled from '@emotion/styled';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);


const Welcome = () => {

    const user = useSelector(selectUser);
    const history = useHistory();

    useEffect(() => {
        if (user && user.id) history.push("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const classes = useStyles();

    return (
        <StyledWrapper className="background-white">
            <PreLoginHeader />
            <div className={classes.root}>
                <Container>
                    <Grid container>
                        <Grid className="network-section content-center" item xs={12} md={6}>
                            <div>
                                <img className="welcome-logo-blue" src={logo} alt="" />
                                <h1 className="network-intro color-gulf-blue mt-5">The network for those without a network</h1>
                            </div>
                        </Grid>
                        <Grid className="network-section" item xs={12} md={6}>
                            <img className="img-fluid object-fit-cover" src={network} alt="" />
                        </Grid>

                    </Grid>
                </Container>
                <Grid container className="mt-spacing">
                    <Grid className="login-section background-dark-blue content-center" item xs={12} md={6}>
                        <h1 className="network-intro network-intro-mn color-white">Login to the Social Mobility Network</h1>
                    </Grid>
                    <Grid className="login-section background-light-navy" item xs={12} md={6}>
                        <div className="links mt-4">
                            <Link className="login-options body-22 color-black" to="/associates/login"><p>Student</p></Link>
                            <Link className="login-options body-22 color-black" to="/professionals/login"><p>Professional</p></Link>
                            <Link className="login-options body-22 color-black" to="/alumni/login"><p>upReach Alumni</p></Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </StyledWrapper>
    )
}

export default Welcome;


const StyledWrapper = styled.div`
    .network-section {
        height: calc(60vh - 42px);
        @media (max-width: 960px) {
            height: unset;
        }
    }
    .login-section {
        height: calc(40vh - 42px);
        @media (max-width: 960px) {
            height: unset;
        }
    }
    .network-intro {
        font-weight: 200;
        max-width: 400px;
    }
    .links {
        margin-left: 10%;
        // margin-top: 8%;
        @media (max-width: 600px) {
            margin-left: 20px;
        }
    }

    .welcome-register {
        color: white;
        a {
            color: white;
        }
    }

    .network-intro-mn {
        padding-left: 20px;
        padding-right: 20px;
    }

    .welcome-logo-blue {
        @media (max-width: 960px) {
            padding-top: 50px;
        }
    }

    .mt-spacing {
        @media (max-width: 960px) {
            margin-top: 50px;
        } 
    }
`;