import firebase from "app/firebase";
import getAxiosInstance from './axios';
import { set } from "date-fns";

const getIdToken = async (): Promise<string | undefined> => {
  try {
    const idToken:
      | string
      | undefined = await firebase.auth().currentUser?.getIdToken(true);
    return idToken;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const getStartOfMonth = (date: Date) => {
  return set(date, {
    date: 1,
    hours: 5,
    minutes: 30,
    seconds: 0,
    milliseconds: 0,
  });
};
export { getIdToken, getAxiosInstance, getStartOfMonth };