import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { format } from "date-fns";
import withStyles from '@mui/styles/withStyles';

const CustomButton = withStyles((theme) => ({
  outlined: {
    padding: "6px",
    fontSize: "14px",
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
}))(Button);

type Props = {
  value: any;
  onChange: any;
  showTimeSelect?: boolean;
  dateFormat?: string;
  placeholder?: string;
};

function DateTimePicker({
  value,
  onChange,
  showTimeSelect = true,
  dateFormat,
  placeholder,
}: Props) {
  var coeff = 1000 * 60 * 15;
  const currentDate = new Date();
  const increamentedTime = currentDate.setMinutes(currentDate.getMinutes() + 15);
  var rounded = new Date(Math.round(increamentedTime / coeff) * coeff);
  const [selectedValue, setSelectedValue] = useState(rounded);

  const CustomInputSelector = forwardRef(({ onClick }: any, ref: any) => (
    <CustomButton variant="outlined" onClick={onClick} ref={ref} color="primary" className="date-time">
      {value
        ? format(value, dateFormat ? dateFormat : "d/M/y - HH:mm - a")
        : placeholder
        ? placeholder
        : "Select date and time"}{" "}
      <InsertInvitationIcon className="calendar-icon" />
    </CustomButton>
  ));

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <DatePicker
      selected={selectedValue}
      onChange={(date: any) => {
        onChange(date);
        setSelectedValue(date);
      }}
      showTimeSelect={showTimeSelect}
      minDate={new Date()}
      filterTime={filterPassedTime}
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="d/M/y - HH:mm"
      customInput={<CustomInputSelector />}
    />
  );
}

export default DateTimePicker;
