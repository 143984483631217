import Stepper from "common/components/Stepper";
import { useSelector } from "react-redux";
import BasicDetails from "./BasicDetails";
import UniversityDetails from "./UniversityDetails";
import { Container } from "@mui/material";
import WorkDetails from "./WorkDetails";
import styled from "styled-components";
import { selectStepperActiveStep } from "./selectors";
import ProfessionalSharedExpectation from "./ProfessionalSharedExpectation";

type Props = {
  isMyAccount?: boolean;
}

const ProfessionalRegistrationForm = ({ isMyAccount = false }: Props ) => {
  const activeStep = useSelector(selectStepperActiveStep);

  const steps = [
    {
      label: "Personal Details",
      content: <BasicDetails isMyAccount={isMyAccount} />,
    },
    {
      label: "University Details",
      content: <UniversityDetails isMyAccount={isMyAccount} />,
    },
    {
      label: "Work Details",
      content: <WorkDetails isMyAccount={isMyAccount} />,
    },
    {
      label: "Shared Expectations",
      content: <ProfessionalSharedExpectation isMyAccount={isMyAccount} />,
    },
  ];

  return (
    <StyledWrapper>
    <Container>
      <Stepper steps={steps} activeStep={activeStep} />
      {steps[activeStep].content}
    </Container>
    </StyledWrapper>
  );
};

export default ProfessionalRegistrationForm;

const StyledWrapper = styled.div`
  padding-bottom: 100px;
`;