import { Checkbox, FormControlLabel } from "@mui/material";
import StepperControls from "common/components/StepperControls";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import { setIsNextStep } from "../professional/professionalRegistrationSlice";
import { selectIsNextStep } from "../professional/selectors";
import { goToPrevStep } from "./studentRegistrationSlice";
import { FaUserFriends, FaComments, FaQuestionCircle, FaSmile, FaCalendarAlt, FaInfinity } from "react-icons/fa";
import { BsFillAlarmFill } from "react-icons/bs";
import styled from "@emotion/styled";

type Props = {
  isMyAccount?: boolean;
}

const ProfessionalismCharter = ({ isMyAccount }: Props) => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const isNextStep = useSelector(selectIsNextStep);

  const onSubmit = () => {
    if (isNextStep) {
      dispatch({
        type: "STUDENT_REGISTRATION_ATTEMPT",
      });
      setIsRegister(true);
    }
    if (!isNextStep) {
      dispatch(goToPrevStep())
    }
  };

  return (
    <StyledForm className="card bg-white border border-light rounded-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body bg-transparent mx-4 my-4 mb-0">
        <div className="row d-flex align-items-center">
          <h3 className="text-primary mb-4">Shared Expectations</h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="fw-600">What you can expect...</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-auto">
            <FaUserFriends />
          </div>
          <div className="col">
            Access to <b>hundreds of professionals</b> via our exclusive networking platform
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-auto">
            <FaComments />
          </div>
          <div className="col">
            Professionals will <b>respond to your messages within 1 week</b> - if it’s been a while, let us know and we can give them a nudge
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-auto">
            <FaQuestionCircle />
          </div>
          <div className="col">
            A friendly <b>Social Mobility Network support team at upReach!</b> Get in touch via the Contact Us tab and we’ll reply within 3-4 working days
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="mt-3 fw-600">What we expect of you in return...</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-auto">
            <FaSmile />
          </div>
          <div className="col">
              Be <b>polite and respectful</b> when speaking to the professionals
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-auto">
            <BsFillAlarmFill />
          </div>
          <div className="col">
            <b>Respond to messages within 1 week</b> - we recommend replying as soon as possible
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-auto">
            <FaCalendarAlt />
          </div>
          <div className="col">
            Turn up to calls on time and if you can no longer make it, <b>let the professional know at least the day before and ask to reschedule</b>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p className="mt-3 fw-600">What we expect of all of our members...</p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-auto">
            <FaInfinity />
          </div>
          <div className="col">
            The Social Mobility Network is a safe and inclusive platform to interact and build connections, so <b>inappropriate behaviour e.g. swearing or discriminatory language, is not tolerated</b>. If members engage in this behaviour, they will be removed from the platform
          </div>
        </div>
        {/* Commenting as per the new requirment in SMN 74 */}
        {/* <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                <p>I understand that I am expected to interact with mentors in a professional manner at all times on the Social Mobility Network</p>
              </li>
              <li>
                <p>I understand that messages I send on the platform should be written as if they were an email, with an appropriate greeting and sign-off</p>
              </li>
              <li>
                <p>I confirm that I will respond to a professional as soon as possible, and I understand that interactions I do not respond to within 7 days will expire</p>
              </li>
              <li>
                <p>I understand that it is my responsibility to honour commitments made with a professional on the platform</p>
              </li>
              <li>
                <p>If for whatever reason I am unable to attend an interaction after scheduling one with a professional, I confirm that I will inform the professional as soon as possible. I understand that failure to show up to an interaction is not acceptable and that this could result in my account being restricted</p>
              </li>
              <li>
                <p>I understand that interactions are expected to be held via video-conferencing software. However, if an interaction is scheduled face-to-face, I agree to always meet the professional in a public place</p>
              </li>
              <li>
                <p>I understand that I should dress professionally for an interaction</p>
              </li>
              <li>
                <p>I agree to effectively use the mentor’s time and counsel by doing necessary research in advance</p>
              </li>
              <li>
                <p>I agree to be open and receptive to feedback and coaching</p>
              </li>
              <li>
                <p>I understand that failure to meet the above will result in me losing access to the Social Mobility Network</p>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-12">
            <FormControlLabel
              control={<Checkbox name="professionalismCharter" onClick={() => setAcceptTerms(!acceptTerms)} />}
              label="I confirm that I have read and agree to the Shared Expectations."
            />
          </div>
        </div>
      </div>
      <div className="card-footer bg-transparent my-4 d-flex justify-content-center">
        <StepperControls
          nextButtonCallback={() => { dispatch(setIsNextStep(true)) }}
          disabled={!acceptTerms}
          nextButtonLabel="Register"
          backButtonCallback={() => { dispatch(setIsNextStep(false)) }}
          backButtonLabel="Back"
          isRegister={isRegister}
        />
      </div>
    </StyledForm>
  );
};

const StyledForm = styled.form`
.MuiTypography-body1 {
  font-family: Nunito Sans, sans-serif !important;
}
`;

export default ProfessionalismCharter;