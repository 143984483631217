import GA4React from "ga-4-react";

const gaID: any = process.env.REACT_APP_GA_ID
const ga4react = new GA4React(gaID);

export const gaPageView = async () => {
    try {
        if (ga4react) {
            await ga4react?.initialize().then((ga4: any) => {
                ga4.pageview(window.location.pathname)
            })
        }
        console.log("GA_INITIALIZED...");
    } catch (error) {
        console.log("ERROR INITIALIZING GA", error);
    }
}