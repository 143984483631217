import MuiTextField from "@mui/material/TextField";

export enum InputType {
  TEXT = "text",
  PASSWORD = "password",
  NUMBER = "number",
  EMAIL = "email",
  DATE = "date"
}

type Props = {
  name: string;
  type: InputType;
  inputRef?: any; // TODO - change to correct type
  label: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  disabled?: boolean;
  handleChange?: any;
  multiline?: boolean;
  inputProp?: any;
  value?: any;
  required?: boolean;
};

// TODO - design controlled and unctrolled mode for use with hook-form/normal

const TextField = ({ name, type, required = false, label, inputRef, error, helperText, defaultValue, disabled, handleChange, inputProp, multiline = false, value }: Props) => {
  return (
    <MuiTextField
      inputRef={inputRef}
      name={name}
      type={type}
      label={label}
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={handleChange}
      multiline={multiline}
      rows={multiline ? 4 : 1}
      value={value}
      inputProps={inputProp}
      required={required}
      className='mui-placeholder'
    />
  );
};

export default TextField;
