import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
    value: any;
    onChange: any;
    showTimeSelect?: boolean;
    dateFormat?: string;
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
    required?: boolean;
    name?: string;
    inputRef?: any;
};

function MonthYearPicker({
    value,
    onChange,
    dateFormat,
    placeholder,
    minDate,
    maxDate,
    required = false,
    name,
    inputRef,
}: Props) {

    return (
        <DatePicker
            className="reactDatePicker"
            required={required}
            selected={value && new Date(value)}
            onChange={onChange}
            showMonthYearPicker
            showFullMonthYearPicker
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={placeholder}
            dateFormat="MMMM yyyy"
            name={name}
            ref={inputRef}
            autoComplete="off"
        />
    );
}

export default MonthYearPicker;
