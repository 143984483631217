import { MessageType } from './../../common/types/index';
import { setIsLoading, setPromptModal } from 'features/auth/slice';
import { InteractionType } from "./../../common/types/index";
import { eventChannel, END } from "redux-saga";
import firebase from "app/firebase";
import { call, put, select, takeLatest } from "@redux-saga/core/effects";
import { take } from "redux-saga/effects";
import { Interaction, shapeToInteraction } from "common/types";
import {
  setInteractionRequestModelOpen,
  setInteractions,
  setMessageSendInProgress,
  setIsRequestButtonDisabled,
} from "./slice";
import {
  acceptInteractionRequest,
  declineInteractionRequest,
  sendInteractionRequest,
  sendMessage,
  sendRating,
  dontSendFeedback,
  sendRemindMeLater,

  // created new for interactioncomplete
  completeInteraction,

  //not over
  interactionNotOver,

  // --------
  sendUnableToAttend,

  intNoShow,
  intNoShowComplete,

  // SorryModalClose
  sendSorryModalClose,
  //AppologyModal Close
  sendAppologyModalClose,
} from "./utils";
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
const setupInteractionListener = (userId: string): any => {
  return eventChannel((emitter): any => {
    const ref = firebase
      .firestore()
      .collection("interactions")
      .where("users", "array-contains", userId);
    const unsubscribe = ref.onSnapshot((snapshot) => {
      const interactions: Array<Interaction> = [];
      snapshot.docs.forEach((doc) => {
        const interaction = shapeToInteraction(doc.data());
        interactions.push(interaction);
      });
      emitter(interactions);
    });
    return () => {
      unsubscribe();
      emitter(END);
    };
  });
};
function* doStartInteractionListener(): any {
  yield put(setIsLoading(true));
  console.log("Attempt interaction listener start");
  const user = yield select((state) => state.auth.user);
  if (user && user.id) {
    const channel = yield call(setupInteractionListener, user.id);
    while (true) {
      let interactions = yield take(channel);
      yield put(setInteractions(interactions));
      yield put(setIsLoading(false));
    }
  }
  yield put(setIsLoading(false));
}
function* doSendMessage(action: any) {
  try {
    yield sendMessage(action.payload.message, action.payload.activeInteractionId);
    if (action.payload.message.type === MessageType.NO_SHOW)
      yield put(setPromptModal({ isOpen: false, ModalType: null }));
    if (action.payload.message.type === MessageType.RESCHEDULE_REQUEST || action.payload.message.type === MessageType.RESCHEDULE)
      yield put(setPromptModal({ isOpen: false, ModalType: null }));
  } catch (error) {
    console.log(error);
  }
  yield put(setMessageSendInProgress(false));
}
function* doDontSendFeedBack(action: any) {
  try {
    yield dontSendFeedback(action.payload.message, action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
}
function* doSendRating(action: any) {
  try {
    yield sendRating(action.payload.message, action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
}
function* doRemindMeLater(action: any) {
  try {
    yield sendRemindMeLater(action.payload.message, action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
}

// SorryModalClose
function* doSorryModalClose(action: any) {
  try {
    yield sendSorryModalClose(action.payload.message, action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
}
// AppologyModal Close
function* doAppologyModalClose(action: any) {
  try {
    yield sendAppologyModalClose(action.payload.message, action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
}

// testing
function* doCompleteInteraction(action: any) {
  try {
    yield completeInteraction(action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
  yield put(setMessageSendInProgress(false));
}

// not over
function* doInteractionNotOver(action: any) {
  try {
    yield interactionNotOver(action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
  yield put(setMessageSendInProgress(false));
}

function* doIntNoShow(action: any) {
  try {
    yield intNoShow(action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
  yield put(setMessageSendInProgress(false));
}

function* doIntNoShowComplete(action: any) {
  try {
    yield intNoShowComplete(action.payload.message, action.payload.activeInteractionId);
    if (action.payload.message.type === MessageType.NO_SHOW)
      yield put(setPromptModal({ isOpen: false, ModalType: null }));
    if (action.payload.message.type === MessageType.RESCHEDULE_REQUEST || action.payload.message.type === MessageType.RESCHEDULE)
      yield put(setPromptModal({ isOpen: false, ModalType: null }));
  } catch (error) {
    console.log(error);
  }
}

// -----
function* doSendUnableToAttend(action: any) {
  try {
    yield sendUnableToAttend(action.payload.message, action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
}

function* doAcceptInteractionRequest(action: any) {
  try {
    yield acceptInteractionRequest(action.payload.activeInteractionId);
  } catch (error) {
    console.log(error);
  }
  yield put(setMessageSendInProgress(false));
}
function* doDeclineInteractionRequest(action: any) {
  try {
    yield declineInteractionRequest(action.payload.interactionId);
  } catch (error) {
    console.log(error);
  }
  yield put(setMessageSendInProgress(false));
  yield put(setPromptModal({ isOpen: false, ModalType: null }));
}
function* doSendInteractionRequest(action: any): any {
  try {
    if (action.payload.interactionType === InteractionType.MOCK_INTERVIEW) {
      yield sendInteractionRequest(
        action.payload.receiverId,
        action.payload.interactionType,
        action.payload.initialMessagePayload,
        action.payload.expectedInterviewDate
      );
    } else
      yield sendInteractionRequest(
        action.payload.receiverId,
        action.payload.interactionType,
        action.payload.initialMessagePayload
      );
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: "The request has been successfully sent.",
    };
    yield put(showToast(toast));
    const isModelOpen = yield select((state) => state.interactions.interactionRequestModelOpen);
    yield put(setInteractionRequestModelOpen(!isModelOpen));
    yield put(setIsRequestButtonDisabled(false));
  } catch (error: any) {
    yield put(setInteractionRequestModelOpen(false));
    yield put(setIsRequestButtonDisabled(false));
    yield put(setPromptModal({ isOpen: true, ModalType: 'SUGGEST_PROFESSIONALS' }));
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: error.response.data,
    };
    yield put(showToast(toast));
  }
}
export default function* interactionsSaga(): any {
  yield takeLatest("START_INTERACTION_LISTENER", doStartInteractionListener);
  yield takeLatest("SEND_RATING",doSendRating)
  yield takeLatest("SEND_MESSAGE", doSendMessage);
  yield takeLatest("ACCEPT_INTERACTION_REQUEST", doAcceptInteractionRequest);
  yield takeLatest("DECLINE_INTERACTION_REQUEST", doDeclineInteractionRequest);
  yield takeLatest("SEND_INTERACTION_REQUEST", doSendInteractionRequest);
  yield takeLatest("DONT_SEND_FEEDBACK", doDontSendFeedBack);
  yield takeLatest("REMIND_ME_LATER", doRemindMeLater);
  // testing
  yield takeLatest("INTERACTION_COMPLETED",doCompleteInteraction);
  // not over
  yield takeLatest("INTERACTION_NOT_OVER",doInteractionNotOver);

  // ----------
  yield takeLatest("UNABLE_TO_ATTEND",doSendUnableToAttend);

  yield takeLatest("INT_NO_SHOW",doIntNoShow);
  yield takeLatest("INT_NO_SHOW_COMPLETE",doIntNoShowComplete);
  // SorryModalClose
  yield takeLatest("SORRY_MODAL_CLOSE",doSorryModalClose);
   // AppologyModalClose
   yield takeLatest("APPOLOGY_MODAL_CLOSE",doAppologyModalClose)
}