import { Button, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import sendLogo from "common/assets/images/sendLogo.svg";
import { useState } from "react";
import { Message, MessageType } from "common/types";
import styled from "styled-components";
import { selectMessageSendInProgress, selectActiveInteractionId } from "../selectors";
import { setMessageSendInProgress } from "../slice";
import recordEvent from "common/utils/mixpanel";
import { selectUser } from "features/auth/selectors";
import { setPromptModal } from "features/auth/slice";

const InteractionRequestResponseInput = () => {
  const dispatch = useDispatch();
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const isDisabled = useSelector(selectMessageSendInProgress);
  const user: any = useSelector(selectUser);

  const [text, setText] = useState<string>("");

  const handleClick = () => {
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.TEXT,
      payload: text,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId,
        message,
      },
    });
    setText("");
  };

  const handleAcceptRequest = () => {
    recordEvent("Request Action Taken", {
      "Action Type": "Accepted",
      "Professional Sector": user?.sectors,
      "Professional Company": user?.company.name,
      "Professional Job Title": user?.jobTitle,
      "Professional University": user?.university?.name,
      "Professional University Course": user?.universityCourse,
    });
    dispatch(setMessageSendInProgress(true));
    dispatch({
      type: "ACCEPT_INTERACTION_REQUEST",
      payload: { activeInteractionId },
    });
  };

  const handleRejectRequest = () => {
    dispatch(setPromptModal({ isOpen: true, ModalType: "DECLINE_REQUEST" }))
  };

  return (
    <StyledWrapper>
      <div className="response-buttons">
        <Button
          className="accept-request-btn color-dark-blue"
          // disabled={isDisabled}
          onClick={handleAcceptRequest}
        >
          Accept Request
        </Button>
        <Button
          className="decline-request-btn"
          // disabled={isDisabled}
          color="secondary"
          onClick={handleRejectRequest}
        >
          Decline Request
        </Button>
      </div>
      <TextField
        value={text}
        onChange={(e) => setText(e.target.value)}
        multiline
        rows={5}
        fullWidth
        variant="filled"
        placeholder="Write a response"
        className="thread-input"
        InputProps={{ disableUnderline: true }}
      />
      <Button
        variant="contained"
        disabled={isDisabled}
        color="primary"
        className="send-btn cursor-pointer"
        onClick={text.replace(/\s/g, "") !== "" ? handleClick : undefined}
      >
        {" "}
        <div className="d-flex send-logo">
          {" "}
          Send <img className="justify-center send-icon" src={sendLogo} alt="" />
        </div>{" "}
      </Button>
    </StyledWrapper>
  );
};

export default InteractionRequestResponseInput;

const StyledWrapper = styled.div`
  .response-buttons {
    display: flex;
  }

  .accept-request-btn {
    border: 1px solid #464fd0;
    margin-left: 20px;
    margin-right: 20px;
    text-transform: capitalize;
    background-color: #464fd0;
    color: white !important;
    /* width: 100px; */
  }
  .accept-request-btn:hover {
    background-color: white;
    color: #464fd0 !important;
  }
  .decline-request-btn {
    border: 1px solid #464fd0;
    margin-left: 20px;
    margin-right: 20px;
    text-transform: capitalize;
    background-color: white;
    color: #464fd0;
    /* width: 100px; */
  }
  .decline-request-btn:hover {
    background-color: #464fd0;
    color: white;
  }
  .send-btn {
    float: right;
    margin-top: 30px;
    border: none;
    width: 100px;
    height: 39px;
    justify-content: center;
    padding-top: 8px;
    background-color: #464fd0;
    color: white;
    font-family: Nunito Sans, sans-serif !important;
  }

  .send-icon {
    margin-left: 10px;
    position: relative;
    top: -5px;
  }

  .send-logo {
    justify-content: center;
    margin: auto;
    font-family: Nunito Sans, sans-serif !important;
  }

  .thread-input {
    ::placeholder: {
      font-family: Nunito Sans, sans-serif !important;
    },
    textarea,
    div {
      background: #f7f7f7;
      margin-top: 20px;
    }
  }
`;
