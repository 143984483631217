import { TextField, Button, Grid, Radio } from "@mui/material";
import { Message, MessageType } from "common/types";
import { useState } from "react";
import styled from "styled-components";
import sendLogo from "common/assets/images/sendLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageSendInProgress, selectActiveInteractionId } from "../selectors";
import { setMessageSendInProgress } from "../slice";
import recordEvent from "common/utils/mixpanel";
import { selectUser } from "features/auth/selectors";

const CallDetailsInput = () => {
  const [text, setText] = useState<string>("");
  const [text2, setText2] = useState<string>("");
  const [isUrl, setIsUrl] = useState<boolean>(true);
  const [isMessageLink, setIsMessageLink] = useState<boolean>(true);
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const isDisabled = useSelector(selectMessageSendInProgress);
  const user: any = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleLinkSend = () => {
    const message: Partial<Message> = {
      type: MessageType.SET_CALL_DETAILS,
      payload: text,
    };
    let isValidUrl = text.match(
      /^(?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/
    ); //eslint-disable-line
    if (isValidUrl) {
      recordEvent("Call Details Confirmed", {
        "Call Details": message.payload,
        "Professional Sector": user.sectors,
        "Professional Company": user.company?.name,
        "Professional Job Title": user.jobTitle,
        "Professional University": user.university?.name,
      });
      dispatch(setMessageSendInProgress(true));
      dispatch({
        type: "SEND_MESSAGE",
        payload: {
          activeInteractionId,
          message,
        },
      });
      setIsUrl(true);
    } else {
      dispatch(setMessageSendInProgress(false));
      setIsUrl(false);
    }
    setText("");
  };

  const handleMessageSend = () => {
    if (text2 === "") return;

    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.SET_CALL_DETAILS_WITHOUT_LINK,
      payload: text2,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId,
        message,
      },
    });
    setText2("");
  };

  const handleMessageTypeToggle = () => {
    setIsMessageLink(!isMessageLink);
  };

  return (
    <StyledWrapper>
      <div className="radio-container">
        <Radio
          color="primary"
          checked={isMessageLink}
          onChange={handleMessageTypeToggle}
          value="a"
          name="radio-button-demo"
          inputProps={{ "aria-label": "A" }}
          className="radio-color"
        />
        <p className="switch-text">Video Conferencing Link</p>
      </div>
      <TextField
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        multiline
        rows={1}
        fullWidth
        variant="filled"
        placeholder="Please paste a video conferencing link"
        className="thread-input"
        InputProps={{ disableUnderline: true }}
        disabled={!isMessageLink}
      />
      {!isUrl && isMessageLink && (
        <p className="rhf-errors">Please enter a valid video conferencing link</p>
      )}
      <div className="radio-container">
        <Radio
          color="primary"
          checked={!isMessageLink}
          onChange={handleMessageTypeToggle}
          name="radio-button-demo"
          inputProps={{ "aria-label": "B" }}
        />
        <p className="switch-text">Alternatively, Enter Call Details Below</p>
      </div>
      <TextField
        value={text2}
        onChange={(e) => setText2(e.target.value)}
        multiline
        rows={5}
        fullWidth
        variant="filled"
        placeholder="Please enter the call details"
        className="thread-input"
        InputProps={{ disableUnderline: true }}
        disabled={isMessageLink}
      />
      <Grid className="buttons">
        <Button
          variant="contained"
          disabled={isDisabled}
          color="primary"
          className="send-btn cursor-pointer"
          onClick={
            isMessageLink
              ? text.replace(/\s/g, "") !== ""
                ? handleLinkSend
                : undefined
              : handleMessageSend
          }
        >
          <div className="d-flex send-logo">
            Set Call Details <img className="justify-center send-icon" src={sendLogo} alt="" />
          </div>{" "}
        </Button>
      </Grid>
    </StyledWrapper>
  );
};

export default CallDetailsInput;

const StyledWrapper = styled.div`
  .send-btn {
    float: right;
    margin-top: 30px;
    margin-left: 50px;
    border: none;
    width: 200px;
    height: 39px;
    justify-content: center;
    padding-top: 8px;
  }

  .d-flex {
    justify-content: flex-start;
    margin: auto;
    display: flex;
    align-items: center;
    align-content: stretch;
    flex-direction: row;
  }

  .send-icon {
    margin-left: 10px;
    position: relative;
    top: -5px;
  }

  .send-logo {
    justify-content: center;
    margin: auto;
    line-height: normal;
  }

  .thread-input {
    textarea,
    div {
      text-indent : 10px;
      background: #f7f7f7;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 600px) {
      display: unset;
    }
  }
  .switch {
    margin-bottom: -30px;
  }
  .radio-wrapper {
    display: flex;
    margin-left: 5px;
  }
  .radio-container {
    display: flex;
    padding-bottom:10px;
  }

  .radio-color{
    color:black !important;
  }
  .css-hyxlzm{
    background-color:white !important;
    color:black !important;
    background:white !important;
  }
`;
