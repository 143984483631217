import { useEffect, useRef, useState } from "react";
// import styled from "styled-components";
import { RootStateOrAny, useSelector } from "react-redux";
import InputWidgetFactory from "./inputWidgets/InputWidgetFactory";
import threadMessageFactory from "./threadMessageWidgets/threadMessageFactory";
import { Grid } from "@mui/material";
import { selectInteractions, selectActiveInteractionId } from "./selectors";
import { format } from "date-fns";
import { UserType, MessageType } from "common/types";
import styled from "@emotion/styled";

const Interaction = () => {
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const interactions = useSelector(selectInteractions);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const thread = useRef<HTMLDivElement>(null);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  const [usersType, setUsersType] = useState<any>("");
  const [alumniType, setAlumniType] = useState<any>("");
  const interactionType = activeInteraction?.type;

  const [isSorryModalOpen, setIsSorryModalOpen] = useState(true);

  useEffect(() => {
    setActiveInteraction(
      interactions?.filter((interaction: any) => interaction.id === activeInteractionId).pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions]);
  
      var thread1 = activeInteraction?.thread;
      const lastItem = thread1?.slice(-1)[0];
      // const messageType = lastItem?.type

  const scrollToBottom = () => {
    thread.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [activeInteraction]);

  useEffect(() => {
    if (thread.current) {
      thread.current.scrollTop = thread.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (user.roles.includes(UserType.PROFESSIONAL)) setUsersType(UserType.PROFESSIONAL);
    if (user.roles.includes(UserType.STUDENT)) setUsersType(UserType.STUDENT);
    if (user.roles.includes(UserType.ALUMNI)) {
      setUsersType(UserType.ALUMNI);
      if (activeInteraction?.thread[0].type === "REQUEST" &&
        activeInteraction?.thread[0].sender.id === user.id) {
        setAlumniType(UserType.STUDENT);
      } else {
        setAlumniType(UserType.PROFESSIONAL);
      }
    }
  }, [user, activeInteraction])

  return (
    <StyledWrapper id="interaction">
      <div className="thread" ref={thread}>
        {activeInteraction && (
          <div>
            {activeInteraction.thread.map((message: any) => {
              return message.type !== MessageType.STUD_SORRY_MODAL_CLOSE && message.type !== MessageType.PROF_SORRY_MODAL_CLOSE &&
              message.type !== MessageType.PROF_APPOLOGY_MODAL_CLOSE && message.type !== MessageType.STUD_APPOLOGY_MODAL_CLOSE &&(
                <Grid
                  container
                  item
                  justifyContent={message.sender.id === user.id ? "flex-end" : "flex-start"}
                >
                  <div
                    className={`interaction-container ${message.type === "TEXT"
                      ? `bubble background-white ${message.sender.id === user.id ? "sent-bubble" : "received-bubble"
                      }`
                      : ""
                      }`}
                  >
                    {threadMessageFactory(message.type)({ message, interactionType })}
                    <span className="timestamp">
                      {(message.type !== MessageType.EXPECTED_INTERVIEW_DATE && message.type !== MessageType.PROF_SORRY_MODAL_CLOSE && message.type !== MessageType.STUD_SORRY_MODAL_CLOSE 
                      && message.type !== MessageType.PROF_APPOLOGY_MODAL_CLOSE && message.type !== MessageType.STUD_APPOLOGY_MODAL_CLOSE) && 
                        format(message.timestamp, "do LLL yyyy - hh:mm 	a")}
                    </span>
                  </div>
                  <div ref={thread} />
                </Grid>
              );
            })}
          </div>
        )}
      </div>

      <div className="input">
        {activeInteraction && (
          <InputWidgetFactory
            interactionId = {activeInteraction?.id}
            interactionState={activeInteraction?.state}
            messageType={lastItem?.type}
            userType={usersType}
            hasGraduateJob={user.roles.includes(UserType.ALUMNI) && user?.hasGraduateJob}
            isAlumniAsProfessional={alumniType}
            isSorryModalOpen = {isSorryModalOpen}
            setIsSorryModalOpen = {setIsSorryModalOpen}
          />
        )}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  max-width: 1200px;
  height: 100%;
  padding: 0 2rem;
  display: grid;
  grid-template-rows: 1fr auto;
  margin: auto;
  background: #ffffff;

  .bubble {
    border: 1px solid rgba(158, 165, 238, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 500px;
    padding: 1rem;
    margin: 0.5rem 0;
  }

  .thread {
    padding: 2rem;
    overflow-y: scroll;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .sent-bubble {
    background: rgba(64, 181, 171, 0.1);
  }

  .received-bubble {
    background: rgba(158, 165, 238, 0.1);
  }

  .input {
    padding: 2rem;
    display: block;

    @media (max-width: 600px) {
      padding: 2rem 0;
    }

    div {
      max-width: 800px;
    }

    .MuiOutlinedInput-input {
      display: block;
      margin: auto;
    }
  }

  .interaction-texts {
    white-space: break-spaces;
    font-size: 18px;
    line-height: 18px;
  }

  .interaction-container {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 550px;

    @media (max-width: 600px) {
      max-width: 200px;
    }
  }

  .timestamp {
    font-size: 13px;
    color: gray;
    display: inline-block;
    margin-top: 6px;
  }
`;

export default Interaction;
