import { Message } from "common/types";
import { format, parseISO } from "date-fns";
import { selectUser } from "features/auth/selectors";
import { User} from "common/types";
import { useSelector } from "react-redux";

type Props = {
    message: Message;
};

const TimeSelectedMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if(message.sender === "SYSTEM") return null;

    return (
        <div className="color-gulf-blue body-17 font-weight-bolder">
            <span>{user?.id === message.sender.id ? "You have" : message.sender.firstName+" has"}</span>
            <span>&nbsp;confirmed the call at</span>
            <span>&nbsp;{format(parseISO(message.payload), "do LLL yyyy - hh:mm 	a")}</span>
        </div>
    )
}

export default TimeSelectedMessage;
