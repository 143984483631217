import { Avatar, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { GenderChoices } from "common/types";
import InitiateInteraction from "features/interactions/RequestInteraction";
import { Link } from "react-router-dom";
import male from "common/assets/images/avatar.svg";
import female from "common/assets/images/avatar.svg";
import neutral from "common/assets/images/avatar.svg";

type Props = {
  classes: any;
  handleButtonClick: (type: string) => void;
  handleViewProfileClicked: () => void;
  suggestedProfessionalDetails: any;
};

const SuggestedProfessionalProfile = ({
  classes,
  handleButtonClick,
  suggestedProfessionalDetails,
  handleViewProfileClicked,
}: Props) => {
  return (
    <div className={classes.suggestions}>
      <Button
        color="primary"
        className={classes.back__btn}
        onClick={() => handleButtonClick("back")}
      >
        <ArrowBack />
      </Button>
      <div className={classes.profile__div}>
        <>
          <Avatar
            className={classes.profile__avatar}
            src={
              suggestedProfessionalDetails?.profilePicture ||
              (suggestedProfessionalDetails.gender === GenderChoices.MALE &&
                male) ||
              (suggestedProfessionalDetails.gender === GenderChoices.FEMALE &&
                female) ||
              (suggestedProfessionalDetails.gender ===
                GenderChoices.NOT_SPECIFIED &&
                neutral) ||
              undefined
            }
          />
          <p>
            {suggestedProfessionalDetails.firstName} {suggestedProfessionalDetails.lastName}
          </p>
        </>
      </div>
      <div className={classes.profile__details}>
        <div>
          <p>
            <b>Hometown</b>: {suggestedProfessionalDetails?.region?.name}
          </p>
          <br />
          <p> {suggestedProfessionalDetails?.company?.name} </p>
          <p>
            <b> {suggestedProfessionalDetails?.jobTitle} </b>
          </p>
        </div>
        <div>
          <p>
            <b>Education</b>:
          </p>
          <br />
          <p>
            <b> {suggestedProfessionalDetails?.university?.name} </b>
          </p>
          <p>
            <b>
              <i>{suggestedProfessionalDetails?.universityCourse?.name}</i>
            </b>
          </p>
        </div>
      </div>
      <div className={classes.profile__availibility}>
        <InitiateInteraction
          isSuggestedProfessionalModal
          profile={suggestedProfessionalDetails}
          receiverId={suggestedProfessionalDetails.id}
        />
      </div>
      <Link
        className="usercard-link"
        to={`/users/${suggestedProfessionalDetails?.id}`}
      >
        <Button
          color="primary"
          variant="contained"
          className="mx-auto view-btn font-family-nunito"
          onClick={handleViewProfileClicked}
        >
          View Full Profile
        </Button>
      </Link>
    </div>
  );
};

export default SuggestedProfessionalProfile;
