import React from 'react';
import { Grid, Container } from "@mui/material";
import hamburger from '../common/assets/images/ham.png'
import styled from "styled-components";
import Logo from "common/assets/images/logo.svg";
import Avatar from '@mui/material/Avatar';
import { NavLink } from 'react-router-dom';
import { selectAuthUser, selectUser } from 'features/auth/selectors';
import { useSelector } from 'react-redux';
import { User } from 'common/types';
import avatar from "common/assets/images/avatar.svg";

type Props = {
  toggleDrawer: (anchor: any, arg1: boolean) => void;
  anchor: any;
  sidebarState: any;
  setSidebarState: any;
  userEmail: String;
};

const StyledWrapper = styled.div`

  .mobile-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

    .hamburger {
      margin-top: 3px;
      width: 30px;
      padding: 0;
    }

    .mobile-logo {
      width: 120px;
    }

    .profile-avatar {
      width: 30px;
      height: 30px;
    }

    .profile-dropdown {
      width: 150px;
      position: absolute;
      right: 5px;
      padding: 10px;
      text-align: center;
      color: white;
    }

    .user-email, .logout-button {
      @media(max-width: 600px){
        font-size: 12px;
      }
    }
 `;

const MobileHeader = ({ toggleDrawer, anchor, setSidebarState }: Props) => {

  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
  const user: User | null = useSelector(selectUser);
  const authUser = useSelector(selectAuthUser);
  
  return (
    <StyledWrapper >
      <Container className="background-gulf-blue mobile-header">
        <Grid container>
          <Grid xs={1} item>
            <div
              onClick={() => { setSidebarOpen(!sidebarOpen); toggleDrawer(anchor, setSidebarState({ left: true })) }}
              className={sidebarOpen ? "open d-md-none col-2 col-md-0" : "d-block d-md-none col-2 col-md-0"}
              id="menu-toggle"
            >
              <img className="hamburger" src={hamburger} alt="" />
            </div>
          </Grid>
          <Grid xs={9} item>
            <NavLink className="ml-auto header-links font-weight-lighter" exact to="/">
              <img className="mx-auto d-block mobile-logo" src={Logo} alt="" />
            </NavLink>
          </Grid>
          <Grid xs={2} item>
          <NavLink className="ml-auto header-links font-weight-lighter" exact to={`/users/${authUser?.id}`}>
            <Avatar className="profile-avatar d-block ml-auto" alt="" src={user?.profilePicture || avatar} />
            </NavLink>
          </Grid>
        </Grid>
      </Container>

    </StyledWrapper>
  );
};

export default MobileHeader;
