import { format } from "date-fns";
import { Message, User } from "common/types";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/selectors";
type Props = {
  message: Message;
};

const InterviewDateMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if (message.sender === "SYSTEM") return null;
  const { payload, sender } = message;

  return (
    <div className="color-gulf-blue body-17" style={{ color: "#464FD0" }}>
      <span>{user?.id === sender.id ? "You have" : sender.firstName + " has"}</span>
      <span>&nbsp;an interview scheduled on {format(new Date(payload), "dd.MM.yyyy")}</span>
    </div>
  );
};

export default InterviewDateMessage;
