import { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import StepperControls from "common/components/StepperControls";
import { InputType } from "common/components/TextField";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import {
  goToPrevStep,
  setUniversityDetails,
  goToNextStep,
  setIsNextStep,
} from "./alumniRegistrationSlice";
import { useForm } from "react-hook-form";
import DocumentPicker from "../../../common/components/DocumentPicker";
import { selectIsNextStep, selectUniversityDetails } from "./selectors";
import styled from "@emotion/styled";

const UniversityDetails = () => {
  const universityDetails = useSelector(selectUniversityDetails);
  const isNextStep = useSelector(selectIsNextStep);
  const dispatch = useDispatch();

  const [selectedUniversity, setSelectedUniversity] = useState<any>();
  const [selectedCourse, setSelectedCourse] = useState<any | null>();
  const [selectedDegreeType, setSelectedDegreeType] = useState<any | null>();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: universityDetails,
  });

  useEffect(() => {
    setSelectedUniversity(universityDetails.university);
    setSelectedCourse(universityDetails.universityCourse);
    setSelectedDegreeType(universityDetails.degreeType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmit = (data: any) => {
    data.university = selectedUniversity;
    data.universityCourse = selectedCourse;
    data.degreeType = selectedDegreeType;

    dispatch(setUniversityDetails(data));
    if (isNextStep) {
      dispatch(goToNextStep());
    }
    if (!isNextStep) {
      dispatch(goToPrevStep())
    }
  };

  const handleUniversityChange = (university: any) =>
    setSelectedUniversity(university);

  const handleCourseChange = (course: any) => setSelectedCourse(course);
  const handleDegreeType = (degree: any) => setSelectedDegreeType(degree);

  return (
    <StyledForm
      className="registration-box-shadow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container item spacing={4} xs={12}>
        <Grid item xs={12}>
          <h3 className="prof-registration-heading">
            Add Education details below.
          </h3>
        </Grid>
      </Grid>
      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6}>
          <DocumentPicker
            handleChange={handleUniversityChange}
            collection="universities"
            name="university"
            inputRef={register({ required: true })}
            error={errors.university}
            defaultValue={selectedUniversity}
            label="University"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DocumentPicker
            handleChange={handleCourseChange}
            collection="courses"
            error={errors.universityCourse}
            name="universityCourse"
            inputRef={register({ required: true })}
            required={!!selectedUniversity}
            defaultValue={selectedCourse}
            label="University Course"
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <DocumentPicker
            handleChange={handleDegreeType}
            collection="degree-types"
            error={errors.degreeType}
            name="degreeType"
            inputRef={register({ required: true })}
            required={!!selectedUniversity}
            defaultValue={selectedDegreeType}
            label="Degree Type"
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            inputRef={register({
              minLength: 4,
              maxLength: 4,
              max: 2023,
              min: 1950,
              validate: {
                required: (value) => {
                  if (!value && selectedUniversity.id !== "")
                    return "This field is required.";
                  return true;
                },
              },
            })}
            name="graduationYear"
            type={InputType.NUMBER}
            variant="outlined"
            fullWidth
            label="Graduation Year"
          />
          {errors && errors.graduationYear && (
            <>
              <p className="rhf-errors">
                {(errors.graduationYear as any).message}
              </p>
              {errors.graduationYear.type === "maxLength" && (
                <span className="rhf-errors">
                  Please enter your 4 digit graduation year.
                </span>
              )}
              {errors.graduationYear.type === "minLength" && (
                <span className="rhf-errors">
                  Please enter your 4 digit graduation year.
                </span>
              )}
              {errors.graduationYear.type === "max" && (
                <span className="rhf-errors">
                  Graduation year should be between 1950 and 2021
                </span>
              )}
              {errors.graduationYear.type === "min" && (
                <span className="rhf-errors">
                  Graduation year should be between 1950 and 2021
                </span>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <StepperControls
        nextButtonLabel="Next"
        disabled={false}
        nextButtonCallback={() => dispatch(setIsNextStep(true))}
        backButtonLabel="Back"
        backButtonCallback={() => dispatch(setIsNextStep(false))}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 2rem;
  .MuiGrid-item {
    margin-bottom: 1rem;
    padding: 0 3rem;
  }
  .MuiFormLabel-root {
    margin-bottom: 1rem;
  }

  .outline-dropdown {
    width: 100%;
  }
`;

export default UniversityDetails;
