import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./../../app/store";
import { initialState } from "./slice";

const searchSlice = (state: RootState) =>
  state.search || initialState;

export const selectSectorFilter = createSelector(
  [searchSlice],
  (state) => state.sectorFilter
);
export const selectInteractionFilter = createSelector(
  [searchSlice],
  (state) => state.interactionFilter
);
export const selectCompanyFilter = createSelector(
  [searchSlice],
  (state) => state.companyFilter
);
export const selectUniversityFilter = createSelector(
  [searchSlice],
  (state) => state.universityFilter
);
export const selectRegionFilter = createSelector(
  [searchSlice],
  (state) => state.regionFilter
);
export const selectGenderFilter = createSelector(
  [searchSlice],
  (state) => state.genderFilter
);
export const selectFilter = createSelector(
  [searchSlice],
  (state) => state.filter
);
export const selectPageNumber = createSelector(
  [searchSlice],
  (state) => state.pageNumber
);
export const selectFilteredProfessionals = createSelector(
  [searchSlice],
  (state) => state.filteredProfessionals
);