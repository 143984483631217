import { InteractionState, InteractionType, MessageType, UserType } from "common/types";
import { Button } from "@mui/material";
// import { getAxiosInstance } from "common/utils";
// import { useDispatch } from "react-redux";
// import { showToast, ToastMessage, ToastType } from "features/toast/slice";
// import PostInteractionActions from "./inputWidgets/PostInteractionActions";

type Props = {
  interactionState: InteractionState;
  userType: UserType;
  interactionType: InteractionType;
  isAttendanceConfirmed: Boolean;
  interactionId: string;
  messageType:MessageType;
  professionalcount:number;
};

const InteractionStateWidgetFactory = ({
  interactionState,
  userType,
  interactionType,
  messageType,
  professionalcount,
  isAttendanceConfirmed = false,
  interactionId,
}: Props) => {
  // const dispatch = useDispatch();

  // const confirmAttendanceHandler = async (
  //   e: React.MouseEvent<HTMLElement>,
  //   interactionId: string
  // ) => {
  //   e.stopPropagation();
  //   const axios = await getAxiosInstance();
  //   const response = await axios.patch(`/interactions/confirmAttendance`, {
  //     interactionId: interactionId,
  //   });
  //   if (response.status === 200) {
  //     const toast: ToastMessage = {
  //       type: ToastType.SUCCESS,
  //       message: "Thank you. This interaction has been marked as complete.",
  //     };
  //     dispatch(showToast(toast));
  //   } else {
  //     const toast: ToastMessage = {
  //       type: ToastType.SUCCESS,
  //       message:
  //         "There was some error confirming attendance. Please try again later.",
  //     };
  //     dispatch(showToast(toast));
  //   }
  // };

// const confirmCompletion=()=>{
//      <PostInteractionActions />
//   }
// console.log("interactionState",interactionState)
  
  if (interactionType !== InteractionType.EXPERT_ADVICE) {
    return (
      <>
        {
          TypeBasedStateComponent[interactionState
          ][userType]
        }
      </>
    );
  } else {
    return (
      <>
        {
          TypeBasedStateComponentForExpertAdvice[interactionType][
            interactionState
          ][userType]
        }
        
        {/*  If Professional accepts and sends an message */}
        {userType === "STUDENT" && interactionState === "ACCEPTED" && messageType === "TEXT" && professionalcount > 1 &&(
          <p className="status-para">Response Received</p>
        )
        }
         {userType === "STUDENT" && interactionState === "ACCEPTED" && messageType === "TEXT" && professionalcount === 1 &&(
          <p className="status-para">Accepted - Awaiting Response</p>
        )
        }
        {userType === "STUDENT" && interactionState === "ACCEPTED" && messageType === "ACCEPT" &&(
          <p className="status-para">Accepted - Awaiting Response</p>
        )
        }


        {/*  If student request and professional accepts and sends an message */}
        {userType === "PROFESSIONAL" && interactionState === "ACCEPTED" && messageType === "TEXT" && professionalcount > 1 &&(
          <p className="status-para">Response Sent, Awaiting Response</p>
        )
        }
        {userType === "PROFESSIONAL" && interactionState === "ACCEPTED" && messageType === "TEXT" && professionalcount === 1 &&(
          <p className="status-para">Request Accepted, Awaiting Response</p>
        )
        }
        {userType === "PROFESSIONAL" && interactionState === "ACCEPTED" && messageType === "ACCEPT" &&(
          <p className="status-para">Request Accepted, Awaiting Response</p>
        )
        }

        {/* {!isAttendanceConfirmed && 
          (userType === "STUDENT" || userType === "PROFESSIONAL"  )&&
          interactionState === "COMPLETED" && (
            <Button
              variant="contained"
              className="confirm-attendance-btn"
              color="primary"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                // confirmAttendanceHandler(e, interactionId)
                confirmCompletion()
              }
            >
              {/* Confirm Attendance */}
              {/* Confirm Completion */}
            {/* </Button> */}
          {/* )} */}
      </>
    );
  }
};

const TypeBasedStateComponent: any = {
// [InteractionType.CAREER_CHAT]:{  
  [InteractionState.REQUESTED]: {
    [UserType.STUDENT]: <p className="text-capitalize">Requested</p>,
    [UserType.PROFESSIONAL]: (
      <Button variant="contained" className="view-request-btn" color="primary">
        View Request
      </Button>
    ),
  },
  [InteractionState.ACCEPTED]: {
    [UserType.STUDENT]: (
      <p className="status-para">Request accepted, awaiting suggested times</p>
    ),
    [UserType.PROFESSIONAL]: <p className="status-para">Request accepted</p>,
  },
// created new for interactionComplete
  [InteractionState.INTERACTIONCOMPLETED]: {
    [UserType.STUDENT]: (
      <p className="status-para">Complete</p>
    ),
    [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
  },
// created new for interactionComplete
[InteractionState.INTERACTION_NOT_OVER]: {
  [UserType.STUDENT]: (
    <p className="status-para">Not Over</p>
  ),
  [UserType.PROFESSIONAL]: <p className="status-para">Not Over</p>,
},
  [InteractionState.DECLINED]: {
    [UserType.STUDENT]: <p className="status-para">Declined</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Declined</p>,
  },
  [InteractionState.SCHEDULING_TIMES_REQUESTED]: {
    [UserType.STUDENT]: (
      <p className="status-para">Scheduling times requested</p>
    ),
    [UserType.PROFESSIONAL]: (
      <p className="status-para">Scheduling times requested</p>
    ),
  },
  [InteractionState.SCHEDULING_TIMES_SUGGESTED]: {
    [UserType.STUDENT]: (
      <p className="status-para">Times suggested - select a time</p>
    ),
    [UserType.PROFESSIONAL]: (
      <p className="status-para">Scheduling times suggested</p>
    ),
  },
  [InteractionState.SCHEDULING_ADDITIONAL_TIMES_REQUESTED]: {
    [UserType.STUDENT]: (
      <p className="status-para">Scheduling additional times requested</p>
    ),
    [UserType.PROFESSIONAL]: (
      <p className="status-para">Scheduling additional times requested</p>
    ),
  },
  [InteractionState.SCHEDULED]: {
    [UserType.STUDENT]: <p className="status-para">Scheduled</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Scheduled</p>,
  },
  [InteractionState.CALL_DETAILS_SET]: {
    [UserType.STUDENT]: <p className="status-para">Call details set</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Call details set</p>,
  },
  [InteractionState.RESCHEDULE_REQUESTED]: {
    [UserType.STUDENT]: <p className="status-para">Requested Reschedule</p>,
    [UserType.PROFESSIONAL]: (
      <p className="status-para">Requested Reschedule</p>
    ),
  },
  [InteractionState.COMPLETED]: {
    [UserType.STUDENT]: (
      <div className="completed-div">
        <p className="status-para">Complete</p>
      </div>
    ),
    [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
  },
  [InteractionState.EXPIRED]: {
    [UserType.STUDENT]: <p className="status-para">Expired</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Expired</p>,
  },

  [InteractionState.INT_NO_SHOW]: {
    [UserType.STUDENT]: <p className="status-para">Complete</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
  },
  [InteractionState.INT_NO_SHOW_COMPLETE]: {
    [UserType.STUDENT]: <p className="status-para">Complete</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
  },
  [InteractionState.SORRY_MODAL_CLOSE]: {
    [UserType.STUDENT]: <p className="status-para">Complete</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
  },
  [InteractionState.APPOLOGY_MODAL_CLOSE]: {
    [UserType.STUDENT]: <p className="status-para">Complete</p>,
    [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
  },
// }
};

const TypeBasedStateComponentForExpertAdvice: any = {
  [InteractionType.EXPERT_ADVICE]: {
    [InteractionState.REQUESTED]: {
      [UserType.STUDENT]: <p className="text-capitalize">Requested</p>,
      [UserType.PROFESSIONAL]: (
        <Button
          variant="contained"
          className="view-request-btn"
          color="primary"
        >
          View Request
        </Button>
      ),
    },
    [InteractionState.ACCEPTED]: {
      [UserType.STUDENT]: (
        // <p className="status-para">Accepted - awaiting response</p>
        // <p className="status-para">Response Received</p>
        null
      ),
      [UserType.PROFESSIONAL]: (
        // <p className="status-para">Response sent, awaiting response</p>
        null
      ),
    },
    [InteractionState.DECLINED]: {
      [UserType.STUDENT]: <p className="status-para">Rejected</p>,
      [UserType.PROFESSIONAL]: (
        <p className="status-para">Completed - Rejected</p>
      ),
    },
    [InteractionState.COMPLETED]: {
      [UserType.STUDENT]: (
        <div className="completed-div">
          {/* <p className="status-para">Completed</p> */}
          <Button variant="contained" className="confirm-attendance-btn" color="primary">
              Confirm Completion
          </Button>
        </div>
      ),
      [UserType.PROFESSIONAL]:
      // <p className="status-para">Completed</p>,
          <Button variant="contained" className="confirm-attendance-btn" color="primary">
            Confirm Completion
           </Button>
    },
    [InteractionState.EXPIRED]: {
      [UserType.STUDENT]: <p className="status-para">Expired</p>,
      [UserType.PROFESSIONAL]: <p className="status-para">Expired</p>,
    },

    // testing
    [InteractionState.INTERACTIONCOMPLETED]: {
      [UserType.STUDENT]: <p className="status-para">Completed</p>,
      [UserType.PROFESSIONAL]: <p className="status-para">Completed</p>,
    },

     // not over
     [InteractionState.INTERACTION_NOT_OVER]: {
      [UserType.STUDENT]: <p className="status-para">Not Over</p>,
      [UserType.PROFESSIONAL]: <p className="status-para">Not Over</p>,
    },
  },
};

export default InteractionStateWidgetFactory;
