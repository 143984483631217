import {
  Interaction,
  InteractionState,
  InteractionType,
  Message,
  // MessageType,s
  UserType,
} from "common/types";
import recordEvent from "common/utils/mixpanel";

const UsersInteractionMap: any = {
  [InteractionState.REQUESTED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: true,
  },
  [InteractionState.ACCEPTED]: {
    [UserType.STUDENT]: true,
    [UserType.PROFESSIONAL]: false,
  },
  [InteractionState.CALL_DETAILS_SET]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: false,
  },
  [InteractionState.RESCHEDULE_REQUESTED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: true,
  },
  [InteractionState.COMPLETED]: {
    [UserType.STUDENT]: true,
    [UserType.PROFESSIONAL]: true,
  },
  [InteractionState.DECLINED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: false,
  },

// created new for interactioncomplete
[InteractionState.INTERACTIONCOMPLETED]:{
  [UserType.STUDENT]: true,
  [UserType.PROFESSIONAL]: true,
},

// not over 
[InteractionState.INTERACTION_NOT_OVER]:{
  [UserType.STUDENT]: true,
  [UserType.PROFESSIONAL]: true,
},

[InteractionState.INT_NO_SHOW]:{
  [UserType.STUDENT]: true,
  [UserType.PROFESSIONAL]: true,
},
[InteractionState.INT_NO_SHOW_COMPLETE]:{
  [UserType.STUDENT]: true,
  [UserType.PROFESSIONAL]: true,
},

  [InteractionState.EXPIRED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: false,
  },
  [InteractionState.SCHEDULED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: true,
  },
  [InteractionState.SCHEDULING_ADDITIONAL_TIMES_REQUESTED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: true,
  },
  [InteractionState.SCHEDULING_TIMES_REQUESTED]: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: true,
  },
  [InteractionState.SCHEDULING_TIMES_SUGGESTED]: {
    [UserType.STUDENT]: true,
    [UserType.PROFESSIONAL]: false,
  },
  // Close sorry Modal
  [InteractionState.SORRY_MODAL_CLOSE]:{
    [UserType.STUDENT]: true,
    [UserType.PROFESSIONAL]: true,
  },
  //AppologyModal Close
  [InteractionState.APPOLOGY_MODAL_CLOSE]:{
    [UserType.STUDENT]: true,
    [UserType.PROFESSIONAL]: true,
  },

  ACTION_DUE_STUDENT: {
    [UserType.STUDENT]: true,
    [UserType.PROFESSIONAL]: false,
  },
  ACTION_DUE_PROFESSIONAL: {
    [UserType.STUDENT]: false,
    [UserType.PROFESSIONAL]: true,
  },
};

export const fetchLastSenderType = (interaction: Interaction) => {
  const reversedThread: Array<Message> = [...interaction.thread].reverse();
  const lastUserMessage: any = reversedThread.find(
    (message: Message) => message.sender !== "SYSTEM"
  );
  if (lastUserMessage && lastUserMessage.sender !== "SYSTEM") return lastUserMessage.sender.roles;
};

const fetchLastUserMessage = (interaction: Interaction) => {
  const reversedThread: Array<Message> = [...interaction.thread].reverse();
  const lastUserMessage: any = reversedThread.find(
    (message: Message) => message.sender !== "SYSTEM"
  );
  if (lastUserMessage && lastUserMessage.sender !== "SYSTEM") return lastUserMessage;
};

export const requiresUserResponse = (interaction: Interaction, userType: UserType) => {
  // if (interaction.type === InteractionType.EXPERT_ADVICE) {
  //   return !fetchLastSenderType(interaction).includes(userType);
  // }
  // if (
  //   (interaction.type === InteractionType.CAREER_CHAT ||
  //     interaction.type === InteractionType.MOCK_INTERVIEW) &&
  //   interaction.state === InteractionState.CALL_DETAILS_SET
  // ) {
  //   // if the last message is set details, return fasle for both, if not , return !lastMessageSender
  //   const lastUserMessage = fetchLastUserMessage(interaction);
  //   if (lastUserMessage.type !== MessageType.SET_CALL_DETAILS) {
  //     if (lastUserMessage.sender.roles.includes(UserType.STUDENT)) {
  //       return UsersInteractionMap["ACTION_DUE_PROFESSIONAL"][userType];
  //     }
  //     if (lastUserMessage.sender.roles.includes(UserType.PROFESSIONAL)) {
  //       return UsersInteractionMap["ACTION_DUE_STUDENT"][userType];
  //     }
  //   }
  // }

  const lastUserMessage = fetchLastUserMessage(interaction);

  if (!lastUserMessage.sender.roles.includes(userType) && interaction.state === "ACCEPTED") {

    return true;

  }

  if (
    (interaction.type === InteractionType.CAREER_CHAT ||
      interaction.type === InteractionType.MOCK_INTERVIEW) &&
    interaction.state === InteractionState.CALL_DETAILS_SET
  ) {
    // if the last message is set details, return fasle for both, if not , return !lastMessageSender
    // if (lastUserMessage.type !== MessageType.SET_CALL_DETAILS) {
    //   if (lastUserMessage.sender.roles.includes(UserType.STUDENT)) {
    //     return UsersInteractionMap["ACTION_DUE_PROFESSIONAL"][userType];
    //   }
    //   if (lastUserMessage.sender.roles.includes(UserType.PROFESSIONAL)) {
    //     return UsersInteractionMap["ACTION_DUE_STUDENT"][userType];
    //   }
    // }
  }
  return UsersInteractionMap[interaction.state][userType];
};

export const sendSeachForProfessionalEvent = (user: any) => {
  recordEvent("Search For Professionals Click", {
    "Student Gender": user.gender,
    "Student Region": user.region?.name,
    "Student Ethnicity": user.ethnicity?.name,
    "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
    "User Roles": user.roles,
    "Student Year Of Graduation": user.graduationYear,
  });
};
