import { useEffect, useState } from 'react';
// import styled from 'styled-components';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import StepperControls from 'common/components/StepperControls';
import { useDispatch, useSelector } from "react-redux";
import { selectStepperActiveStep } from './selectors';
import { selectIsNextStep } from '../professional/selectors';
import { useForm } from "react-hook-form";
import { goToNextStep, goToPrevStep } from './studentRegistrationSlice';
import { setIsNextStep } from '../professional/professionalRegistrationSlice';
import React from 'react';
// import { AspectRatio } from '@mui/joy';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';

type Props = {
  isMyAccount?: boolean;
}
const WelcomeVideo = ({ isMyAccount }: Props) => {

  useEffect(() => {

    if (window.location.hash) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const [value1, setValue1] = React.useState('');
  const [value2, setValue2] = React.useState('');
  const [value3, setValue3] = React.useState('');
  const activeStep = useSelector(selectStepperActiveStep);
  const [accept, setAccept] = useState<boolean>(false);
  const [answer1, setAnswer1] = useState<any>();
  const [answer2, setAnswer2] = useState<any>();
  const [answer3, setAnswer3] = useState<any>();
  const [answer4, setAnswer4] = useState<any>();
  const [displaySuccess, setDisplaySuccess] = useState<boolean>(false);
  const [displaySuccess1, setDisplaySuccess1] = useState<boolean>(false);
  const [displayUnSuccess, setDisplayUnSuccess] = useState<boolean>(false);
  const [displayUnSuccessIcon, setDisplayUnSuccessIcon] = useState<boolean>(false);
  const [displayUnSuccessIcon1, setDisplayUnSuccessIcon1] = useState<boolean>(false);
  const [displayUnSuccessIcon2, setDisplayUnSuccessIcon2] = useState<boolean>(false);
  const [displayUnSuccessIcon3, setDisplayUnSuccessIcon3] = useState<boolean>(false);


  const isNextStep = useSelector(selectIsNextStep);
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    if (isNextStep) {
      dispatch(goToNextStep());
    }
    if (!isNextStep) {
      dispatch(goToPrevStep())
    }
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);

  };
  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue1((event.target as HTMLInputElement).value);

  };
  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue2((event.target as HTMLInputElement).value);

  };
  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue3((event.target as HTMLInputElement).value);

  };

  const handleClick = () => {
    setValue(value);
    if (answer1 === "correct" && answer2 === "correct" && answer3 === "correct" && answer4 === "correct") {
      setDisplaySuccess(true);
      setDisplaySuccess1(true);
      setDisplayUnSuccess(false);
      setAccept(!accept);
    }
    else {
      setDisplayUnSuccess(true);
      setDisplaySuccess(false);
      setDisplaySuccess1(false)
    }
  }


  const handleClick1 = () => {
    if (answer1 === "wrong" || answer1 === "wrong1" || answer1 === undefined) {
      setDisplayUnSuccessIcon(true);
    } else { setDisplayUnSuccessIcon(false); }
    if (answer2 === "wrong" || answer2 === "wrong1" || answer2 === undefined) {
      setDisplayUnSuccessIcon1(true);
    } else { setDisplayUnSuccessIcon1(false); }
    if (answer3 === "wrong" || answer3 === "wrong1" || answer3 === undefined) {
      setDisplayUnSuccessIcon2(true);
    } else { setDisplayUnSuccessIcon2(false); }
    if (answer4 === "wrong" || answer4 === "wrong1" || answer4 === undefined) {
      setDisplayUnSuccessIcon3(true);
    } else { setDisplayUnSuccessIcon3(false); }

  }


  return (
    <StyledForm className="card bg-white border border-light rounded-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body bg-transparent mx-4 my-4">
        <div className="row d-flex align-items-center">
          <div col-md-12>
            <h3 className="text-primary mb-4">Welcome Video</h3>
            <p>You will now need to watch our short onboarding video and complete a short quiz to ensure you understand what is expected of you when onboard the platform.</p>
          </div>
        </div>
        <div className="row d-flex align-items-center">
            {/* <AspectRatio  className="ratio"> */}
              {/* <iframe src="/SocialMobilityNetworkOnboardingVideo.mp4"  title="YouTube video" width={1600} height={647} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" allowFullScreen loading="lazy"></iframe> */}
              <ReactPlayer url="/SocialMobilityNetworkOnboardingVideo.mp4" playing controls loading="lazy" width='100%' height='100%'/>
            {/* </AspectRatio> */}
        </div>
        <div className="row text-center">
          <div col-md-12>
            <h4 className="text-primary mt-5 mb-0 font-weight-bold">Quiz Questions</h4>
          </div>
        </div>
      </div>
      <div className="card-body bg-transparent border border-primary mx-5 p-5">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-primary">Question 1/4 {displayUnSuccessIcon ? <ErrorOutlineOutlinedIcon className='icon' /> : ''}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p id="quiz-group" className='my-2'> Where do you go to request an interaction with a professional?</p>
            <RadioGroup value={value} onChange={handleChange}>
              <FormControlLabel value="wrong1" control={<Radio onChange={(e) => { setAnswer1(e.target.value) }} />} label={"The homepage"} />
              <FormControlLabel value="wrong" control={<Radio onChange={(e) => { setAnswer1(e.target.value) }} />} label={"Edit Profile"} />
              <FormControlLabel value="correct" control={<Radio onChange={(e) => { setAnswer1(e.target.value) }} />} label={"The professional’s profile"} />
            </RadioGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className='mt-5 text-primary'>Question 2/4 {displayUnSuccessIcon1 ? <ErrorOutlineOutlinedIcon className='icon' /> : ''}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p id="quiz-group" className='my-2'>If you want to speak to the professional via direct messaging, which interaction type should you request?</p>
            <RadioGroup value={value1} onChange={handleChange1}>
              <FormControlLabel value="wrong" control={<Radio onChange={(e) => { setAnswer2(e.target.value) }} />} label={"Career Chat"} />
              <FormControlLabel value="correct" control={<Radio onChange={(e) => { setAnswer2(e.target.value) }} />} label={"Expert Advice"} />
              <FormControlLabel value="wrong1" control={<Radio onChange={(e) => { setAnswer2(e.target.value) }} />} label={"Mock Interview"} />
            </RadioGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className='mt-5 text-primary'>Question 3/4 {displayUnSuccessIcon2 ? <ErrorOutlineOutlinedIcon className='icon' /> : ''}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p id="quiz-group" className='my-2'> If you want to speak to the professional via a video or phone call, who is responsible for suggesting date and time options and sending over call details?</p>
            <RadioGroup value={value2} onChange={handleChange2}>
              <FormControlLabel value="correct" control={<Radio onChange={(e) => { setAnswer3(e.target.value) }} />} label={"The professional"} />
              <FormControlLabel value="wrong" control={<Radio onChange={(e) => { setAnswer3(e.target.value) }} />} label={"You"} />
              <FormControlLabel value="wrong1" control={<Radio onChange={(e) => { setAnswer3(e.target.value) }} />} label={"The Social Mobility Network Team"} />
            </RadioGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className='mt-5 text-primary'>Question 4/4 {displayUnSuccessIcon3 ? <ErrorOutlineOutlinedIcon className='icon' /> : ''}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p id="quiz-group" className='my-2'> What should you do if you feel nervous about reaching out to a professional?</p>
            <RadioGroup value={value3} onChange={handleChange3}>
              <FormControlLabel value="wrong" control={<Radio onChange={(e) => { setAnswer4(e.target.value) }} />} label={"Sit and worry without asking anybody for help or guidance"} />
              <FormControlLabel value="correct" control={<Radio onChange={(e) => { setAnswer4(e.target.value) }} />} label={"Check out the Resources page for top tips and advice, and reach out to the Social Mobility Network Team"} />
              <FormControlLabel value="wrong1" control={<Radio onChange={(e) => { setAnswer4(e.target.value) }} />} label={"Nothing! Log off the platform and do not use it again"} />
            </RadioGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center my-3">
            {displaySuccess &&
              <p className='alert alert-success'>Great work! You have got all of the answers correct and can now continue to the final stage of the onboarding process by selecting 'Next' below.</p>
            }
            {displayUnSuccess &&
              <p className='alert alert-danger'>Unfortunately, you have got one or more answers incorrect. Please review your answers and try again. You can always re-watch the onboarding video to help you.</p>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            {
            <Button variant="contained" className='btn' disabled={displaySuccess1} onClick={() => { handleClick(); handleClick1(); }}>Submit</Button>
            }
          </div>
        </div>
      </div>
        <div className="card-body bg-transparent mt-3 d-flex justify-content-center">
          <StepperControls
            nextButtonLabel="Next"
            disabled={!accept}
            nextButtonCallback={() => { dispatch(setIsNextStep(true)) }}
            backButtonLabel="Back"
            backButtonCallback={() => { dispatch(setIsNextStep(false)); dispatch(goToPrevStep()); }}
            activeStep={activeStep}
          />
        </div>
    </StyledForm >
  );

};
const StyledForm = styled.form`
.MuiTypography-body1 {
  font-family: Nunito Sans,sans-serif !important;
}
.icon {
  color: red !important;
}
`;

export default WelcomeVideo;