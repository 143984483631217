import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import { CircularProgress, Container, Tooltip } from '@mui/material';
import network from "../../../common/assets/images/network.png"
// import styled from "styled-components";
import Button from '@mui/material/Button';
// import linkedin from "../../../common/assets/images/linkedin.svg"
// import { initiateLinkedInLogin } from 'features/auth/utils';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginFormWorking, selectOnLoginSyncInProgress, selectUser } from 'features/auth/selectors';
import React, { useEffect, useState } from 'react';
import { InputType } from 'common/components/TextField';
import PreLoginHeader from '../PreLoginHeader';
import PasswordResetModal from 'features/auth/PasswordResetModal';
import styled from '@emotion/styled';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);


type LoginFormType = {
    email: string;
    password: string;
};


const ProfessionalLogin: React.FC = () => {

    const classes = useStyles();
    const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
    const { register, handleSubmit, errors } = useForm<LoginFormType>();
    const user = useSelector(selectUser);
    const loginFormWorking = useSelector(selectLoginFormWorking);
    const onLoginSyncInProgress = useSelector(selectOnLoginSyncInProgress);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (user && user.id) history.push("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onSubmit = (data: LoginFormType) =>
        dispatch({ type: "LOGIN_ATTEMPT", payload: data });

    const openPasswordResetModal = () => {
        setPasswordResetModalOpen(true);
    };

    return (
        <StyledWrapper className="background-white">
            <PreLoginHeader />
            <div className={`${classes.root} w-100-percent`}>
                <Container className="w-100-percent" maxWidth="xl">
                    <Grid className="center-content w-100-percent" container>
                        <Grid className="network-section login-section content-center" item xs={12} md={6}>
                            <div className="linkedin-section">
                                <h1 className="network-intro color-gulf-blue color-gulf-blue">The network for those without a network</h1>
                                <p className="body-24 color-gulf-blue">
                                    Login to support students from less-advantaged backgrounds.
                            </p>    
                                <p className="body-14 register-help"><Tooltip title="If you're not registered, please contact your employer's HR or CSR team about joining the platform." aria-label="If you're not registered, please contact your employer's HR or CSR team about joining the platform." placement="right"><span> Not registered to join SMN?</span></Tooltip></p>
                                <p className="body-14">
                                    You should have received your log-in details already. If you have not please contact your Employer's Social Mobility Network Lead or contact <a href="mailto:smn@upreach.org.uk"> smn@upreach.org.uk </a>
                                </p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <TextField
                                    variant="outlined"
                                    className="email-textfield"
                                    name="email"
                                    inputRef={register({
                                        required: "This field is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Please enter a valid email address",
                                        },
                                    })}
                                    type={InputType.TEXT}
                                    label="email"
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                /> <br />

                                <TextField
                                    variant="outlined"
                                    name="password"
                                    className="password-textfield"
                                    inputRef={register({
                                        required: "This field is required",
                                    })}
                                    type={InputType.PASSWORD}
                                    label="password"
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                /> <br />
                                <span className="body-12" onClick={() => openPasswordResetModal()}>Recover password</span>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    className="submit-btn"
                                >
                                    {loginFormWorking || onLoginSyncInProgress ? (
                                        <CircularProgress color="inherit" />
                                    ) : (
                                        "LOGIN"
                                    )}
                                </Button>
                                </form>
                            </div>
                        </Grid>
                        <Grid className="content-center img-section" item xs={12} md={6}>
                            <img className="img-fluid object-fit-cover" src={network} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <PasswordResetModal passwordResetModalOpen={passwordResetModalOpen} setPasswordResetModalOpen={setPasswordResetModalOpen} />
        </StyledWrapper>
    );
}

export default ProfessionalLogin;

const StyledWrapper = styled.div`

    .email-textfield {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .content-center:not(.img-section) {
        align-items: unset !important;
        position: relative;
        left: -7%;
        @media (max-width: 960px) {
            left: unset;
        }
    }

    .email-textfield, .password-textfield {
        width: 100%;
        max-width: 376px;
    }

    .linkedin-section {
        width: 100%;
        max-width: 420px;
        @media (max-width: 960px) {
            display: block;
            margin: auto;
        }
    }

    .body-12 {
        color: rgba(0, 0, 0, 0.54);
        float: right;
        position: relative;
        right: 50px;
        margin-top: 5px;
        cursor: pointer;
        @media (max-width: 400px) {
            right: unset;
        }
    }

    .body-12:hover {
        text-decoration: underline;
    }

    .submit-btn {
        height: 70px;
        width: 100%;
        max-width: 376px;
        border-radius: 4px;
        margin-top: 50px;
        margin-bottom: 50px;
        background-color: #3f51b5 !important;
    }
    .submit-btn: hover{
        background-color: #303f9f !important;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .register-help {
        text-decoration: underline;
    }

    .network-intro {
        font-weight: 200;
        max-width: 400px;
    }

    .body-14 {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    }

    .linkedin-login {
        height: 70px;
        width: 100%;
        max-width: 379px;
        border-radius: 4px;
        background-color: #0077B7 !important;
        margin-top: 20px;


        .linkedin-logo {
            width: 35px;
            height: 35px;
        }

        .sign-in-linkedin {
            font-size: 15px;
            font-weight: 800;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: 20px;
        }
    }

    .login-section {
        @media (max-width: 960px) {
            order: 2;
        }
    }
`;

