import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
    createStyles({
        boxStyle: {
            borderColor: 'divider',
        },
        tabLabel: {
            fontFamily: 'Nunito Sans, sans-serif !important',
            fontWeight: 900,
            color: '#000 !important',
        },
        tabLabel__disabled: {
            color: '#9F9D9D !important',
        }
    })
);

type Props = {
    setTabValue: any;
    tabValue: number;
}

export default function UserTabs({ setTabValue, tabValue }: Props) {
    const classes = useStyles();

    const handleChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box className={classes.boxStyle}>
            <Tabs value={tabValue} textColor="primary" indicatorColor="primary" onChange={handleChange} >
                <Tab className={classes.tabLabel} label="Professionals" />
                <Tab className={classes.tabLabel__disabled} label="Alumni (Coming soon)" disabled />
            </Tabs>
        </Box>
    );
}