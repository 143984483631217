import { CircularProgress } from "@mui/material";
import { getAxiosInstance } from "common/utils";
import { useEffect, useState } from "react";
import styled from "styled-components";

type EmployerType = {
    name: string;
    id: string; 
    description: string;
    image: File;
    imageUrl: string;
  };
  
const EmployerOverview = () => {
    const [employers, setEmployers] = useState<EmployerType[]>([]);
    const [initialDataLoading, setInitialDataLoading] = useState<boolean>(true);

    const fetchAllEmployers = async () => {
        try {
            setInitialDataLoading(true);
            const axios = await getAxiosInstance();
            const { data } = await axios.get('/dashboard/fetchEmployers');

            const fetchAllEmployer = data.allEmployers
            const filteredEmployers = fetchAllEmployer.filter(
                (employer: EmployerType) => employer.description && employer.imageUrl && employer.name
            );
            const sortedEmployers = filteredEmployers.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));

            setEmployers(sortedEmployers);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setInitialDataLoading(false);
        }
    };
  
    useEffect(() => {
      fetchAllEmployers();
    }, []);
  
    // Each row contains only 4 companies
    const rows = [];
    for (let i = 0; i < employers.length; i += 4) {
      rows.push(employers.slice(i, i + 4));
    }

    return (
        <StyledWrapper>
            <div className="container contactUs mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h2 mb-3" >Employer Overviews</h3>
                        <p>Below you can see the employers that we have onboard the Social Mobility Network. To find out more about each employer, hover over their logo for a short description.</p>
                    </div>
                </div>
                {initialDataLoading ? (
                     <div className="d-flex justify-content-center align-items-center mt-5">
                     <CircularProgress className="no-user-text" />
                   </div>
                 ) : (
                    rows.map((row, rowIndex) => (
                        <div key={rowIndex} className="row">
                            {row.map((employer) => (
                                <div key={employer.id} className="col-md-3">
                                    <div className="text-center img">
                                       <img className="image" src={employer.imageUrl} alt="..." width={"150"} />
                                        <div className="overlay">
                                            <div className="text">
                                                <p>{employer.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                )}
            </div>
        </StyledWrapper>
    )
}
export default EmployerOverview;
const StyledWrapper = styled.div`

.overlay {
    position: absolute;
    display:flex;
    align-items:center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #304563;
  }
  
    .img:hover .overlay {
        opacity: 1;
        transform: scale(1.1);
    }
    .img {
        position: relative;
        // width: 200;
        height: 96px;
        margin-bottom: .75rem !important;
      }
  
  .text {
    color: white;
    font-size: 10.5px;
    position: absolute;
    // top: 18%;
    // left: 15%;
    margin-top: 1rem !important;
    left: 14%;
    right: -5%;
    -webkit-transform: translate(-11%, -11%);
    -ms-transform: translate(-11%, -11%);
    transform: translate(-11%, -11%);
    text-align: center;
    line-height: 1.4;
  }
  .text p {
    margin: 0px
  }
`;