import { useState, useEffect, createContext } from "react";
import { UserType } from "common/types";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAxiosInstance } from "common/utils";

type Props = {
  children: any;
};

const UserContext = createContext<any>(null);

const UserContextProvider = ({ children }: Props) => {
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const [professionals, setProfessionals] = useState<Array<any>>([]);
  const [students, setStudents] = useState<Array<any>>([]);

  useEffect(() => {
    if (user?.roles.includes(UserType.PROFESSIONAL)) {
      const userData = async () => {
        const axios = await getAxiosInstance();
        const response = await axios.get(
          `/users/fetchUsers?type=${UserType.STUDENT}`
        );
        setStudents(response.data);
      };
      userData();
    }

    if (user?.roles.includes(UserType.STUDENT) || user?.roles.includes(UserType.ALUMNI)) {
      const userData = async () => {
        const axios = await getAxiosInstance();
        const response = await axios.get(
          `/users/fetchUsers?type=${UserType.PROFESSIONAL}`
        );
        setProfessionals(response.data);
      };
      userData();
    }
  }, [user]);
  return (
    <UserContext.Provider
      value={{
        professionals: [...professionals],
        students: [...students],
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserContextProvider };
