import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { Button, Switch, Checkbox } from "@mui/material";
import TextField, { InputType } from "../TextField";
import { useDispatch, useSelector } from "react-redux";
import { useForm, ValidationError } from "@formspree/react";
import { setPromptModal } from "features/auth/slice";
import { selectUser } from "features/auth/selectors";
import { useState } from "react";
import { showToast, ToastMessage, ToastType } from "features/toast/slice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "800px",
      margin: "auto",
      [useTheme().breakpoints.down('xl')]: {
        width: "700px",
      },
      [useTheme().breakpoints.down('lg')]: {
        width: "400px",
      },
      [useTheme().breakpoints.down('sm')]: {
        width: "320px",
      },
    },
    paper: {
      position: "relative",
      backgroundColor: useTheme().palette.background.paper,
      boxShadow: useTheme().shadows[5],
      padding: "30px 90px 60px 90px",
      [useTheme().breakpoints.down('xl')]: {
        padding: "25px 60px 45px 60px",
      },
      [useTheme().breakpoints.down('lg')]: {
        padding: "22.5px 60px 40px 60px",
      },
      [useTheme().breakpoints.down('sm')]: {
        padding: "20px 45px 35px 45px",
      },
    },
    closeButton: {
      position: "absolute",
      color: "#464FD0",
      top: "20px",
      right: "5px",
    },
    permissionDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "15px 0",
    },
    subtext: {
      fontFamily: "Nunito Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      margin: "10px 0",
      padding: "0 10px",
    },
    subSubText: {
      fontFamily: "Nunito Sans",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
      margin: "7.5px 0",
      padding: "0 7.5px",
    },
    submitButton: {
      fontFamily: "Nunito Sans",
    },
    checklist: {
      listStyle: "none",
      padding: 0,
    },
  })
);

const TestimonialModal = () => {
  const dispatch = useDispatch();
  const user: any = useSelector(selectUser);
  const classes = useStyles();

  const [testimonial, setTestimonial] = useState<string>("");
  const [permission, setPermission] = useState<boolean>(true);
  const [universityPermission, setUniversityPermission] =
    useState<boolean>(true);
  const [namePermission, setNamePermission] = useState<boolean>(true);
  const [graduationYearPermission, setGraduationYearPermission] =
    useState<boolean>(true);

  const [form, handleFormSubmit] = useForm("xgedvlzg", {
    data: {
      testimonialPermission: JSON.stringify(permission),
      userId: JSON.stringify(user?.id),
      userName: JSON.stringify(`${user?.firstName} ${user?.lastName}`),
      namePermission: JSON.stringify(namePermission),
      universityName: JSON.stringify(user?.university?.name),
      universityPermission: JSON.stringify(universityPermission),
      graduationYear: JSON.stringify(user?.graduationYear),
      graduationYearPermission: JSON.stringify(graduationYearPermission),
    },
  });

  if (form.succeeded) {
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: "Testimonial submitted successfully",
    };
    dispatch(showToast(toast));
    dispatch(setPromptModal({ isOpen: false, ModalType: null }));
  }

  const handleButtonClick = () => {
    dispatch(setPromptModal({ isOpen: false, ModalType: null }));
  };

  const handleInputChange = (e: any) => {
    const testimonialInput: string = e.target.value;
    if (testimonialInput.split(" ").length > 150) return;
    setTestimonial(testimonialInput);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.paper}>
          <Button
            onClick={handleButtonClick}
            className={classes.closeButton}
            color="primary"
          >
            <CloseIcon />
          </Button>
          <p className={classes.subtext}>
            Please leave your testimonial below. We may use this in our
            marketing material and on our website
          </p>
          <form onSubmit={handleFormSubmit}>
            <TextField
              name="Testimonial"
              type={InputType.TEXT}
              label={`Enter your Testimonial (Max 150 words)`}
              multiline={true}
              value={testimonial}
              handleChange={(e: any) => handleInputChange(e)}
            />
            <ValidationError
              prefix="Testimonial"
              field="Testimonial"
              errors={form.errors}
            />
            <div className={classes.permissionDiv}>
              <Checkbox
                defaultChecked
                color="primary"
                onChange={() =>
                  setPermission((prevPermission) => !prevPermission)
                }
                checked={permission}
              />
              <p className={classes.subSubText}>
                Please tick this box to confirm you agree with the following
                statement: From time to time, we use may use this testimonial
                for various purposes including, without limitation, marketing
                and marketing publications, reporting to partner universities
                and employers, annual reports, impact reports, research, and
                fundraising efforts. As part of these efforts, we may also use
                certain identifying information including, without limitation,
                your name, university, or graduation year.
              </p>
            </div>
            <div>
              <p className={classes.subtext}>
                Which information are you happy to share alongside your
                testimonial? (please tick all that applies):
              </p>
              <ul className={classes.checklist}>
                <li className={classes.subSubText}>
                  <Switch
                    checked={universityPermission}
                    onClick={() =>
                      setUniversityPermission(
                        (prevPermission) => !prevPermission
                      )
                    }
                    value={universityPermission}
                    color="primary"
                  />
                  University Attended
                </li>
                <li className={classes.subSubText}>
                  <Switch
                    checked={namePermission}
                    onClick={() =>
                      setNamePermission((prevPermission) => !prevPermission)
                    }
                    value={namePermission}
                    color="primary"
                  />
                  First Name and Last Name
                </li>
                <li className={classes.subSubText}>
                  <Switch
                    checked={graduationYearPermission}
                    onClick={() =>
                      setGraduationYearPermission(
                        (prevPermission) => !prevPermission
                      )
                    }
                    value={graduationYearPermission}
                    color="primary"
                  />
                  Graduation Year
                </li>
              </ul>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              type="submit"
              disabled={testimonial.length < 25 || form.submitting}
            >
              Submit
            </Button>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default TestimonialModal;
