import { AppBar, Button, Avatar } from "@mui/material";
import { User, Interaction, Message, UserType } from "common/types";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectInteractions } from "features/interactions/selectors";
import { setPromptModal } from "features/auth/slice";
import { selectUser } from "features/auth/selectors";
import avatar from "common/assets/images/avatar.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: "#ffffff",
      boxShadow: "unset",
      color: "#464FD0",
      overflowY: "scroll",
      maxHeight: "400px",
    },
    content__div: {
      background: "rgba(158,165,238,0.1)",
      padding: "20px",
    },
    profile__div: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
    },
    profile__name: {
      color: "#000000",
      textDecoration: "none",
      marginLeft: "20px",
    },
    message: {
      fontStyle: "italic",
      fontWeight: "bold",
      margin: "0",
    },
    textCapitalize: {
      textTransform: "capitalize",
      position: "absolute",
      top: "0px",
      right: "30px",
      zIndex: 30,
    },
  })
);

const ThankYouMessagesModal = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const interactions = useSelector(selectInteractions);

  const [thankyouMessages, setThankyouMessages] = useState<any[]>([]);
  const [feedbackMessages, setFeedbackMessages] = useState<any[]>([]);

  useEffect(() => {
    let threads: Array<Message> = [];
    interactions?.forEach((interaction: Interaction) => {
      // below changed COMPLETED with INTERACTIONCOMPLETED(new one created)
      if (interaction.state === "INTERACTIONCOMPLETED") {
        threads = [...threads, ...interaction.thread];
      }
    });
    if (user?.roles?.includes(UserType.PROFESSIONAL)) {
      const thankyouThreads = threads?.filter(
        (thread: Message) => thread.type === "THANKS_NOTE"
      );
      setThankyouMessages(thankyouThreads);
    } else if (user?.roles?.includes(UserType.STUDENT)) {
      const feedBackThreads = threads?.filter(
        (thread: Message) => thread.type === "FEEDBACK_NOTE"
      );
      setFeedbackMessages(feedBackThreads);
    }
    // eslint-disable-next-line
  }, [interactions]);

  const user: User | null = useSelector(selectUser);

  if (!user) return null;

  const handleOnClose = () => {
    dispatch(setPromptModal({ isOpen: false, ModalType: "THANKYOU_MESSAGE" }));
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        {(user?.roles?.includes(UserType.PROFESSIONAL)
          ? thankyouMessages
          : feedbackMessages
        )?.map((message) => (
          <div className={classes.content__div}>
            <div className={classes.profile__div}>
              <a className="redirect-to" href={`/users/${message?.sender?.id}`}>
                <Avatar
                  className="profile-avatar"
                  src={message?.sender?.profilePicture || avatar}
                />
              </a>
              <span className={classes.profile__name}>
                {message?.sender?.firstName}
              </span>
            </div>
            <div>
              <p className={classes.message}>
                &Prime; {message?.payload} &Prime;
              </p>
            </div>
          </div>
        ))}
        <Button
          color="primary"
          className={classes.textCapitalize}
          onClick={handleOnClose}
        >
          Close
        </Button>
      </AppBar>
    </>
  );
};

export default ThankYouMessagesModal;
