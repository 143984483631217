import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { dismissToast, ToastMessage, ToastType } from "./slice";

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Toast = () => {
  const toast: ToastMessage = useSelector(
    (state: RootStateOrAny) => state.toast.queue[0]
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(dismissToast());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={toast?.type === ToastType.SUCCESS ? 10000 : null}
      onClose={handleClose}
      open={!!toast}
    >
      <div>
      <Alert severity={toast?.type} onClose={handleClose}>
        {toast?.message}
      </Alert>
      </div>
    </Snackbar>
  );
};

export default Toast;
