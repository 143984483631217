import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { InteractionType, UserType } from "common/types";
import { Link } from "react-router-dom";
// import styled from "styled-components";
import avatar from "common/assets/images/avatar.svg";
import Bookmark from "common/assets/images/svgToComponent/Bookmark";
import { bookmark, removeBookmark } from "./utils";
import recordEvent from "common/utils/mixpanel";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Workoutline from "@mui/icons-material/WorkOutline";
import ChatBubble from "@mui/icons-material/ChatBubbleOutlineRounded";
import styled from "@emotion/styled";

type Props = {
  user?: any;
  student?: any;
  isUserBookmarked?: any;
  fetchUsers?: any;
  isBookmarkPage?: boolean;
  isSuggestedProfessionalModal?: boolean;
  handleBtnClick?: (id: string) => void;
};

export const interactionTypeAvailability: any = {
  [InteractionType.EXPERT_ADVICE]: "Expert Advice",
  [InteractionType.CAREER_CHAT]: "Career Chat",
  [InteractionType.MOCK_INTERVIEW]: "Mock Interviews",
};

const ProfessionalUserCard = ({
  fetchUsers,
  user,
  student,
  isUserBookmarked,
  isBookmarkPage = false,
  isSuggestedProfessionalModal = false,
  handleBtnClick,
}: Props) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsBookmarked(isUserBookmarked);
  }, [isUserBookmarked]);

  const handleBookmarkClick = async (userId: any) => {
    await setIsLoading(true);
    if (isUserBookmarked) {
      await removeBookmark(userId);
    } else {
      await bookmark(userId);
    }
    if (isBookmarkPage) {
      await fetchUsers();
    } else {
      setIsBookmarked(!isBookmarked);
    }
    await setIsLoading(false);
  };

  const handleViewProfileClicked = () => {
    recordEvent("View Profile Click", {
      "Professionals Name": `${user.firstName} ${user?.lastName}`,
      "Professional Sector": user.sectors,
      "Professional Company": user.company?.name,
      "Professional Job Title": user.jobTitle,
      "Professional University": user.university?.name,
      "Professional Preferred Interaction Type":
        user.participatingInteractionTypes,
      "Student Gender": student.gender,
      "Student Region": student.region?.name,
      "Student Ethnicity": student.ethnicity?.name,
      "Student Year Of Graduation": student.graduationYear,
      "Student Age":
        new Date().getFullYear() - new Date(student.dateOfBirth).getFullYear(),
      "User Roles": student.roles,
    });
  };
  return (
    <StyledWrapper className='parent'>
      <Card className="request-card space-between">
        {isLoading ? (
          <CircularProgress className="progress-logo" />
        ) : (
          <Bookmark
            userId={user?.id}
            handleBookmarkClick={handleBookmarkClick}
            isBookmarked={isBookmarked}
          />
        )}
        <CardContent className="space-between root-div card-content">
          <Grid container className='first-div'>
            <Grid item xs={4}>
              <Link className="redirect-to" to={`/users/${user?.id}`}>
                <Avatar
                  className="profile-avatar"
                  src={user?.profilePicture || avatar}
                />
              </Link>
            </Grid>
            <Grid item xs={8}>
              <Link className="redirect-to" to={`/users/${user?.id}`}>
                <p className="body-20 font-weight-bolder mb-0 usercard-name">
                  {user.firstName} {user.lastName}
                </p>
              </Link>
              <div className="profile-content">
                {user?.roles?.includes(UserType.PROFESSIONAL) && (
                  <p className="body-14 font-weight-bolder mt-0 usercard-name">
                    {user?.company?.name}
                  </p>
                )}
                {user?.roles?.includes(UserType.ALUMNI) && (
                  <>
                    <p className="body-14 font-weight-bolder mt-0 usercard-name">
                      {user?.company}
                    </p>
                    <div className="alumni-pill">Alumni</div>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
          <Box className="profile-info second-div">
            <div className='job-title__div'>
              <span className='job-title__icon'><Workoutline/> </span>
              <p className="body-15 my-0 jobtitle">{user?.jobTitle}</p>
            </div>
            {user?.participatingInteractionTypes && (
              <>
                <div className="available-for-details">
                  <span className="available-for text-uppercase">
                    <ChatBubble/>
                  </span>{" "}
                  <div className='available-for-text'>
                    {user.participatingInteractionTypes.map(
                      (type: any, i: number) => (
                        <span>
                          {interactionTypeAvailability[type]}
                          {user.participatingInteractionTypes.length - 1 === i
                            ? ""
                            : ","}{" "}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </Box>
          <div className="view-section">
            {!isSuggestedProfessionalModal ? (
              <Link className="usercard-link" to={`/users/${user.id}`}>
                <Button
                  color="primary"
                  className="mx-auto view-btn font-family-nunito"
                  onClick={handleViewProfileClicked}
                >
                  View Profile
                </Button>
              </Link>
            ) : (
              <Button
                color="primary"
                className="mx-auto view-btn font-family-nunito"
                onClick={() => {
                  handleBtnClick && handleBtnClick(user.id);
                }}
              >
                View Profile
              </Button>
            )}
          </div>
        </CardContent>
        {/* <CardActions className="card-actions">
            <Link to={`/users/${user.id}`}>
              <Button size="small">View Profile</Button>
            </Link>
          </CardActions> */}
      </Card>
    </StyledWrapper>
  );
};

export default ProfessionalUserCard;

const StyledWrapper = styled.div`
  .request-card {
    position: relative;
    min-height: 240px;
    width: 380px;
    padding: 0 !important;
    .MuiCardContent-root {
      padding: 0 !important;
      width: 380px;
      min-height: 220px;
    }
  }

  .root-div {
    padding: 0 !important;
  }

  .card-content {
    width: 100%;
  }

  .first-div,
  .second-div {
    padding: 16px;
  }

  .profile-avatar {
    width: 60px;
    height: 60px;
    margin-left: 3px;
    margin-right: 5px;
  }

  .bookmark-logo {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    padding: 8px 5px;
    svg {
      width: 30px;
      height: 20px;
    }
  }

  .usercard-name {
    margin-bottom: 2px;
  }

  .profile-content {
    display: flex;
  }

  .job-title__div {
    display: flex;
    align-items: center;
  }

  .job-title__icon {
    margin-right: 10px;
  }

  .available-for-details {
    display: flex;
  }

  .available-for-text {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px; 
  }

  .progress-logo {
    position: absolute;
    top: 10px;
    color: #9ea5ee !important;
    right: 10px;
    width: 25px !important;
    height: 25px !important;
    
  }

  .view-section {
    max-height: 40px;
    background: #DEE1FF;
    padding-bottom: 8px;
  }

  .view-btn {
    font-weight: bold;
    font-size: 18px !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    color: #3f51b5;
  }

  .jobtitle {
    font-weight: 600;
  }

  .sectors-list {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }

  .redirect-to {
    color: black;
    text-decoration: none;
  }

  .redirect-to:hover {
    text-decoration: underline;
  }
`;
