import { Button, Grid } from "@mui/material";
import styled from "styled-components";

type Props = {
  backButtonLabel: string;
  backButtonCallback: Function;
  nextButtonLabel: string;
  nextButtonCallback: Function;
  disabled?: boolean;
  activeStep?: number;
  isRegister?: boolean;
};
const StepperControls = ({
  backButtonLabel,
  backButtonCallback,
  nextButtonLabel,
  nextButtonCallback,
  disabled,
  activeStep,
  isRegister,
}:
  Props) => (
  <StyledWrapper>
    <Grid container item className="stepper-actions">
      <Button
        disabled={activeStep === 0}
        onClick={() => backButtonCallback()}
        variant="outlined"
        className="prev-btn"
        type="submit"
      >
        {backButtonLabel}
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={disabled}
        onClick={() => nextButtonCallback()}
        className="next-btn"
      >
        {isRegister ? "LOADING.." : nextButtonLabel}
      </Button>
    </Grid>
  </StyledWrapper>
);

const StyledWrapper = styled.div`
  button {
    height: 50px;
    width: 80px;
    @media (max-width: 600px) {
     width: 100% !important;
    }
  }

  .prev-btn {
    margin-right: 50px;
    @media (max-width: 600px) {
      margin-right: 0;
      margin-top: 30px;
      order: 2;
    }
  }

  .next-btn {
    @media (max-width: 600px) {
      margin-left: auto;
    }
  }

  button {
    margin-top: 20px;
    width: 160px;
    height: 40px;
  }
`;

export default StepperControls;
