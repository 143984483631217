import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import firebase from "app/firebase";
import withStyles from '@mui/styles/withStyles';

// Expects the following props:
// "collection" - Collection name as a String (REQUIRED)
// "handleChange" - Function that is executed with new value when value changes (REQUIRED)
// "initialID" - DocumentID of current value as a String (OPTIONAL)

export type DocumentDefaultValue = {
    id: string,
    name: string,
    value: number
}

type Props = {
  handleChange: any;
  collection?: string;
  defaultCollection?: object[];
  initialID?: string | null;
  locked?: boolean | null;
  initialKey?: any;
  getLabel?: any;
  multiple?: boolean;
  error?: any;
  name: string;
  required?: boolean;
  defaultValue?: DocumentDefaultValue;
  defaultValues?: DocumentDefaultValue[];
  inputRef?: any;
  label: string;
  disabled?: boolean;
};

const DocumentPicker = ({
  handleChange,
  collection,
  defaultCollection,
  multiple = false,
  initialID = null,
  getLabel,
  locked,
  initialKey,
  name,
  error,
  required,
  defaultValue,
  defaultValues = [],
  inputRef,
  label,
  disabled = false,
}: Props) => {
  const [documents, setDocuments] = useState<any>([]);
  let [value, setValue] = useState<any>(multiple ? [] : null);

  const CustomAutocomplete = withStyles({
    tag: {
      backgroundColor: "#ecedfc",
      "& .MuiChip-label": {
        color: "#304563",
      },
      "& .MuiChip-deleteIcon": {
        color: "#464FD0",
      },
    }
  })(Autocomplete);

  useEffect(() => {
      if(defaultValues && defaultValues.length > 0) {
        setValue(defaultValues);
      } 
      if(defaultValue) {
        setValue(defaultValue);
      }
  }, [defaultValue, defaultValues])

  useEffect(() => {
    if(collection){
      firebase
        .firestore()
        .collection(collection)
        .get()
        .then((querySnapshot) => {
          let docs: any = [];
          querySnapshot.forEach((doc) => {
            docs.push({
              id: doc.id,
              name: getLabel ? getLabel(doc.data()) : doc.data().name,
            });
            if (doc.id === initialID)
              setValue({
                id: doc.id,
                name: getLabel ? getLabel(doc.data()) : doc.data().name,
              });
          });
          setDocuments(docs);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [collection, initialID, getLabel]);
  return (
    // TODO: Asynchronous Autocomplete
    <CustomAutocomplete
      className="autocomplete-field"
      multiple={multiple}
      style={{ width: "100%" }}
      onChange={(_, v: any) => {
        setValue(v);
        handleChange(collection ? v : v.value);
      }}
      options={collection ? documents : defaultCollection}
      value={value}
      getOptionLabel={(doc: any) => doc.name}
      disabled={disabled}
      renderInput={(params) => (
        <TextField name={name} className="autocomplete-textfield" {...params}
        error={error}
        inputRef={inputRef}
        helperText={error ? "This field is required" : ''}
        label={label} variant="outlined" fullWidth required={required} />
      )}
    />
  );
};
export default DocumentPicker;
