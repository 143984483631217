import { Grid, Checkbox, FormControlLabel, Button } from "@mui/material";
import RadioButtonGroup, {
  RadioButtonData,
} from "common/components/RadioButtonGroup";
import StepperControls from "common/components/StepperControls";
import TextField, { InputType } from "common/components/TextField";
import {
  EmailChoices,
  GenderChoices,
  InteractionType,
  UserType,
} from "common/types/";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import styled from "styled-components";
import {
  BasicDetails,
  goToNextStep,
  goToPrevStep,
  setBasicDetails,
  setIsNextStep,
  setProfessionalMetadata,
} from "./professionalRegistrationSlice";
import FormLabel from "@mui/material/FormLabel";
import DocumentPicker from "../../../common/components/DocumentPicker";

import { selectAuthUser, selectUser } from "features/auth/selectors";
import {
  selectBasicDetails,
  selectIsNextStep,
  selectProfessionalMetadata,
  selectStepperActiveStep,
} from "./selectors";
import firebase from "firebase";
import Avatar from "@mui/material/Avatar";
// import Skeleton from '@mui/lab/Skeleton';
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
import { setOnLoginRedirectRoute } from "features/auth/slice";
import {CircularProgress} from "@mui/material";
import styled from "@emotion/styled";

const preferredVideoConferencing: Array<RadioButtonData> = [
  { label: "Zoom", value: "Zoom" },
  { label: "Google Meet", value: "Google Meet" },
  { label: "Other", value: "Other" },
];

const genderRadioButtons: Array<RadioButtonData> = [
  { label: "Male", value: GenderChoices.MALE },
  { label: "Female", value: GenderChoices.FEMALE },
  { label: "Other", value: GenderChoices.OTHER },
  { label: "Do not wish to specify", value: GenderChoices.NOT_SPECIFIED },
];

const preferredEmailChoices: Array<RadioButtonData> = [
  { label: "Work Email", value: EmailChoices.WORK_EMAIL },
  { label: "Personal Email", value: EmailChoices.PERSONAL_EMAIL },
];

const interactionTypeAvailability: Array<RadioButtonData> = [
  
  { label: "Career Chats - a 1-to-1 call with a student", value: InteractionType.CAREER_CHAT },
  {
    label: "Expert Advice - a conversation with a student via direct messaging",
    value: InteractionType.EXPERT_ADVICE,
  },
  { label: "Mock Interviews - a practice interview with a student", value: InteractionType.MOCK_INTERVIEW },
];

type Props = {
  isMyAccount?: boolean;
}

const PersonalDetails = ({ isMyAccount }: Props) => {
  const authUser = useSelector(selectAuthUser);
  const isNextStep = useSelector(selectIsNextStep);
  const activeStep = useSelector(selectStepperActiveStep);
  const metadata: any = useSelector(selectProfessionalMetadata);
  const dispatch = useDispatch();
  const [selectedEthnicity, setSelectedEthnicity] = useState<any | null>();
  const [selectedRegion, setSelectedRegion] = useState<any | null>();
  const [profileUrl, setProfileUrl] = useState<any>("");
  const [profilePictureUploading, setProfilePictureUploading] = useState<boolean>(false);

  const [profileError, setProfileError]=useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [
    selectedPreferredVideoConferencing,
    setSelectedPreferredVideoConferencing,
  ] = useState<any | null>();
  const [otherPreferredVideoConferencing, setOtherPreferredVideoConferencing] =
    useState<any | null>();
  const basicDetails: BasicDetails = useSelector(selectBasicDetails);
  const user: any = useSelector(selectUser);
  const [bioCharacterLimit, setBioCharacterLimit] = useState<string>(user?.bio ? user?.bio : "");

  const userBasicDetails = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    preferredName: user?.preferredName,
    email: user?.email,
    personalEmail: user?.personalEmail,
    preferredEmail: user?.preferredEmail === basicDetails.personalEmail
      ? EmailChoices.PERSONAL_EMAIL
      : EmailChoices.WORK_EMAIL,
    gender: user?.gender,
    region: user?.region,
    ethnicity: user?.ethnicity,
    profilePicture: user?.profilePicture,
    bio: user?.bio,
    preferredVideoConferencing: user?.preferredVideoConferencing,
    participatingInteractionTypes: user?.participatingInteractionTypes,
    linkedinUrl: user?.linkedinUrl,
  }

  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: !isMyAccount ? {
      ...basicDetails,
      region: basicDetails.region,
      email: authUser?.email,
      preferredEmail:
        basicDetails.preferredEmail === basicDetails.personalEmail
          ? EmailChoices.PERSONAL_EMAIL
          : EmailChoices.WORK_EMAIL,
    } : userBasicDetails,
  });

  const uploadPhoto = async (file: any) => {
    setProfilePictureUploading(true);
    setIsLoading(true)
    const firebaseStorageRef = firebase.storage().ref("users/" + authUser?.id + '/profile.jpg');
    await firebaseStorageRef.put(file[0]).then(() => {
      console.log("Image successfully uploaded");
      setProfileError(null);
    }).catch((error) => {
      console.log("error uploading image", error)
      setProfilePictureUploading(false);
    })
    await firebaseStorageRef.getDownloadURL().then((url) => {
      setProfileUrl(url);
      // setProfilePictureUploading(false);
      setTimeout(() => {
        setIsLoading(false)
      }, 4000);
    }).catch((error) => {
      console.log("error fetching user profile url", error)
      // setProfilePictureUploading(false);
    })
  }

  useEffect(() => {
    // if (user?.profilePicture) setProfileUrl(user?.profilePicture);
    dispatch(setOnLoginRedirectRoute(null));
    if (window.location.hash) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isMyAccount) {
      setSelectedEthnicity(user.ethnicity);
      setSelectedRegion(user.region);
    } else {
      if(basicDetails.profilePicture !== undefined) {
        if(basicDetails.profilePicture !== '' && !(basicDetails.profilePicture.includes("img/associate-profiles/avatar.jpg"))) {
          setProfilePictureUploading(true);
        } else {
          setProfilePictureUploading(false);
        }
      }
      setProfileUrl(basicDetails?.profilePicture);
      setSelectedEthnicity(basicDetails.ethnicity);
      setSelectedRegion(basicDetails.region);
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: "Your email address has been successfully verified!",
      };
      dispatch(showToast(toast));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    if(profilePictureUploading === false || profilePictureUploading === null || profilePictureUploading === undefined) {
      setProfileError("upload your photo")
    }else {
      setProfileError(null)
    }
    data.email = authUser?.email;
    if (data.preferredEmail === EmailChoices.PERSONAL_EMAIL) {
      data.preferredEmail = data.personalEmail;
    } else {
      data.preferredEmail = data.email;
    }
    data.ethnicity = selectedEthnicity;
    data.region = selectedRegion;
    data.profilePicture = profileUrl;
    data.roles = [UserType.PROFESSIONAL];
    if (otherPreferredVideoConferencing) {
      data.preferredVideoConferencing = otherPreferredVideoConferencing;
    }
    dispatch(setBasicDetails(data));
    dispatch(setProfessionalMetadata({
      ...metadata,
    }))
    // if (isNextStep) {
    //   dispatch(goToNextStep());
    // }
    if ((profilePictureUploading === true) && isNextStep) {
      dispatch(goToNextStep());
    }
  };

  return (
    <StyledForm
      className="registration-box-shadow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12}>
          <h3 className="prof-registration-heading">
            {isMyAccount ? "Edit your information below" : "To complete your profile, please add the information below."}
          </h3>
        </Grid>
        <Grid item xs={12} lg={6} className="req-ast">
          <TextField
            name="firstName"
            type={InputType.TEXT}
            inputRef={register({ required: true })}
            label="First Name"
            error={!!errors.firstName}
            helperText={errors.firstName ? "Please enter your first name" : ""}
          />
        </Grid>
        <Grid item xs={12} lg={6} className="req-ast">
          <TextField
            name="lastName"
            type={InputType.TEXT}
            inputRef={register({ required: true })}
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors.lastName ? "Please enter your last name" : ""}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            name="preferredName"
            type={InputType.TEXT}
            inputRef={register}
            label="Preferred Name"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6}>
          <TextField
            name="email"
            type={InputType.EMAIL}
            inputRef={register}
            label="Work Email"
            defaultValue={authUser?.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            name="personalEmail"
            type={InputType.EMAIL}
            inputRef={register}
            label="Personal Email"
          />
        </Grid>
        <Grid item xs={12} lg={7} className="req-ast-leg">
          <RadioButtonGroup
            name="preferredEmail"
            radioButtons={preferredEmailChoices}
            formLabel="Preferred Email"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12}>
          <RadioButtonGroup
            name="gender"
            radioButtons={genderRadioButtons}
            formLabel="Gender"
            control={control}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} md={6}>
          <FormLabel component="legend">Additional Information</FormLabel>
          <DocumentPicker
            handleChange={(selectedRegion: any) =>
              setSelectedRegion(selectedRegion)
            }
            collection="regions"
            error={!!errors.region}
            name="region"
            defaultValue={selectedRegion}
            label="Region"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} md={6}>
          <DocumentPicker
            handleChange={(selectedEthnicity: any) =>
              setSelectedEthnicity(selectedEthnicity)
            }
            collection="ethnicities"
            error={!!errors.ethnicity}
            name="ethnicity"
            defaultValue={selectedEthnicity}
            label="Ethnicity"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6} className="req-ast-leg">
          <RadioButtonGroup
            name="preferredVideoConferencing"
            radioButtons={preferredVideoConferencing}
            formLabel="Select your preferred Video Conferencing Options"
            control={control}
            handleChange={(e: any) =>
              setSelectedPreferredVideoConferencing(e.target.value)
            }
          />
          {errors && errors.preferredVideoConferencing && (
            <p className="rhf-errors">
              {(errors.preferredVideoConferencing as any).message}
            </p>
          )}
          {selectedPreferredVideoConferencing === "Other" && (
            <TextField
              name="preferredVideoConferencing"
              type={InputType.TEXT}
              inputRef={register({
                required: !!otherPreferredVideoConferencing,
              })}
              label="Please specify"
              error={!!errors?.preferredVideoConferencing}
              helperText={
                errors?.preferredVideoConferencing && "Please specify"
              }
              handleChange={(e: any) =>
                setOtherPreferredVideoConferencing(e.target.value)
              }
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6}>
          <FormLabel component="legend">
            LinkedIn profile
          </FormLabel>
          <TextField
            inputRef={register}
            name="linkedinUrl"
            type={InputType.TEXT}
            label="LinkedIn profile URL"
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} item lg={12}>
        <Grid className="participatingInteractionTypes req-ast-leg" item xs={12}>
          <FormLabel component="legend">
            There are three types of interactions which students can request of
            you, please select the interaction types that you are happy to
            participate in:
          </FormLabel>
          {interactionTypeAvailability.map((option) => (
            <>
              <FormControlLabel
                value={option.value}
                control={
                  <Checkbox
                    defaultChecked={isMyAccount ? user?.participatingInteractionTypes.includes(
                      option.value
                    ) : basicDetails?.participatingInteractionTypes.includes(
                      option.value
                    )}
                  />
                }
                label={option.label}
                name="participatingInteractionTypes"
                inputRef={register({ required: "This field is required." })}
              />
              <br />
            </>
          ))}
          {errors && errors.participatingInteractionTypes && (
            <p className="rhf-errors">
              {(errors.participatingInteractionTypes as any).message}
            </p>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6}>
          <p className="body-14 font-weight-normal text-muted">Add a short biography to tell the student a little bit about you and your experiences.<span className="text-danger">*</span></p>
          <TextField
            inputRef={register({required:true})}
            error={!!errors.bio}
            helperText={errors.bio ? "Please add a short bio" : ""} 
            name="bio"
            multiline
            type={InputType.TEXT}
            label="Type your bio here"
            inputProp={{ maxLength: 1000 }}
            handleChange={(e: any) => setBioCharacterLimit(e.target.value)}
          />
          <span className="body-12">{bioCharacterLimit.length}/1000</span>
        </Grid>
      </Grid>
      <Grid container spacing={4} item lg={12}>
        <Grid item xs={12} lg={6}>
          {/* <div className="d-flex">
            {!profilePictureUploading ?
              <Avatar
                className="profile-picture"
                alt=""
                src={profileUrl}
              /> : <Skeleton className="profile-picture" variant="circle" />}
            <div className="upload-section">
              <Button
                color="primary"
                component="label"
              >
                Upload Photo&nbsp;<span className="text-danger">*</span>
                <input
                  type="file"
                  hidden
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => uploadPhoto(e.target.files)}
                />
              </Button>
            </div>
          </div> */}
          <p className="body-14 font-weight-normal text-muted">Add a profile picture so the student knows who they are speaking to.<span className="text-danger">*</span></p>
          <div className="d-flex">
              <Avatar
                className="profile-picture"
                alt=""
                src={profileUrl}
              />{ isLoading ? (
                <CircularProgress className="no-user-text" />
              ) : (
              <div className="upload-section">
                <Button
                  color="primary"
                  component="label"
                >
                  Upload Photo
                  <input
                    type="file"
                    hidden
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadPhoto(e.target.files)}
                  />
                </Button>
                <p className="text-danger">{profileError}</p>
              </div>
              )}
            </div>
        </Grid>
      </Grid>
      <StepperControls
        disabled={false}
        activeStep={activeStep}
        nextButtonLabel="Next"
        nextButtonCallback={() => {
          dispatch(setIsNextStep(true));
        }}
        backButtonLabel="Back"
        backButtonCallback={() => {
          dispatch(setIsNextStep(false));
          dispatch(goToPrevStep());
        }}
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: 2rem;

  .profile-picture {
    width: 80px;
    height: 80px;
  }

  .upload-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
  }
`;

export default PersonalDetails;
