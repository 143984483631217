import recordEvent from "common/utils/mixpanel";
import { useEffect, useState } from "react";

const useMixPanelForFilters = (
  user: any,
  sectorFilter?: string,
  interactionFilter?: string,
  companyFilter?: string,
  universityFilter?: string,
  regionFilter?: string,
  genderFilter?: string
) => {
  const [payload, setPayload] = useState<any>();

  useEffect(() => {
    if (user) {
      const tempPayLoad = {
        "Student Gender": user.gender,
        "Student Region": user.region?.name,
        "Student Ethnicity": user.ethnicity?.name,
        "Student Year Of Graduation": user.graduationYear,
        "Student Age": new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
        "User Roles": user.roles,
      };
      setPayload(tempPayLoad);
    }
  }, [user]);

  useEffect(() => {
    if (payload) {
      recordEvent("Search Clicked", {
        ...payload,
        "Search Sector": sectorFilter ? sectorFilter : "any",
        "Search Gender": genderFilter ? genderFilter : "any",
        "Seach Interaction Type": interactionFilter ? interactionFilter : "any",
        "Search Company": companyFilter ? companyFilter : "any",
        "Search University": universityFilter ? universityFilter : "any",
        "Search Region": regionFilter ? regionFilter : "any",
      });
    }
  }, [
    sectorFilter,
    interactionFilter,
    companyFilter,
    universityFilter,
    regionFilter,
    genderFilter,
    payload,
    user,
  ]);
};

export default useMixPanelForFilters;
