import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import firebase from "app/firebase";
import { RootStateOrAny, useSelector } from "react-redux";
import { ConnectionState, User } from "common/types";
import StudentUserCard from "./StudentUserCard";

const Network = () => {
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  const [connections, setConnections] = useState<Array<User>>([]);

  const extractConnections = (docs: Array<any>) => {
    let c: Array<User> = docs.map((doc) => {
      return connections + doc.data().sender.id === user.id
        ? doc.data().receiver
        : doc.data().sender;
    });
    setConnections(c);
  };

  useEffect(() => {
    let unsubscribe;
    const fetchConnections = async () => {
      unsubscribe = firebase
        .firestore()
        .collection("connections")
        .where("users", "array-contains", user.id)
        .where("state", "==", ConnectionState.CONNECTED)
        .onSnapshot((snapshot) => extractConnections(snapshot.docs));
    };
    if (user) fetchConnections();
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Grid
        container
        item
        spacing={3}
        direction="row"
        style={{ padding: "30px 0px" }}
      >
        {connections.map((connection) => (
          <Grid key={connection.id} item lg={3}>
            <StudentUserCard user={connection} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Network;
