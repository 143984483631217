import { call, takeLatest, put, select } from "redux-saga/effects";
import firebase from "app/firebase";
import {
  login,
  setOnLoginRedirectRoute,
  toggleRegistrationFormWorking,
} from "features/auth/slice";
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
import {
  selectBasicDetails as selectProfessionalBasicDetails,
  selectProfessionalMetadata,
  selectUniversityDetails as selectProfessionalUniversityDetails,
  selectWorkDetails as selectProfessionalWorkDetails,
} from "./professional/selectors";
import {
  selectAlumniMetadata,
  selectBasicDetails as selectAlumniBasicDetails,
  selectUniversityDetails as selectAlumniUniversityDetails,
  selectWorkDetails as selectAlumniWorkDetails,
} from "./alumni/selectors";
import {
  selectBasicDetails as selectStudentBasicDetails,
  selectMetadata,
  selectUniversityDetails as selectStudentUniversityDetails,
} from "./student/selectors";
import { createUserProfile, updateUserProfile } from "./utils";
import { selectAuthUser } from "features/auth/selectors";
import { setActiveStep as studentStepperCounter } from "./student/studentRegistrationSlice";
import { setActiveStep as professionalStepperCounter } from "./professional/professionalRegistrationSlice";
import { setActiveStep as alumniStepperCounter } from "./alumni/alumniRegistrationSlice";

const createFirebaseUser = async (email: string, password: string) =>
  await firebase.auth().createUserWithEmailAndPassword(email, password);

function* registerUser(action: any) {
  const { email, password } = action.payload;
  try {
    yield call(createFirebaseUser, email, password);
  } catch (error: any) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: error.message,
    };
    yield put(showToast(toast));
  }
  yield put(toggleRegistrationFormWorking());
}

function* registerProfessional(): any {
  const basicDetails = yield select(selectProfessionalBasicDetails);
  const universityDetails = yield select(selectProfessionalUniversityDetails);
  const workDetails = yield select(selectProfessionalWorkDetails);
  const id = firebase.auth().currentUser?.uid;

  try {
    const userProfile = yield createUserProfile({
      id,
      ...basicDetails,
      ...universityDetails,
      ...workDetails,
    });
    yield put(login(userProfile.data));
    yield put(setOnLoginRedirectRoute("/"));
    yield put(professionalStepperCounter(0));
  } catch (error: any) {
    if (error.response.status === 400) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error.response.data.message,
      };
      yield put(showToast(toast));
    } else {
      console.log("Unable to register the user", error.response);
    }
  }
}

function* professionalProfileUpdate(): any {
  const basicDetails = yield select(selectProfessionalBasicDetails);
  const universityDetails = yield select(selectProfessionalUniversityDetails);
  const workDetails = yield select(selectProfessionalWorkDetails);
  const id = firebase.auth().currentUser?.uid;
  const metadata = yield select(selectProfessionalMetadata);
  try {
    const userProfile = yield updateUserProfile({
      id,
      ...basicDetails,
      ...universityDetails,
      ...workDetails,
      metadata
    });
    yield put(login(userProfile.data));
    yield put(professionalStepperCounter(0));
    yield put(setOnLoginRedirectRoute(`/users/${id}`));
  } catch (error) {
    console.log("Unable to register the user", error);
  }
}

function* alumniProfileUpdate(): any {
  const basicDetails = yield select(selectAlumniBasicDetails);
  const universityDetails = yield select(selectAlumniUniversityDetails);
  const workDetails = yield select(selectAlumniWorkDetails);
  const id = firebase.auth().currentUser?.uid;
  const metadata = yield select(selectAlumniMetadata);
  try {
    const userProfile = yield updateUserProfile({
      id,
      ...basicDetails,
      ...universityDetails,
      ...workDetails,
      metadata
    });
    yield put(login(userProfile.data));
    yield put(alumniStepperCounter(0));
    yield put(setOnLoginRedirectRoute(`/users/${id}`));
  } catch (error) {
    console.log("Unable to register the user", error);
  }
}

function* registerStudent(): any {
  const basicDetails = yield select(selectStudentBasicDetails);
  const universityDetails = yield select(selectStudentUniversityDetails);
  const metadata = yield select(selectMetadata);
  const { id } = yield select(selectAuthUser);

  try {
    const userProfile = yield createUserProfile({
      id,
      metadata,
      ...basicDetails,
      ...universityDetails,
    });
    yield put(login(userProfile.data));
    yield put(setOnLoginRedirectRoute("/"));
    yield put(studentStepperCounter(0));
  } catch (error) {
    console.log("Unable to register the user", error);
  }
}

function* studentProfileUpdate(): any {
  const basicDetails = yield select(selectStudentBasicDetails);
  const universityDetails = yield select(selectStudentUniversityDetails);
  const metadata = yield select(selectMetadata);
  const { id } = yield select(selectAuthUser);
  try {
    const userProfile = yield updateUserProfile({
      id,
      metadata,
      ...basicDetails,
      ...universityDetails,
    });
    yield put(login(userProfile.data));
    yield put(studentStepperCounter(0));
    yield put(setOnLoginRedirectRoute(`/users/${id}`));
  } catch (error) {
    console.log("Unable to register the user", error);
  }
}

function* alumniRegistration(): any {
  const basicDetails = yield select(selectAlumniBasicDetails);
  const universityDetails = yield select(selectAlumniUniversityDetails);
  const workDetails = yield select(selectAlumniWorkDetails);
  const id = firebase.auth().currentUser?.uid;

  try {
    const userProfile = yield createUserProfile({
      id,
      ...basicDetails,
      ...universityDetails,
      ...workDetails,
    });
    yield put(login(userProfile.data));
    yield put(setOnLoginRedirectRoute("/"));
    yield put(professionalStepperCounter(0));
  } catch (error: any) {
    if (error.response.status === 400) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error.response.data.message,
      };
      yield put(showToast(toast));
    } else {
      console.log("Unable to register the user", error.response);
    }
  }
}

export default function* registrationSaga() {
  yield takeLatest("TYPEFORM_REGISTRATION_ATTEMPT", registerUser);
  yield takeLatest("PROFESSIONAL_REGISTRATION_ATTEMPT", registerProfessional);
  yield takeLatest("PROFESSIONAL_PROFILE_UPDATE_ATTEMPT", professionalProfileUpdate);
  yield takeLatest("STUDENT_REGISTRATION_ATTEMPT", registerStudent);
  yield takeLatest("STUDENT_PROFILE_UPDATE_ATTEMPT", studentProfileUpdate);
  yield takeLatest("ALUMNI_REGISTRATION_ATTEMPT", alumniRegistration);
  yield takeLatest("ALUMNI_PROFILE_UPDATE_ATTEMPT", alumniProfileUpdate);
}
