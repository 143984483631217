import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import firebase from "app/firebase";

// Expects the following props:
// "collection" - Collection name as a String (REQUIRED)
// "handleChange" - Function that is executed with new value when value changes (REQUIRED)
// "initialID" - DocumentID of current value as a String (OPTIONAL)

type DocumentDefaultValue = {
    id: string,
    name: string,
}

type Props = {
  handleChange: any;
  collection: string;
  initialID?: string | null;
  locked?: boolean | null;
  initialKey?: any;
  getLabel?: any;
  multiple?: boolean;
  error?: any;
  name: string;
  required?: boolean;
  defaultValue?: DocumentDefaultValue;
  defaultValues?: DocumentDefaultValue[];
  inputRef?: any;
  label: string;
  disabled?: boolean;
};

const DocumentPicker = ({
  handleChange,
  collection,
  multiple = false,
  initialID = null,
  getLabel,
  locked,
  initialKey,
  name,
  error,
  required,
  defaultValue,
  defaultValues = [],
  inputRef,
  label,
  disabled = false,
}: Props) => {
  const [documents, setDocuments] = useState<any>([]);
  let [value, setValue] = useState<any>(multiple ? [] : null);

  useEffect(() => {
      if(defaultValues && defaultValues.length > 0) {
        setValue(defaultValues);
      } 
      if(defaultValue) {
        setValue(defaultValue);
      }
  }, [defaultValue, defaultValues])

  useEffect(() => {
    firebase
      .firestore()
      .collection(collection)
      .get()
      .then((querySnapshot) => {
        let docs: any = [];
        querySnapshot.forEach((doc) => {
          docs.push({
            id: doc.id,
            name: getLabel ? getLabel(doc.data()) : doc.data().name,
          });
          if (doc.id === initialID)
            setValue({
              id: doc.id,
              name: getLabel ? getLabel(doc.data()) : doc.data().name,
            });
        });
        setDocuments(docs);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, initialID]);
  return (
    // TODO: Asynchronous Autocomplete
    <Autocomplete
      className="autocomplete-field"
      multiple={multiple}
      style={{ width: "100%" }}
      onChange={(_, v: any) => {
        setValue(v);
        handleChange(v);
      }}
      options={documents}
      value={value}
      getOptionLabel={(doc: any) => doc.name}
      disabled={disabled}
      renderInput={(params) => (
        <TextField name={name} className="autocomplete-textfield" {...params}
        error={!!error}
        inputRef={inputRef}
        helperText={error ? "This field is required" : ''}
        label={label} variant="outlined" fullWidth required={required} />
      )}
    />
  );
};
export default DocumentPicker;
