import { UserType } from "common/types";
import { getAxiosInstance } from "common/utils";

export const searchProfessionals = async (query:string, filters: string, page: number, queryForAlumnis: boolean, sortColumn: string, sortOrder: string) => {
  const axios = await getAxiosInstance();
  try {
    const searchResults = await axios.post("/search/query", {
      searchQuery: query,
      filters,
      page,
      queryForAlumnis,
      sortColumn,
      sortOrder,
    });
    return searchResults.data;
  } catch (error) {
    console.log(error);
  }
};

export const createFilter = (
  interactionFilter: string,
  sectorFilter: string,
  companyFilter: string,
  universityFilter: string,
  regionFilter: string,
  genderFilter: string,
  userRoles?: string,
) => {
  const filterArray = [];
  // SMN 36 ghosting functionality
  filterArray.push("NOT metadata.isGhost: true");

  filterArray.push("metadata.isDisabled: false");
  filterArray.push("NOT hasGraduateJob: false");

  if (userRoles?.includes(UserType.ALUMNI)) {
    filterArray.push("metadata.isAvailableForAlumni: true");
  }

  // Below code is commented for SMN 97 changes
  // //Temporary filter out for blackrock
  // filterArray.push("NOT company.name: BlackRock");

  // //Temporary filter out for blackrock
  // filterArray.push("NOT company.name: BlackRock");

  if (interactionFilter !== "") {
    filterArray.push("participatingInteractionTypes:" + interactionFilter);
  }
  if (genderFilter !== "") {
    filterArray.push("gender:" + genderFilter);
  }

  if (sectorFilter !== "") {
    if (sectorFilter?.includes(" ")) {
      filterArray.push(`sectors.name: "${sectorFilter}"`);
    } else {
      filterArray.push(`sectors.name: ${sectorFilter}`);
    }
  }
  if (companyFilter !== "") {
    if (companyFilter?.includes(" ")) {
      filterArray.push(`company.name: "${companyFilter}"`);
    } else {
      filterArray.push(`company.name: ${companyFilter}`);
    }
  }
  if (universityFilter !== "") {
    if (universityFilter?.includes(" ")) {
      filterArray.push(`university.name: "${universityFilter}"`);
    } else {
      filterArray.push(`university.name: ${universityFilter}`);
    }
  }
  if (regionFilter !== "") {
    if (regionFilter?.includes(" ")) {
      filterArray.push(`region.name: "${regionFilter}"`);
    } else {
      filterArray.push(`region.name: ${regionFilter}`);
    }
  }

  if (filterArray.length === 0) return "";

  if (filterArray.length === 1) return filterArray[0];

  const filter = filterArray.join(" AND ");

  return filter;
};
