import { Button } from "@mui/material";
import { Message, MessageType } from "common/types";
import { setPromptModal } from "features/auth/slice";
import { selectActiveInteractionId, selectInteractions } from "features/interactions/selectors";
import { setMessageSendInProgress } from "features/interactions/slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const NoShowPromptModal = () => {
  const dispatch = useDispatch();
  const interactions = useSelector(selectInteractions);
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setActiveInteraction(interactions?.filter((interaction: any) => interaction.id === activeInteractionId).pop());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactions]);

  const handleNoShow = () => {
    setIsDisabled(true);
    dispatch(setMessageSendInProgress(true));
    const message: Partial<Message> = {
      type: MessageType.NO_SHOW,
      payload: null,
    };
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        activeInteractionId: activeInteraction?.id,
        message,
      },
    });
  }
  return (
    <ModalContainer>
      <p className="noshow-modal-text font-family-nunito">
        Do you want to mark this interaction as No Show?
      </p>
      <div className="modal-button-container">
        <Button
          variant="outlined"
          color="primary"
          className="cancel-noshow-button font-family-nunito"
          size="large"
          onClick={() => dispatch(setPromptModal({ isOpen: false, ModalType: null }))}
          disabled={isDisabled}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="confirm-noshow-button font-family-nunito"
          onClick={handleNoShow}
          disabled={isDisabled}
        >
          Confirm
        </Button>
      </div>
    </ModalContainer>
  );
}

export default NoShowPromptModal;

const ModalContainer = styled.div`
  .modal-button-container {
    display: flex;
    justify-content: space-between;
  }

  .noshow-modal-text {
    color: #464FD0;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .confirm-noshow-button{
    background-color: #464FD0;
    text-transform: initial;
    padding: 8px 70px;
    font-weight: inherit;
  }

  .cancel-noshow-button{
    background-color: #FFFFFF;
    text-transform: initial;
    margin-right: 20px;
    padding: 8px 70px;
    font-weight: inherit;
  }

  @media (max-width:600px){
    .noshow-modal-text {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }
`;