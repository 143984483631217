import { Grid, IconButton } from '@mui/material';
import { useState, useEffect } from "react";
import TextField, { InputType } from "common/components/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import MonthYearPicker from 'features/interactions/inputWidgets/MonthYearPicker';

type Props = {
  deleteFromEmployerList: (index: number) => void;
  updateToEmployerList: (value: any, index: number) => void;
  value: any;
  index: number;
  register?: any;
  errors?: any;
}

const EmploymentHistory = ({ deleteFromEmployerList, updateToEmployerList, value, index, register, errors }: Props) => {
  const [company, setCompany] = useState<string>(value && value.company && value.company);
  const [jobTitle, setJobTitle] = useState<string>(value && value.jobTitle && value.jobTitle);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    updateToEmployerList({ company, jobTitle, startDate, endDate }, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, jobTitle, startDate, endDate]);

  useEffect(() => {
    if (value && value.company) {
      setCompany(value.company);
    }
    if (value && value.jobTitle) {
      setJobTitle(value.jobTitle);
    }
    if (value && value?.startDate) {
      setStartDate(value?.startDate);
    }
    if (value && value?.endDate) {
      setEndDate(value?.endDate);
    }
  }, [value]);

  return <>
    <Grid className="mb-0 month-picker" item lg={12} container spacing={1}>
      <Grid className="flex-end top-bottom-spacing" item xs={12} sm={8} md={4}>
        <TextField
          name={`employmentHistory.company${index}`}
          type={InputType.TEXT}
          inputRef={register}
          label="Company"
          handleChange={({ target }: any) => setCompany(target.value)}
          defaultValue={company}
          required={!!jobTitle || !!startDate || !!endDate}
        />
      </Grid>
      <Grid className="flex-end top-bottom-spacing" item xs={12} sm={8} md={4}>
        <TextField
          name={`employmentHistory.jobTitle${index}`}
          type={InputType.TEXT}
          inputRef={register}
          label="Job Title"
          handleChange={({ target }: any) => setJobTitle(target.value)}
          defaultValue={jobTitle}
          required={!!company}
        />
      </Grid>
    </Grid>
    <Grid className="mb-0" item lg={12} container spacing={1}>
      <Grid className="flex-end top-bottom-spacing" item xs={12} sm={8} md={4}>
        <MonthYearPicker
          name={`employmentHistory.startDate${index}`}
          value={startDate}
          onChange={(date: Date) => setStartDate(date)}
          maxDate={new Date()}
          placeholder="Select start date"
          required={!!company || !!jobTitle}
        />
      </Grid>
      {startDate &&
        <Grid className="flex-end top-bottom-spacing" item xs={12} sm={8} md={4}>
          <MonthYearPicker
            name={`employmentHistory.endDate${index}`}
            value={endDate}
            onChange={(date: Date) => setEndDate(date)}
            minDate={new Date(startDate)}
            maxDate={new Date()}
            placeholder="Select end date"
            required={!!company || !!jobTitle || !!startDate}
          />
        </Grid>}
      <Grid item className="d-flex" xs={12} md={2}>
        <IconButton
          onClick={() => deleteFromEmployerList(index)}
          color="secondary"
          aria-label="delete"
          size="large">
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  </>;
}

export default EmploymentHistory;
