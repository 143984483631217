import qs from "query-string";
import { useEffect, useState } from "react";
import firebase from "app/firebase";
import Loading from "common/components/Loading";
import { Button, Grid } from "@mui/material";
import { TextField } from "@mui/material";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { showToast, ToastMessage, ToastType } from "features/toast/slice";
import Logo from "common/assets/images/blue_logo.svg";

enum State {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

enum Mode {
  VERIFY_EMAIL = "VERIFY_EMAIL",
  PASSWORD_RESET = "PASSWORD_RESET",
}

type SuccessProps = {
  mode: Mode;
};

const StyledWrapper = styled.div`
  padding: 3rem;

  .submit {
    margin: 20px 0px;
  }

  .text-center {
    text-align: center;
  }
`;

const Error = () => {
  return (
    <div>
      <h3>Sorry, something went wrong!</h3>
      <p>
        Please retry the link you received via email or write to&nbsp;
        <a href="mailto:smn@upreach.org.uk">smn@upreach.org.uk</a> for support
      </p>
    </div>
  );
};

const Success = ({ mode }: SuccessProps) => {
  return (
    <div className="text-center">
      {mode === Mode.PASSWORD_RESET && (
        <h3>Your password has been succesfully reset!</h3>
      )}
      {mode === Mode.VERIFY_EMAIL && (
        <h3>Your email address has been successfully verified!</h3>
      )}
      {process.env.REACT_APP_SELF_URL && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (process.env.REACT_APP_SELF_URL)
              window.location.href = process.env.REACT_APP_SELF_URL;
          }}
        >
          Go back to the SMN app
        </Button>
      )}
    </div>
  );
};

const EmailHandler = () => {
  const params = qs.parse(window.location.search);
  const [state, setState] = useState<State | null>();
  const [mode, setMode] = useState<Mode | null>(null);
  const [actionCode, setActionCode] = useState<any>(null);

  useEffect(() => {
    const handleAction = () => {
      switch (params.mode) {
        case "verifyEmail":
          setMode(Mode.VERIFY_EMAIL);
          break;

        case "resetPassword":
          setMode(Mode.PASSWORD_RESET);
          break;
        default:
          setState(State.ERROR);
      }
    };
    if (params && params.mode && params.oobCode) {
      setActionCode(params.oobCode);
      handleAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mode) return null;

  if (state === State.ERROR) return <Error />;

  return (
    <StyledWrapper>
      <Grid container item xs={12} justifyContent="center" alignContent="center">
        <Grid container item justifyContent="space-evenly">
          <img src={Logo} alt="" />
        </Grid>
        <Grid container item justifyContent="center">
          {mode === Mode.PASSWORD_RESET ? (
            <PasswordReset actionCode={actionCode} />
          ) : (
            <VerifyEmail actionCode={actionCode} />
          )}
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};

type Props = {
  actionCode: string;
};

const VerifyEmail = ({ actionCode }: Props) => {
  const [state, setState] = useState<State | null>(null);
  const auth = firebase.auth();

  useEffect(() => {
    const handleVerifyEmail = () => {
      setState(State.LOADING);
      auth
        .applyActionCode(actionCode)
        .then(() => {
          if (process.env.REACT_APP_SELF_URL) {
            window.location.href = process.env.REACT_APP_SELF_URL;
          }
        })
        .catch((error: any) => {
          setState(State.ERROR);
          console.error(error);
        });
    };
    handleVerifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state === State.LOADING) return <Loading />;

  if (state === State.ERROR) return <Error />;

  return <Success mode={Mode.VERIFY_EMAIL} />;
};

const PasswordReset = ({ actionCode }: Props) => {
  const auth = firebase.auth();
  const [state, setState] = useState<State | null>(null);
  const [accountEmail, setAccountEmail] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyPasswordResetCode = () => {
      auth
        .verifyPasswordResetCode(actionCode)
        .then((email: string) => {
          setAccountEmail(email);
        })
        .catch((error: any) => {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: error.message,
          };
          dispatch(showToast(toast));
          setState(State.ERROR);
          console.error(error);
        });
    };
    verifyPasswordResetCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitNewPassword = () => {
    auth
      .confirmPasswordReset(actionCode, newPassword)
      .then((resp: any) => {
        setState(State.SUCCESS);
      })
      .catch((error: any) => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: error.message,
        };
        dispatch(showToast(toast));
        console.error(error);
      });
  };

  if (!actionCode) return null;

  if (state === State.LOADING) return <Loading />;

  if (state === State.ERROR) return <Error />;

  if (state === State.SUCCESS) return <Success mode={Mode.PASSWORD_RESET} />;

  return (
    <Grid item spacing={4}>
      <Grid item xs={12}>
        <h3>Reset your password for {accountEmail}</h3>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <TextField
            variant="filled"
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} direction="row" alignContent="center">
          <Button onClick={() => setShowPassword(!showPassword)} size="small">
            {showPassword ? "hide" : "show"}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Button
          className="submit"
          onClick={submitNewPassword}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmailHandler;
