import { useEffect } from "react";
// import styled from "styled-components";
import { Grid, Button, Typography } from "@mui/material";
import Logo from "common/assets/images/blue_logo.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import TextField, { InputType } from "common/components/TextField";
import {
  selectRegistrationFormWorking,
  selectUser,
} from "features/auth/selectors";
import { toggleRegistrationFormWorking } from "features/auth/slice";
import PreLoginHeader from "components/login/PreLoginHeader";
import styled from "@emotion/styled";

type TypeformUserRegistrationFormType = {
  email: string;
  password: string;
  confirmPassword: string;
};

const AccountCreationForm = () => {
  const registrationFormWorking = useSelector(selectRegistrationFormWorking);
  const {
    register,
    handleSubmit,
    errors,
    watch,
  } = useForm<TypeformUserRegistrationFormType>();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = (data: TypeformUserRegistrationFormType) => {
    const { email, password } = data;
    dispatch(toggleRegistrationFormWorking());
    dispatch({
      type: "TYPEFORM_REGISTRATION_ATTEMPT",
      payload: { email, password },
    });
  };

  return <>
  <PreLoginHeader />
  <StyledWrapper onSubmit={handleSubmit(onSubmit)}>
    <div className="register-form">
    <Grid
      spacing={3}
      container
      item
      xs={10}
      sm={6}
      md={4}
      lg={3}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Grid container item justifyContent="space-evenly">
        <img src={Logo} alt="" />
      </Grid>

      <Grid container item>
        <TextField
          name="email"
          type={InputType.TEXT}
          label="email"
          inputRef={register({
            required: "This field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Please enter a valid email address",
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      </Grid>
      <Grid container item>
        <TextField
          name="password"
          type={InputType.PASSWORD}
          label="create password"
          inputRef={register({
            required: "This field is required",
            minLength: {
              value: 6,
              message: "Password should contain atleast 6 characters",
            },
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </Grid>
      <Grid container item>
        <TextField
          name="confirmPassword"
          type={InputType.PASSWORD}
          label="confirm password"
          inputRef={register({
            required: "This field is required",
            validate: (value) =>
              value === watch("password") || "Passwords don't match",
          })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
        />
      </Grid>
      <Grid container item>
        <Button
          variant="contained"
          size="large"
          color="primary"
          type="submit"
          disabled={registrationFormWorking}
          fullWidth
        >
          {registrationFormWorking ? "Registering..." : "Register"}
        </Button>
      </Grid>

      <Grid container item justifyContent="space-evenly">
        <Typography variant="body1">
          Already have an account? <Link to="/welcome">Login</Link>
        </Typography>
      </Grid>
    </Grid>
    </div>
  </StyledWrapper>
  </>;
};

const StyledWrapper = styled.form`

.register-form {
  min-height: calc(100vh - 87px);
  display: flex;
  align-items: center;
  justify-content: center;
}

  button {
    padding: 0.75rem;
    margin: 1rem 3rem;
  }
  img {
    margin: 1rem 3rem;
  }
`;

export default AccountCreationForm;
