import firebase from "app/firebase";
import { getAxiosInstance } from "../../common/utils";
import { v4 as uuidv4 } from "uuid";
import { UserType } from "common/types";

export const createFirebaseUser = async (email: string, password: string) => {
  try {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    return response;
  } catch (error) {
    return error;
  }
};

export const sendVerificationEmail = async () => {
  await firebase.auth().currentUser?.sendEmailVerification();
};

export const getUserProfile = async (id: string): Promise<any> => {
  const axios = await getAxiosInstance();
  try {
    const { data } = await axios.get(`/users/fetchUserProfile?id=${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const performOnLoginSync = async (id: string, email: string) => {
  const axios = await getAxiosInstance();
  const { data } = await axios.post(`/users/onLoginSync`, {
    id,
    email,
  });
  return data;
};

export const isCompleteProfile = async (userProfile: any) => {
  const professionalUser = [
    "firstName",
    "lastName",
    "email",
    "gender",
    "participatingInteractionTypes",
    "preferredVideoConferencing",
    "company",
    "jobTitle",
    "sectors",
  ]

  if (userProfile.roles.includes(UserType.STUDENT) ||
    userProfile.roles.includes(UserType.ALUMNI)) return true;

  if (userProfile.roles.includes(UserType.PROFESSIONAL)) {
    for (const field of professionalUser) {
      if (userProfile[field] === undefined || userProfile[field] === "" || (Array.isArray(userProfile[field] && userProfile[field].length === 0))) {
        return false;
      }
    }
    return true;
  }
};

export const initiateLinkedInLogin = () => {
  const OAUTH_SCOPES = process.env.REACT_APP_LINKEDIN_OAUTH_SCOPES;
  const CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const LINKEDIN_REDIRECT_URL = `${process.env.REACT_APP_SELF_URL}/associates/login`;
  const state = uuidv4();
  sessionStorage.setItem("state", state);

  const url =
    `https://www.linkedin.com/oauth/v2/authorization?&response_type=code` +
    `&client_id=${CLIENT_ID}` +
    `&state=${state}` +
    `&scope=${OAUTH_SCOPES}` +
    `&redirect_uri=${encodeURIComponent(LINKEDIN_REDIRECT_URL)}`;

  window.location.href = url;
};

/*
  Post LinkedIn login, the user is redirected back to the /login route

  We differentiate a user being redirected by LinkedIn to /login vs a user
  explicitly going to the /login by the presence of LinkedIn URL parameters 

  This function checks for these parameters and returns a boolean
  (i.e. whether or not the user is midway through LinkedIn login) 
*/
export const linkedInLoginUnderway = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const state = params.get("state");
  const sessionState = sessionStorage.getItem("state");
  return code && state && sessionState && state === sessionState;
};

export const fetchLinkedInToken = async () => {
  const axios = await getAxiosInstance();
  const response = await axios.get(
    "/auth/linkedInLogin" + window.location.search
  );
  return response.data.token;
};

export const loginWithCustomToken = async (token: string) =>
  await firebase.auth().signInWithCustomToken(token);
