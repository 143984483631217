import axios from "axios";
import { getIdToken } from ".";

const getAxiosInstance = async () => {
  const token = await getIdToken();
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default getAxiosInstance;
