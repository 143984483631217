import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailChoices, GenderChoices, Student, UserType } from "common/types/";

export type BasicDetails = Pick<
  Student,
  | "id"
  | "firstName"
  | "lastName"
  | "preferredName"
  | "email"
  | "associateStatus"
  | "personalEmail"
  | "preferredEmail"
  | "gender"
  | "region"
  | "ethnicity"
  | "bio"
  | "profilePicture"
  | "disadvantageIndicators"
  | "phoneNumber"
  | "programType"
  | "dateOfBirth"
  | "roles"
  | "linkedinUrl"
  | "householdIncome"
  | "disability"
>;

export type UniversityDetails = Pick<
  Student,
  | "university"
  | "universityCourse"
  | "degreeType"
  | "graduationYear"
  | "yearOfStudy"
  | "careerInterests"
  | "careerStream"
  | "aLevelGrades"
  | "realGrades"
  | "courseCategory"
  | "gefResult"
>;


export type StudentRegistrationType = {
  activeStep: number;
  basicDetails: BasicDetails;
  universityDetails: UniversityDetails;
  metadata: any;
};

export const initialState: StudentRegistrationType = {
  activeStep: 0,
  basicDetails: {
    id: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    email: "",
    personalEmail: "",
    preferredEmail: EmailChoices.UNIVERSITY_EMAIL,
    associateStatus: "",
    gender: GenderChoices.NOT_SPECIFIED,
    bio: "",
    region: {
      id: "",
      name: "",
    },
    ethnicity: {
      id: "",
      name: "",
    },
    profilePicture: "",
    programType: "",
    dateOfBirth: "",
    phoneNumber: "",
    disadvantageIndicators: [],
    roles: [UserType.STUDENT],
    linkedinUrl: "",
    householdIncome: "",
    disability: false,
  },
  universityDetails: {
    university: {
      id: "",
      name: "",
    },
    universityCourse: {
      id: "",
      name: "",
    },
    courseCategory: {
      id: "",
      name: "",
    },
    degreeType: {
      id: "",
      name: "",
    },
    careerStream: {
      id: "",
      name: "",
    },
    careerInterests: [],
    graduationYear: "",
    yearOfStudy: 1,
    aLevelGrades: "",
    realGrades: "",
    gefResult: ""
  },
  metadata: {
    profileDataVisibility: {
      ethnicity: false,
      gender: false,
      disadvantageIndicators: false,
      region: true,
      householdIncome: false,
      aLevelGrades: true,
      realGrades: true,
    },
    emails: {
      receiveDailyEmail: true,
      receiveRequestEmail: true,
      receiveNewMessageEmail: true,
      receiveReminderEmail: true,
      receiveFeedbackEmail: true,
    },
    isGhost: false,
  },
};

const slice = createSlice({
  name: "studentRegistration",
  initialState,
  reducers: {
    goToNextStep: (state) => {
      window.scrollTo(0, 0);
      state.activeStep += 1;
    },
    goToPrevStep: (state) => {
      state.activeStep -= 1;
    },
    setBasicDetails: (state, action: PayloadAction<any>) => {
      state.basicDetails = action.payload;
    },
    setUniversityDetails: (state, action: PayloadAction<any>) => {
      state.universityDetails = action.payload;
    },
    setMetadata: (state, action: PayloadAction<any>) => {
      state.metadata = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<any>) => {
      state.activeStep = action.payload;
    },
  },
});

export const {
  goToNextStep,
  goToPrevStep,
  setBasicDetails,
  setUniversityDetails,
  setMetadata,
  setActiveStep,
} = slice.actions;

export default slice.reducer;
