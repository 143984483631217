import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import MuiStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styled from "@emotion/styled";
// import styled from "styled-components";

type Props = {
  steps: Array<any>;
  activeStep: number;
};

const Stepper = ({ steps, activeStep }: Props) => {
  const classes = useStyles();

  return (
    <StyledWrapper className={`stepper-container ${classes.root}`}>
      <MuiStepper className="stepper" activeStep={activeStep}>
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel className="step-label" {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </MuiStepper>
    </StyledWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "30px",
      marginBottom: "30px",
    },
    button: {
      marginRight: useTheme().spacing(1),
    },
    instructions: {
      marginTop: useTheme().spacing(1),
      marginBottom: useTheme().spacing(1),
    },
  })
);

export default Stepper;

const StyledWrapper = styled.div`
.stepper-container, .stepper {
  background-color: #F6FAFF !important;
}

.stepper {
  @media (max-width: 600px) {
   padding: 0;
  }
}

.MuiStepLabel-labelContainer .MuiTypography-body2{
  font-size: 12px;
}
`;