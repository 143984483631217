import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from "@mui/icons-material/Send";
import { Children } from "react";
import { useDispatch } from "react-redux";
// import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { InteractionType } from "common/types";
import DateTimePicker from "./inputWidgets/DateTimePicker";
import styled from "@emotion/styled";

const writingTips = [
  "Be brief and to the point. Avoid long messages.",
  "Write your message as if it is an email.",
  "Remain professional at all times.",
  "Avoid sending multiple messages before you've received a response, the professional will get back to you when they can.",
];

type PropsType = {
  interactionRequestModelOpen: any;
  setInteractionRequestModelOpen: any;
  interactionType: any;
  message: string;
  setMessage: any;
  sendRequest: any;
  isButtonDisabled: boolean;
  expectedInterviewDate: Date | null;
  setExpectedInterviewDate: any;
};

const RequestInteractionPopup = ({
  interactionRequestModelOpen,
  setInteractionRequestModelOpen,
  interactionType,
  message,
  setMessage,
  sendRequest,
  isButtonDisabled,
  expectedInterviewDate,
  setExpectedInterviewDate,
}: PropsType) => {
  const dispatch = useDispatch();
  const classes = useStyle();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={interactionRequestModelOpen}
      onClose={() => dispatch(setInteractionRequestModelOpen(false))}
    >
      <DialogTitle className="text-capitalize request-modal-header">
        <StyledHeader className={classes.heading}>
          Request {interactionType?.toLowerCase().replace("_", " ")}
          <button
            onClick={() => {
              dispatch(setInteractionRequestModelOpen(false));
              setExpectedInterviewDate(null);
            }}
            className={classes.button}
          >
            Close
          </button>
        </StyledHeader>
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="4"
          variant="filled"
          className={`request-input background-white-smoke request-modal-input ${classes.input}`}
          placeholder="Write here"
          InputProps={{ disableUnderline: true }}
        />
      </DialogContent>
      {interactionType === InteractionType.MOCK_INTERVIEW && (
        <StyledDatePickerContainer>
          <Typography variant="subtitle2" className={classes.interviewDatePickerText}>
            EXPECTED DATE OF INTERVIEW
          </Typography>
          <DateTimePicker
            value={expectedInterviewDate}
            onChange={(date: Date) => setExpectedInterviewDate(date as Date)}
            showTimeSelect={false}
            dateFormat={"dd/MM/yyyy"}
            placeholder={"Select Date"}
          />
        </StyledDatePickerContainer>
      )}
      <DialogActions className="action-section">
        <Button
          disabled={
            message === "" ||
            isButtonDisabled
          }
          
          variant="contained"
          color="primary"
          onClick={sendRequest}
        >
          Send
          <SendIcon className={classes.left} />
        </Button>
      </DialogActions>
      <DialogContent className={classes.marginTop}>
        <Typography variant="subtitle2" className={classes.heading}>
          <strong>WRITING TIPS</strong>
        </Typography>
        <List className={classes.list}>
          {Children.toArray(
            writingTips.map((tip) => (
              <ListItem className={classes.smallText}>
                <Typography variant="body2" className={classes.heading}>
                  - {` `}
                  {tip}
                </Typography>
              </ListItem>
            ))
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default RequestInteractionPopup;

const useStyle = makeStyles(() => ({
  smallText: {
    fontSize: "0.8em",
    paddingBottom: "0 !important",
    fontFamily: 'Nunito Sans !important',
  },
  marginTop: {
    marginTop: "1em",
  },
  left: {
    marginLeft: "0.3em",
  },
  heading: {
    fontFamily: 'Nunito Sans',
  },
  list: {
    fontFamily: 'Nunito Sans',
  },
  input: {
    "&::placeholder": {
      fontFamily: 'Nunito Sans !important',
    },
    "&-webkit-input-placeholder": {
      fontFamily: 'Nunito Sans !important',
    },
  },
  button: {
    border: "0px",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "#3f51b5",
    fontSize: "0.9em",
    fontFamily: 'Nunito Sans',
  },
  interviewDatePickerText: {
    marginLeft: '30px',
    fontWeight: "bold",
    marginRight: "1em",
  },
}));

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledDatePickerContainer = styled.div`
  display: flex;
  width: 97%;
  justify-content: flex-end;
  margin-top: 2em;
  margin-bottom: 1em;
  align-items: center;
`;
