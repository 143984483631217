import { Message } from "common/types";
import { selectUser } from "features/auth/selectors";
import { User} from "common/types";
import { useSelector } from "react-redux";
type Props = {
  message: Message;
};

const RescheduleDeclineMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if(message.sender === "SYSTEM") return null;
  return (
    <div className="color-gulf-blue body-17 font-weight-bolder">
      <span >{user?.id === message.sender.id ? "You have" : message.sender.firstName+" has"}</span>
      <span>&nbsp;declined the request to reschedule.</span>
    </div>
  )
}

export default RescheduleDeclineMessage;