import { selectOnLoginRedirectRoute } from "features/auth/selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "app/firebase";
import { logout, setAuthUser, setFirstLoad, setIsLoading, setOnLoginRedirectRoute } from "features/auth/slice";

const AuthGateway = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const onLoginRedirectRoute: string | null = useSelector(
    selectOnLoginRedirectRoute
  );

  useEffect(() => {
    setOnLoginRedirectRoute(location.pathname)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          setAuthUser({
            id: user.uid,
            email: user.email ?? '',
            emailVerified: user.emailVerified,
          })
        );
        dispatch({
          type: "TRIGGER_ON_LOGIN_SYNC",
          payload: { id: user.uid, email: user.email },
        });
      } else {
        dispatch(logout());
        dispatch(setAuthUser(null));
        dispatch(setFirstLoad(false));
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "HANDLE_LINKEDIN_LOGIN" });
    setIsLoading(false);
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onLoginRedirectRoute) {
      history.push(onLoginRedirectRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoginRedirectRoute]);

  return null;
};

export default AuthGateway;
