import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  message?: string;
}

const Loading = ({ message }: Props) => {
  return (
    <StyledWrapper>
      <CircularProgress className="no-user-text" />
      {message &&
        <div>
          <p className="body-16">{message}</p>
        </div>}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2000;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: 200px;
  }
`;

export default Loading;
