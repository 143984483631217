import { Button, Grid } from "@mui/material";
import { useState } from "react";
import DateTimePicker from "./DateTimePicker";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageSendInProgress, selectActiveInteractionId } from "../selectors";
import { Message, MessageType } from "common/types";
import { setMessageSendInProgress } from "../slice";
import recordEvent from "common/utils/mixpanel";
import { selectUser } from "features/auth/selectors";

const TimeSuggestionInput = () => {
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [date3, setDate3] = useState();
  const [isSameDates, setIsSameDates] = useState(false);
  const [isDatesNull, setIsDatesNull] = useState(false);
  const dispatch = useDispatch();
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const isDisabled = useSelector(selectMessageSendInProgress);
  const user: any = useSelector(selectUser);

  const checkSelectedDateMatch = (time: any) => {
    return (
      time[0].toString() === time[1].toString() ||
      time[1].toString() === time[2].toString() ||
      time[0].toString() === time[2].toString()
    );
  };

  const checkNullDate = (time: any) => {
    return time[0] != null && time[1] != null && time[2] != null;
  };

  const handleClick = (time: any) => {
    const isNotNull = checkNullDate(time);
    if (isNotNull) {
      setIsDatesNull(false);
      setIsSameDates(false);
      const isDateSelectionSame = checkSelectedDateMatch(time);
      if (!isDateSelectionSame) {
        const dateArray = time.map((el: Date) => el.toString());
        recordEvent("Availability Selected For Professional", {
          "Date Selected": dateArray,
          "Professional Sector": user?.sectors,
          "Professional Company": user?.company.name,
          "Professional Job Title": user?.jobTitle,
          "Professional University": user?.university?.name,
          "Professional University Course": user?.universityCourse,
        });
        setIsSameDates(false);
        dispatch(setMessageSendInProgress(true));
        const message: Partial<Message> = {
          type: MessageType.SUGGEST_TIMES,
          payload: time,
        };
        dispatch({
          type: "SEND_MESSAGE",
          payload: {
            activeInteractionId,
            message,
          },
        });
      } else {
        setIsSameDates(true);
      }
    } else {
      setIsDatesNull(true);
    }
  };

  return (
    <StyledWrapper>
      <Grid container>
        <Grid className="important-info" item xs={12} sm={12} lg={12}>
          <p>
            Please choose 3 convenient meeting slots that are available on your calendar. The
            student has 48 hours to respond.
          </p>
        </Grid>
        <Grid className="padding-lr" item xs={12} sm={4}>
          <DateTimePicker value={date1} onChange={setDate1} />
        </Grid>
        <Grid className="padding-lr" item xs={12} sm={4}>
          <DateTimePicker value={date2} onChange={setDate2} />
        </Grid>
        <Grid className="padding-lr" item xs={12} sm={4}>
          <DateTimePicker value={date3} onChange={setDate3} />
        </Grid>
        {isSameDates && (
          <Grid item xs={12}>
            <p className="rhf-errors">
              Please ensure that all three meeting slots you're sending are different.
            </p>
          </Grid>
        )}

        {isDatesNull && (
          <Grid item xs={12}>
            <p className="rhf-errors">Please select all dates and time.</p>
          </Grid>
        )}
        <Grid item xs={12} lg={4}>
          <Button
            disabled={isDisabled}
            variant="contained"
            color="primary"
            className="send-btn"
            onClick={() => handleClick([date1, date2, date3])}
          >
            Send Availability
          </Button>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};

export default TimeSuggestionInput;

const StyledWrapper = styled.div`
  .date-time-section-section {
    display: flex;
    justify-content: space-around;
  }

  .send-btn {
    height: 45px;
    text-transform: capitalize;
    background-color : #3f51b5;
  }

  .date-time-input {
    background: rgba(158, 165, 238, 0.1);
    margin-bottom: 20px !important;
    margin-right: 20px;
    .MuiOutlinedInput-input {
      height: 15px;
      border: 1px solid rgba(158, 165, 238, 0.2);
      color: #304563;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      border-right: none;
    }
  }

  .padding-lr {
    padding-right: 10px;
    padding-bottom: 20px;
    font-size: 14px;
  }

  .calendar-icon {
    margin-left: 10px;
    color : #3f51b5 !important;
  }

  .react-datepicker__header {
    background-color: rgb(241, 242, 255);
    border-bottom: 1px solid #d5d8fc;
  }

  .react-datepicker {
    border: 1px solid #d5d8fc;
  }

  .react-datepicker__time-container {
    border-left: 1px solid #d5d8fc;
  }
`;
