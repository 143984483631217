import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { setPromptModal } from "features/auth/slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "features/auth/selectors";
import { selectFilteredProfessionals } from "features/network/selectors";
import recordEvent from "common/utils/mixpanel";
import { searchProfessionals } from "features/network/searchUtils";
import { Professional } from "common/types";
import Carousel from "components/suggestedProfessionals/suggestedProfessionalCarousel";
import SuggestedProfessionalProfile from "components/suggestedProfessionals/suggestedProfessionalProfile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestions: {
      height: "auto",
    },
    close__btn: {
      position: "absolute",
      top: "15px",
      right: "15px",
    },
    back__btn: {
      position: "absolute",
      top: "15px",
      left: "15px",
    },
    content: {
      padding: ".75rem",
      margin: "10px 20px 20px 20px",
    },
    heading: {
      fontSize: "24px",
      color: "#304563",
      fontWeight: 400,
      lineHeight: "30px",
      [useTheme().breakpoints.down('sm')]: {
        fontSize: "20px",
        lineHeight: "20px",
      },
    },
    sub__heading: {
      fontSize: "16px",
      fontWeight: 500,
    },
    slider: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // overflow: 'scroll'
    },
    left__arrow: {
      position: "absolute",
      top: "50%",
      left: "32px",
      zIndex: 10,
      cursor: "pointer",

      [useTheme().breakpoints.down('xl')]: {
        left: "12px",
      },
      [useTheme().breakpoints.down('sm')]: {
        top: "-10%",
        left: "40%",
        transform: "translate(-50%, -50%)",
      },
    },
    right__arrow: {
      position: "absolute",
      top: "50%",
      right: "32px",
      zIndex: 10,
      cursor: "pointer",

      [useTheme().breakpoints.down('xl')]: {
        right: "12px",
      },
      [useTheme().breakpoints.down('sm')]: {
        top: "-10%",
        right: "40%",
        transform: "translate(-50%, -50%)",
      },
    },
    slide: {
      opacity: 0,
      transitionDuration: "1s ease",
      maxWidth: "300px",
      marginRight: "17.5px",
    },
    active: {
      opacity: 1,
      transitionDuration: "1s",
    },
    profile__div: {
      textAlign: "center",
    },
    profile__avatar: {
      display: "inline-block",
      width: "100px",
      height: "100px",
      marginBottom: "5px",
    },
    profile__details: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const SuggestedProfessionalsModal = () => {
  const filteredProfessionals: any[] = useSelector(selectFilteredProfessionals);
  const user: any = useSelector(selectUser);

  const [current, setCurrent] = useState<number>(0);
  //   const [loading, setLoading] = useState<boolean>(false);
  const [suggestedProfessionals, setSuggestedProfessionals] = useState<any[]>(
    []
  );
  const [suggestedProfessionalDetails, setSuggestedProfessionalDetails] =
    useState<Professional | null>(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const currentId = window.location.pathname.split("/")[2];
    const suggestedProfessionals = filteredProfessionals.filter(
      (professional) => professional.id !== currentId
    );
    if (suggestedProfessionals.length === 0) {
      searchProfessionals("", "", 0, false, "TOTAL_INTERACTIONS", "ASC").then(
        (searchResults) => {
          setSuggestedProfessionals(searchResults?.searchResults);
        }
      );
    } else {
      setSuggestedProfessionals(suggestedProfessionals);
    }
  }, [filteredProfessionals]);

  const handleButtonClick = (type: string) => {
    if (type === "close")
      dispatch(
        setPromptModal({ isOpen: false, ModalType: "SUGGEST_PROFESSIONALS" })
      );
    else setSuggestedProfessionalDetails(null);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? suggestedProfessionals.length - 1 : current - 1);
  };

  const nextSlide = () => {
    setCurrent(current === suggestedProfessionals.length - 1 ? 0 : current + 1);
  };

  const handleShowProfile = (id: string) => {
    const selectedUser = suggestedProfessionals.find(
      (professional) => professional.id === id
    );
    setSuggestedProfessionalDetails(selectedUser);
  };

  const handleViewProfileClicked = () => {
    recordEvent("View Profile Click", {
      "Professionals Name": `${suggestedProfessionalDetails?.firstName} ${suggestedProfessionalDetails?.lastName}`,
      "Professional Sector": suggestedProfessionalDetails?.sectors,
      "Professional Company": suggestedProfessionalDetails?.company?.name,
      "Professional Job Title": suggestedProfessionalDetails?.jobTitle,
      "Professional University": suggestedProfessionalDetails?.university?.name,
      "Professional Preferred Interaction Type":
        suggestedProfessionalDetails?.participatingInteractionTypes,
      "Student Gender": user.gender,
      "Student Region": user.region?.name,
      "Student Ethnicity": user.ethnicity?.name,
      "Student Year Of Graduation": user.graduationYear,
      "Student Age":
        new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear(),
      "User Roles": user.roles,
    });
    dispatch(
      setPromptModal({ isOpen: false, ModalType: "SUGGEST_PROFESSIONALS" })
    );
  };

  return suggestedProfessionalDetails === null ? (
    <Carousel
      classes={classes}
      handleButtonClick={handleButtonClick}
      handleShowProfile={handleShowProfile}
      prevSlide={prevSlide}
      nextSlide={nextSlide}
      current={current}
      suggestedProfessionals={suggestedProfessionals}
    />
  ) : (
    <SuggestedProfessionalProfile
      classes={classes}
      handleButtonClick={handleButtonClick}
      handleViewProfileClicked={handleViewProfileClicked}
      suggestedProfessionalDetails={suggestedProfessionalDetails}
    />
  );
};

export default SuggestedProfessionalsModal;
