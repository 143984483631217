// import { useState } from "react";
import {Message, MessageType, User, UserType } from "common/types";
import styled from "styled-components";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { setMessageSendInProgress } from "../slice";
import { selectUser } from "features/auth/selectors";
import { Grid } from "@mui/material";
// import { useEffect } from "react";
// import { selectActiveInteractionId } from "../selectors";
// import { useState } from "react";

type Props = {
    interactionId: string;
    isSorryModalOpen: boolean;
    setIsSorryModalOpen: any;
    interactionState:any;
};

const SorryModal = ({ interactionId, isSorryModalOpen, setIsSorryModalOpen , interactionState}: Props) => {
    const dispatch = useDispatch();
    // const [thankYouNote, setThankYouNote] = useState<string>('');
    // const isDisabled = useSelector(selectMessageSendInProgress);
    const user: User | null = useSelector(selectUser);
    // const interactions = useSelector(selectInteractions);
    // const activeInteractionId = useSelector(selectActiveInteractionId);
    // const [activeInteraction, setActiveInteraction] = useState<any>();
    // const [count, setCount] = useState<number>(1);
    // const [isSending, setIsSending] = useState(false);
    

    // useEffect(() => {
    //     setActiveInteraction(
    //         interactions
    //             ?.filter((interaction: any) => interaction.id === activeInteractionId)
    //             .pop()
    //     );
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [interactions]);
    
    // const sendMessage = () => {
    //     if (user?.roles.includes(UserType.STUDENT)) {
        
    //     }
    //     if (user?.roles.includes(UserType.PROFESSIONAL)) {

    //     }
    // }
    // const doNotSendMessage = () => {
    //     if (isSending) {
    //         return; // Don't proceed if the process is still ongoing
    //     }
    //     setIsSending(true);
    //     if (user?.roles.includes(UserType.STUDENT)) {
            
    //     }
    //     if (user?.roles.includes(UserType.PROFESSIONAL)) {
            
    //     }
    // }
    const handleClose = () => {
        // dispatch({
        //     type: "INT_NO_SHOW_COMPLETE",
        //     payload: { activeInteractionId },
        //   });
        // setIsSorryModalOpen(false)
        if (user?.roles.includes(UserType.STUDENT)) {
            // dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.STUD_SORRY_MODAL_CLOSE,
                payload: null,
            };
            dispatch({
                type: "SORRY_MODAL_CLOSE",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
        }
        if (user?.roles.includes(UserType.PROFESSIONAL)) {
            // dispatch(setMessageSendInProgress(true));
            const message: Partial<Message> = {
                type: MessageType.PROF_SORRY_MODAL_CLOSE,
                payload: null,
            };
            dispatch({
                type: "SORRY_MODAL_CLOSE",
                payload: {
                    activeInteractionId: interactionId,
                    message,
                },
            })
        }
    }
    return (
        <StyledWrapper>
            <Dialog
                fullWidth
                className="post-interaction-background bubble"
                maxWidth="sm"
                open={isSorryModalOpen}
                // onClose={() => setIsSorryModalOpen(false)}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setIsSorryModalOpen(false);
                    }
                   
                }}
            >
                <Grid container justifyContent="flex-end">
                    <Button variant="text" color="primary" onClick={handleClose} style={{ textTransform: "none" }}>
                        <Grid direction="column" >
                            <Grid item>Close</Grid>
                        </Grid>
                    </Button>
                </Grid>
                <DialogTitle className="p-0 f-23">
                    <Grid className="p-0 f-23 font-family-nunito">
                        We're sorry!
                    </Grid>
                </DialogTitle>
                {user?.roles.includes(UserType.STUDENT) ? (
                <DialogContent>
                    <Grid container direction="row">
                        <Grid item md className ="text-center f-18" >
                                <Grid className="mt-1">
                                    We are really sorry that the professional did not attend your scheduled interaction. We will reach out to them to make sure everything is okay and remind them of our shared expectations.
                                </Grid>
                                <Grid className="mt-3 mb-3">
                                    Whilst we know this can be disappointing, we really encourage you to reach out to other professionals on the platform for advice. If you would like suggestions of who may be a good fit for you, get in touch with the SMN team via the Contact Us tab.
                                </Grid>
                                <Grid className="mt-3 mb-3">
                                    If you would like to request another interaction with this professional, you can do so via their profile.
                                </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>) : (
                    <DialogContent>
                        <Grid container direction="row">
                            <Grid item md className ="text-center f-18">
                                <Grid className="mt-1">
                                    We are really sorry that the student did not attend your scheduled interaction.
                                </Grid>
                                <Grid className="mt-3">
                                    We will follow up with the student to make sure everything is okay and remind them of our shared expectations and the importance of turning up to interactions promptly.                              
                                </Grid>
                                <Grid className="mt-3 mb-3">
                                    We apologise for the inconvenience this may have caused.                              
                                </Grid>
                            </Grid>
                        </Grid>
                </DialogContent>
                )}
            </Dialog>
        </StyledWrapper>
    );
};

export default SorryModal;

const StyledWrapper = styled.div`
  .interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }

  .click-here {
    margin-top: 30px;
  }
  .MuiButton-label {
    display: unset !important;
  }
`;
